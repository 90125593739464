import store from '@/store/index.js'
export default {
    // Capture Add to homescreen
    captureEvent() {
        window.addEventListener("beforeinstallprompt", (e) => {
            // ! Prevent Chrome 67 and earlier from automatically showing the prompt
            e.preventDefault();
            // Stash the event so it can be triggered later.
            store.state.addToHomescreenFunction = e;
        });
    },
    clickCallback() {
        // Show the prompt
        store.state.addToHomescreenFunction.prompt();
        // Wait for the user to respond to the prompt
        store.state.addToHomescreenFunction.userChoice.then((choiceResult) => {
            if (choiceResult.outcome === "accepted") {
                // Call another function?
            }
            store.state.addToHomescreenFunction = null;
        });
    },
}