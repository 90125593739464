<!-- ---------- ---------- ---------- HTML Structure ---------- ---------- ---------- -->
<template>
  <!--Navigation Bar-->
  <div>
    <!--DiscoverView-->
    <div class="navbottomelement" v-on:click="$router.push('/')">
      <div class="top">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          height="22px"
          width="22px"
          fill="#FFFFFF"
          class="svgs"
          v-bind:class="{
            infocus: $router.currentRoute.value.name == 'DiscoverView',
          }"
        >
          <path d="M0 0h24v24H0V0z" fill="none" />
          <path
            d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm-5.5-2.5l7.51-3.49L17.5 6.5 9.99 9.99 6.5 17.5zm5.5-6.6c.61 0 1.1.49 1.1 1.1s-.49 1.1-1.1 1.1-1.1-.49-1.1-1.1.49-1.1 1.1-1.1z"
          />
        </svg>
      </div>
      <div
        class="bottom"
        v-bind:class="{
          infocus: $router.currentRoute.value.name == 'DiscoverView',
        }"
      >
        <span class="textitem" v-if="$store.state.user.countryCode == 'DE'">Entdecken</span>
        <span class="textitem" v-else>Discover</span>
      </div>
    </div>

    <!--DetailsView-->
    <div class="navbottomelement" v-on:click="clickDetails()">
      <div class="top">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          height="24px"
          width="24px"
          fill="#FFFFFF"
          class="svgs"
          v-bind:class="{
            infocus:
              $router.currentRoute.value.name == 'DetailsView' ||
              $router.currentRoute.value.name == 'ActivityListView' ||
              $router.currentRoute.value.name == 'FilterView',
          }"
        >
          <g>
            <rect fill="none" height="24" width="24" />
          </g>
          <g>
            <g />
            <g>
              <path
                d="M12,3c-0.46,0-0.93,0.04-1.4,0.14C7.84,3.67,5.64,5.9,5.12,8.66c-0.48,2.61,0.48,5.01,2.22,6.56 C7.77,15.6,8,16.13,8,16.69V19c0,1.1,0.9,2,2,2h0.28c0.35,0.6,0.98,1,1.72,1s1.38-0.4,1.72-1H14c1.1,0,2-0.9,2-2v-2.31 c0-0.55,0.22-1.09,0.64-1.46C18.09,13.95,19,12.08,19,10C19,6.13,15.87,3,12,3z M14,17h-4v-1h4V17z M10,19v-1h4v1H10z M15.31,13.74c-0.09,0.08-0.16,0.18-0.24,0.26H8.92c-0.08-0.09-0.15-0.19-0.24-0.27c-1.32-1.18-1.91-2.94-1.59-4.7 c0.36-1.94,1.96-3.55,3.89-3.93C11.32,5.03,11.66,5,12,5c2.76,0,5,2.24,5,5C17,11.43,16.39,12.79,15.31,13.74z"
              />
            </g>
            <g>
              <rect height="3" width="1" x="11.5" y="11" />
              <rect
                height="3"
                transform="matrix(0.7071 -0.7071 0.7071 0.7071 -4.0312 10.8536)"
                width="1"
                x="10.59"
                y="8.79"
              />
              <rect
                height="3"
                transform="matrix(-0.7071 -0.7071 0.7071 -0.7071 14.7678 26.7028)"
                width="1"
                x="12.41"
                y="8.79"
              />
            </g>
          </g>
        </svg>
      </div>
      <div
        class="bottom"
        v-bind:class="{
          infocus:
            $router.currentRoute.value.name == 'DetailsView' ||
            $router.currentRoute.value.name == 'ActivityListView' ||
            $router.currentRoute.value.name == 'FilterView',
        }"
      >
        <span class="textitem" v-if="$store.state.user.countryCode == 'DE'">Aktivititäten</span>
        <span class="textitem" v-else>Activities</span>
      </div>
    </div>

    <!--FavoritesView-->
    <div class="navbottomelement" v-on:click="$router.push('/favorites')">
      <div class="top">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          height="22px"
          width="22px"
          fill="#FFFFFF"
          class="svgs"
          v-bind:class="{
            infocus: $router.currentRoute.value.name == 'FavoritesListView',
          }"
        >
          <path d="M0 0h24v24H0V0z" fill="none" />
          <path
            d="M16.5 3c-1.74 0-3.41.81-4.5 2.09C10.91 3.81 9.24 3 7.5 3 4.42 3 2 5.42 2 8.5c0 3.78 3.4 6.86 8.55 11.54L12 21.35l1.45-1.32C18.6 15.36 22 12.28 22 8.5 22 5.42 19.58 3 16.5 3zm-4.4 15.55l-.1.1-.1-.1C7.14 14.24 4 11.39 4 8.5 4 6.5 5.5 5 7.5 5c1.54 0 3.04.99 3.57 2.36h1.87C13.46 5.99 14.96 5 16.5 5c2 0 3.5 1.5 3.5 3.5 0 2.89-3.14 5.74-7.9 10.05z"
          />
        </svg>
      </div>
      <div
        class="bottom"
        v-bind:class="{
          infocus: $router.currentRoute.value.name == 'FavoritesListView',
        }"
      >
        <span class="textitem" v-if="$store.state.user.countryCode == 'DE'">Favoriten</span>
        <span class="textitem" v-else>Favorites</span>
      </div>
    </div>

    <!--SettingsView-->
    <div class="navbottomelement" v-on:click="$router.push('/settings')">
      <div class="top">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          height="24px"
          width="24px"
          fill="#FFFFFF"
          class="svgs"
          v-bind:class="{
            infocus: $router.currentRoute.value.name == 'SettingsView',
          }"
        >
          <g>
            <path d="M0,0h24v24H0V0z" fill="none" />
          </g>
          <g>
            <g>
              <path
                d="M4,18v-0.65c0-0.34,0.16-0.66,0.41-0.81C6.1,15.53,8.03,15,10,15c0.03,0,0.05,0,0.08,0.01c0.1-0.7,0.3-1.37,0.59-1.98 C10.45,13.01,10.23,13,10,13c-2.42,0-4.68,0.67-6.61,1.82C2.51,15.34,2,16.32,2,17.35V20h9.26c-0.42-0.6-0.75-1.28-0.97-2H4z"
              />
              <path
                d="M10,12c2.21,0,4-1.79,4-4s-1.79-4-4-4C7.79,4,6,5.79,6,8S7.79,12,10,12z M10,6c1.1,0,2,0.9,2,2s-0.9,2-2,2 c-1.1,0-2-0.9-2-2S8.9,6,10,6z"
              />
              <path
                d="M20.75,16c0-0.22-0.03-0.42-0.06-0.63l1.14-1.01l-1-1.73l-1.45,0.49c-0.32-0.27-0.68-0.48-1.08-0.63L18,11h-2l-0.3,1.49 c-0.4,0.15-0.76,0.36-1.08,0.63l-1.45-0.49l-1,1.73l1.14,1.01c-0.03,0.21-0.06,0.41-0.06,0.63s0.03,0.42,0.06,0.63l-1.14,1.01 l1,1.73l1.45-0.49c0.32,0.27,0.68,0.48,1.08,0.63L16,21h2l0.3-1.49c0.4-0.15,0.76-0.36,1.08-0.63l1.45,0.49l1-1.73l-1.14-1.01 C20.72,16.42,20.75,16.22,20.75,16z M17,18c-1.1,0-2-0.9-2-2s0.9-2,2-2s2,0.9,2,2S18.1,18,17,18z"
              />
            </g>
          </g>
        </svg>
      </div>
      <div
        class="bottom"
        v-bind:class="{
          infocus: $router.currentRoute.value.name == 'SettingsView',
        }"
      >
        <span class="textitem" v-if="$store.state.user.countryCode == 'DE'">Einstellungen</span>
        <span class="textitem" v-else>Settings</span>
      </div>
    </div>
  </div>
</template>

  <!-- ---------- ---------- ---------- Code ---------- ---------- ---------- -->

<script>
/* eslint-disable */
import TimetipSVG from "@/components/Navigation/TimetipSVG.vue";
import TimetipSVG2 from "@/components/Navigation/TimetipSVG2.vue";
import TimetipSVG3 from "@/components/Navigation/TimetipSVG3.vue";

export default {
  components: { TimetipSVG, TimetipSVG2, TimetipSVG3 },
  data() {
    return {};
  },
  mounted() { },
  methods: {
    clickDetails() {
      if (this.$router.currentRoute.value.name == 'DetailsView') {
        this.$router.push('/activitylist')
      }
      else {
        this.$router.push('/details')
      }
    }
  },
  computed: {},
};
</script>

<style scoped>
.overlay {
  position: absolute;
  top: 0rem;
  left: 10%;
  right: 10%;
  height: 0.2rem;
  background: #20c997;
  border-radius: 0.1rem;
}
.ttcontainer {
  position: relative;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.navbottomelement {
  /* display: inline-flex;
  align-self: center; */
  position: relative;
  height: 100%;
  width: 100%;
  cursor: pointer;
}
.top {
  height: 60%;
  position: relative;
}
.bottom {
  height: 40%;
  font-size: 0.7rem;
  color: #000000;
  display: flex;
  justify-content: center;
}
.textitem {
}
.svgs {
  position: absolute;
  bottom: 45%;
  left: 50%;
  transform: translate(-50%, 50%);
  fill: #000000;
}
.infocus {
  fill: #007bff;
  color: #007bff;
  font-weight: bold;
}

#tabcontainer {
  position: fixed;
  bottom: 0;
  width: 100%;
  overflow: hidden;
  background-color: #ffffff;
  border: none;
  /*border-top: 1px solid #ced4da;*/
  z-index: 90;
}

.tab {
  color: #ffffff;
  background-color: #007bff;
  border: none;
  outline: none;
  cursor: pointer;
  width: 33.3333%;
  vertical-align: middle;
}

.selected1 {
  color: #007bff;
  font-weight: bold;
  animation-name: example;
  animation-duration: 1s;
}
.selected2 {
  color: #007bff;
  font-weight: bold;
  animation-name: example;
  animation-duration: 1s;
}
.selected3 {
  color: #000000;
  font-weight: bold;
  animation-name: example;
  animation-duration: 1s;
}
@keyframes example {
  from {
    background-color: #007bff;
  }
  to {
    background-color: #ffffff;
  }
}

.rotatecenter {
  -webkit-animation: rotatecenter 0.6s ease-in-out both;
  animation: rotatecenter 0.6s ease-in-out both;
}
@-webkit-keyframes rotatecenter {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotatecenter {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.rotatescaleup {
  -webkit-animation: rotatescaleup 0.65s linear both;
  animation: rotatescaleup 0.65s linear both;
}
/* ----------------------------------------------
 * Generated by Animista on 2022-1-9 19:55:2
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation rotatescaleup
 * ----------------------------------------
 */
@-webkit-keyframes rotatescaleup {
  0% {
    -webkit-transform: scale(1) rotateZ(0);
    transform: scale(1) rotateZ(0);
  }
  50% {
    -webkit-transform: scale(1.75) rotateZ(180deg);
    transform: scale(1.75) rotateZ(180deg);
  }
  100% {
    -webkit-transform: scale(1) rotateZ(360deg);
    transform: scale(1) rotateZ(360deg);
  }
}
@keyframes rotatescaleup {
  0% {
    -webkit-transform: scale(1) rotateZ(0);
    transform: scale(1) rotateZ(0);
  }
  50% {
    -webkit-transform: scale(1.75) rotateZ(180deg);
    transform: scale(1.75) rotateZ(180deg);
  }
  100% {
    -webkit-transform: scale(1) rotateZ(360deg);
    transform: scale(1) rotateZ(360deg);
  }
}
</style>