<!-- ---------- ---------- ---------- HTML Structure ---------- ---------- ---------- -->
<template>
  <div>
    <!--Snackbar-->
    <transition name="splash">
      <div
        style="position: fixed; top: 60%; left: 50%; transform: translate(-50%, -50%); max-width: 50rem; width: auto; height: auto;
        background: #007bff; color: #ffffff;
        padding: 0.5rem; border-radius: 1rem;
        cursor: pointer; pointer-events: all;
        z-index: 5555;
        box-shadow: 0 2px 8px grey;
      "
        v-if="favoritesSnackboarAndText.snackbar"
        @click="favoritesSnackboarAndText.snackbar = false"
        v-bind:class="{
          white: favoritesSnackboarAndText.favType == 'noheart',
          red: favoritesSnackboarAndText.favType == 'heart',
          blue: favoritesSnackboarAndText.favType == 'plan',
          green: favoritesSnackboarAndText.favType == 'done',
          grey: favoritesSnackboarAndText.favType == 'deleted',
          nodetailsview: $router.currentRoute.value.name != 'DetailsView',
        
        }"
      >
        <span>{{ $store.state.favoritesSnackboarAndText.text }}</span>
      </div>
    </transition>
  </div>
</template>

  <!-- ---------- ---------- ---------- Code ---------- ---------- ---------- -->

<script>
/* eslint-disable */

export default {
  components: {},
  props: {},
  data() {
    return {};
  },
  mounted() { },
  methods: {},
  computed: {
    favoritesSnackboarAndText() {
      return this.$store.state.favoritesSnackboarAndText
    },
  },
};
</script>

<style scoped>
.red {
  background: #dc3545 !important;
}
.blue {
  background: #0dcaf0 !important;
}
.green {
  background: #20c997 !important;
}
.grey {
  background: grey !important;
}
.white {
  background: white !important;
  color: black !important;
}
.splash-enter-from,
.splash-leave-to {
  opacity: 0;
}
.splash-enter-active {
  transition: all 0.3s ease;
}
.splash-leave-active {
  transition: all 0.6s ease;
}
.nodetailsview {
  top: 50% !important;
}
</style>