/* eslint-disable */

import store from '@/store/index.js'

// https://www.w3schools.com/js/js_date_methods.asp
export default {
  getDate() {
    console.log("GetDate is executed")
    var d = new Date()
    var month = d.getMonth() + 1 // 0-11 --> 1-12
    var day = d.getDay() // 0-6 / Sunday to Saturday
    var hours = d.getHours() // 0-24

    // Airbnb: Morgen: Vor 12 | Nachmittag: Nach 12 | Abend: Nach 17 Uhr

    var season = ""
    if (month >= 12 || month <= 2) { season = "winter" }
    if (3 <= month && month <= 5) { season = "spring" }
    if (6 <= month && month <= 8) { season = "summer" } // todo: add spring filter and change 4 to 6
    if (9 <= month && month <= 11) { season = "fall" } // todo: add fall filter (in fall)

    var weekday = ""
    if (day == 0) { weekday = "sunday" }
    if (day == 1) { weekday = "monday" }
    if (day == 2) { weekday = "tuesday" }
    if (day == 3) { weekday = "wednesday" }
    if (day == 4) { weekday = "thursday" }
    if (day == 5) { weekday = "friday" }
    if (day == 6) { weekday = "saturday" }

    var daytime = ""
    if (hours < 5) { daytime = "earlymorning" } // todo: add night filter
    if (5 <= hours && hours < 12) { daytime = "morning" }
    // if (10 <= hours && hours < 15) { daytime = "day" } // todo: add "day filter"
    if (12 <= hours && hours < 17) { daytime = "afternoon" } // todo: add "afternoon filter"
    if (17 <= hours && hours < 22) { daytime = "evening" }
    if (hours >= 22) { daytime = "latenight" } // todo: add night filter

    store.state.date.season = season
    store.state.date.weekday = weekday
    store.state.date.daytime = daytime

  }
}

