/* eslint-disable */
import store from '@/store/index.js'
import UpdateActivitiesService from "@/services/UpdateActivitiesService.js";

export default {
  clickFilter(cQuestion, cAnswer) {

    if (cQuestion != "onlycheckcountzeroandresethistory") {
      var clickedFilterQuestion = cQuestion;
      var clickedFilterAnswer = cAnswer;
      console.log("clickFilter is executed for question: '" + clickedFilterQuestion + " with answer " + clickedFilterAnswer + "'")


      // If all alswers are clicked, reset all clicked answers (below)
      var allAnswersAreSelected = true

      // go though all entries of the parent / question
      store.state.filters[clickedFilterQuestion].forEach(function (filterObject) {
        if (filterObject.filterkey == clickedFilterAnswer) {
          console.log(filterObject)
          // Set the "selected" boolean of question.filterkey to "true", if it was "false" before and the other way around
          filterObject.selected = !filterObject.selected


          // create store.state.clickedAnswers Object
          // create question object, if not already existing
          if (!(clickedFilterQuestion in store.state.clickedAnswers)) {
            store.state.clickedAnswers[clickedFilterQuestion] = {}
          }
          // create answer object, if not already existing
          if (!(filterObject.filterkey in store.state.clickedAnswers[clickedFilterQuestion])) {
            store.state.clickedAnswers[clickedFilterQuestion][filterObject.filterkey] = filterObject
          }
          else {
            // delete answer object, if already existing
            delete store.state.clickedAnswers[clickedFilterQuestion][filterObject.filterkey]
            // delete question object, if empty
            if (Object.keys(store.state.clickedAnswers[clickedFilterQuestion]).length == 0) { delete store.state.clickedAnswers[clickedFilterQuestion] }
          }



          // Reset the filter values of "sports" and "games", if the respective element is collapsed
          if (filterObject.filterkey == "sports") {
            store.state.filters.sport.forEach(function (x) {
              x.selected = false;
              delete store.state.clickedAnswers["sport"]; // move this outside of the forEach?
            });
          }
          if (false && filterObject.filterkey == "games") {
            store.state.filters.game.forEach(function (x) {
              x.selected = false
              delete store.state.clickedAnswers["game"];
            });
          }
        }

        // Check, if all answers were clicked | Check, if at least one answer was not clicked
        if (!filterObject.selected) { allAnswersAreSelected = false }

      })

      // If all alswers were clicked, reset all clicked answers
      if (allAnswersAreSelected) {
        store.state.filters[clickedFilterQuestion].forEach(function (answer) {
          answer.selected = false;
        });
        delete store.state.clickedAnswers[clickedFilterQuestion];

        store.state.filterSnackbar = true
        setTimeout(function () {
          console.log("FALSE")
          store.state.filterSnackbar = false
        }, 5000);
      }

      UpdateActivitiesService.updateActivities()
    }
    else {
      console.log("clickFilter is executed onlycheckcountzeroandresethistory")
    }
    ///////////////////////////////////////////////////////////////////////////////////////////////////
    // Check for all filters, if the activity count would go down to 0, if a specific filter is clicked
    ///////////////////////////////////////////////////////////////////////////////////////////////////
    for (const [questionKey, questionValue] of Object.entries(store.state.filters)) {
      var questionHasSelectedAnswerWithCountZero = false
      var goodList = []
      questionValue.forEach(function (answer) {
        answer.lastAnswerWithValues = false
        if (answer.selected) {
          if (answer.count == 0) {
            questionHasSelectedAnswerWithCountZero = true
          }
          else { goodList.push(answer) }
        }
      })
      if (questionHasSelectedAnswerWithCountZero && goodList.length == 1) {
        console.log("questionHasSelectedAnswerWithCountZero")
        var goodEntry = goodList[0]
        store.state.filters[goodEntry.parent][goodEntry.id].lastAnswerWithValues = true
      }

    }

    if (store.state.storeActivityListFiltered.length == 0) {
      console.log("ERROR - Keine Aktivität entspricht deinen Filtern")
    }

    if (store.state.storeActivityListFiltered.length != 0) {
      console.log("ClickFilterService successful")
      store.state.storeSelectedActivityId = store.state.storeActivityListFiltered[0].id
      store.state.activity = store.state.storeActivityListFiltered[0]
    }
  }
}