<!-- ---------- ---------- ---------- HTML Structure ---------- ---------- ---------- -->
<template>
  <div class="page">
    <div class="container">
      <!--Settings-->
      <div style="padding: 0rem 0rem 1rem">
        <div class="setting-area-title">
          {{
          $store.state.appTexts.settingstitle[$store.state.user.countryCode]
          }}
        </div>
        <div class="setting-area-content">

          <!--3 Install-->
          <div id="tt-s-install" class="space border-bottom lineitem" style="color: #007bff"
            v-if="$store.state.addToHomescreenFunction" v-on:click="addToHomeScreen()">
            <div style="pointer-events: none">
              <span v-if="$store.state.user.countryCode == 'DE'">Zum Startbildschirm hinzufügen</span>
              <span v-else>Add Timetip to your Homescreen</span>
            </div>
            <svg style="pointer-events: none" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24"
              width="24px" fill="#000000">
              <path d="M0 0h24v24H0V0z" fill="none" />
              <path d="M10.02 6L8.61 7.41 13.19 12l-4.58 4.59L10.02 18l6-6-6-6z" />
            </svg>
          </div>
          <!--1 Sign Up-->
          <div id="tt-s-signup" class="space border-bottom lineitem"
            v-if="authIsReady && !loggedInUser" v-on:click="$router.push('/signupwithemailandpassword')">
            <div style="pointer-events: none">
              <span v-if="$store.state.user.countryCode == 'DE'">Account erstellen</span>
              <span v-else>Sign Up</span>
            </div>
            <svg style="pointer-events: none" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24"
              width="24px" fill="#000000">
              <path d="M0 0h24v24H0V0z" fill="none" />
              <path d="M10.02 6L8.61 7.41 13.19 12l-4.58 4.59L10.02 18l6-6-6-6z" />
            </svg>
          </div>
          <!--2 Login-->
          <div id="tt-s-login" class="space border-bottom lineitem"
            v-if="authIsReady && !loggedInUser" v-on:click="$router.push('/loginwithemailandpassword')">
            <div style="pointer-events: none">
              <span v-if="$store.state.user.countryCode == 'DE'">Einloggen</span>
              <span v-else>Login</span>
            </div>
            <svg style="pointer-events: none" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24"
              width="24px" fill="#000000">
              <path d="M0 0h24v24H0V0z" fill="none" />
              <path d="M10.02 6L8.61 7.41 13.19 12l-4.58 4.59L10.02 18l6-6-6-6z" />
            </svg>
          </div>
          <!--Language-->
          <div class="space" style="
              border-bottom: 1px solid #ced4da;
              display: inline-flex;
              width: 100%;
              justify-content: space-between;
              cursor: pointer;
            " @click="doshowShowLanguage">
            <div>
              <span>
                {{
                $store.state.appTexts.languageswitch[
                $store.state.user.countryCode
                ]
                }}
              </span>
              <span>{{
              ": " +
              $store.state.appTexts.language[$store.state.user.countryCode]
              }}</span>
            </div>
            <div style="padding-right: 0rem">
              <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path d="M10.02 6L8.61 7.41 13.19 12l-4.58 4.59L10.02 18l6-6-6-6z" />
              </svg>
            </div>
            <!--Language Drawer-->
            <n-drawer v-model:show="showLanguage" :width="502" :height="200" :placement="placement">
              <n-drawer-content body-content-style="padding: 0rem 0rem 0rem 0rem; text-align: center">
                <div class="bheader border-bottom">
                  <span>
                    {{
                    $store.state.appTexts.languageswitch[
                    $store.state.user.countryCode
                    ]
                    }}
                  </span>
                </div>
                <!--English-->
                <div class="language" v-on:click="
                  ($store.state.user.countryCode = 'EN'), switchLanguage()
                ">
                  <div v-html="$store.state.appTexts.flag.EN" class="flag"></div>
                  <span v-bind:class="{ languageselected: $store.state.user.countryCode == 'EN', }">
                    {{ $store.state.appTexts.english[$store.state.user.countryCode] }}
                  </span>
                </div>
                <!--German-->
                <div class="language" v-on:click="
                  ($store.state.user.countryCode = 'DE'), switchLanguage()
                ">
                  <div v-html="$store.state.appTexts.flag.DE" class="flag"></div>
                  <span v-bind:class="{
                    languageselected: $store.state.user.countryCode == 'DE',
                  }">{{ $store.state.appTexts.german[$store.state.user.countryCode] }}
                  </span>
                </div>
              </n-drawer-content>
            </n-drawer>
          </div>

          <!--Beta Features-->
          <div class="space border-bottom" style="
              display: inline-flex;
              width: 100%;
              justify-content: space-between;
              cursor: pointer;
            ">
            <div v-on:click="
              $store.state.betaFeatures = !$store.state.betaFeatures
            " style="flex-grow: 1">
              <span v-if="$store.state.user.countryCode == 'DE'">Erweiterte Filter</span>
              <span v-else>Advanced filters</span>
            </div>
            <n-switch v-model:value="$store.state.betaFeatures" @update:value="!$store.state.betaFeatures"
              style="z-index= 1000" />
          </div>
          <!--Beta Features-->
          <!-- <div class="space border-bottom" style="
              display: inline-flex;
              width: 100%;
              justify-content: space-between;
              cursor: pointer;
            ">
            <div v-on:click="
              $store.state.newUI = !$store.state.newUI
            " style="flex-grow: 1">
              <span v-if="$store.state.user.countryCode == 'DE'">Neue UI</span>
              <span v-else>New UI</span>
            </div>
            <n-switch v-model:value="$store.state.newUI" @update:value="!$store.state.newUI" style="z-index= 1000" />
          </div> -->
          <!--4 Logout-->
          <div class="space lineitem" v-if="authIsReady && loggedInUser" @click="logOutUser">
            <span v-if="$store.state.user.countryCode == 'DE'">Logout {{ loggedInUser.email }}</span>
            <span v-else>Logout {{ loggedInUser.email }}</span>
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
              <path d="M0 0h24v24H0V0z" fill="none" />
              <path d="M10.02 6L8.61 7.41 13.19 12l-4.58 4.59L10.02 18l6-6-6-6z" />
            </svg>
          </div>
        </div>
      </div>

      <!--About-->
      <div style="padding: 1rem 0rem 0rem 0rem">
        <div class="setting-area-title">
          {{ $store.state.appTexts.abouttitle[$store.state.user.countryCode] }}
        </div>

        <div class="setting-area-content">
          <div class="space" style="border-bottom: 1px solid #ced4da; font-size: 0.9rem" v-if="$store.state.user.countryCode == 'DE'">
            <span>
              Timetip möchte dir dabei helfen,
              dass du für jede Situation in deinem Leben immer
              die passenden Freizeitideen für dich und dein Umfeld findest.
              <br />
              <br />
              Dabei wirst du hoffentlich nicht nur viele neue
              Aktivitäten entdecken, sondern vielleicht auch längst vergessene
              Aktivitäten aus deiner Erinnerung hervorholen können.
            </span>
          </div>
          <div class="space" style="border-bottom: 1px solid #ced4da">
            <a style="text-decoration: none; color: #2c3e50"
              href="mailto:fback&commat;timetip&period;app?subject=Feedback%20Timetip&body=New%20activity%20ideas%3A%0D%0A-%20%0D%0A-%20%0D%0AFeedback%20for%20current%20functionalities%3A%0D%0A-%20%0D%0A-%20%0D%0AIdeas%20for%20new%20functionalities%3A%0D%0A-%20%0D%0A-%20%0D%0AYour%20final%20advice%0D%0A-%20">
              <div style="
                  display: inline-flex;
                  width: 100%;
                  justify-content: space-between;
                  align-items: center;
                ">
                <div style="padding-right: 0.5rem">
                  <span v-if="$store.state.user.countryCode == 'DE'">Ich freue mich über dein Feedback</span>
                  <span v-else>I am happy for your feedback mail</span>
                </div>
                <div style="padding-right: 0rem; align-items: center">
                  <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
                    <path d="M0 0h24v24H0V0z" fill="none" />
                    <path d="M10.02 6L8.61 7.41 13.19 12l-4.58 4.59L10.02 18l6-6-6-6z" />
                  </svg>
                </div>
              </div>
            </a>
          </div>

          <div class="space" style="border-bottom: 1px solid #ced4da"><span>App-version: 0.6</span></div>
          <!--DeleteUser-->
          <div class="space"
            style="display: inline-flex; width: 100%; justify-content: space-between; align-items: center; color: #2c3e5080; cursor: pointer"
            v-if="authIsReady && loggedInUser" v-on:click="$router.push('/deleteuser')">
            <span v-if="$store.state.user.countryCode == 'DE'">Account löschen</span>
            <span v-else>Delete account</span>
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#2c3e5080">
              <path d="M0 0h24v24H0V0z" fill="none" />
              <path d="M10.02 6L8.61 7.41 13.19 12l-4.58 4.59L10.02 18l6-6-6-6z" />
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

  <!-- ---------- ---------- ---------- Code ---------- ---------- ---------- -->

<script>
/* eslint-disable */
import AgeContainer from "@/components/Filters/AgeContainer.vue";
import ResetFiltersService from "@/services/ResetFiltersService.js";
import { ref } from "vue";
import { useStore } from "vuex";
import { computed } from "vue";
import AddToHomeScreenService from "@/services/AddToHomeScreenService.js";

export default {
  components: {
    AgeContainer,
  },
  mounted() { },
  setup() {
    // Logout
    const store = useStore();

    const logOutUser = async () => {
      store.dispatch("logout");
      localStorage.setItem("userLoggedIn", "false");
      localStorage.setItem("user", "null");
    };

    // SlideUps
    const showLanguageRef = ref(false);
    const showAgeRef = ref(false);
    const placement = ref("bottom");

    return {
      // Login
      logOutUser,
      loggedInUser: computed(() => store.state.nutzer),
      authIsReady: computed(() => store.state.authIsReady),
      // SlideUps
      placement,
      showLanguage: showLanguageRef,
      doshowShowLanguage() {
        showLanguageRef.value = true;
      },
      showAge: showAgeRef,
      doshowShowAge() {
        showAgeRef.value = true;
      },
    };
  },
  computed: {},
  methods: {
    addToHomeScreen() {
      AddToHomeScreenService.clickCallback();
    },
    switchBetaFeatures() {
      console.log("HHHHHHHHHHHHHHHHHHHHHHHHHHH");
      if (this.$store.state.betaFeatures) {
        this.$store.state.betaFeatures = false;
      }
      if (!this.$store.state.betaFeatures) {
        this.$store.state.betaFeatures = true;
      }
    },
    changeFilterMethod(input) {
      console.log(this.$store.state.updateActivitiesMethod);
      if (this.$store.state.updateActivitiesMethod != input) {
        this.$store.state.updateActivitiesMethod = input;
      }
      ResetFiltersService.resetFilters();
    },
    switchLanguage() {
      console.log("County code saved in local storage");
      localStorage.setItem("countryCode", this.$store.state.user.countryCode);
    },
  },
};
</script>
}
<style scoped>
.lineitem {
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.container {
  margin-bottom: 5rem;
  padding: 0.75rem 0.75rem 0.75rem 0.75rem;
  text-align: left;
  max-width: 50rem;
  /* background: #007bff30; */
  min-height: 100vh;
}

.setting-area-title {
  font-weight: bold;
  padding: 0rem 0rem 0rem;
  color: #000000;
}

.setting-area-content {
  padding: 0rem 0.5rem 0rem;
}

.n-switch {
  --n-rail-color-active: #007bff !important;
  --n-box-shadow-focus: 0 0 0 2px transparent !important;
  --n-loading-color: #007bff !important;
  /*background-color: #6610f280 !important;*/
  --n-rail-color: #007bff80 !important;
}

.language {
  height: 4rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 1.5rem;
  padding-right: 2rem;
  cursor: pointer;
}

.flag {
  padding-right: 2rem;
}

.bheader {
  font-weight: bold;
  color: black;
  padding: 1rem 0rem 1rem 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.languageselected {
  font-weight: bold;
}

.userbutton {
  color: white;
  text-align: center;
  font-weight: bold;
  /* padding: 1rem; */
  margin-top: 2rem;
  margin-bottom: 1rem;
  border-radius: 0.6rem;
  cursor: pointer;
}

button {
  margin-top: 2rem;
  padding: 0.5rem 0.75rem 0.5rem 0.75rem;
  background: #2c3e5080;
  border: none;
  border-radius: 0.6rem;
  color: white;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

button:focus {
  outline: none;
}
</style>