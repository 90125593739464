<!-- ---------- ---------- ---------- HTML Structure ---------- ---------- ---------- -->
<template>
  <div class="page">
    <ExplanationComponent />
    <div class="favoritesContainer" style="margin-top: 0.5rem">
      <div class="favoriteViewTitle" v-if="$store.state.storeActivityListFavorites.length > 0 && false">
        <span>
          {{ $store.state.appTexts.findfavoritesbelow[$store.state.user.countryCode] }}
        </span>
      </div>
      <div class="favoriteViewTitle" v-if="$store.state.storeActivityListFavorites.length == 0 && false">
        <span>
          {{ $store.state.appTexts.nofavoritesselected[$store.state.user.countryCode] }}
        </span>
      </div>

      <!--Ohne diesen Befehl werden die Listeneinträge der Kinder nicht gelesen-->
      <div style="margin-top: 0.375rem" v-if="$store.state.storeActivityListFavorites.length > 0">
        <div v-for="favTypeList in sortedStoreActivityListFavorites">
          <div v-for="activity in favTypeList" v-bind:key="activity" class="container">
            <div class="borderandpadding" v-bind:class="{
              noborderandpadding:
                activity.nobb
            }">
              <div class="cardContainer">
                <div class="cardContainerAbsolute">
                  <div class="cardLeft" v-bind:selectedActivityName="activity.activity"
                    v-bind:selectedActivityuuid="activity.uuid" v-bind:selectedActivityId="activity.id"
                    v-bind:selectedActivityListPosition="activity.listPosition" v-bind:selectedActivity="activity"
                    v-on:click="setStoreSelectedActivity(activity.id, activity.listPosition, activity)">
                    <div class="cardImageContainer">
                      <!--loading="lazy"-->
                      <img v-if="activity.imageavailable < 3" class="cardImage" v-bind:src="
                        'https://storage.googleapis.com/timetip-images/activity-images/' +
                        activity.imagetext +
                        '.jpg'
                      " @error="setAltImg" />
                    </div>
                  </div>
                  <div class="cardMiddle" v-bind:selectedActivityName="activity.activity"
                    v-bind:selectedActivityuuid="activity.uuid" v-bind:selectedActivityId="activity.id"
                    v-bind:selectedActivityListPosition="activity.listPosition" v-bind:selectedActivity="activity"
                    v-on:click="setStoreSelectedActivity(activity.id, activity.listPosition, activity)">
                    <div class="cardMiddleText column">
                      <span v-if="$store.state.user.countryCode != 'DE'">
                        {{ activity.activity }}
                      </span>
                      <span v-if="$store.state.user.countryCode == 'DE'">
                        {{ activity.activityde }}
                      </span>
                    </div>
                  </div>
                  <div class="cardRight">
                    <DetailsHeartComponent :activityNumber="activity.listPosition" style="  
                      height: 100%;
                      width: 100%;
                      " />
                    <!-- <div class="cardRightHeart column">
                    <svg
                      v-if="
                        !Object.keys($store.state.favoritesObject).includes(
                          activity.uuid
                        )
                      "
                      xmlns="http://www.w3.org/2000/svg"
                      width="20px"
                      height="20px"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      class="hearticon favoritenotselected"
                    >
                      <path d="M0 0h24v24H0V0z" fill="none" />
                      <path
                        d="M16.5 3c-1.74 0-3.41.81-4.5 2.09C10.91 3.81 9.24 3 7.5 3 4.42 3 2 5.42 2 8.5c0 3.78 3.4 6.86 8.55 11.54L12 21.35l1.45-1.32C18.6 15.36 22 12.28 22 8.5 22 5.42 19.58 3 16.5 3zm-4.4 15.55l-.1.1-.1-.1C7.14 14.24 4 11.39 4 8.5 4 6.5 5.5 5 7.5 5c1.54 0 3.04.99 3.57 2.36h1.87C13.46 5.99 14.96 5 16.5 5c2 0 3.5 1.5 3.5 3.5 0 2.89-3.14 5.74-7.9 10.05z"
                      />
                    </svg>
                    <svg
                      v-if="
                        Object.keys($store.state.favoritesObject).includes(
                          activity.uuid
                        )
                      "
                      xmlns="http://www.w3.org/2000/svg"
                      width="20px"
                      height="20px"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      class="hearticon favoriteselected"
                    >
                      <path d="M0 0h24v24H0V0z" fill="none" />
                      <path
                        d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"
                      />
                    </svg>
                    </div>-->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container" style="margin-top: 3rem; font-size: 0.9rem" v-if="
        $store.state.storeActivityListFavorites.length != 0 &&
        !$store.state.nutzer
      " v-on:click="$router.push('/settings')">
        <div style="color: #2c3e5080">
          <span v-if="$store.state.user.countryCode == 'DE'">
            Aktuell sind deine Favoriten temporär auf deinem Handy gespeichert.
            Logge dich ein, um deine Favoriten dauerhaft zu speichern und auch
            von anderen Geräten darauf zugreifen zu können.
          </span>
          <span v-else>
            Your favorites are currently temporarily stored on your mobile
            device. If you want to save them permanently and access them from
            other devices, you can login.
          </span>
        </div>
        <div style="margin-top: 1rem; margin-bottom: 0.5rem">
          <button style="font-weight: normal; pointer-events: none; border-radius: 0.6rem; color: white; background: #007bff80; text-transform: none;
            height: 2.5rem; padding: 0rem 1rem; font-size: 0.9rem; letter-spacing: normal;">
            <span v-if="$store.state.user.countryCode == 'DE'">Account erstellen oder einloggen</span>
            <span v-else>Sign up or Login</span>
          </button>
        </div>
      </div>

      <!--FavoritesView Explanation-->
      <div v-if="
        $route.name == 'FavoritesListView' &&
        $store.state.storeActivityListFavorites.length == 0
      " v-on:click="$router.push('/details')" class="favoritesExplanation">
        <!-- Upper Part-->
        <div>
          <div style="color: white">
            <span>
              {{ $store.state.appTexts.nofavoritesselected[$store.state.user.countryCode] }}
            </span>
          </div>
        </div>
        <!-- Logo-->
        <div class="timetipContainer">
          <TimetipSVG />
        </div>
        <!-- Lower Part-->
        <div style="color: white">
          <span v-if="$store.state.user.countryCode == 'DE'">
            Den Favoriten Button findest du auf der Swipe Ansicht unten rechts
            im Bild
          </span>
          <span v-if="$store.state.user.countryCode != 'DE'">
            You can find the favorites Button at the Swipe View in the lower
            left corner of the image
          </span>
        </div>
      </div>

      <BottomButtonsComponent v-if="false" />
    </div>
  </div>
</template>

  <!-- ---------- ---------- ---------- Code ---------- ---------- ---------- -->

<script>
/* eslint-disable */
import ActivityListComponent from "@/components/ActivityList/ActivityListComponent.vue";
import BottomButtonsComponent from "@/components/FavoritesList/BottomButtonsComponent.vue";
import DetailsHeartComponent from "@/components/Details/DetailsHeartComponent.vue";

import ExplanationComponent from "@/components/Navigation/ExplanationComponent.vue";
import TimetipSVG from "@/components/Navigation/TimetipSVG.vue";

import UpdateFavoritesService from "@/services/UpdateFavoritesService.js";
import ResetFiltersService from "@/services/ResetFiltersService.js";


export default {
  components: {
    ActivityListComponent,
    BottomButtonsComponent,
    ExplanationComponent,
    TimetipSVG,
    DetailsHeartComponent,
  },
  data() {
    return {
      sortedStoreActivityListFavorites: [],
    };
  },
  mounted() {
    this.calculateSortedStoreActivityListFavorites()

  },
  computed: {
    favoritesObjectLength() {
      return Object.keys(this.$store.state.favoritesObject).length
    }
  },
  watch: {
    // favoritesObjectLength() {
    //   var that = this
    //   setTimeout(function () {
    //     that.calculateSortedStoreActivityListFavorites()
    //   }, 2000);
    // }
    sortedStoreActivityListFavorites() {
      // Delete last border on the bottom
      var list = this.sortedStoreActivityListFavorites
      var heart = list[0];
      var plan = list[1];
      var done = list[2];
      var deleted = list[3];
      if (deleted.length > 0) { list[3][deleted.length - 1].nobb = true } else
        if (done.length > 0) { list[2][done.length - 1].nobb = true } else
          if (plan.length > 0) { list[1][plan.length - 1].nobb = true } else
            if (heart.length > 0) { list[0][heart.length - 1].nobb = true }
    }
  },
  methods: {
    calculateSortedStoreActivityListFavorites() {
      var heart = [];
      var plan = [];
      var done = [];
      var deleted = [];
      var that = this
      this.$store.state.storeActivityListFavorites.forEach(function (favorite) {
        favorite.nobb = false
        // if (Object.keys(that.$store.state.favoritesObject).includes(favorite.uuid)) {
        // }
        var favType = that.$store.state.favoritesObject[favorite.uuid].favType
        console.log(favType)
        if (favType == "heart" || !favType) { heart.push(favorite) }
        if (favType == "plan") { plan.push(favorite) }
        if (favType == "done") { done.push(favorite) }
        if (favType == "deleted") { deleted.push(favorite) }
      })
      this.sortedStoreActivityListFavorites = [heart, plan, done, deleted]
    },

    setStoreSelectedActivity: function (selectedActivityId, selectedActivityListPosition, selectedActivity) {
      ResetFiltersService.resetFilters();

      this.$store.state.storeSelectedActivityId = selectedActivityId;
      this.$store.state.storeSelectedActivityListPosition = selectedActivityListPosition;
      this.$store.state.activity = selectedActivity;
      this.$store.state.storeActivityListFiltered = this.$store.state.storeActivityListFavorites;

      this.$router.push("/details");
    },
    updateFavorites: function (event) {
      var uuid = event.target.getAttribute("selectedActivityuuid")
      var name = event.target.getAttribute("selectedActivityName")
      UpdateFavoritesService.updateFavorites(uuid, name);
    },
    setAltImg(event) {
      console.log("image could not be loaded");
      event.target.src = require("../assets/error.png");
    },
  },
};
</script>

<style scoped>
.buton {
  max-width: 50rem;
  margin-top: 1.5rem;
  padding: 0.5rem 0.75rem 0.5rem 0.75rem;
  background: #007bff80;
  border: none;
  border-radius: 0.6rem;
  color: white;
}

.buton:focus {
  outline: none;
}

.favoritesExplanation {
  max-width: 50rem;
  position: absolute;
  width: 80%;
  height: auto;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #007bff;
  padding: 2rem;
  border-radius: 4rem;
  pointer-events: all;
  z-index: 5;
  cursor: pointer;
}

.timetipContainer {
  position: relative;
  padding: 20%;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.favoritesContainer {
  padding-bottom: 1rem;
  min-height: 100vh;
}

.favoriteViewTitle {
  /*margin-bottom: 0.375em;*/
  padding: 0.75rem 0.2rem 0rem 0.2rem;
  font-weight: bold;
  color: #000000;
}

.borderandpadding {
  padding-top: 0.375em;
  border-bottom: 1px solid #ced4da;
  padding-bottom: 0.375em;
}

.noborderandpadding {
  border-bottom: 0px solid #ced4da;
}

.cardContainer {
  cursor: pointer;
  overflow: hidden;
  padding-top: 20%;
  /*18.54% equals golden ratio*/
  background: #ffffff;
  position: relative;
}

.cardContainerAbsolute {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.cardLeft {
  position: absolute;
  left: 0;
  width: 30%;
  height: 100%;
}

.cardImageContainer {
  width: 100%;
  overflow: hidden;
  height: 100%;
  background: #9fcdff;
  pointer-events: none;
  border-radius: 0.5rem;
}

.cardImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
}

.cardMiddle {
  position: absolute;
  left: 30%;
  width: 60%;
  height: 100%;
  font-size: 0.9rem;
}

.cardMiddleText {
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
  padding-left: 5%;
  padding-right: 5%;
  pointer-events: none;
  text-align: left;
}

.cardRight {
  position: absolute;
  left: 90%;
  width: 10%;
  height: 100%;
}

.cardRightHeart {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}

.hearticon {
  pointer-events: none;
}

.selected {
  background-color: #20c997;
  color: #ffffff;
  border-radius: 0.25rem;
}

.favoriteselected {
  color: red;
}

.favoritenotselected {
  color: #6fb5ff;
}

.comment {
  margin-top: 2em;
  font-size: 60%;
}

.form-control {
  margin-bottom: 0.5rem;
}

/*.image-list {
}*/
.image-item {
  cursor: pointer;
  width: 25%;
  margin: 0;
  padding: 0rem 0rem;
  display: inline;
}

.thumbnail {
  position: relative;
}

.caption {
  color: #000000;
  font-size: 1.5vw;
  /*font-weight: bold;*/
  position: absolute;
  top: 10%;
  left: 0;
  width: 100%;
}

.selectedFilterContainer {
  padding-top: 1rem;
}

.selectedFilter {
  background-color: #6fb5ff;
  color: #ffffff;
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  margin: 0.1rem;
  padding: 0rem 0.5rem;
  border-radius: 0.6rem;
  border: 0;
  cursor: pointer;
}

.delete-underline {
  text-decoration: none;
  color: #ffffff;
}

.container {
  max-width: 50rem;
}
</style>