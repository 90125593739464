<!-- ---------- ---------- ---------- HTML Structure ---------- ---------- ---------- -->
<template>
  <div class="page">
    <!--English-->
    <div v-if="$store.state.user.countryCode != 'DE'">
      <div class="container">
        <h2>Information pursuant to Sect. 5 German Telemedia Act (TMG)</h2>
        <p>
          Timetip GmbH<br />
          Angererstrasse, 24<br />
          80796 München
        </p>

        <p>
          Commercial Register: HRB 266188<br />
          Registration court: Handelsregister B des Amtsgerichts München
        </p>

        <p>Represented by: Carsten Brendel</p>

        <h2>Contact</h2>
        <p>
          <img v-bind:src="require('../assets/ttp.png')" class="phonum" /><br />
          <img v-bind:src="require('../assets/ttm.png')" class="emage" />
        </p>

        <h2>Person responsible for editorial</h2>
        <p>Carsten Brendel</p>

        <h2>EU dispute resolution</h2>
        <p>
          The European Commission provides a platform for online dispute
          resolution (ODR):
          <a
            href="https://ec.europa.eu/consumers/odr/"
            target="_blank"
            rel="noopener noreferrer"
            >https://ec.europa.eu/consumers/odr/</a
          >.<br />
          Our e-mail address can be found above in the site notice.
        </p>

        <h2>
          Dispute resolution proceedings in front of a consumer arbitration
          board
        </h2>
        <p>
          We are not willing or obliged to participate in dispute resolution
          proceedings in front of a consumer arbitration board.
        </p>

        <!--Break e-recht24.de/muster-disclaimer-->

        <h2>Liability for content</h2>
        <p>
          According to § 7 para. 1 TMG (German Teleservices Act), we, as a
          service provider, are responsible for our own content on these pages
          pursuant to general law. According to §§ 8 to 10 TMG, however, we as a
          service provider are not obliged to monitor transmitted or stored
          external information or to investigate circumstances that may indicate
          unlawful activity.
        </p>
        <p>
          Obligations to remove or block the use of information pursuant to
          general law remain unaffected. However, liability in this regard is
          only possible from the date that a specific infringement becomes known
          to us. Upon notification of a relevant infringement, we will remove
          such content immediately.
        </p>
        <h2>Liability for links</h2>
        <p>
          Our service offer contains links to external websites of third
          parties; we do not have an influence on their contents. Therefore, we
          cannot assume any liability for said external contents. The respective
          provider or operator of the webpages is responsible in all cases for
          the contents on the linked webpages. At the time that the link was
          placed, the linked webpages were reviewed for possible legal
          violations. Illegal content was not found at the time of linking
        </p>
        <p>
          However, permanent content review of the linked webpages is not
          reasonable without concrete evidence of an infringement. Upon
          notification of infringements, we will remove such links immediately.
        </p>
        <h2>Copyright</h2>
        <p>
          The content and works created by the site operators on these webpages
          are subject to German copyright law. The reproduction, processing,
          distribution, and any kind of use outside the limits of the copyright
          require written consent from the respective author or creator.
          Downloads and copies made from this website are solely for private,
          non-commercial use.
        </p>
        <p>
          Insofar as the content on this page was not created by the operator,
          the copyrights of third parties are considered and respected. In
          particular, third-party content will be marked as such. In the event
          that you should still become aware of a copyright infringement, we
          kindly ask you to notify us thereof. Upon notification of
          infringements, we will remove such content immediately.
        </p>
      </div>
    </div>

    <!--German-->
    <div v-if="$store.state.user.countryCode == 'DE'" class="container">
      <h1>Impressum</h1>

      <h2>Angaben gemäß § 5 TMG</h2>
      <p>
        Timetip GmbH<br />
        Angererstrasse, 24<br />
        80796 München
      </p>

      <p>
        Handelsregister: HRB 266188<br />
        Registergericht: Handelsregister B des Amtsgerichts München
      </p>

      <p>
        <strong>Vertreten durch:</strong><br />
        Carsten Brendel
      </p>

      <h2>Kontakt</h2>
      <p>
        <img v-bind:src="require('../assets/ttp.png')" class="phonum" /><br />
        <img v-bind:src="require('../assets/ttm.png')" class="emage" />
      </p>

      <h2>Redaktionell verantwortlich</h2>
      <p>Carsten Brendel</p>

      <h2>EU-Streitschlichtung</h2>
      <p>
        Die Europäische Kommission stellt eine Plattform zur
        Online-Streitbeilegung (OS) bereit:
        <a
          href="https://ec.europa.eu/consumers/odr/"
          target="_blank"
          rel="noopener noreferrer"
          >https://ec.europa.eu/consumers/odr/</a
        >.<br />
        Unsere E-Mail-Adresse finden Sie oben im Impressum.
      </p>

      <h2>Verbraucher­streit­beilegung/Universal­schlichtungs­stelle</h2>
      <p>
        Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren
        vor einer Verbraucherschlichtungsstelle teilzunehmen.
      </p>

      <!--Break e-recht24.de/muster-disclaimer-->

      <h2>Haftung für Inhalte</h2>
      <p>
        Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf
        diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8
        bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet,
        übermittelte oder gespeicherte fremde Informationen zu überwachen oder
        nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit
        hinweisen.
      </p>
      <p>
        Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
        Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt.
        Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der
        Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von
        entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend
        entfernen.
      </p>
      <h2>Haftung für Links</h2>
      <p>
        Unser Angebot enthält Links zu externen Websites Dritter, auf deren
        Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden
        Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten
        Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten
        verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der
        Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte
        waren zum Zeitpunkt der Verlinkung nicht erkennbar.
      </p>
      <p>
        Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch
        ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei
        Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend
        entfernen.
      </p>
      <h2>Urheberrecht</h2>
      <p>
        Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen
        Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung,
        Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
        Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des
        jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite
        sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
      </p>
      <p>
        Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden,
        werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte
        Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
        Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
        entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden
        wir derartige Inhalte umgehend entfernen.
      </p>
    </div>
  </div>
</template>

  <!-- ---------- ---------- ---------- Code ---------- ---------- ---------- -->

<script>
/* eslint-disable */

export default {
  mounted() {},
  components: {},
  methods: {},
};
</script>
}
<style scoped>
.container {
  margin-top: 1rem;
  margin-bottom: 2rem;
  text-align: left;
}
.emage {
  height: 1rem;
}
.phonum {
  height: 1rem;
}
</style>