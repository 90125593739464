<template>
  <n-space vertical class="swiper-no-swiping">
    <n-slider v-model:value="value" range step="mark" :min="$store.state.timeFixMin" :max="$store.state.timeFixMax"
      :marks="marks" :tooltip="false" />
  </n-space>
  <!--timeActive-->
  <!-- <n-space style="margin-top: 1rem; cursor: pointer">
    <n-switch v-model:value="timeActive" @update:value="activateTimeSlider" />
    <span style="cursor: pointer" v-on:click="activateTimeSlider">
      {{
        $store.state.appTexts.ageactive[$store.state.user.countryCode]
      }}
    </span>
  </n-space>-->
</template>

<script>
/* eslint-disable */
import { defineComponent, ref } from "vue";
import UpdateActivitiesService from "@/services/UpdateActivitiesService.js";
import { useStore } from "vuex";
import { computed } from "vue";
import ClickFilterService from "@/services/ClickFilterService.js";

export default defineComponent({
  data() {
    return { show: false };
  },
  props: {
    deactivateTimeFilter: Boolean,
  },
  setup() {
    const store = useStore();
    var day = (
      <svg height="20px" viewBox="0 0 24 24" width="20px" fill="#2c3e50">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path d="M19 3h-1V1h-2v2H8V1H6v2H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V9h14v10zm0-12H5V5h14v2zM7 11h5v5H7z" />
      </svg>
    );
    var we = (
      <svg height="20px" viewBox="0 0 24 24" width="20px" fill="#2c3e50">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path d="M21 9V7c0-1.65-1.35-3-3-3H6C4.35 4 3 5.35 3 7v2c-1.65 0-3 1.35-3 3v5c0 1.65 1.35 3 3 3h18c1.65 0 3-1.35 3-3v-5c0-1.65-1.35-3-3-3zM5 7c0-.55.45-1 1-1h12c.55 0 1 .45 1 1v2.78c-.61.55-1 1.34-1 2.22v2H6v-2c0-.88-.39-1.67-1-2.22V7zm17 10c0 .55-.45 1-1 1H3c-.55 0-1-.45-1-1v-5c0-.55.45-1 1-1s1 .45 1 1v4h16v-4c0-.55.45-1 1-1s1 .45 1 1v5z" />
      </svg>
    );
    var week = (
      <svg height="20px" viewBox="0 0 24 24" width="20px" fill="#2c3e50">
        <path d="M5.33 20H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2h1.33c1.1 0 2 .9 2 2v12a2 2 0 0 1-2 2zM22 18V6c0-1.1-.9-2-2-2h-1.33c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2H20a2 2 0 0 0 2-2zm-7.33 0V6c0-1.1-.9-2-2-2h-1.33c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h1.33c1.1 0 2-.9 2-2z"></path>
      </svg>
    );
    var month = (
      <svg
        enable-background="new 0 0 24 24"
        height="20px"
        viewBox="0 0 24 24"
        width="20px"
        fill="#2c3e50"
      >
        <g>
          <rect fill="none" height="24" width="24" />
        </g>
        <g>
          <path d="M19,4h-1V2h-2v2H8V2H6v2H5C3.89,4,3.01,4.9,3.01,6L3,20c0,1.1,0.89,2,2,2h14c1.1,0,2-0.9,2-2V6C21,4.9,20.1,4,19,4z M19,20 H5V10h14V20z M19,8H5V6h14V8z M9,14H7v-2h2V14z M13,14h-2v-2h2V14z M17,14h-2v-2h2V14z M9,18H7v-2h2V18z M13,18h-2v-2h2V18z M17,18 h-2v-2h2V18z" />
        </g>
      </svg>
    );
    var ongoing = (
      <svg height="20px" viewBox="0 0 24 24" width="20px" fill="#2c3e50">
        <g fill="none">
          <path
            d="M14.712 2.289l-.087-.078a1 1 0 0 0-1.327.078l-.078.087a.999.999 0 0 0 .078 1.326l1.299 1.297H8.999l-.24.004A6.997 6.997 0 0 0 2 11.993c0 1.445.438 2.788 1.189 3.899a.999.999 0 0 0 1.626-1.163l-.135-.218A4.997 4.997 0 0 1 9 6.998h5.595l-1.297 1.297l-.078.087a.999.999 0 0 0 1.492 1.326l3.006-3.003l.077-.087a.999.999 0 0 0-.078-1.326l-3.005-3.003zm6.075 5.771A.999.999 0 0 0 19 8.677c0 .209.064.402.172.561a4.997 4.997 0 0 1-4.17 7.75H9.414l1.294-1.29l.083-.096a1 1 0 0 0-.006-1.23l-.077-.088l-.095-.084a1.001 1.001 0 0 0-1.232.006l-.088.078l-3.005 3.003l-.083.095a1 1 0 0 0 .006 1.231l.077.087l3.005 3.003l.095.084a1 1 0 0 0 1.397-1.41l-.077-.087l-1.304-1.303h5.596l.24-.003a6.997 6.997 0 0 0 5.546-10.927v.003z"
            fill="currentColor"
          ></path>
        </g>
      </svg>
    );

    if (store.state.timeEmptyList.includes(0)) {
      var n0 = 0;
      var v0 = "15";
    }
    if (store.state.timeEmptyList.includes(1)) {
      var n1 = 1;
      var v1 = "30";
    }
    if (store.state.timeEmptyList.includes(2)) {
      var n2 = 2;
      var v2 = "3h";
    }
    if (store.state.timeEmptyList.includes(3)) {
      var n3 = 3;
      var v3 = "1d";
    }
    if (store.state.timeEmptyList.includes(4)) {
      var n4 = 4;
      var v4 = "2d";
    }
    if (store.state.timeEmptyList.includes(5)) {
      var n5 = 5;
      var v5 = week;
    }
    if (store.state.timeEmptyList.includes(6)) {
      var n6 = 6;
      var v6 = month;
    }
    if (store.state.timeEmptyList.includes(7)) {
      var n7 = 7;
      var v7 = ongoing;
    }

    return {
      value: ref([
        store.state.timeSliderValueMin,
        store.state.timeSliderValueMax,
      ]),
      timeActive: ref(computed(() => store.state.timeActive)),

      marks: {
        [n0]: v0,
        [n1]: v1,
        [n2]: v2,
        [n3]: v3,
        [n4]: v4,
        [n5]: v5,
        [n6]: v6,
        [n7]: v7,
      },
    };
  },
  watch: {
    value() {
      console.log("TimeSlider value changed");
      var that = this
      // setTimeout(function () {
      // }, 250);
      this.updateTimeSlider();
    },
    deactivateTimeFilter() {
      console.log("deactivateTimeFilter")
      this.$store.state.timeActive = false;
      this.$store.state.filters.time.forEach(function (filterObject) {
        filterObject.selected = false;
        filterObject.lastAnswerWithValues = false
      })
      delete this.$store.state.clickedAnswers.time
      UpdateActivitiesService.updateActivities();
    }
  },
  methods: {
    // activateTimeSlider: function () {
    //   this.$store.state.timeActive = !this.$store.state.timeActive;
    //   this.updateTimeSlider();
    // },
    updateTimeSlider: function () {
      //console.log("KKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKK");
      //console.log(this.$store.state.timeEmptyList);
      let that = this;
      let thats = this.$store;
      let timeSliderValueMin = this.value[0];
      let timeSliderValueMax = this.value[1];
      var ftime = this.$store.state.filters.time;

      setTimeout(function () {
        that.$store.state.timeActive = true;
        if (
          that.value[0] == timeSliderValueMin &&
          that.value[1] == timeSliderValueMax
        ) {
          // console.log( "timeSliderValueMin = " + Math.min.apply(null, that.value) );
          thats.state.timeSliderValueMin = Math.min.apply(null, that.value);
          timeSliderValueMin = Math.min.apply(null, that.value);

          // console.log( "timeSliderValueMax = " + Math.max.apply(null, that.value) );
          thats.state.timeSliderValueMax = Math.max.apply(null, that.value);
          timeSliderValueMax = Math.max.apply(null, that.value);

          thats.state.clickedAnswers["time"] = {};
          switch (thats.state.timeSliderValueMax) {
            case 0:
              thats.state.clickedAnswers["time"][ftime[0].filterkey] = ftime[0];
              thats.state.filters.time[0].selected = true;
              thats.state.filters.time[1].selected = false;
              thats.state.filters.time[2].selected = false;
              thats.state.filters.time[3].selected = false;
              thats.state.filters.time[4].selected = false;
              thats.state.filters.time[5].selected = false;
              thats.state.filters.time[6].selected = false;
              thats.state.filters.time[7].selected = false;

              // console.log("000000000000000000000000000000");
              // console.log(thats.state.filters.time[0].selected);
              break;
            case 1:
              if (timeSliderValueMin == 0) {
                thats.state.clickedAnswers["time"][ftime[0].filterkey] =
                  ftime[0];
              }
              thats.state.clickedAnswers["time"][ftime[1].filterkey] = ftime[1];
              thats.state.filters.time[0].selected = timeSliderValueMin == 0;
              thats.state.filters.time[1].selected = true;
              thats.state.filters.time[2].selected = false;
              thats.state.filters.time[3].selected = false;
              thats.state.filters.time[4].selected = false;
              thats.state.filters.time[5].selected = false;
              thats.state.filters.time[6].selected = false;
              thats.state.filters.time[7].selected = false;
              // console.log("1111111111111111111111111111111");
              // console.log(thats.state.filters.time[1].selected);
              break;

            case 2:
              if (timeSliderValueMin == 0) {
                thats.state.clickedAnswers["time"][ftime[0].filterkey] =
                  ftime[0];
                thats.state.clickedAnswers["time"][ftime[1].filterkey] =
                  ftime[1];
              }
              if (timeSliderValueMin == 1) {
                thats.state.clickedAnswers["time"][ftime[1].filterkey] =
                  ftime[1];
              }
              thats.state.clickedAnswers["time"][ftime[2].filterkey] = ftime[2];

              thats.state.filters.time[0].selected = timeSliderValueMin == 0;
              thats.state.filters.time[1].selected =
                timeSliderValueMin == 0 || timeSliderValueMin == 1;
              thats.state.filters.time[2].selected = true;
              thats.state.filters.time[3].selected = false;
              thats.state.filters.time[4].selected = false;
              thats.state.filters.time[5].selected = false;
              thats.state.filters.time[6].selected = false;
              thats.state.filters.time[7].selected = false;
              // console.log("2222222222222222222222222222");
              // console.log(thats.state.filters.time[2].selected);
              break;

            case 3:
              if (timeSliderValueMin == 0) {
                thats.state.clickedAnswers["time"][ftime[0].filterkey] =
                  ftime[0];
                thats.state.clickedAnswers["time"][ftime[1].filterkey] =
                  ftime[1];
                thats.state.clickedAnswers["time"][ftime[2].filterkey] =
                  ftime[2];
              }
              if (timeSliderValueMin == 1) {
                thats.state.clickedAnswers["time"][ftime[1].filterkey] =
                  ftime[1];
                thats.state.clickedAnswers["time"][ftime[2].filterkey] =
                  ftime[2];
              }
              if (timeSliderValueMin == 2) {
                thats.state.clickedAnswers["time"][ftime[2].filterkey] =
                  ftime[2];
              }
              thats.state.clickedAnswers["time"][ftime[3].filterkey] = ftime[3];

              thats.state.filters.time[0].selected = timeSliderValueMin == 0;
              thats.state.filters.time[1].selected =
                timeSliderValueMin == 0 || timeSliderValueMin == 1;
              thats.state.filters.time[2].selected =
                timeSliderValueMin == 0 ||
                timeSliderValueMin == 1 ||
                timeSliderValueMin == 2;
              thats.state.filters.time[3].selected = true;
              thats.state.filters.time[4].selected = false;
              thats.state.filters.time[5].selected = false;
              thats.state.filters.time[6].selected = false;
              thats.state.filters.time[7].selected = false;
              // console.log("33333333333333333333333333");
              // console.log(thats.state.filters.time[3].selected);
              break;

            case 4:
              if (timeSliderValueMin == 0) {
                thats.state.clickedAnswers["time"][ftime[0].filterkey] =
                  ftime[0];
                thats.state.clickedAnswers["time"][ftime[1].filterkey] =
                  ftime[1];
                thats.state.clickedAnswers["time"][ftime[2].filterkey] =
                  ftime[2];
                thats.state.clickedAnswers["time"][ftime[3].filterkey] =
                  ftime[3];
              }
              if (timeSliderValueMin == 1) {
                thats.state.clickedAnswers["time"][ftime[1].filterkey] =
                  ftime[1];
                thats.state.clickedAnswers["time"][ftime[2].filterkey] =
                  ftime[2];
                thats.state.clickedAnswers["time"][ftime[3].filterkey] =
                  ftime[3];
              }
              if (timeSliderValueMin == 2) {
                thats.state.clickedAnswers["time"][ftime[2].filterkey] =
                  ftime[2];
                thats.state.clickedAnswers["time"][ftime[3].filterkey] =
                  ftime[3];
              }
              if (timeSliderValueMin == 3) {
                thats.state.clickedAnswers["time"][ftime[3].filterkey] =
                  ftime[3];
              }
              thats.state.clickedAnswers["time"][ftime[4].filterkey] = ftime[4];

              thats.state.filters.time[0].selected = timeSliderValueMin == 0;
              thats.state.filters.time[1].selected =
                timeSliderValueMin == 0 || timeSliderValueMin == 1;
              thats.state.filters.time[2].selected =
                timeSliderValueMin == 0 ||
                timeSliderValueMin == 1 ||
                timeSliderValueMin == 2;
              thats.state.filters.time[3].selected =
                timeSliderValueMin == 0 ||
                timeSliderValueMin == 1 ||
                timeSliderValueMin == 2 ||
                timeSliderValueMin == 3;
              thats.state.filters.time[4].selected = true;
              thats.state.filters.time[5].selected = false;
              thats.state.filters.time[6].selected = false;
              thats.state.filters.time[7].selected = false;
              // console.log("44444444444444444444444444");
              // console.log(thats.state.filters.time[4].selected);
              break;

            case 5:
              if (timeSliderValueMin == 0) {
                thats.state.clickedAnswers["time"][ftime[0].filterkey] =
                  ftime[0];
                thats.state.clickedAnswers["time"][ftime[1].filterkey] =
                  ftime[1];
                thats.state.clickedAnswers["time"][ftime[2].filterkey] =
                  ftime[2];
                thats.state.clickedAnswers["time"][ftime[3].filterkey] =
                  ftime[3];
                thats.state.clickedAnswers["time"][ftime[4].filterkey] =
                  ftime[4];
              }
              if (timeSliderValueMin == 1) {
                thats.state.clickedAnswers["time"][ftime[1].filterkey] =
                  ftime[1];
                thats.state.clickedAnswers["time"][ftime[2].filterkey] =
                  ftime[2];
                thats.state.clickedAnswers["time"][ftime[3].filterkey] =
                  ftime[3];
                thats.state.clickedAnswers["time"][ftime[4].filterkey] =
                  ftime[4];
              }
              if (timeSliderValueMin == 2) {
                thats.state.clickedAnswers["time"][ftime[2].filterkey] =
                  ftime[2];
                thats.state.clickedAnswers["time"][ftime[3].filterkey] =
                  ftime[3];
                thats.state.clickedAnswers["time"][ftime[4].filterkey] =
                  ftime[4];
              }
              if (timeSliderValueMin == 3) {
                thats.state.clickedAnswers["time"][ftime[3].filterkey] =
                  ftime[3];
                thats.state.clickedAnswers["time"][ftime[4].filterkey] =
                  ftime[4];
              }
              if (timeSliderValueMin == 4) {
                thats.state.clickedAnswers["time"][ftime[4].filterkey] =
                  ftime[4];
              }
              thats.state.clickedAnswers["time"][ftime[5].filterkey] = ftime[5];

              thats.state.filters.time[0].selected = timeSliderValueMin == 0;
              thats.state.filters.time[1].selected =
                timeSliderValueMin == 0 || timeSliderValueMin == 1;
              thats.state.filters.time[2].selected =
                timeSliderValueMin == 0 ||
                timeSliderValueMin == 1 ||
                timeSliderValueMin == 2;
              thats.state.filters.time[3].selected =
                timeSliderValueMin == 0 ||
                timeSliderValueMin == 1 ||
                timeSliderValueMin == 2 ||
                timeSliderValueMin == 3;
              thats.state.filters.time[4].selected =
                timeSliderValueMin == 0 ||
                timeSliderValueMin == 1 ||
                timeSliderValueMin == 2 ||
                timeSliderValueMin == 3 ||
                timeSliderValueMin == 4;
              thats.state.filters.time[5].selected = true;
              thats.state.filters.time[6].selected = false;
              thats.state.filters.time[7].selected = false;
              // console.log("5555555555555555555555555");
              break;

            case 6:
              if (timeSliderValueMin == 0) {
                thats.state.clickedAnswers["time"][ftime[0].filterkey] =
                  ftime[0];
                thats.state.clickedAnswers["time"][ftime[1].filterkey] =
                  ftime[1];
                thats.state.clickedAnswers["time"][ftime[2].filterkey] =
                  ftime[2];
                thats.state.clickedAnswers["time"][ftime[3].filterkey] =
                  ftime[3];
                thats.state.clickedAnswers["time"][ftime[4].filterkey] =
                  ftime[4];
                thats.state.clickedAnswers["time"][ftime[5].filterkey] =
                  ftime[5];
              }
              if (timeSliderValueMin == 1) {
                thats.state.clickedAnswers["time"][ftime[1].filterkey] =
                  ftime[1];
                thats.state.clickedAnswers["time"][ftime[2].filterkey] =
                  ftime[2];
                thats.state.clickedAnswers["time"][ftime[3].filterkey] =
                  ftime[3];
                thats.state.clickedAnswers["time"][ftime[4].filterkey] =
                  ftime[4];
                thats.state.clickedAnswers["time"][ftime[5].filterkey] =
                  ftime[5];
              }
              if (timeSliderValueMin == 2) {
                thats.state.clickedAnswers["time"][ftime[2].filterkey] =
                  ftime[2];
                thats.state.clickedAnswers["time"][ftime[3].filterkey] =
                  ftime[3];
                thats.state.clickedAnswers["time"][ftime[4].filterkey] =
                  ftime[4];
                thats.state.clickedAnswers["time"][ftime[5].filterkey] =
                  ftime[5];
              }
              if (timeSliderValueMin == 3) {
                thats.state.clickedAnswers["time"][ftime[3].filterkey] =
                  ftime[3];
                thats.state.clickedAnswers["time"][ftime[4].filterkey] =
                  ftime[4];
                thats.state.clickedAnswers["time"][ftime[5].filterkey] =
                  ftime[5];
              }
              if (timeSliderValueMin == 4) {
                thats.state.clickedAnswers["time"][ftime[4].filterkey] =
                  ftime[4];
                thats.state.clickedAnswers["time"][ftime[5].filterkey] =
                  ftime[5];
              }
              if (timeSliderValueMin == 5) {
                thats.state.clickedAnswers["time"][ftime[5].filterkey] =
                  ftime[5];
              }
              thats.state.clickedAnswers["time"][ftime[6].filterkey] = ftime[6];

              thats.state.filters.time[0].selected = timeSliderValueMin == 0;
              thats.state.filters.time[1].selected =
                timeSliderValueMin == 0 || timeSliderValueMin == 1;
              thats.state.filters.time[2].selected =
                timeSliderValueMin == 0 ||
                timeSliderValueMin == 1 ||
                timeSliderValueMin == 2;
              thats.state.filters.time[3].selected =
                timeSliderValueMin == 0 ||
                timeSliderValueMin == 1 ||
                timeSliderValueMin == 2 ||
                timeSliderValueMin == 3;
              thats.state.filters.time[4].selected =
                timeSliderValueMin == 0 ||
                timeSliderValueMin == 1 ||
                timeSliderValueMin == 2 ||
                timeSliderValueMin == 3 ||
                timeSliderValueMin == 4;
              thats.state.filters.time[5].selected =
                timeSliderValueMin == 0 ||
                timeSliderValueMin == 1 ||
                timeSliderValueMin == 2 ||
                timeSliderValueMin == 3 ||
                timeSliderValueMin == 4 ||
                timeSliderValueMin == 5;
              thats.state.filters.time[6].selected = true;
              thats.state.filters.time[7].selected = false;
              // console.log("66666666666666666666666666666");
              break;

            case 7:
              if (timeSliderValueMin == 0) {
                thats.state.clickedAnswers["time"][ftime[0].filterkey] =
                  ftime[0];
                thats.state.clickedAnswers["time"][ftime[1].filterkey] =
                  ftime[1];
                thats.state.clickedAnswers["time"][ftime[2].filterkey] =
                  ftime[2];
                thats.state.clickedAnswers["time"][ftime[3].filterkey] =
                  ftime[3];
                thats.state.clickedAnswers["time"][ftime[4].filterkey] =
                  ftime[4];
                thats.state.clickedAnswers["time"][ftime[5].filterkey] =
                  ftime[5];
                thats.state.clickedAnswers["time"][ftime[6].filterkey] =
                  ftime[6];
              }
              if (timeSliderValueMin == 1) {
                thats.state.clickedAnswers["time"][ftime[1].filterkey] =
                  ftime[1];
                thats.state.clickedAnswers["time"][ftime[2].filterkey] =
                  ftime[2];
                thats.state.clickedAnswers["time"][ftime[3].filterkey] =
                  ftime[3];
                thats.state.clickedAnswers["time"][ftime[4].filterkey] =
                  ftime[4];
                thats.state.clickedAnswers["time"][ftime[5].filterkey] =
                  ftime[5];
                thats.state.clickedAnswers["time"][ftime[6].filterkey] =
                  ftime[6];
              }
              if (timeSliderValueMin == 2) {
                thats.state.clickedAnswers["time"][ftime[2].filterkey] =
                  ftime[2];
                thats.state.clickedAnswers["time"][ftime[3].filterkey] =
                  ftime[3];
                thats.state.clickedAnswers["time"][ftime[4].filterkey] =
                  ftime[4];
                thats.state.clickedAnswers["time"][ftime[5].filterkey] =
                  ftime[5];
                thats.state.clickedAnswers["time"][ftime[6].filterkey] =
                  ftime[6];
              }
              if (timeSliderValueMin == 3) {
                thats.state.clickedAnswers["time"][ftime[3].filterkey] =
                  ftime[3];
                thats.state.clickedAnswers["time"][ftime[4].filterkey] =
                  ftime[4];
                thats.state.clickedAnswers["time"][ftime[5].filterkey] =
                  ftime[5];
                thats.state.clickedAnswers["time"][ftime[6].filterkey] =
                  ftime[6];
              }
              if (timeSliderValueMin == 4) {
                thats.state.clickedAnswers["time"][ftime[4].filterkey] =
                  ftime[4];
                thats.state.clickedAnswers["time"][ftime[5].filterkey] =
                  ftime[5];
                thats.state.clickedAnswers["time"][ftime[6].filterkey] =
                  ftime[6];
              }
              if (timeSliderValueMin == 5) {
                thats.state.clickedAnswers["time"][ftime[5].filterkey] =
                  ftime[5];
                thats.state.clickedAnswers["time"][ftime[6].filterkey] =
                  ftime[6];
              }
              if (timeSliderValueMin == 6) {
                thats.state.clickedAnswers["time"][ftime[6].filterkey] =
                  ftime[6];
              }
              thats.state.clickedAnswers["time"][ftime[7].filterkey] = ftime[7];

              thats.state.filters.time[0].selected = timeSliderValueMin == 0;
              thats.state.filters.time[1].selected =
                timeSliderValueMin == 0 || timeSliderValueMin == 1;
              thats.state.filters.time[2].selected =
                timeSliderValueMin == 0 ||
                timeSliderValueMin == 1 ||
                timeSliderValueMin == 2;
              thats.state.filters.time[3].selected =
                timeSliderValueMin == 0 ||
                timeSliderValueMin == 1 ||
                timeSliderValueMin == 2 ||
                timeSliderValueMin == 3;
              thats.state.filters.time[4].selected =
                timeSliderValueMin == 0 ||
                timeSliderValueMin == 1 ||
                timeSliderValueMin == 2 ||
                timeSliderValueMin == 3 ||
                timeSliderValueMin == 4;
              thats.state.filters.time[5].selected =
                timeSliderValueMin == 0 ||
                timeSliderValueMin == 1 ||
                timeSliderValueMin == 2 ||
                timeSliderValueMin == 3 ||
                timeSliderValueMin == 4 ||
                timeSliderValueMin == 5;
              thats.state.filters.time[6].selected =
                timeSliderValueMin == 0 ||
                timeSliderValueMin == 1 ||
                timeSliderValueMin == 2 ||
                timeSliderValueMin == 3 ||
                timeSliderValueMin == 4 ||
                timeSliderValueMin == 5 ||
                timeSliderValueMin == 6;
              thats.state.filters.time[7].selected = true;
              // console.log("77777777777777777777777777");
              break;
          }
          if (!thats.state.timeActive) {
            delete thats.state.clickedAnswers["time"];
            thats.state.filters.time[0].selected = false;
            thats.state.filters.time[1].selected = false;
            thats.state.filters.time[2].selected = false;
            thats.state.filters.time[3].selected = false;
            thats.state.filters.time[4].selected = false;
            thats.state.filters.time[5].selected = false;
            thats.state.filters.time[6].selected = false;
            thats.state.filters.time[7].selected = false;
          }
          // console.log(thats.state.filters.time);
          // console.log(thats.state.clickedAnswers);

          UpdateActivitiesService.updateActivities();

          ClickFilterService.clickFilter("onlycheckcountzeroandresethistory");
        }
      }, 200);
    },
  },
});
</script>
<style scoped>
.n-slider {
  --n-dot-border: none !important;
  --n-dot-border-active: none !important;
  --n-fill-color: #007bff !important;
  --n-fill-color-hover: #007bff !important;
  touch-action: pan-x;
}

.n-switch.n-switch--active {
  --n-rail-color-active: #007bff !important;
  --n-box-shadow-focus: 0 0 0 2px transparent !important;
  --n-loading-color: #007bff !important;
  /*background-color: #6610f280 !important;*/
  --n-rail-color: #007bff !important;
}

.n-switch {
  --n-rail-color-active: #007bff !important;
  --n-box-shadow-focus: 0 0 0 2px transparent !important;
  --n-loading-color: #007bff !important;
  /*background-color: #6610f280 !important;*/
  --n-rail-color: #007bff80 !important;
}
</style>
<style>
.v-binder-follower-container {
  z-index: 1 !important;
}

.n-slider-dot {
  height: 0rem !important;
}

.n-slider-mark {
  width: 2rem;
  position: static;
}
</style>