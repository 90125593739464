<!-- ---------- ---------- ---------- HTML Structure ---------- ---------- ---------- -->
<template>
  <div>
    <div class="infotitle" v-if="false">
      <span>
        {{ $store.state.appTexts.learnmore[$store.state.user.countryCode] }}
      </span>
    </div>

    <WelcomeComponent
      class="border-bottom spacebelow"
      v-if="activity.welcome[$store.state.user.countryCode] != 0"
    />

    <!--Wikipedia excerpt-->
    <div
      v-if="activity.wikitext[$store.state.user.countryCode] != 0"
      v-bind:class="{
        space: activity.welcome[$store.state.user.countryCode] != 0,
        spacebelow: activity.welcome[$store.state.user.countryCode] == 0,
      }"
    >
      <div class="textboxwikipedia" v-if="$store.state.wikiLoaded">
        {{ $store.state.storeWikipediaText }}
      </div>
      <div v-if="!$store.state.wikiLoaded" class="loading">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <div class="linktowiki">
        <div class="linkleft">
          <a
            v-bind:href="
              'https://www.wikipedia.org/wiki/' +
              activity.wikitext[$store.state.user.countryCode]
            "
            target="_blank"
            text-align="center"
          >
            <img
              v-bind:src="'https://storage.googleapis.com/timetip-images/link-images/wikipedia.png'"
              @error="setAltImg"
              class="link2"
          /></a>
        </div>
        <div class="linkright">
          <a
            class="link2"
            v-bind:href="
              $store.state.appTexts.wikilink[$store.state.user.countryCode] +
              activity.wikitext[$store.state.user.countryCode]
            "
            target="_blank"
            text-align="center"
          >
            {{
              $store.state.appTexts.readwholewikipediaarticle[
                $store.state.user.countryCode
              ]
            }}
          </a>
        </div>
      </div>
    </div>
    <PlanComponent
      v-if="activity.plan != 0 && false"
      class="border-bottom space"
    />
  </div>
</template>

  <!-- ---------- ---------- ---------- Code ---------- ---------- ---------- -->

<script>
/* eslint-disable */
import PlanComponent from "@/components/Details/PlanComponent.vue";
import WelcomeComponent from "@/components/Details/WelcomeComponent.vue";

export default {
  components: {
    PlanComponent,
    WelcomeComponent,
  },
  props: {
    activityListPosition: Number,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    setAltImg(event) {
      console.log("image could not be loaded");
      event.target.src = require("../../assets/timetip-logo.svg");
    },
  },
  computed: {
    activity() {
      return this.$store.state.storeActivityList[this.activityListPosition];
    },
  },
};
</script>

<style scoped>
.infotitle {
  padding-bottom: 1rem;
  font-weight: bold;
  color: #007bff;
}
.btn {
  color: #000000;
  background-color: #ffffff;
  font-weight: bold;
  border: none;
  display: inline-block;
  width: 100%;
  margin-left: 0rem;
  margin-right: 0rem;
  border-radius: 0.25rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  position: relative;
}

.textboxwikipedia {
  padding: 0rem 0.5rem 0rem 0.5rem;
  font-size: 0.9rem;
  border-radius: 1rem;
  background-color: #ffffff;
  text-align: left;
  color: #2c3e50;
}
.textdummy {
  height: 1.5rem;
  color: transparent;
}
.link {
  position: absolute;
  right: 90%;
  height: 1.5rem;
  margin: 0rem;
  padding: 0rem;
}
.headerwikipedia {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.linktowiki {
  display: inline-block;
  margin-top: 1rem;
  font-size: 0.9rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-radius: 1rem;
  border: 1px solid #ced4da;
  background-color: #ffffff;
  color: #2c3e50;
  text-align: center;
  cursor: pointer;
  width: 100%;
}
.linkleft {
  display: inline-block;
  width: 20%;
  text-align: center;
}
.linkright {
  display: inline-block;
  width: 80%;
  text-align: left;
  vertical-align: middle;
}
.link2 {
  height: 2rem;
  color: #2c3e50;
}
a {
  color: white;
  text-decoration: none;
}
</style>