<template>
  <n-space vertical class="swiper-no-swiping">
    <n-slider
      v-model:value="value"
      :step="1"
      :min="$store.state.ageFixMin"
      :max="$store.state.ageFixMax"
      :marks="marks"
    />
  </n-space>
</template>

<script>
/* eslint-disable */
import { defineComponent, ref } from "vue";
import UpdateActivitiesService from "@/services/UpdateActivitiesService.js";
import { useStore } from "vuex";
import ClickFilterService from "@/services/ClickFilterService.js";

export default defineComponent({
  setup() {
    const store = useStore();
    return {
      value: ref(store.state.ageMin),
      valueMax: ref(store.state.ageMax),

      marks: {
        [store.state.ageFixMin]: store.state.ageFixMin,
        [store.state.ageFixMax]: store.state.ageFixMax,
      },
    };
  },
  props: {
    deactivateAgeFilter: Boolean,
  },
  watch: {
    value() {
      console.log("Slider value changed");
      this.updateAgeSlider()
    },
    deactivateAgeFilter() {
      this.$store.state.ageActive = !this.$store.state.ageActive;
      if (!this.$store.state.ageActive) {
        this.$store.state.ageRangeSliderSwitch = true;
      }

      UpdateActivitiesService.updateActivities();

      ClickFilterService.clickFilter("onlycheckcountzeroandresethistory");
    }
  },
  methods: {
    updateAgeSlider() {
      let that = this;
      let thats = this.$store;
      let ageMin = this.value;

      setTimeout(function () {
        if (that.value == ageMin) {
          console.log("ageMin = " + that.value);
          thats.state.ageMin = that.value;
          thats.state.ageMax = that.valueMax;

          thats.state.ageActive = true;
          UpdateActivitiesService.updateActivities();

          ClickFilterService.clickFilter("onlycheckcountzeroandresethistory");
        }
      }, 100);
    },
  },
});
</script>
<style scoped>
.n-slider {
  --n-dot-border-active: 2px solid #007bff !important;
  --n-fill-color: #007bff !important;
  --n-fill-color-hover: #007bff !important;
  touch-action: pan-x;
}
</style>