import { createStore } from "vuex";
import { auth } from '../firebase/config'
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged
} from 'firebase/auth'

const store = createStore({
  state: {
    appleAppStore: false,
    betaFeatures: false,
    newUI: true,
    // Might be deleted soon - also at other places
    dataLoaded: false,
    bootingDone: false,
    linksloaded: false,
    spicesloaded: false,
    imageLoaded: false,
    storeTest: "storeTest",
    activityListView: "card",
    linkView: "icondetails",
    storeWikipediaText: "",
    storeWikipediaImage: "noImageAvailable", // currently not used
    wikiLoaded: false,
    weather: {},
    weatherLoaded: false,
    date: {},
    city: "Frankfurt",
    storeUnsplashImage: "noImageAvailable",
    storeLinkList: [],
    storeSpiceObject: {},
    storeActivityList: [],
    storeActivityListFiltered: [],
    storeActivityListFavorites: [],
    storeActivityListSearched: [],
    // user
    favoritesObject: {},
    checkboxDataPrivacy: false,
    checkboxMails: false,
    user: {
      countryCode: "DE",
    },

    appTexts: {},
    filterCombosNew: {},
    activityCombo: {},
    updateActivitiesMethod: "setUnion", // toggle between "Vereinigungsmenge / "set union" and "Schnittmenge / intersection"
    isTouchDevice: true,
    browserName: String,
    firstVisit: true,
    discoverySwipe: 0,
    // Explanations
    filterExplanationClicked: false,
    detailsExplanationFinished: false,
    explanationState: 0,
    lastSlide: false,

    // 02.08.2021 New Objects for updateActivitiesService
    clickedAnswers: {},
    questionsObject: {},
    // An Object including one Array for each question that includes objects with "name" and "count"
    filters: {},
    filterClicked: false,
    activityViewDetails: true,
    // filterSetAtDiscoverView: false,
    // Selectors for Intersection mode at the following three questions:
    itypical: false,
    itarget: false,
    idayweektime: true,
    ispecial: true,
    showClickedFiltersAtDetails: false,
    // ageSlider
    ageFixMin: 0,
    ageFixMax: 99,
    ageMin: 18,
    ageMax: 40,
    ageRangeSliderSwitch: true,
    ageChildrenParentsSwitch: true,
    ageFixMinSmallerEighteen: true,
    ageActive: false,
    ageSave: false,

    // costSlider
    costSliderValueMin: 1,
    costSliderValueMax: 4,
    costFixMin: 0,
    costFixMax: 4,
    costEmptyList: [],
    costActive: false,

    // timeSlider
    timeSliderValueMin: 1,
    timeSliderValueMax: 3,
    timeFixMin: 0,
    timeFixMax: 7,
    timeEmptyList: [],
    timeActive: false,


    favoritesToggle: false,
    searchToggle: false,
    searchText: "",
    favoritesSnackboarAndText: { snackbar: false, text: 'favoriteSnackbar', favType: 'noheart' },
    filterSnackbar: false,

    // Details
    imageViewToggle: 2,
    detailsViewToggle: 2,
    detailsView: 'link',
    detailsPageVisited: false,

    activity: "none",
    storeSelectedActivityId: (-1), // Hilft aktuell bei der Prüfung, ob eine Aktivität ausgwählt wurde
    storeSelectedActivityListPosition: 0,
    storeActivityHistory: [],
    storeActivityHistoryPosition: 0,

    addToHomescreenFunction: null,
    // Firebase
    nutzer: null,
    authIsReady: false,
    loginUrl: {
      local: "http://localhost:8080/login",
      test: "https://test.timetip.app/login",
      prod: "https://timetip.app/login",
    },
    FSSnapShotSetUp: false,
    unsubscibeOnSnapshot: null,
  },
  //////////////////////////////////////////////// Mutations
  mutations: {
    setUser(state, payload) {
      state.nutzer = payload
      console.log('user state changed:', state.nutzer)
    },
    setAuthIsReady(state, payload) {
      state.authIsReady = payload
    }
  },
  //////////////////////////////////////////////// Actions
  actions: {
    async signup(context, { email, password }) {
      console.log("BACKEND CALL")
      console.log('signup action')

      const res = await createUserWithEmailAndPassword(auth, email, password)
      if (res) {
        context.commit('setUser', res.user)
      } else {
        throw new Error('could not complete signup')
      }
    },
    async login(context, { email, password }) {
      console.log("BACKEND CALL")
      console.log('login action')

      const res = await signInWithEmailAndPassword(auth, email, password)
      if (res) {
        context.commit('setUser', res.user)
      } else {
        throw new Error('could not complete login')
      }
    },
    async logout(context) {
      console.log("BACKEND CALL")
      console.log('logout action')
      store.state.unsubscibeOnSnapshot()
      console.log("unsubscibeOnSnapshot")
      await signOut(auth)
      context.commit('setUser', null)
    }
  },
  modules: {},
});

// wait until auth is ready
const unsub = onAuthStateChanged(auth, (user) => {
  console.log("BACKEND CALL")
  console.log("AuthIs Ready")
  store.commit('setAuthIsReady', true)
  store.commit('setUser', user)
  unsub()
})

// export the store
export default store