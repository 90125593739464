<!-- ---------- ---------- ---------- HTML Structure ---------- ---------- ---------- -->
<template>
  <div class="page">
    <!--English-->
    <div v-if="$store.state.user.countryCode != 'DE'" class="container">
      <h1>Privacy Policy</h1>
      <h2>1. An overview of data protection</h2>
      <h3>General information</h3>
      <p>
        The following information will provide you with an easy to navigate
        overview of what will happen with your personal data when you visit this
        website. The term “personal data” comprises all data that can be used to
        personally identify you. For detailed information about the subject
        matter of data protection, please consult our Data Protection
        Declaration, which we have included beneath this copy.
      </p>
      <h3>Data recording on this website</h3>
      <h4>
        Who is the responsible party for the recording of data on this website
        (i.e., the “controller”)?
      </h4>
      <p>
        The data on this website is processed by the operator of the website,
        whose contact information is available under section “Information about
        the responsible party (referred to as the “controller” in the GDPR)” in
        this Privacy Policy.
      </p>
      <h4>How do we record your data?</h4>
      <p>
        We collect your data as a result of your sharing of your data with us.
        This may, for instance be information you enter into our contact form.
      </p>
      <p>
        Other data shall be recorded by our IT systems automatically or after
        you consent to its recording during your website visit. This data
        comprises primarily technical information (e.g., web browser, operating
        system, or time the site was accessed). This information is recorded
        automatically when you access this website.
      </p>
      <h4>What are the purposes we use your data for?</h4>
      <p>
        A portion of the information is generated to guarantee the error free
        provision of the website. Other data may be used to analyze your user
        patterns.
      </p>
      <h4>What rights do you have as far as your information is concerned?</h4>
      <p>
        You have the right to receive information about the source, recipients,
        and purposes of your archived personal data at any time without having
        to pay a fee for such disclosures. You also have the right to demand
        that your data are rectified or eradicated. If you have consented to
        data processing, you have the option to revoke this consent at any time,
        which shall affect all future data processing. Moreover, you have the
        right to demand that the processing of your data be restricted under
        certain circumstances. Furthermore, you have the right to log a
        complaint with the competent supervising agency.
      </p>
      <p>
        Please do not hesitate to contact us at any time if you have questions
        about this or any other data protection related issues.
      </p>
      <h3>Analysis tools and tools provided by third parties</h3>
      <p>
        There is a possibility that your browsing patterns will be statistically
        analyzed when your visit this website. Such analyses are performed
        primarily with what we refer to as analysis programs.
      </p>
      <p>
        For detailed information about these analysis programs please consult
        our Data Protection Declaration below.
      </p>
      <h2>2. Hosting</h2>
      <h3>External Hosting</h3>
      <p>
        This website is hosted by an external service provider (host). Personal
        data collected on this website are stored on the servers of the host.
        These may include, but are not limited to, IP addresses, contact
        requests, metadata and communications, contract information, contact
        information, names, web page access, and other data generated through a
        web site.
      </p>
      <p>
        The host is used for the purpose of fulfilling the contract with our
        potential and existing customers (Art. 6(1)(b) GDPR) and in the interest
        of secure, fast, and efficient provision of our online services by a
        professional provider (Art. 6(1)(f) GDPR). If appropriate consent has
        been obtained, the processing is carried out exclusively on the basis of
        Art. 6 (1)(a) GDPR and § 25 (1) TTDSG, insofar the consent includes the
        storage of cookies or the access to information in the user's end device
        (e.g., device fingerprinting) within the meaning of the TTDSG. This
        consent can be revoked at any time.
      </p>
      <p>
        Our host will only process your data to the extent necessary to fulfil
        its performance obligations and to follow our instructions with respect
        to such data.
      </p>
      <p>We are using the following host:</p>
      <p>
        Google Ireland Limited<br />
        Gordon House, Barrow Street<br />
        Dublin 4<br />
        Irland
      </p>
      <h2>3. General information and mandatory information</h2>
      <h3>Data protection</h3>
      <p>
        The operators of this website and its pages take the protection of your
        personal data very seriously. Hence, we handle your personal data as
        confidential information and in compliance with the statutory data
        protection regulations and this Data Protection Declaration.
      </p>
      <p>
        Whenever you use this website, a variety of personal information will be
        collected. Personal data comprises data that can be used to personally
        identify you. This Data Protection Declaration explains which data we
        collect as well as the purposes we use this data for. It also explains
        how, and for which purpose the information is collected.
      </p>
      <p>
        We herewith advise you that the transmission of data via the Internet
        (i.e., through e-mail communications) may be prone to security gaps. It
        is not possible to completely protect data against third-party access.
      </p>
      <h3>
        Information about the responsible party (referred to as the “controller”
        in the GDPR)
      </h3>
      <p>The data processing controller on this website is:</p>
      <p>
        Timetip GmbH<br />
        Angererstrasse, 24<br />
        80796 München
      </p>

      <p>
        <img v-bind:src="require('../assets/ttp.png')" class="phonum" /><br />
        <img v-bind:src="require('../assets/ttm.png')" class="emage" />
      </p>
      <p>
        The controller is the natural person or legal entity that
        single-handedly or jointly with others makes decisions as to the
        purposes of and resources for the processing of personal data (e.g.,
        names, e-mail addresses, etc.).
      </p>

      <h3>Storage duration</h3>
      <p>
        Unless a more specific storage period has been specified in this privacy
        policy, your personal data will remain with us until the purpose for
        which it was collected no longer applies. If you assert a justified
        request for deletion or revoke your consent to data processing, your
        data will be deleted, unless we have other legally permissible reasons
        for storing your personal data (e.g., tax or commercial law retention
        periods); in the latter case, the deletion will take place after these
        reasons cease to apply.
      </p>
      <h3>
        General information on the legal basis for the data processing on this
        website
      </h3>
      <p>
        If you have consented to data processing, we process your personal data
        on the basis of Art. 6(1)(a) GDPR or Art. 9 (2)(a) GDPR, if special
        categories of data are processed according to Art. 9 (1) DSGVO. In the
        case of explicit consent to the transfer of personal data to third
        countries, the data processing is also based on Art. 49 (1)(a) GDPR. If
        you have consented to the storage of cookies or to the access to
        information in your end device (e.g., via device fingerprinting), the
        data processing is additionally based on § 25 (1) TTDSG. The consent can
        be revoked at any time. If your data is required for the fulfillment of
        a contract or for the implementation of pre-contractual measures, we
        process your data on the basis of Art. 6(1)(b) GDPR. Furthermore, if
        your data is required for the fulfillment of a legal obligation, we
        process it on the basis of Art. 6(1)(c) GDPR. Furthermore, the data
        processing may be carried out on the basis of our legitimate interest
        according to Art. 6(1)(f) GDPR. Information on the relevant legal basis
        in each individual case is provided in the following paragraphs of this
        privacy policy.
      </p>
      <h3>
        Information on data transfer to the USA and other non-EU countries
      </h3>
      <p>
        Among other things, we use tools of companies domiciled in the United
        States or other from a data protection perspective non-secure non-EU
        countries. If these tools are active, your personal data may potentially
        be transferred to these non-EU countries and may be processed there. We
        must point out that in these countries, a data protection level that is
        comparable to that in the EU cannot be guaranteed. For instance, U.S.
        enterprises are under a mandate to release personal data to the security
        agencies and you as the data subject do not have any litigation options
        to defend yourself in court. Hence, it cannot be ruled out that U.S.
        agencies (e.g., the Secret Service) may process, analyze, and
        permanently archive your personal data for surveillance purposes. We
        have no control over these processing activities.
      </p>
      <h3>Revocation of your consent to the processing of data</h3>
      <p>
        A wide range of data processing transactions are possible only subject
        to your express consent. You can also revoke at any time any consent you
        have already given us. This shall be without prejudice to the lawfulness
        of any data collection that occurred prior to your revocation.
      </p>
      <h3>
        Right to object to the collection of data in special cases; right to
        object to direct advertising (Art. 21 GDPR)
      </h3>
      <p>
        IN THE EVENT THAT DATA ARE PROCESSED ON THE BASIS OF ART. 6(1)(E) OR (F)
        GDPR, YOU HAVE THE RIGHT TO AT ANY TIME OBJECT TO THE PROCESSING OF YOUR
        PERSONAL DATA BASED ON GROUNDS ARISING FROM YOUR UNIQUE SITUATION. THIS
        ALSO APPLIES TO ANY PROFILING BASED ON THESE PROVISIONS. TO DETERMINE
        THE LEGAL BASIS, ON WHICH ANY PROCESSING OF DATA IS BASED, PLEASE
        CONSULT THIS DATA PROTECTION DECLARATION. IF YOU LOG AN OBJECTION, WE
        WILL NO LONGER PROCESS YOUR AFFECTED PERSONAL DATA, UNLESS WE ARE IN A
        POSITION TO PRESENT COMPELLING PROTECTION WORTHY GROUNDS FOR THE
        PROCESSING OF YOUR DATA, THAT OUTWEIGH YOUR INTERESTS, RIGHTS AND
        FREEDOMS OR IF THE PURPOSE OF THE PROCESSING IS THE CLAIMING, EXERCISING
        OR DEFENCE OF LEGAL ENTITLEMENTS (OBJECTION PURSUANT TO ART. 21(1)
        GDPR).
      </p>
      <p>
        IF YOUR PERSONAL DATA IS BEING PROCESSED IN ORDER TO ENGAGE IN DIRECT
        ADVERTISING, YOU HAVE THE RIGHT TO OBJECT TO THE PROCESSING OF YOUR
        AFFECTED PERSONAL DATA FOR THE PURPOSES OF SUCH ADVERTISING AT ANY TIME.
        THIS ALSO APPLIES TO PROFILING TO THE EXTENT THAT IT IS AFFILIATED WITH
        SUCH DIRECT ADVERTISING. IF YOU OBJECT, YOUR PERSONAL DATA WILL
        SUBSEQUENTLY NO LONGER BE USED FOR DIRECT ADVERTISING PURPOSES
        (OBJECTION PURSUANT TO ART. 21(2) GDPR).
      </p>
      <h3>Right to log a complaint with the competent supervisory agency</h3>
      <p>
        In the event of violations of the GDPR, data subjects are entitled to
        log a complaint with a supervisory agency, in particular in the member
        state where they usually maintain their domicile, place of work or at
        the place where the alleged violation occurred. The right to log a
        complaint is in effect regardless of any other administrative or court
        proceedings available as legal recourses.
      </p>
      <h3>Right to data portability</h3>
      <p>
        You have the right to demand that we hand over any data we automatically
        process on the basis of your consent or in order to fulfil a contract be
        handed over to you or a third party in a commonly used, machine readable
        format. If you should demand the direct transfer of the data to another
        controller, this will be done only if it is technically feasible.
      </p>
      <h3>SSL and/or TLS encryption</h3>
      <p>
        For security reasons and to protect the transmission of confidential
        content, such as purchase orders or inquiries you submit to us as the
        website operator, this website uses either an SSL or a TLS encryption
        program. You can recognize an encrypted connection by checking whether
        the address line of the browser switches from “http://” to “https://”
        and also by the appearance of the lock icon in the browser line.
      </p>
      <p>
        If the SSL or TLS encryption is activated, data you transmit to us
        cannot be read by third parties.
      </p>
      <h3>Information about, rectification and eradication of data</h3>
      <p>
        Within the scope of the applicable statutory provisions, you have the
        right to at any time demand information about your archived personal
        data, their source and recipients as well as the purpose of the
        processing of your data. You may also have a right to have your data
        rectified or eradicated. If you have questions about this subject matter
        or any other questions about personal data, please do not hesitate to
        contact us at any time.
      </p>
      <h3>Right to demand processing restrictions</h3>
      <p>
        You have the right to demand the imposition of restrictions as far as
        the processing of your personal data is concerned. To do so, you may
        contact us at any time. The right to demand restriction of processing
        applies in the following cases:
      </p>
      <ul>
        <li>
          In the event that you should dispute the correctness of your data
          archived by us, we will usually need some time to verify this claim.
          During the time that this investigation is ongoing, you have the right
          to demand that we restrict the processing of your personal data.
        </li>
        <li>
          If the processing of your personal data was/is conducted in an
          unlawful manner, you have the option to demand the restriction of the
          processing of your data in lieu of demanding the eradication of this
          data.
        </li>
        <li>
          If we do not need your personal data any longer and you need it to
          exercise, defend or claim legal entitlements, you have the right to
          demand the restriction of the processing of your personal data instead
          of its eradication.
        </li>
        <li>
          If you have raised an objection pursuant to Art. 21(1) GDPR, your
          rights and our rights will have to be weighed against each other. As
          long as it has not been determined whose interests prevail, you have
          the right to demand a restriction of the processing of your personal
          data.
        </li>
      </ul>
      <p>
        If you have restricted the processing of your personal data, these data
        – with the exception of their archiving – may be processed only subject
        to your consent or to claim, exercise or defend legal entitlements or to
        protect the rights of other natural persons or legal entities or for
        important public interest reasons cited by the European Union or a
        member state of the EU.
      </p>
      <h3>Rejection of unsolicited e-mails</h3>
      <p>
        We herewith object to the use of contact information published in
        conjunction with the mandatory information to be provided in our Site
        Notice to send us promotional and information material that we have not
        expressly requested. The operators of this website and its pages reserve
        the express right to take legal action in the event of the unsolicited
        sending of promotional information, for instance via SPAM messages.
      </p>
      <h2>4. Recording of data on this website</h2>
      <h3>Cookies</h3>
      <p>
        Our websites and pages use what the industry refers to as “cookies.”
        Cookies are small text files that do not cause any damage to your
        device. They are either stored temporarily for the duration of a session
        (session cookies) or they are permanently archived on your device
        (permanent cookies). Session cookies are automatically deleted once you
        terminate your visit. Permanent cookies remain archived on your device
        until you actively delete them, or they are automatically eradicated by
        your web browser.
      </p>
      <p>
        In some cases, it is possible that third-party cookies are stored on
        your device once you enter our site (third-party cookies). These cookies
        enable you or us to take advantage of certain services offered by the
        third party (e.g., cookies for the processing of payment services).
      </p>
      <p>
        Cookies have a variety of functions. Many cookies are technically
        essential since certain website functions would not work in the absence
        of the cookies (e.g., the shopping cart function or the display of
        videos). The purpose of other cookies may be the analysis of user
        patterns or the display of promotional messages.
      </p>
      <p>
        Cookies, which are required for the performance of electronic
        communication transactions, or for the provision of certain functions
        you want to use (e.g., for the shopping cart function) or those that are
        necessary for the optimization (required cookies) of the website (e.g.,
        cookies that provide measurable insights into the web audience), shall
        be stored on the basis of Art. 6(1)(f) GDPR, unless a different legal
        basis is cited. The operator of the website has a legitimate interest in
        the storage of required cookies to ensure the technically error free and
        optimized provision of the operator’s services. If your consent to the
        storage of the cookies and similar recognition technologies has been
        requested, processing occurs exclusively on the basis of the consent
        obtained (Art. 6(1)(a) GDPR and § 25 (1) TTDSG); this consent may be
        revoked at any time.
      </p>
      <p>
        You have the option to set up your browser in such a manner that you
        will be notified any time cookies are placed and to permit the
        acceptance of cookies only in specific cases. You may also exclude the
        acceptance of cookies in certain cases or in general or activate the
        delete function for the automatic eradication of cookies when the
        browser closes. If cookies are deactivated, the functions of this
        website may be limited.
      </p>
      <p>
        In the event that third-party cookies are used or if cookies are used
        for analytical purposes, we will separately notify you in conjunction
        with this Data Protection Policy and, if applicable, ask for your
        consent.
      </p>
      <h3>Consent with Usercentrics</h3>
      <p>
        This website uses the consent technology of Usercentrics to obtain your
        consent to the storage of certain cookies on your device or for the use
        of specific technologies, and to document the former in a data
        protection compliant manner. The party offering this technology is
        Usercentrics GmbH, Sendlinger Straße 7, 80331 München, Germany, website:
        <a
          href="https://usercentrics.com/de/"
          target="_blank"
          rel="noopener noreferrer"
          >https://usercentrics.com/</a
        >
        (hereinafter referred to as “Usercentrics”).
      </p>
      <p>
        Whenever you visit our website, the following personal data will be
        transferred to Usercentrics:
      </p>
      <ul>
        <li>
          Your declaration(s) of consent or your revocation of your
          declaration(s) of consent
        </li>
        <li>Your IP address</li>
        <li>Information about your browser</li>
        <li>Information about your device</li>
        <li>The date and time you visited our website</li>
      </ul>
      <p>
        Moreover, Usercentrics shall store a cookie in your browser to be able
        to allocate your declaration(s) of consent or any revocations of the
        former. The data that are recorded in this manner shall be stored until
        you ask us to eradicate them, delete the Usercentrics cookie or until
        the purpose for archiving the data no longer exists. This shall be
        without prejudice to any mandatory legal retention periods.
      </p>
      <p>
        Usercentrics uses cookies to obtain the declarations of consent mandated
        by law. The legal basis for the use of specific technologies is Art.
        6(1)(c) GDPR.
      </p>
      <h4>Data processing</h4>
      <p>
        We have concluded a data processing agreement (DPA) with the
        above-mentioned provider. This is a contract mandated by data privacy
        laws that guarantees that they process personal data of our website
        visitors only based on our instructions and in compliance with the GDPR.
      </p>
      <h3>Contact form</h3>
      <p>
        If you submit inquiries to us via our contact form, the information
        provided in the contact form as well as any contact information provided
        therein will be stored by us in order to handle your inquiry and in the
        event that we have further questions. We will not share this information
        without your consent.
      </p>
      <p>
        The processing of these data is based on Art. 6(1)(b) GDPR, if your
        request is related to the execution of a contract or if it is necessary
        to carry out pre-contractual measures. In all other cases the processing
        is based on our legitimate interest in the effective processing of the
        requests addressed to us (Art. 6(1)(f) GDPR) or on your agreement (Art.
        6(1)(a) GDPR) if this has been requested; the consent can be revoked at
        any time.
      </p>
      <p>
        The information you have entered into the contact form shall remain with
        us until you ask us to eradicate the data, revoke your consent to the
        archiving of data or if the purpose for which the information is being
        archived no longer exists (e.g., after we have concluded our response to
        your inquiry). This shall be without prejudice to any mandatory legal
        provisions, in particular retention periods.
      </p>
      <h3>Request by e-mail, telephone, or fax</h3>
      <p>
        If you contact us by e-mail, telephone or fax, your request, including
        all resulting personal data (name, request) will be stored and processed
        by us for the purpose of processing your request. We do not pass these
        data on without your consent.
      </p>
      <p>
        These data are processed on the basis of Art. 6(1)(b) GDPR if your
        inquiry is related to the fulfillment of a contract or is required for
        the performance of pre-contractual measures. In all other cases, the
        data are processed on the basis of our legitimate interest in the
        effective handling of inquiries submitted to us (Art. 6(1)(f) GDPR) or
        on the basis of your consent (Art. 6(1)(a) GDPR) if it has been
        obtained; the consent can be revoked at any time.
      </p>
      <p>
        The data sent by you to us via contact requests remain with us until you
        request us to delete, revoke your consent to the storage or the purpose
        for the data storage lapses (e.g. after completion of your request).
        Mandatory statutory provisions - in particular statutory retention
        periods - remain unaffected.
      </p>
      <h3>Google Forms</h3>
      <p>
        We have integrated Google Forms into this website. The provider is
        Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Ireland
        (hereinafter referred to as „Google”).
      </p>
      <p>
        Google Forms enables us to generate online forms to record messages,
        inquiries and other entries entered by visitors to our website. All
        entries you make will be processed on Google’s servers. Google Forms
        stores a cookie in your browser that contains a unique ID (NID cookie).
        This cookie stores a wide range of information, including, for example
        your language settings.
      </p>
      <p>
        We use Google Forms on the basis of our legitimate interest in
        determining your needs as effectively as possible (Art. 6(1)(f) GDPR).
        If appropriate consent has been obtained, the processing is carried out
        exclusively on the basis of Art. 6(1)(a) GDPR and § 25 (1) TTDSG,
        insofar the consent includes the storage of cookies or the access to
        information in the user’s end device (e.g., device fingerprinting)
        within the meaning of the TTDSG. This consent can be revoked at any
        time.
      </p>
      <p>
        The data you enter into the form will remain in our possession until you
        ask us to delete them, revoke your consent to the archiving of your data
        or until the purpose of archiving the data no longer exists (e.g., upon
        completion of the processing of your inquiry). This does not affect
        mandatory statutory provisions – in particular those governing retention
        periods.
      </p>
      <p>
        For more information, please consult Google’s Data Privacy Policy at
        <a
          href="https://policies.google.com/"
          target="_blank"
          rel="noopener noreferrer"
          >https://policies.google.com/</a
        >.
      </p>
      <h3>Registration on this website</h3>
      <p>
        You have the option to register on this website to be able to use
        additional website functions. We shall use the data you enter only for
        the purpose of using the respective offer or service you have registered
        for. The required information we request at the time of registration
        must be entered in full. Otherwise, we shall reject the registration.
      </p>
      <p>
        To notify you of any important changes to the scope of our portfolio or
        in the event of technical modifications, we shall use the e-mail address
        provided during the registration process.
      </p>
      <p>
        We shall process the data entered during the registration process on the
        basis of your consent (Art. 6(1)(a) GDPR).
      </p>
      <p>
        The data recorded during the registration process shall be stored by us
        as long as you are registered on this website. Subsequently, such data
        shall be deleted. This shall be without prejudice to mandatory statutory
        retention obligations.
      </p>
      <h3>Registration with Google</h3>
      <p>
        Instead of registering directly on this website, you can register with
        Google. The provider of this service is Google Ireland Limited
        (“Google”), Gordon House, Barrow Street, Dublin 4, Ireland.
      </p>
      <p>
        To register with Google, you must only enter your Google name and
        password. Google will identify you and confirm your identity to our
        website.
      </p>
      <p>
        When you sign in with Google, we may be able to use certain information
        in your account to complete your profile with us. You decide whether you
        want this information to be used and if so, which information it is,
        within the framework of your Google security settings, which you can
        find here:
        <a
          href="https://myaccount.google.com/security"
          target="_blank"
          rel="noopener noreferrer"
          >https://myaccount.google.com/security</a
        >
        and
        <a
          href="https://myaccount.google.com/permissions"
          target="_blank"
          rel="noopener noreferrer"
          >https://myaccount.google.com/permissions</a
        >.
      </p>
      <p>
        The data processing associated with Google’s registration is based on
        our legitimate interest in making the registration process as simple as
        possible for our users (Art. 6(1)(f) GDPR). Since the use of the
        registration function is voluntary and the users themselves can decide
        on the respective access options, no conflicting predominant rights of
        the data subjects are apparent.
      </p>
      <h2>5. Analysis tools and advertising</h2>
      <h3>Google Analytics</h3>
      <p>
        This website uses functions of the web analysis service Google
        Analytics. The provider of this service is Google Ireland Limited
        (“Google”), Gordon House, Barrow Street, Dublin 4, Ireland.
      </p>
      <p>
        Google Analytics enables the website operator to analyze the behavior
        patterns of website visitors. To that end, the website operator receives
        a variety of user data, such as pages accessed, time spent on the page,
        the utilized operating system and the user’s origin. This data is
        summarized in a user-ID and assigned to the respective end device of the
        website visitor.
      </p>
      <p>
        Furthermore, Google Analytics allows us to record your mouse and scroll
        movements and clicks, among other things. Google Analytics uses various
        modeling approaches to augment the collected data sets and uses machine
        learning technologies in data analysis.
      </p>
      <p>
        Google Analytics uses technologies that make the recognition of the user
        for the purpose of analyzing the user behavior patterns (e.g., cookies
        or device fingerprinting). The website use information recorded by
        Google is, as a rule transferred to a Google server in the United
        States, where it is stored.
      </p>
      <p>
        The use of these services occurs on the basis of your consent pursuant
        to Art. 6(1)(a) GDPR and § 25(1) TTDSG. You may revoke your consent at
        any time.
      </p>
      <p>
        Data transmission to the US is based on the Standard Contractual Clauses
        (SCC) of the European Commission. Details can be found here:
        <a
          href="https://privacy.google.com/businesses/controllerterms/mccs/"
          target="_blank"
          rel="noopener noreferrer"
          >https://privacy.google.com/businesses/controllerterms/mccs/</a
        >.
      </p>
      <h4>Browser plug-in</h4>
      <p>
        You can prevent the recording and processing of your data by Google by
        downloading and installing the browser plugin available under the
        following link:
        <a
          href="https://tools.google.com/dlpage/gaoptout?hl=en"
          target="_blank"
          rel="noopener noreferrer"
          >https://tools.google.com/dlpage/gaoptout?hl=en</a
        >.
      </p>
      <p>
        For more information about the handling of user data by Google
        Analytics, please consult Google’s Data Privacy Declaration at:
        <a
          href="https://support.google.com/analytics/answer/6004245?hl=en"
          target="_blank"
          rel="noopener noreferrer"
          >https://support.google.com/analytics/answer/6004245?hl=en</a
        >.
      </p>
      <h4>Google Signals</h4>
      <p>
        We use Google Signals. Whenever you visit our website, Google Analytics
        records, among other things, your location, the progression of your
        search and YouTube progression as well as demographic data (site visitor
        data). This data may be used for customized advertising with the
        assistance of Google Signal. If you have a Google account, your site
        visitor information will be linked to your Google account by Google
        Signal and used to send you customized promotional messages. The data is
        also used to compile anonymized statistics of our users’ online
        patterns.
      </p>
      <h4>Google Analytics E-Commerce-Tracking</h4>
      <p>
        This website uses the “E-Commerce Tracking” function of Google
        Analytics. With the assistance of E-Commerce Tracking, the website
        operator is in a position to analyze the purchasing patterns of website
        visitors with the aim of improving the operator’s online marketing
        campaigns. In this context, information, such as the orders placed, the
        average order values, shipping costs and the time from viewing the
        product to making the purchasing decision are tracked. These data may be
        consolidated by Google under a transaction ID, which is allocated to the
        respective user or the user’s device.
      </p>
      <h3>Google Ads</h3>
      <p>
        The website operator uses Google Ads. Google Ads is an online
        promotional program of Google Ireland Limited (“Google”), Gordon House,
        Barrow Street, Dublin 4, Ireland.
      </p>
      <p>
        Google Ads enables us to display ads in the Google search engine or on
        third-party websites, if the user enters certain search terms into
        Google (keyword targeting). It is also possible to place targeted ads
        based on the user data Google has in its possession (e.g., location data
        and interests; target group targeting). As the website operator, we can
        analyze these data quantitatively, for instance by analyzing which
        search terms resulted in the display of our ads and how many ads led to
        respective clicks.
      </p>
      <p>
        The use of these services occurs on the basis of your consent pursuant
        to Art. 6(1)(a) GDPR and § 25(1) TTDSG. You may revoke your consent at
        any time.
      </p>
      <p>
        Data transmission to the US is based on the Standard Contractual Clauses
        (SCC) of the European Commission. Details can be found here:
        <a
          href="https://policies.google.com/privacy/frameworks"
          target="_blank"
          rel="noopener noreferrer"
          >https://policies.google.com/privacy/frameworks</a
        >
        and
        <a
          href="https://privacy.google.com/businesses/controllerterms/mccs/"
          target="_blank"
          rel="noopener noreferrer"
          >https://privacy.google.com/businesses/controllerterms/mccs/</a
        >.
      </p>
      <h3>Google Conversion-Tracking</h3>
      <p>
        This website uses Google Conversion Tracking. The provider of this
        service is Google Ireland Limited (“Google”), Gordon House, Barrow
        Street, Dublin 4, Ireland.
      </p>
      <p>
        With the assistance of Google Conversion Tracking, we are in a position
        to recognize whether the user has completed certain actions. For
        instance, we can analyze the how frequently which buttons on our website
        have been clicked and which products are reviewed or purchased with
        particular frequency. The purpose of this information is to compile
        conversion statistics. We learn how many users have clicked on our ads
        and which actions they have completed. We do not receive any information
        that would allow us to personally identify the users. Google as such
        uses cookies or comparable recognition technologies for identification
        purposes.
      </p>
      <p>
        The use of these services occurs on the basis of your consent pursuant
        to Art. 6(1)(a) GDPR and § 25(1) TTDSG. You may revoke your consent at
        any time.
      </p>
      <p>
        For more information about Google Conversion Tracking, please review
        Google’s data protection policy at:
        <a
          href="https://policies.google.com/privacy?hl=en"
          target="_blank"
          rel="noopener noreferrer"
          >https://policies.google.com/privacy?hl=en</a
        >
      </p>
      <h2>6. Plug-ins and Tools</h2>
      <h3>Google Web Fonts</h3>
      <p>
        To ensure that fonts used on this website are uniform, this website uses
        so-called Web Fonts provided by Google. When you access a page on our
        website, your browser will load the required web fonts into your browser
        cache to correctly display text and fonts.
      </p>
      <p>
        To do this, the browser you use will have to establish a connection with
        Google’s servers. As a result, Google will learn that your IP address
        was used to access this website. The use of Google Web Fonts is based on
        Art. 6(1)(f) GDPR. The website operator has a legitimate interest in a
        uniform presentation of the font on the operator’s website. If
        appropriate consent has been obtained, the processing is carried out
        exclusively on the basis of Art. 6(1)(a) GDPR and § 25 (1) TTDSG,
        insofar the consent includes the storage of cookies or the access to
        information in the user’s end device (e.g., device fingerprinting)
        within the meaning of the TTDSG. This consent can be revoked at any
        time.
      </p>
      <p>
        If your browser should not support Web Fonts, a standard font installed
        on your computer will be used.
      </p>
      <p>
        For more information on Google Web Fonts, please follow this link:
        <a
          href="https://developers.google.com/fonts/faq"
          target="_blank"
          rel="noopener noreferrer"
          >https://developers.google.com/fonts/faq</a
        >
        and consult Google’s Data Privacy Declaration under:
        <a
          href="https://policies.google.com/privacy?hl=en"
          target="_blank"
          rel="noopener noreferrer"
          >https://policies.google.com/privacy?hl=en</a
        >.
      </p>
      <h2>7. Online marketing and partner programs</h2>
      <h3>Affiliate Programs on this website</h3>
      <p>
        We participate in affiliate partner programs. In conjunction with
        affiliate partner programs, ads of businesses (advertisers) are placed
        on websites of other enterprises within the affiliate partner network
        (publisher). If you click on one of these affiliate ads, you will be
        transferred to the promoted offer. If you should subsequently engage in
        a certain transaction (conversion), the publisher will receive a
        respective commission in exchange for the service. To be able to compute
        the commission amount, the affiliate network operator must be in a
        position to track the ad that has resulted in you seeing the offer and
        in you completing the predefined transaction. To make this possible,
        cookies or comparable recognition technologies are deployed (e.g.,
        device fingerprinting).
      </p>
      <p>
        Data is stored and analyzed on the basis of Art. 6(1)(f) GDPR. The
        website operator has a legitimate interest in the correct computation of
        its affiliate compensation. If appropriate consent has been obtained,
        the processing is carried out exclusively on the basis of Art. 6(1)(a)
        GDPR and § 25 (1) TTDSG, insofar the consent includes the storage of
        cookies or the access to information in the user’s end device (e.g.,
        device fingerprinting) within the meaning of the TTDSG. This consent can
        be revoked at any time.
      </p>
      <p>We participate in the following affiliate programs:</p>
      <h4>Amazon partner program</h4>
      <p>
        The provider is Amazon Europe Core S.à.r.l. For details, please consult
        Amazon’s Data Privacy Declaration at:
        <a
          href="https://www.amazon.de/gp/help/customer/display.html?nodeId=201909010"
          target="_blank"
          rel="noopener"
          >https://www.amazon.de/gp/help/customer/display.html?nodeId=201909010</a
        >.
      </p>
    </div>

    <!--German-->
    <div v-if="$store.state.user.countryCode == 'DE'" class="container">
      <h1>Datenschutz­erklärung</h1>
      <h2>1. Datenschutz auf einen Blick</h2>
      <h3>Allgemeine Hinweise</h3>
      <p>
        Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit
        Ihren personenbezogenen Daten passiert, wenn Sie diese Website besuchen.
        Personenbezogene Daten sind alle Daten, mit denen Sie persönlich
        identifiziert werden können. Ausführliche Informationen zum Thema
        Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten
        Datenschutzerklärung.
      </p>
      <h3>Datenerfassung auf dieser Website</h3>
      <h4>Wer ist verantwortlich für die Datenerfassung auf dieser Website?</h4>
      <p>
        Die Datenverarbeitung auf dieser Website erfolgt durch den
        Websitebetreiber. Dessen Kontaktdaten können Sie dem Abschnitt „Hinweis
        zur Verantwortlichen Stelle“ in dieser Datenschutzerklärung entnehmen.
      </p>
      <h4>Wie erfassen wir Ihre Daten?</h4>
      <p>
        Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese
        mitteilen. Hierbei kann es sich z. B. um Daten handeln, die Sie in ein
        Kontaktformular eingeben.
      </p>
      <p>
        Andere Daten werden automatisch oder nach Ihrer Einwilligung beim Besuch
        der Website durch unsere IT-Systeme erfasst. Das sind vor allem
        technische Daten (z. B. Internetbrowser, Betriebssystem oder Uhrzeit des
        Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald
        Sie diese Website betreten.
      </p>
      <h4>Wofür nutzen wir Ihre Daten?</h4>
      <p>
        Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der
        Website zu gewährleisten. Andere Daten können zur Analyse Ihres
        Nutzerverhaltens verwendet werden.
      </p>
      <h4>Welche Rechte haben Sie bezüglich Ihrer Daten?</h4>
      <p>
        Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft,
        Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu
        erhalten. Sie haben außerdem ein Recht, die Berichtigung oder Löschung
        dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur
        Datenverarbeitung erteilt haben, können Sie diese Einwilligung jederzeit
        für die Zukunft widerrufen. Außerdem haben Sie das Recht, unter
        bestimmten Umständen die Einschränkung der Verarbeitung Ihrer
        personenbezogenen Daten zu verlangen. Des Weiteren steht Ihnen ein
        Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
      </p>
      <p>
        Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich
        jederzeit an uns wenden.
      </p>
      <h3>Analyse-Tools und Tools von Dritt­anbietern</h3>
      <p>
        Beim Besuch dieser Website kann Ihr Surf-Verhalten statistisch
        ausgewertet werden. Das geschieht vor allem mit sogenannten
        Analyseprogrammen.
      </p>
      <p>
        Detaillierte Informationen zu diesen Analyseprogrammen finden Sie in der
        folgenden Datenschutzerklärung.
      </p>
      <h2>2. Hosting</h2>
      <h3>Externes Hosting</h3>
      <p>
        Diese Website wird bei einem externen Dienstleister gehostet (Hoster).
        Die personenbezogenen Daten, die auf dieser Website erfasst werden,
        werden auf den Servern des Hosters gespeichert. Hierbei kann es sich v.
        a. um IP-Adressen, Kontaktanfragen, Meta- und Kommunikationsdaten,
        Vertragsdaten, Kontaktdaten, Namen, Websitezugriffe und sonstige Daten,
        die über eine Website generiert werden, handeln.
      </p>
      <p>
        Der Einsatz des Hosters erfolgt zum Zwecke der Vertragserfüllung
        gegenüber unseren potenziellen und bestehenden Kunden (Art. 6 Abs. 1
        lit. b DSGVO) und im Interesse einer sicheren, schnellen und effizienten
        Bereitstellung unseres Online-Angebots durch einen professionellen
        Anbieter (Art. 6 Abs. 1 lit. f DSGVO). Sofern eine entsprechende
        Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschließlich
        auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG,
        soweit die Einwilligung die Speicherung von Cookies oder den Zugriff auf
        Informationen im Endgerät des Nutzers (z. B. Device-Fingerprinting) im
        Sinne des TTDSG umfasst. Die Einwilligung ist jederzeit widerrufbar.
      </p>
      <p>
        Unser Hoster wird Ihre Daten nur insoweit verarbeiten, wie dies zur
        Erfüllung seiner Leistungspflichten erforderlich ist und unsere
        Weisungen in Bezug auf diese Daten befolgen.
      </p>
      <p>Wir setzen folgenden Hoster ein:</p>
      <p>
        Google Ireland Limited<br />
        Gordon House, Barrow Street<br />
        Dublin 4<br />
        Irland
      </p>
      <h2>3. Allgemeine Hinweise und Pflicht­informationen</h2>
      <h3>Datenschutz</h3>
      <p>
        Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten
        sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und
        entsprechend den gesetzlichen Datenschutzvorschriften sowie dieser
        Datenschutzerklärung.
      </p>
      <p>
        Wenn Sie diese Website benutzen, werden verschiedene personenbezogene
        Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie
        persönlich identifiziert werden können. Die vorliegende
        Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir
        sie nutzen. Sie erläutert auch, wie und zu welchem Zweck das geschieht.
      </p>
      <p>
        Wir weisen darauf hin, dass die Datenübertragung im Internet (z. B. bei
        der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein
        lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
        möglich.
      </p>
      <h3>Hinweis zur verantwortlichen Stelle</h3>
      <p>
        Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website
        ist:
      </p>
      <p>
        Timetip GmbH<br />
        Angererstrasse, 24<br />
        80796 München
      </p>

      <p>
        <img v-bind:src="require('../assets/ttp.png')" class="phonum" /><br />
        <img v-bind:src="require('../assets/ttm.png')" class="emage" />
      </p>
      <p>
        Verantwortliche Stelle ist die natürliche oder juristische Person, die
        allein oder gemeinsam mit anderen über die Zwecke und Mittel der
        Verarbeitung von personenbezogenen Daten (z. B. Namen, E-Mail-Adressen
        o. Ä.) entscheidet.
      </p>

      <h3>Speicherdauer</h3>
      <p>
        Soweit innerhalb dieser Datenschutzerklärung keine speziellere
        Speicherdauer genannt wurde, verbleiben Ihre personenbezogenen Daten bei
        uns, bis der Zweck für die Datenverarbeitung entfällt. Wenn Sie ein
        berechtigtes Löschersuchen geltend machen oder eine Einwilligung zur
        Datenverarbeitung widerrufen, werden Ihre Daten gelöscht, sofern wir
        keine anderen rechtlich zulässigen Gründe für die Speicherung Ihrer
        personenbezogenen Daten haben (z. B. steuer- oder handelsrechtliche
        Aufbewahrungsfristen); im letztgenannten Fall erfolgt die Löschung nach
        Fortfall dieser Gründe.
      </p>
      <h3>
        Allgemeine Hinweise zu den Rechtsgrundlagen der Datenverarbeitung auf
        dieser Website
      </h3>
      <p>
        Sofern Sie in die Datenverarbeitung eingewilligt haben, verarbeiten wir
        Ihre personenbezogenen Daten auf Grundlage von Art. 6 Abs. 1 lit. a
        DSGVO bzw. Art. 9 Abs. 2 lit. a DSGVO, sofern besondere Datenkategorien
        nach Art. 9 Abs. 1 DSGVO verarbeitet werden. Im Falle einer
        ausdrücklichen Einwilligung in die Übertragung personenbezogener Daten
        in Drittstaaten erfolgt die Datenverarbeitung außerdem auf Grundlage von
        Art. 49 Abs. 1 lit. a DSGVO. Sofern Sie in die Speicherung von Cookies
        oder in den Zugriff auf Informationen in Ihr Endgerät (z. B. via
        Device-Fingerprinting) eingewilligt haben, erfolgt die Datenverarbeitung
        zusätzlich auf Grundlage von § 25 Abs. 1 TTDSG. Die Einwilligung ist
        jederzeit widerrufbar. Sind Ihre Daten zur Vertragserfüllung oder zur
        Durchführung vorvertraglicher Maßnahmen erforderlich, verarbeiten wir
        Ihre Daten auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO. Des Weiteren
        verarbeiten wir Ihre Daten, sofern diese zur Erfüllung einer rechtlichen
        Verpflichtung erforderlich sind auf Grundlage von Art. 6 Abs. 1 lit. c
        DSGVO. Die Datenverarbeitung kann ferner auf Grundlage unseres
        berechtigten Interesses nach Art. 6 Abs. 1 lit. f DSGVO erfolgen. Über
        die jeweils im Einzelfall einschlägigen Rechtsgrundlagen wird in den
        folgenden Absätzen dieser Datenschutzerklärung informiert.
      </p>
      <h3>Hinweis zur Datenweitergabe in die USA und sonstige Drittstaaten</h3>
      <p>
        Wir verwenden unter anderem Tools von Unternehmen mit Sitz in den USA
        oder sonstigen datenschutzrechtlich nicht sicheren Drittstaaten. Wenn
        diese Tools aktiv sind, können Ihre personenbezogene Daten in diese
        Drittstaaten übertragen und dort verarbeitet werden. Wir weisen darauf
        hin, dass in diesen Ländern kein mit der EU vergleichbares
        Datenschutzniveau garantiert werden kann. Beispielsweise sind
        US-Unternehmen dazu verpflichtet, personenbezogene Daten an
        Sicherheitsbehörden herauszugeben, ohne dass Sie als Betroffener
        hiergegen gerichtlich vorgehen könnten. Es kann daher nicht
        ausgeschlossen werden, dass US-Behörden (z. B. Geheimdienste) Ihre auf
        US-Servern befindlichen Daten zu Überwachungszwecken verarbeiten,
        auswerten und dauerhaft speichern. Wir haben auf diese
        Verarbeitungstätigkeiten keinen Einfluss.
      </p>
      <h3>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h3>
      <p>
        Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen
        Einwilligung möglich. Sie können eine bereits erteilte Einwilligung
        jederzeit widerrufen. Die Rechtmäßigkeit der bis zum Widerruf erfolgten
        Datenverarbeitung bleibt vom Widerruf unberührt.
      </p>
      <h3>
        Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie
        gegen Direktwerbung (Art. 21 DSGVO)
      </h3>
      <p>
        WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER F
        DSGVO ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GRÜNDEN, DIE SICH AUS
        IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG IHRER
        PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN; DIES GILT AUCH FÜR EIN
        AUF DIESE BESTIMMUNGEN GESTÜTZTES PROFILING. DIE JEWEILIGE
        RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT, ENTNEHMEN SIE
        DIESER DATENSCHUTZERKLÄRUNG. WENN SIE WIDERSPRUCH EINLEGEN, WERDEN WIR
        IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT MEHR VERARBEITEN, ES SEI
        DENN, WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE VERARBEITUNG
        NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN ÜBERWIEGEN ODER
        DIE VERARBEITUNG DIENT DER GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG
        VON RECHTSANSPRÜCHEN (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO).
      </p>
      <p>
        WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU
        BETREIBEN, SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE
        VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE
        DERARTIGER WERBUNG EINZULEGEN; DIES GILT AUCH FÜR DAS PROFILING, SOWEIT
        ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG STEHT. WENN SIE
        WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN ANSCHLIESSEND NICHT
        MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET (WIDERSPRUCH NACH ART. 21
        ABS. 2 DSGVO).
      </p>
      <h3>Beschwerde­recht bei der zuständigen Aufsichts­behörde</h3>
      <p>
        Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein
        Beschwerderecht bei einer Aufsichtsbehörde, insbesondere in dem
        Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes oder
        des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht besteht
        unbeschadet anderweitiger verwaltungsrechtlicher oder gerichtlicher
        Rechtsbehelfe.
      </p>
      <h3>Recht auf Daten­übertrag­barkeit</h3>
      <p>
        Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung
        oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder
        an einen Dritten in einem gängigen, maschinenlesbaren Format aushändigen
        zu lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen
        Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch
        machbar ist.
      </p>
      <h3>SSL- bzw. TLS-Verschlüsselung</h3>
      <p>
        Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung
        vertraulicher Inhalte, wie zum Beispiel Bestellungen oder Anfragen, die
        Sie an uns als Seitenbetreiber senden, eine SSL- bzw.
        TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran,
        dass die Adresszeile des Browsers von „http://“ auf „https://“ wechselt
        und an dem Schloss-Symbol in Ihrer Browserzeile.
      </p>
      <p>
        Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten,
        die Sie an uns übermitteln, nicht von Dritten mitgelesen werden.
      </p>
      <h3>Auskunft, Löschung und Berichtigung</h3>
      <p>
        Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit
        das Recht auf unentgeltliche Auskunft über Ihre gespeicherten
        personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der
        Datenverarbeitung und ggf. ein Recht auf Berichtigung oder Löschung
        dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene
        Daten können Sie sich jederzeit an uns wenden.
      </p>
      <h3>Recht auf Einschränkung der Verarbeitung</h3>
      <p>
        Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer
        personenbezogenen Daten zu verlangen. Hierzu können Sie sich jederzeit
        an uns wenden. Das Recht auf Einschränkung der Verarbeitung besteht in
        folgenden Fällen:
      </p>
      <ul>
        <li>
          Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen
          Daten bestreiten, benötigen wir in der Regel Zeit, um dies zu
          überprüfen. Für die Dauer der Prüfung haben Sie das Recht, die
          Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu
          verlangen.
        </li>
        <li>
          Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig
          geschah/geschieht, können Sie statt der Löschung die Einschränkung der
          Datenverarbeitung verlangen.
        </li>
        <li>
          Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie
          jedoch zur Ausübung, Verteidigung oder Geltendmachung von
          Rechtsansprüchen benötigen, haben Sie das Recht, statt der Löschung
          die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu
          verlangen.
        </li>
        <li>
          Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben,
          muss eine Abwägung zwischen Ihren und unseren Interessen vorgenommen
          werden. Solange noch nicht feststeht, wessen Interessen überwiegen,
          haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer
          personenbezogenen Daten zu verlangen.
        </li>
      </ul>
      <p>
        Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt
        haben, dürfen diese Daten – von ihrer Speicherung abgesehen – nur mit
        Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung
        von Rechtsansprüchen oder zum Schutz der Rechte einer anderen
        natürlichen oder juristischen Person oder aus Gründen eines wichtigen
        öffentlichen Interesses der Europäischen Union oder eines Mitgliedstaats
        verarbeitet werden.
      </p>
      <h3>Widerspruch gegen Werbe-E-Mails</h3>
      <p>
        Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten
        Kontaktdaten zur Übersendung von nicht ausdrücklich angeforderter
        Werbung und Informationsmaterialien wird hiermit widersprochen. Die
        Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im
        Falle der unverlangten Zusendung von Werbeinformationen, etwa durch
        Spam-E-Mails, vor.
      </p>
      <h2>4. Datenerfassung auf dieser Website</h2>
      <h3>Cookies</h3>
      <p>
        Unsere Internetseiten verwenden so genannte „Cookies“. Cookies sind
        kleine Textdateien und richten auf Ihrem Endgerät keinen Schaden an. Sie
        werden entweder vorübergehend für die Dauer einer Sitzung
        (Session-Cookies) oder dauerhaft (permanente Cookies) auf Ihrem Endgerät
        gespeichert. Session-Cookies werden nach Ende Ihres Besuchs automatisch
        gelöscht. Permanente Cookies bleiben auf Ihrem Endgerät gespeichert, bis
        Sie diese selbst löschen oder eine automatische Löschung durch Ihren
        Webbrowser erfolgt.
      </p>
      <p>
        Teilweise können auch Cookies von Drittunternehmen auf Ihrem Endgerät
        gespeichert werden, wenn Sie unsere Seite betreten
        (Third-Party-Cookies). Diese ermöglichen uns oder Ihnen die Nutzung
        bestimmter Dienstleistungen des Drittunternehmens (z. B. Cookies zur
        Abwicklung von Zahlungsdienstleistungen).
      </p>
      <p>
        Cookies haben verschiedene Funktionen. Zahlreiche Cookies sind technisch
        notwendig, da bestimmte Websitefunktionen ohne diese nicht funktionieren
        würden (z. B. die Warenkorbfunktion oder die Anzeige von Videos). Andere
        Cookies dienen dazu, das Nutzerverhalten auszuwerten oder Werbung
        anzuzeigen.
      </p>
      <p>
        Cookies, die zur Durchführung des elektronischen Kommunikationsvorgangs,
        zur Bereitstellung bestimmter, von Ihnen erwünschter Funktionen (z. B.
        für die Warenkorbfunktion) oder zur Optimierung der Website (z. B.
        Cookies zur Messung des Webpublikums) erforderlich sind (notwendige
        Cookies), werden auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO
        gespeichert, sofern keine andere Rechtsgrundlage angegeben wird. Der
        Websitebetreiber hat ein berechtigtes Interesse an der Speicherung von
        notwendigen Cookies zur technisch fehlerfreien und optimierten
        Bereitstellung seiner Dienste. Sofern eine Einwilligung zur Speicherung
        von Cookies und vergleichbaren Wiedererkennungstechnologien abgefragt
        wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage dieser
        Einwilligung (Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG); die
        Einwilligung ist jederzeit widerrufbar.
      </p>
      <p>
        Sie können Ihren Browser so einstellen, dass Sie über das Setzen von
        Cookies informiert werden und Cookies nur im Einzelfall erlauben, die
        Annahme von Cookies für bestimmte Fälle oder generell ausschließen sowie
        das automatische Löschen der Cookies beim Schließen des Browsers
        aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität
        dieser Website eingeschränkt sein.
      </p>
      <p>
        Soweit Cookies von Drittunternehmen oder zu Analysezwecken eingesetzt
        werden, werden wir Sie hierüber im Rahmen dieser Datenschutzerklärung
        gesondert informieren und ggf. eine Einwilligung abfragen.
      </p>
      <h3>Einwilligung mit Usercentrics</h3>
      <p>
        Diese Website nutzt die Consent-Technologie von Usercentrics, um Ihre
        Einwilligung zur Speicherung bestimmter Cookies auf Ihrem Endgerät oder
        zum Einsatz bestimmter Technologien einzuholen und diese
        datenschutzkonform zu dokumentieren. Anbieter dieser Technologie ist die
        Usercentrics GmbH, Sendlinger Straße 7, 80331 München, Website:
        <a
          href="https://usercentrics.com/de/"
          target="_blank"
          rel="noopener noreferrer"
          >https://usercentrics.com/de/</a
        >
        (im Folgenden „Usercentrics“).
      </p>
      <p>
        Wenn Sie unsere Website betreten, werden folgende personenbezogene Daten
        an Usercentrics übertragen:
      </p>
      <ul>
        <li>Ihre Einwilligung(en) bzw. der Widerruf Ihrer Einwilligung(en)</li>
        <li>Ihre IP-Adresse</li>
        <li>Informationen über Ihren Browser</li>
        <li>Informationen über Ihr Endgerät</li>
        <li>Zeitpunkt Ihres Besuchs auf der Website</li>
      </ul>
      <p>
        Des Weiteren speichert Usercentrics ein Cookie in Ihrem Browser, um
        Ihnen die erteilten Einwilligungen bzw. deren Widerruf zuordnen zu
        können. Die so erfassten Daten werden gespeichert, bis Sie uns zur
        Löschung auffordern, das Usercentrics-Cookie selbst löschen oder der
        Zweck für die Datenspeicherung entfällt. Zwingende gesetzliche
        Aufbewahrungspflichten bleiben unberührt.
      </p>
      <p>
        Der Einsatz von Usercentrics erfolgt, um die gesetzlich vorgeschriebenen
        Einwilligungen für den Einsatz bestimmter Technologien einzuholen.
        Rechtsgrundlage hierfür ist Art. 6 Abs. 1 lit. c DSGVO.
      </p>
      <h4>Auftragsverarbeitung</h4>
      <p>
        Wir haben einen Vertrag über Auftragsverarbeitung (AVV) mit dem oben
        genannten Anbieter geschlossen. Hierbei handelt es sich um einen
        datenschutzrechtlich vorgeschriebenen Vertrag, der gewährleistet, dass
        dieser die personenbezogenen Daten unserer Websitebesucher nur nach
        unseren Weisungen und unter Einhaltung der DSGVO verarbeitet.
      </p>
      <h3>Kontaktformular</h3>
      <p>
        Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre
        Angaben aus dem Anfrageformular inklusive der von Ihnen dort angegebenen
        Kontaktdaten zwecks Bearbeitung der Anfrage und für den Fall von
        Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht ohne
        Ihre Einwilligung weiter.
      </p>
      <p>
        Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1
        lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags
        zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen
        erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf
        unserem berechtigten Interesse an der effektiven Bearbeitung der an uns
        gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer
        Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde;
        die Einwilligung ist jederzeit widerrufbar.
      </p>
      <p>
        Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei uns,
        bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung
        widerrufen oder der Zweck für die Datenspeicherung entfällt (z. B. nach
        abgeschlossener Bearbeitung Ihrer Anfrage). Zwingende gesetzliche
        Bestimmungen – insbesondere Aufbewahrungsfristen – bleiben unberührt.
      </p>
      <h3>Anfrage per E-Mail, Telefon oder Telefax</h3>
      <p>
        Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird Ihre
        Anfrage inklusive aller daraus hervorgehenden personenbezogenen Daten
        (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei uns
        gespeichert und verarbeitet. Diese Daten geben wir nicht ohne Ihre
        Einwilligung weiter.
      </p>
      <p>
        Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1
        lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags
        zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen
        erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf
        unserem berechtigten Interesse an der effektiven Bearbeitung der an uns
        gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer
        Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde;
        die Einwilligung ist jederzeit widerrufbar.
      </p>
      <p>
        Die von Ihnen an uns per Kontaktanfragen übersandten Daten verbleiben
        bei uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur
        Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt
        (z. B. nach abgeschlossener Bearbeitung Ihres Anliegens). Zwingende
        gesetzliche Bestimmungen – insbesondere gesetzliche Aufbewahrungsfristen
        – bleiben unberührt.
      </p>
      <h3>Google Forms</h3>
      <p>
        Wir haben Google Forms auf dieser Website eingebunden. Anbieter ist die
        die Google Ireland Limited, Gordon House, Barrow Street, Dublin 4,
        Irland (nachfolgend Google).
      </p>
      <p>
        Google Forms ermöglicht es uns, Online-Formulare zu erstellen um
        Nachrichten, Anfragen und sonstige Eingaben unserer Websitebesucher
        strukturiert zu erfassen. Alle von Ihnen getätigten Eingaben werden auf
        den Servern von Google verarbeitet. Google Forms speichert ein Cookie in
        Ihrem Browser, das eine eindeutige ID enthält (NID-Cookie). Dieses
        Cookie speichert verschiedene Informationen wie beispielsweise Ihre
        Spracheinstellungen.
      </p>
      <p>
        Der Einsatz von Google Forms erfolgt auf Grundlage unseres berechtigten
        Interesses an einer möglichst nutzerfreundlichen Ermittlung Ihres
        Anliegens (Art. 6 Abs. 1 lit. f DSGVO). Sofern eine entsprechende
        Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschließlich
        auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG,
        soweit die Einwilligung die Speicherung von Cookies oder den Zugriff auf
        Informationen im Endgerät des Nutzers (z. B. Device-Fingerprinting) im
        Sinne des TTDSG umfasst. Die Einwilligung ist jederzeit widerrufbar.
      </p>
      <p>
        Die von Ihnen im Formular eingegebenen Daten verbleiben bei uns, bis Sie
        uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung
        widerrufen oder der Zweck für die Datenspeicherung entfällt (z. B. nach
        abgeschlossener Bearbeitung Ihrer Anfrage). Zwingende gesetzliche
        Bestimmungen – insbesondere Aufbewahrungsfristen – bleiben unberührt.
      </p>
      <p>
        Weitere Informationen entnehmen Sie der Datenschutzerklärung von Google
        unter
        <a
          href="https://policies.google.com/"
          target="_blank"
          rel="noopener noreferrer"
          >https://policies.google.com/</a
        >.
      </p>
      <h3>Registrierung auf dieser Website</h3>
      <p>
        Sie können sich auf dieser Website registrieren, um zusätzliche
        Funktionen auf der Seite zu nutzen. Die dazu eingegebenen Daten
        verwenden wir nur zum Zwecke der Nutzung des jeweiligen Angebotes oder
        Dienstes, für den Sie sich registriert haben. Die bei der Registrierung
        abgefragten Pflichtangaben müssen vollständig angegeben werden.
        Anderenfalls werden wir die Registrierung ablehnen.
      </p>
      <p>
        Für wichtige Änderungen etwa beim Angebotsumfang oder bei technisch
        notwendigen Änderungen nutzen wir die bei der Registrierung angegebene
        E-Mail-Adresse, um Sie auf diesem Wege zu informieren.
      </p>
      <p>
        Die Verarbeitung der bei der Registrierung eingegebenen Daten erfolgt
        zum Zwecke der Durchführung des durch die Registrierung begründeten
        Nutzungsverhältnisses und ggf. zur Anbahnung weiterer Verträge (Art. 6
        Abs. 1 lit. b DSGVO).
      </p>
      <p>
        Die bei der Registrierung erfassten Daten werden von uns gespeichert,
        solange Sie auf dieser Website registriert sind und werden anschließend
        gelöscht. Gesetzliche Aufbewahrungsfristen bleiben unberührt.
      </p>
      <h3>Registrierung mit Google</h3>
      <p>
        Statt einer direkten Registrierung auf dieser Website können Sie sich
        mit Google registrieren. Anbieter dieses Dienstes ist die Google Ireland
        Limited („Google”), Gordon House, Barrow Street, Dublin 4, Irland.
      </p>
      <p>
        Um sich mit Google zu registrieren, müssen Sie ausschließlich Ihre
        Google-Namen und Ihr Passwort eingeben. Google wird Sie identifizieren
        und unserer Website Ihre Identität bestätigen.
      </p>
      <p>
        Wenn Sie sich mit Google anmelden, ist es uns ggf. möglich bestimmte
        Informationen auf Ihrem Konto zu nutzen, um Ihr Profil bei uns zu
        vervollständigen. Ob und welche Informationen das sind, entscheiden Sie
        im Rahmen Ihrer Google-Sicherheitseinstellungen, die Sie hier finden:
        <a
          href="https://myaccount.google.com/security"
          target="_blank"
          rel="noopener noreferrer"
          >https://myaccount.google.com/security</a
        >
        und
        <a
          href="https://myaccount.google.com/permissions"
          target="_blank"
          rel="noopener noreferrer"
          >https://myaccount.google.com/permissions</a
        >.
      </p>
      <p>
        Die Datenverarbeitung, die mit der Google-Registrierung einhergeht
        beruht auf unserem berechtigten Interesse, unseren Nutzern einen
        möglichst einfachen Registrierungsprozess zu ermöglichen (Art. 6 Abs. 1
        lit. f DSGVO). Da die Nutzung der Registrierungsfunktion freiwillig ist
        und die Nutzer selbst über die jeweiligen Zugriffsmöglichkeiten
        entscheiden können, sind keine entgegenstehenden überwiegenden Rechte
        der Betroffenen ersichtlich.
      </p>
      <h2>5. Analyse-Tools und Werbung</h2>
      <h3>Google Analytics</h3>
      <p>
        Diese Website nutzt Funktionen des Webanalysedienstes Google Analytics.
        Anbieter ist die Google Ireland Limited („Google“), Gordon House, Barrow
        Street, Dublin 4, Irland.
      </p>
      <p>
        Google Analytics ermöglicht es dem Websitebetreiber, das Verhalten der
        Websitebesucher zu analysieren. Hierbei erhält der Websitebetreiber
        verschiedene Nutzungsdaten, wie z. B. Seitenaufrufe, Verweildauer,
        verwendete Betriebssysteme und Herkunft des Nutzers. Diese Daten werden
        in einer User-ID zusammengefasst und dem jeweiligen Endgerät des
        Websitebesuchers zugeordnet.
      </p>
      <p>
        Des Weiteren können wir mit Google Analytics u. a. Ihre Maus- und
        Scrollbewegungen und Klicks aufzeichnen. Ferner verwendet Google
        Analytics verschiedene Modellierungsansätze, um die erfassten Datensätze
        zu ergänzen und setzt Machine-Learning-Technologien bei der Datenanalyse
        ein.
      </p>
      <p>
        Google Analytics verwendet Technologien, die die Wiedererkennung des
        Nutzers zum Zwecke der Analyse des Nutzerverhaltens ermöglichen (z. B.
        Cookies oder Device-Fingerprinting). Die von Google erfassten
        Informationen über die Benutzung dieser Website werden in der Regel an
        einen Server von Google in den USA übertragen und dort gespeichert.
      </p>
      <p>
        Die Nutzung dieses Dienstes erfolgt auf Grundlage Ihrer Einwilligung
        nach Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG. Die Einwilligung
        ist jederzeit widerrufbar.
      </p>
      <p>
        Die Datenübertragung in die USA wird auf die Standardvertragsklauseln
        der EU-Kommission gestützt. Details finden Sie hier:
        <a
          href="https://privacy.google.com/businesses/controllerterms/mccs/"
          target="_blank"
          rel="noopener noreferrer"
          >https://privacy.google.com/businesses/controllerterms/mccs/</a
        >.
      </p>
      <h4>Browser Plugin</h4>
      <p>
        Sie können die Erfassung und Verarbeitung Ihrer Daten durch Google
        verhindern, indem Sie das unter dem folgenden Link verfügbare
        Browser-Plugin herunterladen und installieren:
        <a
          href="https://tools.google.com/dlpage/gaoptout?hl=de"
          target="_blank"
          rel="noopener noreferrer"
          >https://tools.google.com/dlpage/gaoptout?hl=de</a
        >.
      </p>
      <p>
        Mehr Informationen zum Umgang mit Nutzerdaten bei Google Analytics
        finden Sie in der Datenschutzerklärung von Google:
        <a
          href="https://support.google.com/analytics/answer/6004245?hl=de"
          target="_blank"
          rel="noopener noreferrer"
          >https://support.google.com/analytics/answer/6004245?hl=de</a
        >.
      </p>
      <h4>Google-Signale</h4>
      <p>
        Wir nutzen Google-Signale. Wenn Sie unsere Website besuchen, erfasst
        Google Analytics u. a. Ihren Standort, Suchverlauf und YouTube-Verlauf
        sowie demografische Daten (Besucherdaten). Diese Daten können mit Hilfe
        von Google-Signal für personalisierte Werbung verwendet werden. Wenn Sie
        über ein Google-Konto verfügen, werden die Besucherdaten von
        Google-Signal mit Ihrem Google-Konto verknüpft und für personalisierte
        Werbebotschaften verwendet. Die Daten werden außerdem für die Erstellung
        anonymisierter Statistiken zum Nutzerverhalten unserer User verwendet.
      </p>
      <h4>Google Analytics E-Commerce-Messung</h4>
      <p>
        Diese Website nutzt die Funktion „E-Commerce-Messung“ von Google
        Analytics. Mit Hilfe von E-Commerce-Messung kann der Websitebetreiber
        das Kaufverhalten der Websitebesucher zur Verbesserung seiner
        Online-Marketing-Kampagnen analysieren. Hierbei werden Informationen,
        wie zum Beispiel die getätigten Bestellungen, durchschnittliche
        Bestellwerte, Versandkosten und die Zeit von der Ansicht bis zum Kauf
        eines Produktes erfasst. Diese Daten können von Google unter einer
        Transaktions-ID zusammengefasst werden, die dem jeweiligen Nutzer bzw.
        dessen Gerät zugeordnet ist.
      </p>
      <h3>Google Ads</h3>
      <p>
        Der Websitebetreiber verwendet Google Ads. Google Ads ist ein
        Online-Werbeprogramm der Google Ireland Limited („Google“), Gordon
        House, Barrow Street, Dublin 4, Irland.
      </p>
      <p>
        Google Ads ermöglicht es uns Werbeanzeigen in der Google-Suchmaschine
        oder auf Drittwebseiten auszuspielen, wenn der Nutzer bestimmte
        Suchbegriffe bei Google eingibt (Keyword-Targeting). Ferner können
        zielgerichtete Werbeanzeigen anhand der bei Google vorhandenen
        Nutzerdaten (z. B. Standortdaten und Interessen) ausgespielt werden
        (Zielgruppen-Targeting). Wir als Websitebetreiber können diese Daten
        quantitativ auswerten, indem wir beispielsweise analysieren, welche
        Suchbegriffe zur Ausspielung unserer Werbeanzeigen geführt haben und wie
        viele Anzeigen zu entsprechenden Klicks geführt haben.
      </p>
      <p>
        Die Nutzung dieses Dienstes erfolgt auf Grundlage Ihrer Einwilligung
        nach Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG. Die Einwilligung
        ist jederzeit widerrufbar.
      </p>
      <p>
        Die Datenübertragung in die USA wird auf die Standardvertragsklauseln
        der EU-Kommission gestützt. Details finden Sie hier:
        <a
          href="https://policies.google.com/privacy/frameworks"
          target="_blank"
          rel="noopener noreferrer"
          >https://policies.google.com/privacy/frameworks</a
        >
        und
        <a
          href="https://privacy.google.com/businesses/controllerterms/mccs/"
          target="_blank"
          rel="noopener noreferrer"
          >https://privacy.google.com/businesses/controllerterms/mccs/</a
        >.
      </p>
      <h3>Google Conversion-Tracking</h3>
      <p>
        Diese Website nutzt Google Conversion Tracking. Anbieter ist die Google
        Ireland Limited („Google“), Gordon House, Barrow Street, Dublin 4,
        Irland.
      </p>
      <p>
        Mit Hilfe von Google-Conversion-Tracking können Google und wir erkennen,
        ob der Nutzer bestimmte Aktionen durchgeführt hat. So können wir
        beispielsweise auswerten, welche Buttons auf unserer Website wie häufig
        geklickt und welche Produkte besonders häufig angesehen oder gekauft
        wurden. Diese Informationen dienen dazu, Conversion-Statistiken zu
        erstellen. Wir erfahren die Gesamtanzahl der Nutzer, die auf unsere
        Anzeigen geklickt haben und welche Aktionen sie durchgeführt haben. Wir
        erhalten keine Informationen, mit denen wir den Nutzer persönlich
        identifizieren können. Google selbst nutzt zur Identifikation Cookies
        oder vergleichbare Wiedererkennungstechnologien.
      </p>
      <p>
        Die Nutzung dieses Dienstes erfolgt auf Grundlage Ihrer Einwilligung
        nach Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG. Die Einwilligung
        ist jederzeit widerrufbar.
      </p>
      <p>
        Mehr Informationen zu Google Conversion-Tracking finden Sie in den
        Datenschutzbestimmungen von Google:
        <a
          href="https://policies.google.com/privacy?hl=de"
          target="_blank"
          rel="noopener noreferrer"
          >https://policies.google.com/privacy?hl=de</a
        >.
      </p>
      <h2>6. Plugins und Tools</h2>
      <h3>Google Web Fonts</h3>
      <p>
        Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so
        genannte Web Fonts, die von Google bereitgestellt werden. Beim Aufruf
        einer Seite lädt Ihr Browser die benötigten Web Fonts in ihren
        Browsercache, um Texte und Schriftarten korrekt anzuzeigen.
      </p>
      <p>
        Zu diesem Zweck muss der von Ihnen verwendete Browser Verbindung zu den
        Servern von Google aufnehmen. Hierdurch erlangt Google Kenntnis darüber,
        dass über Ihre IP-Adresse diese Website aufgerufen wurde. Die Nutzung
        von Google WebFonts erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f
        DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an der
        einheitlichen Darstellung des Schriftbildes auf seiner Website. Sofern
        eine entsprechende Einwilligung abgefragt wurde, erfolgt die
        Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO
        und § 25 Abs. 1 TTDSG, soweit die Einwilligung die Speicherung von
        Cookies oder den Zugriff auf Informationen im Endgerät des Nutzers
        (z. B. Device-Fingerprinting) im Sinne des TTDSG umfasst. Die
        Einwilligung ist jederzeit widerrufbar.
      </p>
      <p>
        Wenn Ihr Browser Web Fonts nicht unterstützt, wird eine Standardschrift
        von Ihrem Computer genutzt.
      </p>
      <p>
        Weitere Informationen zu Google Web Fonts finden Sie unter
        <a
          href="https://developers.google.com/fonts/faq"
          target="_blank"
          rel="noopener noreferrer"
          >https://developers.google.com/fonts/faq</a
        >
        und in der Datenschutzerklärung von Google:
        <a
          href="https://policies.google.com/privacy?hl=de"
          target="_blank"
          rel="noopener noreferrer"
          >https://policies.google.com/privacy?hl=de</a
        >.
      </p>
      <h2>7. Online-Marketing und Partner­programme</h2>
      <h3>Affiliate-Programme auf dieser Website</h3>
      <p>
        Wir nehmen an Affiliate-Partnerprogrammen teil. Bei
        Affiliate-Partner-Programmen werden Werbeanzeigen eines Unternehmens
        (Advertiser) auf Webseiten von anderen Unternehmen des
        Affiliate-Partner-Netzwerks (Publisher) platziert. Wenn Sie auf eine
        dieser Affiliate-Werbeanzeigen klicken, werden Sie zum beworbenen
        Angebot weitergeleitet. Sollten Sie anschließend eine bestimmte
        Transaktion (Conversion) tätigen, erhält der Publisher hierfür eine
        Vergütung. Zur Berechnung dieser Vergütung ist es erforderlich, dass der
        Affiliate-Netzwerkbetreiber nachvollziehen kann, über welche
        Werbeanzeige Sie auf das jeweilige Angebot gekommen sind und die
        vordefinierte Transaktion vorgenommen haben. Hierfür werden Cookies oder
        vergleichbare Wiedererkennungstechnologien (z. B. Device-Fingerprinting)
        eingesetzt.
      </p>
      <p>
        Die Speicherung und Analyse der Daten erfolgt auf Grundlage von Art. 6
        Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse
        an der korrekten Berechnung seiner Affiliate-Vergütung. Sofern eine
        entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung
        ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25
        Abs. 1 TTDSG, soweit die Einwilligung die Speicherung von Cookies oder
        den Zugriff auf Informationen im Endgerät des Nutzers (z. B.
        Device-Fingerprinting) im Sinne des TTDSG umfasst. Die Einwilligung ist
        jederzeit widerrufbar.
      </p>
      <p>Wir nehmen an folgenden Affiliate-Programmen teil:</p>
      <h4>Amazon Partner­programm</h4>
      <p>
        Anbieter ist die Amazon Europe Core S.à.r.l. Details entnehmen Sie der
        Datenschutzerklärung von Amazon unter:
        <a
          href="https://www.amazon.de/gp/help/customer/display.html?nodeId=201909010"
          target="_blank"
          rel="noopener noreferrer"
          >https://www.amazon.de/gp/help/customer/display.html?nodeId=201909010</a
        >.
      </p>
    </div>
  </div>
</template>

  <!-- ---------- ---------- ---------- Code ---------- ---------- ---------- -->

<script>
/* eslint-disable */

export default {
  mounted() {},
  components: {},
  methods: {},
};
</script>
}
<style scoped>
.container {
  margin-top: 1rem;
  margin-bottom: 2rem;
  text-align: left;
  word-break: break-word;
}
.link {
  display: block;
  word-break: break-word;
}
.emage {
  height: 1rem;
}
.phonum {
  height: 1rem;
}
</style>