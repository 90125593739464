/* eslint-disable */
import store from '@/store/index.js'
export default {
  updateActivities(callType) {
    console.log("UpdateActivitiesService is executed")

    var activityList = store.state.storeActivityList
    var activityListFiltered = []
    var clickedAnswers = store.state.clickedAnswers
    var questionObject = store.state.filters
    var ageMin = store.state.ageMin
    var ageMax = store.state.ageMax


    // localStorage.setItem("ageChildrenParentsSwitch", store.state.ageChildrenParentsSwitch);
    // console.log(store.state.ageChildrenParentsSwitch)
    localStorage.setItem("ageMin", store.state.ageMin);
    localStorage.setItem("ageMax", store.state.ageMax);
    localStorage.setItem("ageSave", store.state.ageSave);
    console.log("Age saved in local Storage")
    // console.log(ageMin)
    // console.log(ageMax)
    // console.log(store.state.ageSave)



    // Vereinigungsmenge / set union
    if (store.state.updateActivitiesMethod == "setUnion" /*&& Object.keys(clickedAnswers).length != 0*/) {



      ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      // STEP 1 Start: Create activityListFiltered based on clickedAnswers
      activityList.forEach(function (activity) {

        // Same as below
        var addActivity = true;
        // Selection Buttons
        for (const [questionKey, questionValue] of Object.entries(clickedAnswers)) {
          var oneAnswerInQuestionTrue = false
          for (const [answerKey, answerValue] of Object.entries(questionValue)) {
            if (activity[questionKey].includes(answerValue.filterkey) &&
              !(questionKey == "target" && store.state.itarget) &&
              !(questionKey == "special" && store.state.ispecial) &&
              !(questionKey == "typical" && store.state.itypical) &&
              !(questionKey == "dayweektime" && store.state.idayweektime)
            ) {
              oneAnswerInQuestionTrue = true; break
            }
            //special case for question == "sport" || "game"
            else if ((questionKey == "sport" && !activity.interest.includes("sports"))) { oneAnswerInQuestionTrue = true; break }
            //else if ((questionKey == "game" && !activity.interest.includes("games"))) { oneAnswerInQuestionTrue = true; break }
            //Special Intersection Logic for target and special (also a few lines above)
            else if (
              questionKey == "target" && store.state.itarget ||
              questionKey == "special" && store.state.ispecial ||
              questionKey == "typical" && store.state.itypical ||
              questionKey == "dayweektime" && store.state.idayweektime
            ) {
              if ((activity[questionKey].includes(answerValue.filterkey))) { oneAnswerInQuestionTrue = true }
              else { oneAnswerInQuestionTrue = false; break }
            }
          }
          if (!oneAnswerInQuestionTrue) { addActivity = false; break }
        }


        // Age Slider Exception 1 Start: Do not add activities to activityListFiltered if not in to Age Slider (same as below)
        if (addActivity) {
          if (store.state.ageChildrenParentsSwitch) {
            var agestart = activity.agewp
          }
          else {
            var agestart = activity.agestart
          }
          var ageend = activity.ageend
          if (store.state.ageActive) {
            if (!store.state.ageRangeSliderSwitch) {
              if (!(agestart <= ageMin && ageMin <= ageend)) {
                addActivity = false
              }
            }
            if (store.state.ageRangeSliderSwitch) {
              // if (!(agestart <= ageMin && ageMin <= ageend && agestart <= ageMax && ageMax <= ageend)) {
              if (!((ageMin <= agestart && agestart <= ageMax) || (ageMin <= ageend && ageend <= ageMax)
                || (agestart <= ageMin && ageMin <= ageend) || (agestart <= ageMax && ageMax <= ageend)
              )) {
                addActivity = false
              }
            }
          }
        }
        // Age Slider Exception 1 End

        // ShowIf
        if (addActivity) {
          if (store.state.clickedAnswers.nowlater) {
            if (Object.keys(store.state.clickedAnswers.nowlater).includes("now")) {
              if (
                (!(activity.showifdaytime.includes(store.state.date.daytime) || activity.showifdaytime == "")) ||
                (!(activity.showifweekday.includes(store.state.date.weekday) || activity.showifweekday == "")) ||
                (!(activity.showifseason.includes(store.state.date.season) || activity.showifseason == "")) ||
                (!(activity.showifweather.includes(store.state.weather.condition) || activity.showifweather == ""))
              ) {
                addActivity = false
              }
            }
          }
          // Do not show specific winter activities in spring or summer
          if ((store.state.date.season == "spring" || store.state.date.season == "summer") && activity.showifseason.includes("winter")) {
            // unless "winter" is selected
            if (!(store.state.clickedAnswers.season && Object.keys(store.state.clickedAnswers.season).includes("winter"))) {
              addActivity = false
            }
          }
        }
        // ShowIf END

        if (addActivity == true) { activityListFiltered.push(activity) }
      })
      store.state.storeActivityListFiltered = activityListFiltered
      // STEP 1 End: ActivityListFiltered is created: store.state.storeActivityListFiltered
      ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////





      ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      // STEP 2 Start: Calculate possible Minimum and Maximum values for Age Slider, so that activityListFiltered.length does not get 0
      // STEP 2a: Create activityListFiltered WithoutSlider
      var activityListFilteredWithoutSlider = []
      activityList.forEach(function (activity) {
        var addActivity = true;
        // Selection Buttons
        for (const [questionKey, questionValue] of Object.entries(clickedAnswers)) {
          var oneAnswerInQuestionTrue = false
          for (const [answerKey, answerValue] of Object.entries(questionValue)) {
            if (activity[questionKey].includes(answerValue.filterkey) &&
              !(questionKey == "target" && store.state.itarget) &&
              !(questionKey == "special" && store.state.ispecial) &&
              !(questionKey == "typical" && store.state.itypical) &&
              !(questionKey == "dayweektime" && store.state.idayweektime)
            ) {
              oneAnswerInQuestionTrue = true; break
            }
            //special case for question == "sport" || "game"
            else if ((questionKey == "sport" && !activity.interest.includes("sports"))) { oneAnswerInQuestionTrue = true; break }
            //else if ((questionKey == "game" && !activity.interest.includes("games"))) { oneAnswerInQuestionTrue = true; break }
            //Special Intersection Logic for target and special (also a few lines above)
            else if (
              questionKey == "target" && store.state.itarget ||
              questionKey == "special" && store.state.ispecial ||
              questionKey == "typical" && store.state.itypical ||
              questionKey == "dayweektime" && store.state.idayweektime
            ) {
              if ((activity[questionKey].includes(answerValue.filterkey))) { oneAnswerInQuestionTrue = true }
              else { oneAnswerInQuestionTrue = false; break }
            }
          }
          if (!oneAnswerInQuestionTrue) { addActivity = false; break }
        }
        if (addActivity == true) { activityListFilteredWithoutSlider.push(activity) }
      })
      // STEP 2b: Create min and max values for Slider
      var min = store.state.ageFixMax//activityListFilteredWithoutSlider[0].agestart
      var max = store.state.ageFixMin//activityListFilteredWithoutSlider[0].ageend
      activityListFilteredWithoutSlider.forEach(function (activity) {
        if (store.state.ageChildrenParentsSwitch) {
          var agestart = activity.agewp
        }
        else {
          var agestart = activity.agestart
        }
        if (agestart < min) { min = agestart }
        if (activity.ageend > max) { max = activity.ageend }
      });
      store.state.ageFixMin = min
      store.state.ageFixMax = max

      // If ageChildrenParentsSwitch
      // if (store.state.ageChildrenParentsSwitch && !store.state.ageRangeSliderSwitch) { store.state.ageFixMax = Math.min.apply(null, [max, 17]) }

      // Check, if ageMin < ageFixMin || ageMax > ageFixMax to prevent errors
      if (store.state.ageMin < store.state.ageFixMin) {
        store.state.ageMin = store.state.ageFixMin;
        ageMin = store.state.ageFixMin
      }
      if (store.state.ageMin > store.state.ageFixMax) {
        store.state.ageMin = store.state.ageFixMax;
        ageMin = store.state.ageFixMax
      }
      if (store.state.ageMax > store.state.ageFixMax) {
        store.state.ageMax = store.state.ageFixMax;
        ageMax = store.state.ageFixMax
      }
      if (store.state.ageMax < store.state.ageFixMin) {
        store.state.ageMax = store.state.ageFixMin;
        ageMax = store.state.ageFixMin
      }
      /*
      console.log("AGEFIXMIN AGEFIXMAX")
      console.log(store.state.ageFixMin)
      console.log(store.state.ageFixMax)
      console.log("AGEMIN AGEMAX")
      console.log(store.state.ageMin)
      console.log(store.state.ageMax)
      */
      // STEP 2 End: Minimum and Maximum values for Age Slider are created: store.state.ageFixMin + store.state.ageFixMax 
      ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////





      ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      // STEP 3 Start: Calculate answer count for all questions and all answers except age, so that activityListFiltered.length does not get 0
      // for each question
      for (const [questionKeyOuter, questionValueOuter] of Object.entries(questionObject)) {
        var activityListFilteredHelper = []



        //////////////////////////////
        // STEP 3a Start: Calculate activityListFilteredHelper for all questions
        // Only do this for clickedAnwers
        if (questionKeyOuter in clickedAnswers) {
          // target: create one activityListFilteredHelper based on clickedAnswers. Exclude clickedAnswers from same question
          activityList.forEach(function (activity) {

            // STEP 3a i:
            // All but AgeSlider
            // Create activityListFiltered and filter all clicked answers except ageSlider and the anwers of the clicked Question (almost the same as above)
            // Do not add activities to activityListFilteredHelper due to all clicked answers except ageSlider and the anwers of the clicked Question
            var addActivity = true;
            for (const [questionKey, questionValue] of Object.entries(clickedAnswers)) {
              if (questionKey != questionKeyOuter ||
                questionKey == "target" && store.state.itarget ||
                questionKey == "special" && store.state.ispecial ||
                questionKey == "typical" && store.state.itypical ||
                questionKey == "dayweektime" && store.state.idayweektime
              ) {
                var oneAnswerInQuestionTrue = false
                for (const [answerKey, answerValue] of Object.entries(questionValue)) {
                  if ((activity[questionKey].includes(answerValue.filterkey)) &&
                    !(questionKey == "target" && store.state.itarget) &&
                    !(questionKey == "special" && store.state.ispecial) &&
                    !(questionKey == "typical" && store.state.itypical) &&
                    !(questionKey == "dayweektime" && store.state.idayweektime)
                  ) {
                    oneAnswerInQuestionTrue = true; break
                  }
                  //special case for question == "sport" || "game"
                  else if ((questionKey == "sport" && !activity.interest.includes("sports"))) { oneAnswerInQuestionTrue = true; break }
                  //else if ((questionKey == "game" && !activity.interest.includes("games"))) { oneAnswerInQuestionTrue = true; break }
                  //Special Intersection Logic for target and special (also a few lines above)
                  else if (
                    questionKey == "target" && store.state.itarget ||
                    questionKey == "special" && store.state.ispecial ||
                    questionKey == "typical" && store.state.itypical ||
                    questionKey == "dayweektime" && store.state.idayweektime
                  ) {
                    if ((activity[questionKey].includes(answerValue.filterkey))) { oneAnswerInQuestionTrue = true }
                    else { oneAnswerInQuestionTrue = false; break }

                  }
                }
                //console.log(allAnswerInQuestionTrue)
                if (!oneAnswerInQuestionTrue) { addActivity = false; break }
              }
            }
            //if (activity.id == 282) { console.log("CASINOOOOOOOOO") }
            //if (activity.id == 282) { console.log(addActivity) }

            // STEP 3a ii:
            // AgeSlider
            // Create activityListFiltered and filter values from ageSlider, if clicked (same as above)
            // Do not add activities to activityListFilteredHelper due to Age Slider
            if (addActivity) {
              if (store.state.ageChildrenParentsSwitch) {
                var agestart = activity.agewp
              }
              else {
                var agestart = activity.agestart
              }
              var ageend = activity.ageend
              if (store.state.ageActive) {
                if (!store.state.ageRangeSliderSwitch) {
                  if (!(agestart <= ageMin && ageMin <= ageend)) {
                    addActivity = false
                  }
                }
                if (store.state.ageRangeSliderSwitch) {
                  // if (!(agestart <= ageMin && ageMin <= ageend && agestart <= ageMax && ageMax <= ageend)) {
                  if (!((ageMin <= agestart && agestart <= ageMax) || (ageMin <= ageend && ageend <= ageMax)
                    || (agestart <= ageMin && ageMin <= ageend) || (agestart <= ageMax && ageMax <= ageend)
                  )) {
                    addActivity = false
                  }
                }
              }
            }

            if (addActivity == true) { activityListFilteredHelper.push(activity) }
          })
        }
        else activityListFilteredHelper = activityListFiltered
        //console.log(activityListFilteredHelper)
        // STEP 3a End: activityListFilteredHelper calculated for all questions
        //////////////////////////////


        //////////////////////////////
        // STEP 3b Start: Calculate answer count and possible values for cost and time
        // - store.state.filters[question][answer].count for all questions except age
        // - costFixMin, costFixMax, costEmptyList
        // - timeFixMin, timeFixMax, timeEmptyList  

        //////
        // STEP 3bi Start: Calculate store.state.filters[question][answer].count
        // Do this for interactiontype / place / setting / interest / target / typical / special / sport / cost / time (/ game)
        // target: calculate answer count for all answers in one question
        var i = 0
        // for all answers
        questionValueOuter.forEach(function (answerValue) {
          var activitycount = 0;

          // for all activities
          activityListFilteredHelper.forEach(function (activity) {
            if (activity[questionKeyOuter].includes(answerValue.filterkey)) { activitycount = activitycount + 1 }
          })
          questionObject[questionKeyOuter][i].count = activitycount
          i = i + 1
        })
        // STEP 3b End: CountZero calculated for store.state.filters.interactiontype / place / setting / interest / target / typical / special / sport (/ game)



        //////
        // STEP 3bii: Calculate costFixMin, costFixMax, costEmptyList
        if (questionKeyOuter == "cost") {
          var i = 0
          var costEmptyList = []
          questionObject.cost.forEach(function (cost) {
            if (cost.count != 0) { costEmptyList.push(i) }
            i = i + 1
          })
          store.state.costFixMin = Math.min.apply(null, costEmptyList)
          store.state.costFixMax = Math.max.apply(null, costEmptyList)
          store.state.costEmptyList = costEmptyList
        }

        //////
        // STEP 3biii: Calculate timeFixMin, timeFixMax, timeEmptyList        
        if (questionKeyOuter == "time") {
          var i = 0
          var timeEmptyList = []
          questionObject.time.forEach(function (time) {
            if (time.count != 0) { timeEmptyList.push(i) }
            i = i + 1
          })
          store.state.timeFixMin = Math.min.apply(null, timeEmptyList)
          store.state.timeFixMax = Math.max.apply(null, timeEmptyList)
          store.state.timeEmptyList = timeEmptyList
        }


      }
      store.state.filters = questionObject
      // STEP 3 End: Answer count for all questions and all answers except age is calculated, so that activityListFiltered.length does not get 0
      // - store.state.filters.interactiontype / place / setting / interest / target / typical / special / sport (/ game)
      // - store.state.filters.cost
      // - store.state.filters.time
      ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    }


















    // Calculation Method: Schnittmenge / intersection
    if (store.state.updateActivitiesMethod == "intersection") {
      var response = []

      store.state.storeActivityList.forEach(function (activityItem) {
        var hasAllSelectedFilters = true
        // For each entry in filters - For example "age"
        for (const [key, value] of Object.entries(store.state.filters)) {
          // For each entry in "age" - For example "toddler"
          value.forEach(function (x) {
            // Check, if "toddler" is selected
            if (x.selected == true) {
              // If "toddler" is selected and the activityItem does not include "toddler, set "hasAllSelectedFilters" to false
              if (activityItem[key].includes(x.filterkey)) {
              }
              else {
                hasAllSelectedFilters = false
              }
            }
          })
        }
        if (hasAllSelectedFilters) {
          response.push(activityItem)

          // Count Start
          for (const [key, value] of Object.entries(store.state.filters)) {
            value.forEach(function (x) {
              if (activityItem[key].includes(x.filterkey)) {
                x.count = x.count + 1
              }
            })
          }
          // Count End

        }
      })
      store.state.storeActivityListFiltered = response

    }

    store.state.favoritesToggle = false
    //Not needed anymore due to changed logic and "storeActivityListSearched"
    //store.state.searchToggle = false
    //store.state.searchText = ""
    return Promise.resolve()

  }
}