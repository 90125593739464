<!-- ---------- ---------- ---------- HTML Structure ---------- ---------- ---------- -->
<template>
  <div class="heightandwidth">
    <!--Image wikipedia / unsplash online-->
    <div
      v-if="$store.state.activity.imageavailable != 1"
      class="heightandwidth"
    >
      <div v-if="$store.state.imageLoaded" class="heightandwidth">
        <img v-bind:src="unsplashImage" error="setAltImg" />
      </div>
      <!--Image loading-->
      <div class="loading">
        <div
          class="spinner-border"
          role="status"
          v-if="!$store.state.imageLoaded"
        >
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  </div>
</template>

  <!-- ---------- ---------- ---------- Code ---------- ---------- ---------- -->

<script>
/* eslint-disable */

export default {
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {
    setAltImg(event) {
      console.log("image could not be loaded");
      event.target.src = require("../../assets/error.png");
    },
  },
  computed: {
    unsplashImage() {
      return this.$store.state.storeUnsplashImage;
    },
  },
};
</script>

<style scoped>
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.heightandwidth {
  height: 100%;
  width: 100%;
}
.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
}
</style>