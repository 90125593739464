<!-- ---------- ---------- ---------- HTML Structure ---------- ---------- ---------- -->
<template>
  <div>
    <!--Buttons on the bottom-->
    <div
      class="filterViewBottomContainer filterViewBottomContainerMiddle"
      v-bind:class="{
        infront: $store.state.explanationState == 1,
        iphonefooter: this.$store.state.browserName == 'safari' && safariStandalone
      }"
    >
      <div class="container containermiddle">
        <!-- One Button -->
        <div
          style="
            color: #ffffff;
            background-color: #F39EC5;
            border-radius: 1rem;
            cursor: pointer;
            pointer-events: all;
            display: flex;
            justify-content: space-between;
            height: 2.5rem;
            padding: 0rem 0rem 0rem 0.5rem;
          "
          v-on:click="
            ($store.state.filterClicked = true), $router.push('/filter')
          "
          v-bind:class="{
            nopointer: $store.state.explanationState != 1,
            rotatecenter: $store.state.lastSlide == true,
          }"
        >
          <div style="width: 2rem; height: 100%; position: relative">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              enable-background="new 0 0 24 24"
              height="18px"
              viewBox="0 0 24 24"
              width="18px"
              fill="#FFFFFF"
            >
              <g>
                <path d="M0,0h24 M24,24H0" fill="none" />
                <path
                  d="M7,6h10l-5.01,6.3L7,6z M4.25,5.61C6.27,8.2,10,13,10,13v6c0,0.55,0.45,1,1,1h2c0.55,0,1-0.45,1-1v-6 c0,0,3.72-4.8,5.74-7.39C20.25,4.95,19.78,4,18.95,4H5.04C4.21,4,3.74,4.95,4.25,5.61z"
                />
                <path d="M0,0h24v24H0V0z" fill="none" />
              </g>
            </svg>
          </div>
          <div
            style="
              height: 100%;
              display: flex;
              align-items: center;
              padding: 0rem 1rem 0rem 0rem;
            "
          >
            <span v-if="$store.state.user.countryCode == 'DE'">
              Filter</span
            >
            <span v-else>Filter</span>
          </div>
        </div>

        <!-- Left Button -->
        <div
          class="leftButton"
          v-on:click="
            ($store.state.filterClicked = true), $router.push('/filter')
          "
          v-bind:class="{
            nopointer:
              $store.state.firstVisit && $store.state.explanationState != 1,
            rotatecenter: $store.state.lastSlide == true,
          }"
          v-if="$store.state.betaFeatures && false"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            enable-background="new 0 0 24 24"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
            fill="#FFFFFF"
          >
            <g>
              <path d="M0,0h24 M24,24H0" fill="none" />
              <path
                d="M7,6h10l-5.01,6.3L7,6z M4.25,5.61C6.27,8.2,10,13,10,13v6c0,0.55,0.45,1,1,1h2c0.55,0,1-0.45,1-1v-6 c0,0,3.72-4.8,5.74-7.39C20.25,4.95,19.78,4,18.95,4H5.04C4.21,4,3.74,4.95,4.25,5.61z"
              />
              <path d="M0,0h24v24H0V0z" fill="none" />
            </g>
          </svg>
        </div>
        <!-- Right Button -->
        <div
          class="rightButton"
          v-on:click="$router.push('/activitylist')"
          v-bind:class="{
            nopointer: $store.state.firstVisit,
            leftnoclick: $store.state.explanationState == 1,
          }"
          v-if="$store.state.betaFeatures && false"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
            fill="#FFFFFF"
          >
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path
              d="M4 10.5c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5 1.5-.67 1.5-1.5-.67-1.5-1.5-1.5zm0-6c-.83 0-1.5.67-1.5 1.5S3.17 7.5 4 7.5 5.5 6.83 5.5 6 4.83 4.5 4 4.5zm0 12c-.83 0-1.5.68-1.5 1.5s.68 1.5 1.5 1.5 1.5-.68 1.5-1.5-.67-1.5-1.5-1.5zM7 19h14v-2H7v2zm0-6h14v-2H7v2zm0-8v2h14V5H7z"
            />
          </svg>
        </div>

        <!--
        <div
          v-if="false"
          class="middleButton"
          v-on:click="$router.push('/favorites')"
          v-bind:class="{
            middleButtonInvisible:
              $store.state.storeActivityListFavorites.length == 0,
            nopointer: $store.state.firstVisit,
          }"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
            fill="#FFFFFF"
            v-bind:class="{
              middleButtonInvisible:
                $store.state.storeActivityListFavorites.length == 0,
            }"
          >
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path
              d="M16.5 3c-1.74 0-3.41.81-4.5 2.09C10.91 3.81 9.24 3 7.5 3 4.42 3 2 5.42 2 8.5c0 3.78 3.4 6.86 8.55 11.54L12 21.35l1.45-1.32C18.6 15.36 22 12.28 22 8.5 22 5.42 19.58 3 16.5 3zm-4.4 15.55l-.1.1-.1-.1C7.14 14.24 4 11.39 4 8.5 4 6.5 5.5 5 7.5 5c1.54 0 3.04.99 3.57 2.36h1.87C13.46 5.99 14.96 5 16.5 5c2 0 3.5 1.5 3.5 3.5 0 2.89-3.14 5.74-7.9 10.05z"
            />
          </svg>
        </div>
        -->
      </div>
    </div>
  </div>
</template>

  <!-- ---------- ---------- ---------- Code ---------- ---------- ---------- -->

<script>
/* eslint-disable */

export default {
  components: {},
  data() {
    return {};
  },
  props: {},
  mounted() {},
  methods: {

  },
  computed: {
        safariStandalone() {
      return window.navigator.standalone
    },
  },
};
</script>

<style scoped>
.filterViewBottomContainer {
  position: fixed;
  bottom: 3rem;
  padding-top: 1rem;
  height: 6rem;
  width: 100%;
  overflow: hidden;
  pointer-events: none;
  z-index: 91;
}
.filterViewBottomContainerMiddle {
  bottom: 2.5rem;
}
.iphonefooter {
  margin-bottom: env(safe-area-inset-bottom, 1rem) !important;
}
.infront {
  z-index: 100;
}

.container {
  display: inline-flex;
  justify-content: space-between; /*space-between;*/
  max-width: 50rem;
}
.containermiddle {
  justify-content: center; /*space-between;*/
}
.leftButton {
  position: relative;
  color: #ffffff;
  padding: 1.75rem 1.75rem;
  background-color: #e83e8c;
  border-radius: 1rem;
  cursor: pointer;
  pointer-events: all;
  margin-left: 0.5rem;
}
.leftnoclick {
  pointer-events: none;
  background-color: #8fc5ff;
}
.rightButton {
  position: relative;
  color: #ffffff;
  padding: 1.75rem 1.75rem;
  background-color: #007bff; /*#20c997;*/
  border-radius: 1rem;
  cursor: pointer;
  pointer-events: all;
  margin-right: 0.5rem;
}
svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.middleButton {
  position: relative;
  color: #ffffff;
  padding: 1.75rem 1.75rem;
  background-color: #e83e8c;
  border-radius: 1rem;
  cursor: pointer;
  pointer-events: all;
}

.middleButtonInvisible {
  background: transparent;
  pointer-events: none;
  fill: none;
}
.nopointer {
  pointer-events: none;
}

.rotatecenter {
  animation: rotatecenter 0.6s ease-in-out both;
}
@keyframes rotatecenter {
  0% {
    transform: scale(1);
  }
  33% {
    transform: scale(0.66);
  }
  66% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
</style>