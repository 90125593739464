<template>
  <div class="page">
    <!-- <ClickedFiltersTopComponent /> -->
    <div class="overlay"></div>
    <transition name="fade">
      <div style="max-width: 80vh; width: 100%; margin-right: auto; margin-left: auto;"
        v-if="Object.keys($store.state.filterCombosNew).length != 0">

        <!--NEW UI-->
        <!-- <div v-if="$store.state.newUI">
          <div style="padding-top: 1rem; padding-left: 0.75rem; padding-right: 0.75rem;">
            <swiper slidesPerView="1" :spaceBetween="15" :rewind="true" :centeredSlides="false" :initialSlide="0"
              @swiper="setSwiperRef" :modules="modules" :navigation="!$store.state.isTouchDevice"
              :pagination="{ el: '.swiper-pagination0', clickable: 'true' }"
              :style="{ '--swiper-navigation-color': '#007bff', '--swiper-navigation-size': '4vh', '--swiper-pagination-color': '#000000', }"
              style="padding: 5px 5px 0px 5px" class="questionswiper">
              <swiper-slide v-for="question in filtersAdapted" v-bind:key="question.id"
                v-bind:class="{ disableswipe: question[0].parent == 'time' && false, additionalpadding: !$store.state.isTouchDevice }">
                <div
                  style="margin:0rem 0rem 0rem 0rem; cursor: pointer; height: 30vh; text-align: center;overflow: auto; 
                display: flex; vertical-align: middle; flex-direction: column; justify-content: center; border-radius: 0.6rem"
                  class="test4">
                  <AllFiltersComponent :discoveryViewQuestion="question[0].parent" :isDiscoveryView="true"
                    :isDiscoveryViewCarousel="true" v-on:click="slideTo(index)"
                    style="overflow: auto; padding: 0.75rem 0rem 0.75rem 0rem" />
                </div>
              </swiper-slide>
              <div class="swiper-pagination0"
                style="height: 3rem; z-index: 1000;display: flex; justify-content: center; align-items: center;"></div>
            </swiper>
          </div>
          <div style="margin: 0rem 0.75rem 0rem 0.75rem; cursor: pointer">
            <button v-if="Object.keys(this.$store.state.clickedAnswers).length == 0 && !this.$store.state.ageActive"
              style="border-radius: 0.6rem; color: white; background: #007bff80; box-shadow: 0px 1px 5px #dee2e6; font-size: 0.9rem; height: 2.5rem; padding: 0rem 1.5rem;"
              v-bind:class="{ blue2: Object.keys(this.$store.state.clickedAnswers).length != 0 || this.$store.state.ageActive }"
              id="tt-d-surprise" v-on:click="supprise()">
              <div style="pointer-events: none">
                <span v-if="$store.state.user.countryCode == 'DE'">Lass dich überraschen</span>
                <span v-else>Surprise me</span>
              </div>
            </button>
            <button v-else
              style="border-radius: 0.6rem; color: white; background: #007bff; box-shadow: 0px 1px 5px #dee2e6; font-size: 0.9rem; height: 2.5rem; padding: 0rem 1.5rem;"
              v-bind:class="{ blue2: Object.keys(this.$store.state.clickedAnswers).length != 0 || this.$store.state.ageActive }"
              id="tt-d-showactivities" @click="$router.push('/activitylist')">
              <div style="pointer-events: none">
                <span v-if="$store.state.user.countryCode != 'DE'">Show&nbsp;</span>
                {{ animatedNumber + "&nbsp;" }}
                <span v-if="$store.state.user.countryCode == 'DE'">Aktivitäten anzeigen</span>
                <span v-else>activities</span>
              </div>
            </button>
          </div>
        </div> -->

        <!--OLD UI-->
        <div v-if="!$store.state.newUI">
          <h1
            style=" margin-top: 1.25rem; margin-bottom: 1.05rem; margin-left: 0.5rem; margin-right: 0.5rem; font-weight: bold; font-size: 1.1rem;">
            <span v-if="$store.state.user.countryCode == 'DE'"> Finde Ideen für deine Freizeit</span>
            <span v-else>Get ideas for your free time</span>
          </h1>
          <!--Questions at the beginning - QuestionDrawer-->
          <div
            style="margin-left: 0.75rem; margin-right: 0.75rem; box-shadow: 0px 1px 5px #dee2e6; border-radius: 0.6rem">
            <div
              style="margin-left: .75rem; margin-right: 0.75rem; box-shadow: 0px 0px 0px #dee2e6; cursor: pointer; border-radius: 0.6rem">

              <!--People Question-->
              <div class="questionitem" @click="doShowPeople('clickFromOutside')"
                style="padding: 0.5rem 0rem; border-bottom: 1px solid #dee2e6; display: flex; flex-direction: column; justify-content: center">
                <!--Question-->
                <div @click="doShowPeople" style="display: flex; align-items: center; position: relative">
                  <div style="width: 20%; display: inline-block; text-align: center">
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px"
                      fill="#000000">
                      <path d="M0 0h24v24H0V0z" fill="none" />
                      <path
                        d="M12 6c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2m0 10c2.7 0 5.8 1.29 6 2H6c.23-.72 3.31-2 6-2m0-12C9.79 4 8 5.79 8 8s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 10c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" />
                    </svg>
                  </div>
                  <div style="width: 80%; display: inline-block; text-align: left">
                    <span v-if="$store.state.user.countryCode == 'DE'">{{
                    $store.state.filters.typical[0].questionde
                    }}</span>
                    <span v-else>{{ $store.state.filters.typical[0].question }}</span>
                  </div>
                </div>
              </div>

              <!--Place Question-->
              <div class="questionitem" @click="doShowPlace('clickFromOutside')"
                style="padding: 0.5rem 0rem; border-bottom: 1px solid #dee2e6; display: flex; flex-direction: column; justify-content: center  ">
                <div @click="doShowPlace" style="display: flex; align-items: center; position: relative">
                  <div style="width: 20%; display: inline-block; text-align: center">
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px"
                      fill="#000000">
                      <path d="M0 0h24v24H0V0z" fill="none" />
                      <path
                        d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zM7 9c0-2.76 2.24-5 5-5s5 2.24 5 5c0 2.88-2.88 7.19-5 9.88C9.92 16.21 7 11.85 7 9z" />
                      <circle cx="12" cy="9" r="2.5" />
                    </svg>
                  </div>
                  <div style="width: 80%; display: inline-block; text-align: left">
                    <span v-if="$store.state.user.countryCode == 'DE'">{{
                    $store.state.filters.place[0].questionde
                    }}</span>
                    <span v-else>{{ $store.state.filters.place[0].question }}</span>
                  </div>
                </div>
              </div>

              <!--Time Question-->
              <div class="questionitem" @click="doShowTime('clickFromOutside')"
                style="padding: 0.5rem 0rem; display: flex; flex-direction: column; justify-content: center">
                <div @click="doShowTime" style="display: flex; align-items: center; position: relative">
                  <div style="width: 20%; display: inline-block; text-align: center">
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px"
                      fill="#000000">
                      <path d="M0 0h24v24H0V0z" fill="none" />
                      <path
                        d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm.5-13H11v6l5.25 3.15.75-1.23-4.5-2.67z" />
                    </svg>
                  </div>
                  <div style="width: 80%; display: inline-block; text-align: left">
                    <span v-if="$store.state.user.countryCode == 'DE'">{{
                    $store.state.filters.time[0].questionde
                    }}</span>
                    <span v-else>{{ $store.state.filters.time[0].question }}</span>
                  </div>
                </div>
              </div>
            </div>
            <!-- Show Activities / Show Random Activities-->
            <div style="
            font-size: 0.9rem; 
            min-height: 3rem; 
            padding: 0.5rem 0rem; 
            display: flex; 
            align-items: center; 
            position: relative; 
            border-radius: 0rem 0rem 0.6rem 0.6rem; 
            cursor: pointer;
            background-image: linear-gradient(to bottom, transparent -300%, #007bff80 100%)">
              <div style="width: 100%; display: inline-block; text-align: center; color: white">
                <div v-if="$store.state.storeActivityList.length == $store.state.storeActivityListFiltered.length"
                  v-on:click="supprise()">
                  <span v-if="$store.state.user.countryCode == 'DE'">Lass dich überraschen</span>
                  <span v-else>Surprise me</span>
                </div>
                <div v-if="$store.state.storeActivityList.length != $store.state.storeActivityListFiltered.length"
                  @click="$router.push('/activitylist')">
                  <span v-if="$store.state.user.countryCode != 'DE'">Show&nbsp;</span>
                  {{ animatedNumber + "&nbsp;" }}
                  <span v-if="$store.state.user.countryCode == 'DE'">Aktivitäten anzeigen</span>
                  <span v-else>activities</span>
                </div>
              </div>
            </div>
          </div>

          <!--Drawer on the bottom New-->
          <n-drawer v-model:show="drawerActive" :height="400" :placement="'bottom'" style="text-align: center">
            <n-drawer-content
              body-content-style="padding: 0rem 0rem 0rem 0rem; max-width: 50rem; margin-right: auto; margin-left: auto; position: relative">
              <div style="padding-left: 0.75rem; padding-right: 0.75rem; width: 100%; height: 100%; position: relative">
                <swiper slidesPerView="1" :spaceBetween="15" :loop="false" :centeredSlides="false" :initialSlide="0"
                  @swiper="setSwiperRef" :modules="modules" :navigation="!$store.state.isTouchDevice"
                  :pagination="{ el: '.swiper-pagination1', clickable: 'true' }"
                  :style="{ '--swiper-navigation-color': '#007bff', '--swiper-navigation-size': '4vh', '--swiper-pagination-color': '#000000', }"
                  style="width: 100%; height: 70%; position: relative">
                  <swiper-slide v-for="question in filtersAdapted" v-bind:key="question.id"
                    v-bind:class="{ disableswipe: question[0].parent == 'time' && false, additionalpadding: !$store.state.isTouchDevice }"
                    style="height: 100%; overflow: auto">
                    <div style="margin:0rem 0rem; cursor: pointer; text-align: center;
                    display: flex; vertical-align: middle; flex-direction: column; justify-content: center;">
                      <AllFiltersComponent :discoveryViewQuestion="question[0].parent" :isDiscoveryView="true"
                        :isDiscoveryViewDrawerCarousel="true" v-on:click="slideTo(index)" />
                    </div>
                  </swiper-slide>
                </swiper>

                <!-- Show Activities / Show Random Activities-->
                <div style="height: 30%; position: relative; display: flex; align-items: center">
                  <div style="font-size: 0.9rem; width: 100%; min-height: 3rem; padding: 0.5rem 0rem; display: flex; align-items: center; ; cursor: pointer; border-radius: 0.6rem;
                  background-image: linear-gradient(to bottom, transparent -300%, #007bff80 100%)"
                    v-bind:class="{ blue: swiperRef.activeIndex == 2 && $store.state.clickedAnswers.time }">
                    <div style="width: 100%; display: inline-block; text-align: center; color: white">
                      <div v-if="$store.state.storeActivityList.length == $store.state.storeActivityListFiltered.length"
                        v-on:click="supprise()">
                        <span v-if="$store.state.user.countryCode == 'DE'">Lass dich überraschen</span>
                        <span v-else>Surprise me</span>
                      </div>
                      <div v-if="$store.state.storeActivityList.length != $store.state.storeActivityListFiltered.length"
                        @click="$router.push('/activitylist')">
                        <span v-if="$store.state.user.countryCode != 'DE'">Show&nbsp;</span>
                        {{ animatedNumber + "&nbsp;" }}
                        <span v-if="$store.state.user.countryCode == 'DE'">Aktivitäten anzeigen</span>
                        <span v-else>activities</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </n-drawer-content>
          </n-drawer>
        </div>

        <!--Inspiration Headline 1-->
        <div style="display: flex; justify-content: center; align-items: center; padding: 0rem 0.75rem">

          <h2 style=" font-weight: bold; margin: 1.5rem 0.75rem 1.5rem 0.75rem; font-size: 1.1rem;">
            <span v-if="$store.state.user.countryCode == 'DE'">Finde Ideen für deine Freizeit</span>
            <span v-else>Get ideas for your free-time</span>
          </h2>
          <div style="display: flex; justify-content: center; align-items: center;">
            <svg v-if="$store.state.weather.condition == 'clear'" xmlns="http://www.w3.org/2000/svg" height="17px"
              viewBox="0 0 24 24" fill="#000000">
              <path d="M0 0h24v24H0V0z" fill="none" />
              <path
                d="M6.76 4.84l-1.8-1.79-1.41 1.41 1.79 1.79zM1 10.5h3v2H1zM11 .55h2V3.5h-2zm8.04 2.495l1.408 1.407-1.79 1.79-1.407-1.408zm-1.8 15.115l1.79 1.8 1.41-1.41-1.8-1.79zM20 10.5h3v2h-3zm-8-5c-3.31 0-6 2.69-6 6s2.69 6 6 6 6-2.69 6-6-2.69-6-6-6zm0 10c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm-1 4h2v2.95h-2zm-7.45-.96l1.41 1.41 1.79-1.8-1.41-1.41z" />
            </svg>
            <svg v-if="$store.state.weather.condition == 'clouds'" xmlns="http://www.w3.org/2000/svg" height="17px"
              viewBox="0 0 24 24" fill="#000000">
              <path
                d="M6.5 20Q4.225 20 2.613 18.425Q1 16.85 1 14.575Q1 12.625 2.175 11.1Q3.35 9.575 5.25 9.15Q5.875 6.85 7.75 5.425Q9.625 4 12 4Q14.925 4 16.962 6.037Q19 8.075 19 11Q20.725 11.2 21.863 12.487Q23 13.775 23 15.5Q23 17.375 21.688 18.688Q20.375 20 18.5 20ZM12 12Q12 12 12 12Q12 12 12 12Q12 12 12 12Q12 12 12 12Q12 12 12 12Q12 12 12 12Q12 12 12 12Q12 12 12 12Q12 12 12 12Q12 12 12 12Q12 12 12 12Q12 12 12 12ZM6.5 18H18.5Q19.55 18 20.275 17.275Q21 16.55 21 15.5Q21 14.45 20.275 13.725Q19.55 13 18.5 13H17V11Q17 8.925 15.538 7.462Q14.075 6 12 6Q9.925 6 8.463 7.462Q7 8.925 7 11H6.5Q5.05 11 4.025 12.025Q3 13.05 3 14.5Q3 15.95 4.025 16.975Q5.05 18 6.5 18Z" />
            </svg>
            <svg v-if="$store.state.weather.condition == 'rain'" xmlns="http://www.w3.org/2000/svg" height="17px"
              viewBox="0 0 24 24" fill="#000000">
              <g>
                <rect fill="none" height="24" width="24" />
                <path
                  d="M14.5,6.92L13,5.77V3.88V3.4c0-0.26,0.22-0.48,0.5-0.48c0.28,0,0.5,0.21,0.5,0.48V4h2V3.4C16,2.07,14.88,1,13.5,1 C12.12,1,11,2.07,11,3.4v0.48v1.89L9.5,6.92L6,6.07l5.05,15.25C11.2,21.77,11.6,22,12,22s0.8-0.23,0.95-0.69L18,6.07L14.5,6.92z M13.28,8.5l0.76,0.58l0.92-0.23L13,14.8V8.29L13.28,8.5z M9.96,9.09l0.76-0.58L11,8.29v6.51L9.03,8.86L9.96,9.09z" />
              </g>
            </svg>
          </div>
          <!-- <div style="font-size: 0.9rem; padding: 0.12rem 0rem 0rem 0.5rem;"> {{ $store.state.weather.temperature + " °C" }}</div> -->

        </div>

        <!--Filter Carousel New 1 situation-->
        <div style="margin-bottom: 1rem;">
          <swiper slidesPerView="2" :spaceBetween="15" :rewind="true" :centeredSlides="false" :initialSlide="0"
            :modules="modules" :navigation="!$store.state.isTouchDevice"
            :pagination="{ el: '.swiper-pagination1', clickable: 'true' }"
            :style="{ '--swiper-navigation-color': '#fff', '--swiper-navigation-size': '4vh', '--swiper-pagination-color': '#000000', }"
            style=" width: 100%; position: relative; padding-left: 0.75rem; padding-right: 0.75rem; padding-bottom: 4vh;">
            <swiper-slide v-for="dCombo in $store.state.filterCombosNew.situation" :key="dCombo">
              <div v-on:click="setStoreSelectedActivity(dCombo.dComboFilters, 'firstb')"
                style="box-shadow: 0px 1px 5px #dee2e6; border-radius: 0.6rem; cursor: pointer"
                class="click-filter-combo" v-bind:id="dCombo.dComboKey">
                <v-responsive :aspect-ratio="3 / 2" style="position: relative; pointer-events: none">
                  <img
                    style="object-fit: cover; width: 100%; height: 100%; border-radius: 0.6rem 0.6rem 0rem 0rem; 
                    align-self: baseline; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); opacity: 1;"
                    v-bind:src="'https://storage.googleapis.com/timetip-images/activity-images/' + dCombo.dComboImageName + '.jpg'"
                    @load="onImgLoad" @error="setAltImg" />
                </v-responsive>
                <div style="position: relative; margin: 0rem 0.375rem 0.75rem 0.375rem; padding: 0.25rem; font-size: 0.9rem; 
                  min-height: 5rem; display: flex; justify-content: center; align-items: center; pointer-events: none">
                  <div>
                    <span v-if="Object.keys(dCombo.dComboFilters).length != 0">{{ dCombo.dCount + " " }} </span>
                    <span>{{ dCombo.dComboName[$store.state.user.countryCode] }}</span>
                  </div>
                </div>
              </div>
            </swiper-slide>
            <div class="swiper-pagination1" style="position: absolute; height: 2vh"></div>
          </swiper>
        </div>

        <!--Button Answer all questions-->
        <div style="margin: 0.5rem 0.75rem 1rem 0.75rem">
          <button id="tt-d-search"
            v-on:click="($store.state.filterClicked = true), ($store.state.activityViewDetails = false), $router.push('/filter')"
            style="font-weight: normal; border-radius: 0.6rem; text-transform: none; color: white;  
          background-image: linear-gradient(to bottom, transparent -300%, #e83e8c80 100%); 
          background: #e83e8c80; box-shadow: 0px 1px 5px #dee2e6; cursor: pointer;
          height: 2.5rem; padding: 0rem 1rem; font-size: 0.9rem; letter-spacing: normal;">
            <span style="pointer-events: none" v-if="$store.state.user.countryCode == 'DE'">Starte deine eigene
              Suche</span>
            <span style="pointer-events: none" v-else>Start your own search</span>
          </button>
        </div>

        <div v-for="(combo,index) in ['groups','one','place','interest','type','special','target','specialkind']"
          :key="combo">
          <!--Inspiration Headline 5-->
          <h2 style="font-weight: bold; margin: 1.5rem 0.75rem 1.5rem 0.75rem; font-size: 1.1rem;">
            <span>{{ $store.state.filterCombosNew[combo][0].titleText[$store.state.user.countryCode] }}</span>
          </h2>
          <!--Filter Carousel New 5-->
          <v-lazy v-model="isActiveList[index]" :options="{ threshold: 1 }" min-height="5rem"
            transition="fade-transition">
            <div style="margin-bottom: 1rem;">
              <swiper slidesPerView="2" :spaceBetween="15" :rewind="true" :centeredSlides="false" :initialSlide="0"
                :modules="modules" :navigation="!$store.state.isTouchDevice"
                :pagination="{ el: '.swiper-pagination1', clickable: 'true' }"
                :style="{ '--swiper-navigation-color': '#fff', '--swiper-navigation-size': '4vh', '--swiper-pagination-color': '#000000', }"
                style=" width: 100%; position: relative; padding-left: 0.75rem; padding-right: 0.75rem; padding-bottom: 4vh;">
                <swiper-slide v-for="dCombo in $store.state.filterCombosNew[combo]" :key="dCombo">
                  <div v-on:click="setStoreSelectedActivity(dCombo.dComboFilters, 'firstb')"
                    style="box-shadow: 0px 1px 5px #dee2e6; border-radius: 0.6rem; cursor: pointer"
                    class="click-filter-combo" v-bind:id="dCombo.dComboKey">
                    <v-responsive :aspect-ratio="3 / 2" style="position: relative; pointer-events: none">
                      <img
                        style="object-fit: cover; width: 100%; height: 100%; border-radius: 0.6rem 0.6rem 0rem 0rem; 
                    align-self: baseline; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); opacity: 1;"
                        v-bind:src="'https://storage.googleapis.com/timetip-images/activity-images/' + dCombo.dComboImageName + '.jpg'"
                        @load="onImgLoad" @error="setAltImg" />
                    </v-responsive>
                    <div style="position: relative; margin: 0rem 0.375rem 0.75rem 0.375rem; padding: 0.25rem; font-size: 0.9rem; 
                  min-height: 5rem; display: flex; justify-content: center; align-items: center; pointer-events: none">
                      <div>
                        <span v-if="Object.keys(dCombo.dComboFilters).length != 0">{{ dCombo.dCount + " " }} </span>
                        <span>{{ dCombo.dComboName[$store.state.user.countryCode] }}</span>
                      </div>
                    </div>
                  </div>
                </swiper-slide>
                <div class="swiper-pagination1" style="position: absolute; height: 2vh"></div>
              </swiper>
            </div>
          </v-lazy>
        </div>

        <!--Button surprise-->
        <div style="margin: 1.5rem 0.75rem 1.5rem 0.75rem">
          <button id="tt-d-surprise" v-on:click="supprise()" style="font-weight: normal; border-radius: 0.6rem; text-transform: none; color: white;  
          background-image: linear-gradient(to bottom, transparent -300%, #007bff80 100%); 
          background: #007bff80; box-shadow: 0px 1px 5px #dee2e6; cursor: pointer;
          height: 2.5rem; padding: 0rem 1rem; font-size: 0.9rem; letter-spacing: normal;">
            <span v-if="$store.state.user.countryCode == 'DE'">Lass dich überraschen</span>
            <span v-else>Surprise me</span>
          </button>
        </div>

        <!--New Activities-->
        <v-lazy v-model="isActiven" :options="{ threshold: 1 }" min-height="5rem" transition="fade-transition">
          <div style="margin-bottom: 4rem">
            <h5
              style="margin-top: 0.75rem; margin-bottom: 1.5rem; font-weight: bold; padding: 0rem 0.75rem 0rem 0.75rem; font-size: 1.1rem;">
              <span v-if="$store.state.user.countryCode == 'DE'">Neu hinzugefügte Aktivitäten</span>
              <span v-else>New activities</span>
            </h5>

            <!--New Activities Carousel-->
            <swiper slidesPerView="2.1" :spaceBetween="15" :rewind="true" :centeredSlides="false" :initialSlide="0"
              :modules="modules" :navigation="!$store.state.isTouchDevice"
              :pagination="{ el: '.swiper-pagination2', clickable: 'true' }"
              :style="{ '--swiper-navigation-color': '#fff', '--swiper-navigation-size': '4vh', '--swiper-pagination-color': '#000000', }"
              style="
              width: 100%;
              position: relative;
              padding-left: 0.75rem;
              padding-right: 0.75rem;
              padding-bottom: 0.5rem;
              font-size: 0.9rem;
            ">
              <swiper-slide style="box-shadow: 0px 1px 5px #dee2e6; border-radius: 0.6rem; cursor: pointer;"
                v-for="activitynumber in $store.state.activityCombo.dComboIdList" :key="activitynumber">
                <v-responsive :aspect-ratio="3 / 2"
                  v-on:click="setStoreSelectedActivity($store.state.activityCombo.dComboFilters, activitynumber)">
                  <div style="width: 100%; height: 100%; position: relative">
                    <img style="object-fit: cover; width: 100%; height: 100%; border-radius: 0.6rem 0.6rem 0rem 0rem; align-self: baseline; position: absolute; 
                    top: 50%; left: 50%; transform: translate(-50%, -50%); opacity: 1;"
                      v-if="$store.state.storeActivityList[activitynumber].imageavailable < 3"
                      v-bind:src="'https://storage.googleapis.com/timetip-images/activity-images/' + $store.state.storeActivityList[activitynumber].imagetext + '.jpg'"
                      @load="onImgLoad" @error="setAltImg" />
                  </div>
                </v-responsive>
                <div v-on:click="setStoreSelectedActivity($store.state.activityCombo.dComboFilters, activitynumber)"
                  style="width: 100%; min-height: 5rem; display: flex; justify-content: center; align-items: center; padding: 0.25rem">
                  <div
                    v-if="activitynumber != $store.state.activityCombo.dComboIdList[$store.state.activityCombo.dComboIdList.length - 1]">
                    <span v-if="$store.state.user.countryCode == 'DE'">{{
                    $store.state.storeActivityList[activitynumber].activityde
                    }} </span>
                    <span v-else> {{ $store.state.storeActivityList[activitynumber].activity }}</span>
                  </div>
                  <div
                    v-if="activitynumber == $store.state.activityCombo.dComboIdList[$store.state.activityCombo.dComboIdList.length - 1]">
                    <span v-if="$store.state.user.countryCode == 'DE'">Entdecke alle {{
                    " " + $store.state.activityCombo.dCount + " "
                    }} neuen Aktivitäten </span>
                    <span v-else>Discover all {{ " " + $store.state.activityCombo.dCount + " " }} new activities
                    </span>
                  </div>
                </div>
                <!--  -->
              </swiper-slide>
            </swiper>
          </div>
        </v-lazy>

      </div>
    </transition>
  </div>
</template>
<script>
/* eslint-disable */
import ClickFilterService from "@/services/ClickFilterService.js";
import ResetFiltersService from "@/services/ResetFiltersService.js";
import UpdateActivitiesService from "@/services/UpdateActivitiesService.js";
import GetFilterCombosService from "@/services/GetFilterCombosService.js";



import { ref, computed, watch } from "vue";

import AllFiltersComponent from "@/components/Filters/AllFiltersComponent.vue";
import ClickedFiltersComponent from "@/components/Filters/ClickedFiltersComponent.vue";
import ClickedFiltersTopComponent from "@/components/Filters/ClickedFiltersTopComponent.vue";

// Import Swiper styles
import "swiper/css";

import "swiper/css/lazy";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { useStore } from "vuex";

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";
import { Lazy, Pagination, Navigation } from "swiper";

export default {
  components: { GetFilterCombosService, Swiper, SwiperSlide, AllFiltersComponent, ClickedFiltersComponent, ClickedFiltersTopComponent },
  data() {
    return {
      tweenedNumber: this.$store.state.storeActivityListFiltered.length,
      swiperRef: {},
      drawerActive: false,
      isActive: false,
      isActive2: false,
      isActive3: false,
      isActive4: false,
      isActive5: false,
      isActive6: false,
      isActiven: false,
      isActiveList: [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false]
    };
  },
  setup() {
    // SlideUps
    const store = useStore();

    // const x = ref(false);
    // watch(store.state.clickedAnswers, (currentValue, oldValue) => {
    //   doThis()
    // });
    return {
      modules: [Lazy, Pagination, Navigation],
    };
  },
  beforeCreate: function () {
    if (Object.keys(this.$store.state.filterCombosNew).length == 0) {
      GetFilterCombosService.getFilterCombos();
    }
  },
  mounted() {
  },
  methods: {
    doShowPeople() { this.drawerActive = true; this.swiperRef.slideTo(0, 0) },
    doShowPlace() { this.drawerActive = true; this.swiperRef.slideTo(1, 0) },
    doShowTime() { this.drawerActive = true; this.swiperRef.slideTo(2, 0) },

    slideTo() {
      var that = this

      // Swipe Next
      if (that.swiperRef.activeIndex == 0 && this.$store.state.clickedAnswers.typical || that.swiperRef.activeIndex == 1 && this.$store.state.clickedAnswers.place) {
        // Those two are needed to prevent a bug that the slidePrev Below takes too long
        var that = this;
        setTimeout(function () {
          that.swiperRef.slideNext(1000);
        }, 500)
        this.$store.state.discoverySwipe = this.$store.state.discoverySwipe + 1
      }

      // Initial Swipe
      // if (that.swiperRef.activeIndex == 0 && this.$store.state.clickedAnswers.typical && Object.keys(this.$store.state.clickedAnswers).length == 1 && this.$store.state.discoverySwipe < 1) {
      //   // Those two are needed to prevent a bug that the slidePrev Below takes too long
      //   this.swiperRef.slideNext(1000);
      //   this.swiperRef.slidePrev(1000);
      //   var that = this;
      //   setTimeout(function () {
      //     that.swiperRef.slideNext(3000);
      //     setTimeout(function () {
      //       that.swiperRef.slidePrev(3000);
      //     }, 500);
      //   }, 500);

      //   this.$store.state.discoverySwipe = this.$store.state.discoverySwipe + 1
      // }
    },
    setSwiperRef(swiper) {
      this.swiperRef = swiper;
    },
    setStoreSelectedActivity: function (dComboFilters, selectedActivityListPosition) {
      // this.$store.state.filterSetAtDiscoverView = true;
      ResetFiltersService.resetFilters();
      var that = this;
      for (const [dQuestion, dAnswer] of Object.entries(dComboFilters)) {
        dAnswer.forEach(function (answer) {

          if (dQuestion != "agewp" && dQuestion != "ageend") {
            ClickFilterService.clickFilter(dQuestion, answer);
          }
          if (dQuestion == "cost") {
            that.$store.state.costActive = true;
            switch (answer) {
              case "make money":
                that.$store.state.costSliderValueMin = 0;
                that.$store.state.costSliderValueMax = 0;
                break;
              case "nothing":
                that.$store.state.costSliderValueMin = 1;
                that.$store.state.costSliderValueMax = 1;
                break;
              case "little":
                that.$store.state.costSliderValueMin = 2;
                that.$store.state.costSliderValueMax = 2;
                break;
              case "medium":
                that.$store.state.costSliderValueMin = 3;
                that.$store.state.costSliderValueMax = 3;
                break;
              case "much":
                that.$store.state.costSliderValueMin = 4;
                that.$store.state.costSliderValueMax = 4;
                break;
            }
          }
          if (dQuestion == "time") {
            that.$store.state.timeActive = true;
            switch (answer) {
              case "15m":
                that.$store.state.timeSliderValueMin = 0;
                that.$store.state.timeSliderValueMax = 0;
                break;
              case "30m":
                that.$store.state.timeSliderValueMin = 1;
                that.$store.state.timeSliderValueMax = 1;
                break;
              case "3h":
                that.$store.state.timeSliderValueMin = 2;
                that.$store.state.timeSliderValueMax = 2;
                break;
              case "day":
                that.$store.state.timeSliderValueMin = 3;
                that.$store.state.timeSliderValueMax = 3;
                break;
              case "2d":
                that.$store.state.timeSliderValueMin = 4;
                that.$store.state.timeSliderValueMax = 4;
                break;
              case "week":
                that.$store.state.timeSliderValueMin = 5;
                that.$store.state.timeSliderValueMax = 5;
                break;
              case "month":
                that.$store.state.timeSliderValueMin = 6;
                that.$store.state.timeSliderValueMax = 6;
                break;
              case "ongoing":
                that.$store.state.timeSliderValueMin = 7;
                that.$store.state.timeSliderValueMax = 7;
                break;
            }
          }
          if (dQuestion == "agewp") {
            that.$store.state.ageMin = answer;
            that.$store.state.ageActive = true;
            UpdateActivitiesService.updateActivities();
          }
          if (dQuestion == "ageend") {
            that.$store.state.ageMax = answer;
          }
        })
      }

      if (selectedActivityListPosition == "firsta" || selectedActivityListPosition == "firstb") {
        this.$store.state.activityViewDetails = false;
        this.$store.state.storeSelectedActivityId = this.$store.state.storeActivityListFiltered[0].id;
        this.$store.state.activity = this.$store.state.storeActivityListFiltered[0];


        if (Object.keys(dComboFilters).length == 0) {
          this.$store.state.filterClicked = true;
          this.$router.push({ name: "FilterView" });
        } else {
          this.$router.push("/details");
        }
      } else {
        this.$store.state.selectedActivityListPosition = selectedActivityListPosition
        this.$store.state.storeSelectedActivityId = this.$store.state.storeActivityList[selectedActivityListPosition].id;
        this.$store.state.activity = this.$store.state.storeActivityList[selectedActivityListPosition];

        this.$router.push("/details");
      }
    },
    supprise: function () {
      ResetFiltersService.resetFilters();
      var x = Math.floor(Math.random() * this.$store.state.storeActivityList.length)
      this.$store.state.selectedActivityListPosition = x
      this.$store.state.storeSelectedActivityId = this.$store.state.storeActivityList[x].id;
      this.$store.state.activity = this.$store.state.storeActivityList[x];
      this.$router.push("/details");
    },
    setAltImg(event) {
      console.log("image could not be loaded");
      event.target.src = require("@/assets/error.png");
    },
  },
  watch: {
    activityCount() {
      gsap.to(this.$data, { duration: 0.6, tweenedNumber: this.activityCount });
    },
  },
  computed: {
    filtersAdapted() {
      var helper = {}
      // Check, if "sports" in "interest" is selected
      var sportsSelected = false
      this.$store.state.filters.interest.forEach(function (x) {
        if (x.filterkey == "sports" && x.selected) {
          sportsSelected = true
        }
      })
      // Either add sports to the filter object or not
      for (const [questionKey, questionValue] of Object.entries(this.$store.state.filters)) {
        if (questionKey != "sport" || sportsSelected) {
          helper[questionKey] = questionValue
        }
      }
      return helper
    },
    activityCount() {
      return this.$store.state.storeActivityListFiltered.length;
    },
    animatedNumber() {
      return this.tweenedNumber.toFixed(0);
    },
  },
};
</script>
<style>
.swiper-button-next {
  right: 20px !important;
}

.swiper-button-prev {
  left: 20px !important;
}
</style>
<style scoped>
.overlay {
  /* position: fixed;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(to left, transparent -0.75rem, #ffffff 100%);
  z-index: 20000; */
}

.flexwrap {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  color: white;
  font-weight: normal;
  z-index: 1;
  border-radius: 0.6rem;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  cursor: pointer;
  background-image: linear-gradient(to bottom, transparent 33%, #000000 120%);
  font-size: 0.9rem;
}

.filtercarousel {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 0.2rem 0.2rem 0.5rem 0.2rem;
  color: white;
  font-weight: normal;
  z-index: 1;
  border-radius: 0.6rem;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  cursor: pointer;
  background-image: linear-gradient(to bottom, transparent 33%, #000000 120%);
  font-size: 0.9rem;

}

.imagetitle {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 1;
  color: white;
  padding: 0.5rem;
  height: 100%;
  background-image: linear-gradient(to bottom, transparent 50%, #000000 120%);
  /*background: #00000065;*/
  border-radius: 0.6rem;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  cursor: pointer;
}

.carousel-img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 0.6rem;
  align-self: baseline;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
}

.blue {
  background-color: #007bff;
  color: white;
  background-image: none;
}

.fade-enter-from {
  opacity: 0;
}

.fade-enter-to {}

.fade-enter-active {
  transition: all 0.5s ease;
}

.fade-leave-from {}

.fade-leave-to {}

.fade-leave-active {}

.questionitem {
  font-size: 0.9rem;
  min-height: 3.5rem;
}

.blue {
  background-image: linear-gradient(to bottom, transparent -300%, #007bff 100%) !important;
  color: white;
  /* background-image: none; */
}

.blue2 {
  background-image: linear-gradient(to bottom, transparent -300%, #007bff 100%) !important;
}

.disableswipe {
  pointer-events: none;
}

.additionalpadding {
  padding: 0rem 3.5rem;
}

.questionswiper {
  width: 100%;
  position: relative;
  border-radius: 0.6rem;
  /* box-shadow: 0px 2px 2px #dee2e6; */
  /* box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%); */
  /* background: #007bff10; */
}

.test4 {
  /* background: #00000005 */
  box-shadow: 0px 1px 5px #dee2e6;
  /* box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%); */

}
</style>
<!-- <style>.swiper-pagination {
  bottom: -30px!important;
}</style> -->