/* eslint-disable */

import axios from 'axios'
import store from '@/store/index.js'
import GetLocalStorage from "@/services/GetLocalStorage.js";

export default {
  getActivities() {
    console.log("getActivities is executed")

    var activityList = []

    var id = (0)
    return axios.get('/data.json')
      .then(response => (
        parseData(response.data.activitylist) // executes function parseData with the input response.data.feed.entry (CB)
      ))

    function parseData(entries) {
      for (const [key, value] of Object.entries(entries)) {
        if (key != "") {
          // console.log(value)
          // ignore first Value with id 0 (Test activity)
          var entry = {
            "id": id++,
            "uuid": value.uuid,
            // get conditions & interests
            "activity": value.activity,
            "activityde": value.activityde,

            //Filters
            "timetip": { EN: value.timetipen, DE: value.timetipde },
            "setting": value.setting.split(','),
            "reality": value.reality.split(','),
            "place": value.place.split(','),
            "weather": value.weather.split(','),
            "temperature": value.temperature.split(','),
            "age": "",
            "agewp": parseInt(value.agewp),
            "agestart": parseInt(value.agestart),
            "ageend": parseInt(value.ageend),
            "pcount": value.pcount.split(','),
            "restriction": value.restriction.split(','),
            "cost": value.cost.split(','),
            "time": value.time.split(','),
            "interest": value.interest.split(','),
            "target": value.target.split(','),
            "typical": value.typical.split(','),
            "type": value.type.split(','),
            "special": value.special.split(','),
            "season": value.season.split(','),
            "specialkind": value.specialkind.split(','),
            "nowlater": value.nowlater.split(','),
            "dayweektime": value.dayweektime.split(','),
            "sport": value.sport.split(','),
            "game": value.game.split(','),
            //"interactiontype": value.interactiontype.split(','),

            // ShowIf
            "showifweather": value.showifweather.split(','),
            "showifdaytime": value.showifdaytime.split(','),
            "showifweekday": value.showifweekday.split(','),
            "showifseason": value.showifseason.split(','),

            // Text, Image, Wiki
            "welcome": { EN: value.welcomeen, DE: value.welcomede },
            "plan": value.plan,
            "imagetext": value.imagename,
            "imagesource": value.imagesource,
            "imageavailable": value.imageavailable,
            "wikitext": { EN: value.wikitexten, DE: value.wikitextde },

            //////////////////////////////////////////////////////////////////////// Links
            // Buy
            "buypayload": { EN: value.buyen, DE: value.buyde },
            "buyteaser": { EN: value.buyteaseren, DE: value.buyteaserde },
            "buylinks": value.buylinks.split(','),

            // Search
            "searchpayload": { EN: value.searchen, DE: value.searchde },
            "bestlinksworld": value.bestlinksworld.split(','),
            "bestlinksde": value.bestlinksde.split(','),

            //Place
            "placepayload": { EN: value.placeen, DE: value.placede },
            "placeteaser": { EN: value.placeteaseren, DE: value.placeteaserde },
            "placelinks": value.placelinks.split(','),

            // Hashtag
            "hashtag": value.hashtag,
            "hashtaglinks": value.hashtaglinks.split(','),

            // Action
            "action": value.action,

            // alinks & blinks
            "alinkteaser": { EN: value.alinkten, DE: value.alinktde },
            "alinkurl": { EN: value.alinkurlen, DE: value.alinkurlde },
            "alinkicon": value.alinkicon,

            "blinkteaser": { EN: value.blinkten, DE: value.blinktde },
            "blinkurl": { EN: value.blinkurlen, DE: value.blinkurlde },
            "blinkicon": value.blinkicon,

          };
          // if (!value.showifseason.includes("winter")) {
            activityList.push(entry);
          // }
          // else { console.log("activities excluded because we currently do not have " + value.showifseason) }
        }
      };

      //deletes the first entry of the ActivityList, so that the test activity is deleted
      // activityList.shift()


      // Randomize ActivityList
      // setTimeout(function () {
      for (let i = activityList.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [activityList[i], activityList[j]] = [activityList[j], activityList[i]];
      }
      // }, 5000);
      var i = 0
      activityList.forEach(function (activity) {
        activity.listPosition = i
        i++
      })


      // console.log("OOOOOOOOOOOOOOOOOOOO3")
      console.log("store.state.storeActivityList")
      console.log(activityList)


      store.state.storeActivityList = activityList
      store.state.storeActivityListFiltered = activityList

      store.state.dataLoaded = true
      console.log("activities stored (dataLoaded = true)")

      store.state.checkerSet = 1


      GetLocalStorage.getLocalStorage();




    }

    //return Promise.resolve(activityList)
  }
}