<!-- ---------- ---------- ---------- HTML Structure ---------- ---------- ---------- -->
<template>
  <!--Clicked Filters-->
  <div class="clickedfilterssticky">
    <transition name="fade">
      <div
        v-if="(clickedFilters.size != 0 || clickedAgeTimeCostList.length != 0) && (this.$route.name != 'DetailsView' || showAtDetails)">
        <div class="clickedfiltersinner" v-bind:class="{
          clickedfiltersinnernomaxheight: !maxHeightOn,
          clickedfiltersinnernomaxheightb: maxHeightOn,
        }">
          <div ref="checkForHeight">
            <ClickedFiltersComponent />
          </div>
        </div>
        <!--Todo: add dropdown-->
        <div style="width: 100%; min-height: 0.5rem; cursor: pointer" v-on:click="maxHeightOn = !maxHeightOn">
          <transition name="fade2">
            <div v-if="showDropDown">
              <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#ffffff"
                v-if="maxHeightOn">
                <path d="M24 24H0V0h24v24z" fill="none" opacity=".87" />
                <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z" />
              </svg>
              <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#ffffff"
                v-if="!maxHeightOn">
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14l-6-6z" />
              </svg>
            </div>
          </transition>
        </div>
      </div>
    </transition>
  </div>
</template>

<!-- ---------- ---------- ---------- Code ---------- ---------- ---------- -->

<script>
/* eslint-disable */
import AllFiltersComponent from "@/components/Filters/AllFiltersComponent.vue";
import ClickedFiltersComponent from "@/components/Filters/ClickedFiltersComponent.vue";
import ResetFiltersService from "@/services/ResetFiltersService.js";
import ExplanationComponent from "@/components/Navigation/ExplanationComponent.vue";
import ActivityListComponent from "@/components/ActivityList/ActivityListComponent.vue";
import TimetipSVG from "@/components/Navigation/TimetipSVG.vue";

export default {
  components: {
    AllFiltersComponent,
    ExplanationComponent,
    ClickedFiltersComponent,
    ActivityListComponent,
    TimetipSVG,
  },
  data() {
    return {
      showDropDown: false,
      maxHeightOn: true,
      checker: 1, // als Erinnerung
      tweenedNumber: this.$store.state.storeActivityListFiltered.length,
      rerenderActivityListKey: 0,
      showAtDetails: false,
    };
  },

  mounted() {
    if (this.$store.state.showClickedFiltersAtDetails || this.$route.name != 'DetailsView') { this.checkHeight(); }
  },
  beforeCreate() {
    // this.$store.state.filterClicked = true;
    // if (this.$store.state.filterSetAtDiscoverView) {
    //   ResetFiltersService.resetFilters();
    //   this.$store.state.filterSetAtDiscoverView = false;
    // }
  },
  unmounted() {
    // this.$store.state.filterClicked = false;
  },
  methods: {
    checkHeight: function () {
      if (
        this.$store.state.storeActivityListFiltered.length !=
        this.$store.state.storeActivityList.length
      ) {
        var that = this;
        setTimeout(function () {
          if ( that.$refs.checkForHeight ) {
            console.log("checkClientHeight");
            console.log(that.$refs.checkForHeight.clientHeight);
            if (parseInt(that.$refs.checkForHeight.clientHeight) > 26) {
              that.showDropDown = true;
            } else {
              that.showDropDown = false;
            }
          }
        }, 100);
      }
    },
    resetFilters: function () {
      ResetFiltersService.resetFilters();
    },
    clickFilterButton: function () {
      // this.$router.push("/activitylist");
      if (this.$store.state.activityViewDetails && false) {
        (this.$store.state.filterClicked = true), this.$router.push("/details");
      } else {
        (this.$store.state.filterClicked = true),
          this.$router.push("/activitylist");
      }
    },
  },
  computed: {
    animatedNumber() {
      return this.tweenedNumber.toFixed(0);
    },
    activityCount() {
      return this.$store.state.storeActivityListFiltered.length;
    },
    activityListFiltered() {
      return this.$store.state.storeActivityListFiltered;
    },
    // Create list of clicked filters
    clickedFilters() {
      var filterList = new Set([]);
      for (const [key, value] of Object.entries(this.$store.state.filters)) {
        // For each entry in "age" - For example "toddler"
        value.forEach(function (x) {
          // Check, if "toddler" is selected
          if (x.selected == true) {
            filterList.add(x);
          }
        });
      }
      return filterList;
    },
    clickedAgeTimeCostList() {
      var ageTimeCostList = [];

      if (this.$store.state.ageActive) {
        ageTimeCostList.push("age");
      }
      if (this.$store.state.timeActive) {
        ageTimeCostList.push("time");
      }
      if (this.$store.state.costActive) {
        ageTimeCostList.push("cost");
      }
      return ageTimeCostList;
    },
    activityListFilteredLength() {
      return this.$store.state.storeActivityListFiltered.length;
    },
    showClickedFiltersAtDetails() {
      return this.$store.state.showClickedFiltersAtDetails
    },
  },
  watch: {
    showClickedFiltersAtDetails() {
      this.showAtDetails = !this.showAtDetails
      if (this.showAtDetails) { this.checkHeight() }
    },
    activityCount() {
      gsap.to(this.$data, { duration: 0.5, tweenedNumber: this.activityCount });
      this.checkHeight();
    },
    activityListFilteredLength() {
      this.rerenderActivityListKey += 1;
    },
  },
};
</script>

<style scoped>
/* http://jsfiddle.net/Wcgvt/181/ */
/* https://www.w3schools.com/css/css_overflow.asp */

/* switch transitions */
.switch-enter-from,
.switch-leave-to {
  /* opacity: 0; */
  transform: translateY(100vh);
}

.switch-enter-active {
  transition: all 0.5s ease;
}

.switch-leave-active {
  transition: all 0.5s ease;
}

.clickedfilterssticky {
  padding: 0rem;
  padding: 0rem 0rem 0rem 0rem;
  top: 40px;
  position: sticky;
  width: 100%;
  /* background: #e7f2ff; */
  z-index: 50;
  overflow: auto;
  border-bottom: 0px solid #dee2e6;
  color: white;
  background: #007bff;
  /* box-shadow: 0px 3px 3px -3px #dee2e6; */
  /* background: #ffffff;
  ; */

  /* box-shadow: 0 6px 36px rgb(0 0 0 / 30%) !important; */
}

.clickedfiltersinner {
  display: flex;
  flex-direction: column-reverse;
  overflow: auto;
  z-index: 50;
}

.clickedfiltersinnernomaxheight {
  max-height: 40vh;
  animation: slidedown 0.6s ease-in;
}

@keyframes slidedown {
  0% {
    max-height: 1.62rem;
  }

  100% {
    max-height: 40vh;
  }
}

.clickedfiltersinnernomaxheightb {
  max-height: 1.62rem;
  animation: slideup 0.5s ease-out;
}

@keyframes slideup {
  0% {
    max-height: 40vh;
  }

  100% {
    max-height: 1.62rem;
  }
}

.fade-enter-from {
  height: 0rem;
  opacity: 0;
  transform: scale(0);
}

.fade-enter-to {
  height: 2.12rem;
  transform: scale(1);
}

.fade-enter-active {
  transition: height 0.3s ease, opacity 0.3s ease 0.3s, transform 0.3s ease 0.3s;
}

.fade-leave-from {
  height: 2.12rem;
}

.fade-leave-to {
  height: 0rem;
  opacity: 0;
}

.fade-leave-active {
  transition: height 0.3s ease-in-out 0.3s, opacity 0.3s ease-in-out;
}

.fade2-enter-from {
  height: 0rem;
  opacity: 0;
  transform: scale(0);
}

.fade2-enter-to {
  height: 25.4px;
  transform: scale(1);
}

.fade2-enter-active {
  transition: height 0.3s ease, opacity 0.3s ease 0.3s, transform 0.3s ease 0.3s;
}

.fade2-leave-from {
  height: 25.4px;
}

.fade2-leave-to {
  height: 0rem;
  opacity: 0;
}

.fade2-leave-active {
  transition: height 0.3s ease-in-out 0.3s, opacity 0.3s ease-in-out;
}

/* Show Filterbuttons */
.buttonenter-enter-from {
  opacity: 0;
  transform: scale(0.6);
}

.buttonenter-enter-active {
  transition: opacity 0s ease 0.5s, transform 0.3s ease 0.5s;
}

.buttonenter-leave-to {
  transform: scale(0.6);
}

.buttonenter-leave-active {
  transition: all 1s ease;
}

/* Show Filterbuttons End */

/* resetenter */
.resetenter-enter-from {
  transform: translateX(-5rem);
}

.resetenter-enter-active {
  transition: all 0.3s ease;
}
</style>