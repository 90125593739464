/* eslint-disable */
import store from '@/store/index.js'

const getTenActivities = (number) => {

    if (!store.state.searchToggle) {
      let ret = []

      for (var i = 0; i < 10; i++) {
        if ((i + number + 1) <= store.state.storeActivityListFiltered.length) { ret.push(store.state.storeActivityListFiltered[i + number]) }
        else { break }

      }

      return ret
    }
    if (store.state.searchToggle) {
      let ret = []

      for (var i = 0; i < 10; i++) {
        if ((i + number + 1) <= store.state.storeActivityListSearched.length) { ret.push(store.state.storeActivityListSearched[i + number]) }
        else { break }

      }

      return ret
    }
}

export default getTenActivities