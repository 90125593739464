/* eslint-disable */

import axios from 'axios'
import store from '@/store/index.js'

export default {
  getAppTexts() {
    console.log("getAppTexts is executed")

    var appTexts = new Object()

    axios.get('/data.json')
      .then(response => (
        parseData(response.data.apptexts) // executes function parseData with the input response.data.feed.entry (CB)
      ))

    function parseData(entries) {
      for (const [key, value] of Object.entries(entries)) {
        if (key != "") {
          appTexts[value.apptextkey] = {EN:value.en, DE: value.de};
        }
      };

      store.state.appTexts = appTexts
      console.log("store.state.appTexts")
      console.log(appTexts)

    }
  }
}