<template>
  <div class="container">
    <div style="margin-top: 2rem">
      <span v-if="$store.state.user.countryCode == 'DE'">
        Wir schicken dir einen link an deine E-Mail-Adresse, mit dem du dich
        direkt einloggen kannst!
      </span>
      <span v-else>We will send a link to your email with which you can directly login.</span>
    </div>
    <!--Step 1 | Enter E-mail-->
    <form @submit.prevent="sendEmail()" style="margin-top: 2rem">
      <input type="email" name="email" placeholder="E-Mail" v-model="email" required style />
      <br />
      <button class="signupbutton">
        <span>Login</span>
      </button>
    </form>

    <!--Step 2 + 3 | Go to Mail Client + Accept Data Privacy + Save User-->
    <div v-if="!loggedInUser">
      <!--Step 2 | Go to Mail Client-->
      <div>
        <transition name="splash">
          <div v-if="mailSent" class="overlayouter">
            <div class="overlayinner">
              <div class="closebutton" v-on:click="mailSent = false">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24px"
                  viewBox="0 0 24 24"
                  width="24px"
                  fill="#2c3e50"
                >
                  <path d="M0 0h24v24H0V0z" fill="none" />
                  <path
                    d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"
                  />
                </svg>
              </div>
              <h2
                v-if="$store.state.user.countryCode == 'DE'"
              >Großartig, schaue in deinen Posteingang!</h2>
              <h2 v-else>Greate, Have a look at your mailbox!</h2>
              <br />
              <div style="padding: 0rem 0.5rem">
                <span
                  v-if="$store.state.user.countryCode == 'DE'"
                  style="color: #2c3e50; font-size: 0.9rem"
                >
                  Wir haben dir gerade einen Registrierungslink an
                  {{ " " + email + " " }} gesendet. Prüfe ggf. deinen
                  Spam-Ordner, wenn du die E-Mail nicht finden kannst.
                </span>
                <span v-else>
                  We have sent you a registration link to
                  {{ " " + email + " " }}. Please check your Spam folder, if you
                  cannot find the email.
                </span>
                <br />
                <button>
                  <span
                    v-if="$store.state.user.countryCode == 'DE'"
                    v-on:click="sendEmail()"
                  >E-Mail noch einmal senden</span>
                  <span v-else v-on:click="sendEmail()">Send email again</span>
                </button>
              </div>
            </div>
          </div>
        </transition>

        <!--Todo: Wird jedes Mal angezeigt, obwohl nur beim ersten Mal nötig-->
        <!--Step 3 | Accept Data Privacy and Save User-->
        <div v-if="confirmationMailSent" class="overlayouter">
          <div class="overlayinner">
            <div class="closebutton" v-on:click="$router.push('/settings')">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 0 24 24"
                width="24px"
                fill="#2c3e50"
              >
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path
                  d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"
                />
              </svg>
            </div>
            <h2 v-if="$store.state.user.countryCode == 'DE'">Nur noch ein Schritt und du bist fertig</h2>
            <h2 v-else>Only one step left and you are done</h2>

            <br />
            <form @submit.prevent="acceptDataPrivacyAndLoginUser()" style="margin-top: 1rem">
              <div style="display: flex; align-items: top">
                <div style="width: 20%; margin-top: 0.13rem">
                  <input style="transform: scale(1.2)" type="checkbox" v-model="checkboxMails" />
                </div>
                <label
                  v-if="$store.state.user.countryCode == 'DE'"
                  style="width: 80%; padding-right: 1.5rem"
                >
                  Ich möchte Neuigkeiten von Timetip per E-Mail erhalten. Von
                  diesen Benachrichtigungen kann ich mich jederzeit wieder
                  abmelden.
                </label>
                <label v-else style="width: 80%; padding-right: 1.5rem">
                  I want to receive news from timetip per email. I can
                  unsubscribe from those messages anytime.
                </label>
              </div>
              <div style="display: flex; align-items: top; margin-top: 2rem">
                <div style="width: 20%; margin-top: 0.13rem">
                  <input
                    style="transform: scale(1.2)"
                    type="checkbox"
                    v-model="checkboxDataPrivacy"
                    required
                  />
                </div>
                <label style="width: 80%; padding-right: 1.5rem">
                  <span v-if="$store.state.user.countryCode == 'DE'">
                    Mir ist bekannt, dass meine persönlichen Daten in
                    Übereinstimmung mit der
                  </span>
                  <span v-else>
                    I know that my personal data will be processed by timetip
                    in accordance to the timetip
                  </span>
                  <router-link
                    :to="{ name: 'PrivacyPolicy' }"
                    style="text-decoration: none; color: #007bff"
                  >
                    <span
                      v-if="$store.state.user.countryCode == 'DE'"
                    >{{ " " }}Datenschutzerklärung{{ " " }}</span>
                    <span v-else>
                      {{ " " }}Privacy Policy.
                      <span style="color: #dee2e6">(Mandatory)</span>
                    </span>
                  </router-link>
                  <span v-if="$store.state.user.countryCode == 'DE'">
                    von Timetip verarbeitet werden.
                    <span style="color: #dee2e6">(Pflichtfeld)</span>
                  </span>
                </label>
              </div>
              <br />
              <button class="signupbutton">
                <span v-if="$store.state.user.countryCode == 'DE'">Final anmelden</span>
                <span v-else>Finalize login</span>
              </button>
            </form>
            <div v-if="errorSignInWithEmailLink" style="margin-top: 1rem; font-size: 0.9rem">
              <div
                v-if="
                  errorSignInWithEmailLink ==
                  'FirebaseError: Firebase: Error (auth/invalid-action-code).'
                "
              >
                <span v-if="$store.state.user.countryCode == 'DE'">
                  Dieser Link ist abgelaufen. Bitte prüfe, ob du einen
                  aktuellen Link in deinem Postfach hast.
                  <br />Alternativ
                  kannst du auch dieses Fenster schließen und dir einen neuen
                  Link zusenden lassen.
                </span>
                <span v-else>
                  This link is not valid anymore. Please check for an updated
                  link in your mailbox. Alternatively you can close this window
                  and send a new mail.
                </span>
              </div>
              <span v-else>{{ errorSignInWithEmailLink }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--TODO: Alternativ kannst du dich hier mit Email und Passwort einloggen-->
    <!--Alternativ kannst du dich hier mit Email und Passwort einloggen-->
    <!--Alternativ kannst du dich hier mit Email und Passwort einloggen-->
    <!--Alternativ kannst du dich hier mit Email und Passwort einloggen-->
    <!--Alternativ kannst du dich hier mit Email und Passwort einloggen-->

    <!--Already Logged In Message-->
    <div v-else class="overlayouter">
      <div class="overlayinner">
        <div>
          <h2
            v-if="$store.state.user.countryCode == 'DE'"
            style="padding-left: 0.5rem; padding-right: 0.5rem"
          >Du bist bereits eingelogged</h2>
          <h2 v-else style="padding-left: 0.5rem; padding-right: 0.5rem">You are already logged in</h2>
        </div>
        <button
          v-on:click="$router.push('/settings')"
          style="background: #007bff; margin-left: 1rem; margin-right: 1rem"
        >
          <span
            v-if="$store.state.user.countryCode == 'DE'"
          >Klicke hier, um zurück zu den Einstellungen zu gelangen</span>
          <span v-else>Click here to get back to the settings page</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import FireStoreService from "@/firebase/FireStoreService.js";
import {
  getAuth,
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
} from "firebase/auth";

export default {
  setup() {
    const email = ref("");
    const checkboxMails = ref("");
    const checkboxDataPrivacy = ref("");
    const error = ref(null);
    const errorSignInWithEmailLink = ref(null);
    var mailSent = ref(false);
    var confirmationMailSent = computed(() => {
      return isSignInWithEmailLink(auth, window.location.href);
    });

    const store = useStore();
    const router = useRouter();
    const auth = getAuth();
    const actionCodeSettings = {
      // URL you want to redirect back to. The domain (www.example.com) for this
      // URL must be in the authorized domains list in the Firebase Console.
      url: store.state.loginUrl.prod, //"https://test.timetip.app/login" ||| PROD: prod | TEST: test | LOCAL: local ----------BEFOREDEPLOY----------BEFOREDEPLOY----------
      // This must be true.
      handleCodeInApp: true,
      iOS: {
        bundleId: "com.apple.mobilesafari", //"com.example.ios", before 16.03.2022
      },
      android: {
        packageName: "com.example.android",
        installApp: true,
        minimumVersion: "12",
      },
      dynamicLinkDomain: "timetipapp.page.link", // PROD: timetipapp | TEST & LOCAL: timetip ----------BEFOREDEPLOY----------BEFOREDEPLOY----------BEFOREDEPLOY----------
    };

    // Step 2
    const sendEmail = async () => {
      console.log(auth);
      console.log(email.value);
      console.log(actionCodeSettings);
      await sendSignInLinkToEmail(auth, email.value, actionCodeSettings)
        .then(() => {
          // The link was successfully sent. Inform the user.
          // Save the email locally so you don't need to ask the user for it again
          // if they open the link on the same device.
          window.localStorage.setItem("emailForSignIn", email.value);
          localStorage.setItem("confirmationMail", email.value);
          mailSent.value = true;
          // ...
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log(errorCode);
          console.log(errorMessage);
          // ...
        });
    };

    // Step 3
    // Confirm the link is a sign-in with email link.
    const acceptDataPrivacyAndLoginUser = async () => {
      if (isSignInWithEmailLink(auth, window.location.href)) {
        // Additional state parameters can also be passed via URL.
        // This can be used to continue the user's intended action before triggering
        // the sign-in operation.
        // Get the email if available. This should be available if the user completes
        // the flow on the same device where they started it.
        email.value = localStorage.getItem("confirmationMail"); //;

        if (!email.value) {
          email.value = window.localStorage.getItem("emailForSignIn");
        }

        if (!email.value) {
          // User opened the link on a different device. To prevent session fixation
          // attacks, ask the user to provide the associated email again. For example:
          email.value = window.prompt(
            "Please provide your email for confirmation"
          );
        }
        // The client SDK will parse the code from the link for you.
        signInWithEmailLink(auth, email.value, window.location.href)
          .then((result) => {
            // Clear email from storage.
            // window.localStorage.removeItem("emailForSignIn"); - deleted
            console.log("signInWithEmailLink successful");
            localStorage.setItem("userLoggedIn", "true");
            localStorage.setItem("user", JSON.stringify(result.user));

            store.state.nutzer = result.user;
            store.state.checkboxMails = checkboxMails.value;
            store.state.checkboxDataPrivacy = checkboxDataPrivacy.value;
            console.log("store.state.nutzer = result.user");
            FireStoreService.getFSMergeFavoritesSetFS();

            router.push("/");

            // You can access the new user via result.user
            // Additional user info profile not available via:
            // result.additionalUserInfo.profile == null
            // You can check if the user is new or existing:
            // result.additionalUserInfo.isNewUser
          })
          .catch((error) => {
            console.log(error);
            errorSignInWithEmailLink.value = error;
            // Some error occurred, you can inspect the code: error.code
            // Common errors could be invalid email and invalid or expired OTPs.
          });
      }
    };
    return {
      sendEmail,
      email,
      error,
      errorSignInWithEmailLink,
      actionCodeSettings,
      sendSignInLinkToEmail,
      acceptDataPrivacyAndLoginUser,
      mailSent,
      confirmationMailSent,
      checkboxMails,
      checkboxDataPrivacy,
      loggedInUser: computed(() => store.state.nutzer),
    };
  },
  mounted() {
    if (!this.email && window.localStorage.getItem("emailForSignIn")) {
      this.email = window.localStorage.getItem("emailForSignIn");
    }
  },
  // mounted() {
  //   console.log("C");
  //   const auth = getAuth();
  //   if (isSignInWithEmailLink(auth, window.location.href)) {
  //     this.confirm();
  //   }
  // },
  // watch: {
  //   isSignInWithEmailLinkCP() {
  //     console.log("B");
  //     const auth = getAuth();
  //     if (isSignInWithEmailLink(auth, window.location.href)) {
  //       this.confirm();
  //     }
  //   },
  // },
  methods: {},
  computed: {},
};
</script>
<style scoped>
input {
  border-radius: 0.6rem !important;
  border-style: solid !important;
  border-image: none;
  border-color: #dee2e6;
  background-color: unset;
  border-width: 1.5px;
  padding: 0.5rem 1rem 0.5rem 1rem;
  cursor: pointer;
}
input:focus {
  outline: none;
}
/* navbar */
.container {
  min-height: 100vh;
}
form {
  width: 100%;
}

/* forms */
label {
  text-align: left;
  /* display: block; */
  /* margin-bottom: 10px; */
  /* margin-top: 20px; */
  /* font-size: 0.9rem; */
}
button {
  margin-top: 2rem;
  padding: 0.5rem 0.75rem 0.5rem 0.75rem;
  background: #2c3e5080;
  border: none;
  border-radius: 0.6rem;
  color: white;
}
button:focus {
  outline: none;
}
form button {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.signupbutton {
  margin-top: 2rem;
  display: inline-flex;
  justify-content: center;
  align-items: baseline;
  background: #00dba4;
  outline: none;
  border: none;
  padding: 6px 30px;
  border-radius: 0.6rem;
  color: #fff;
  cursor: pointer;
}

/* blogs */
.blog {
  padding: 10px;
  margin: 30px 0 20px;
  border-top: 1px dotted #ddd;
}
.blog p {
  color: #999;
}
.blog h3 {
  color: #00dba4;
}
.blog .icons {
  color: #555;
  font-weight: bold;
  display: flex;
  align-items: center;
  font-size: 0.9em;
}
.blog .icons .material-icons {
  font-size: 1em;
  margin-left: 10px;
  cursor: pointer;
}
.blog .icons .material-icons:hover {
  color: #00dba4;
}

.overlayouter {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: #acacac9a;
  pointer-events: all;
  cursor: auto;
  z-index: 300;
  touch-action: pan-x;
}
h2 {
  padding: 0rem 2.75rem 0rem 2.75rem;
}
.overlayinner {
  position: absolute;
  max-width: 50rem;
  max-height: 100%;
  overflow: auto;
  width: 95%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  color: black;
  padding: 3rem 0rem 2rem 0rem;
  border-radius: 1rem;
  pointer-events: all;
  z-index: 301;
  position: relative;
}
.closebutton {
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
}
/* switch transitions */
.splash-enter-from,
.splash-leave-to {
  opacity: 0;
}
.splash-enter-active {
  transition: all 0.2s ease;
}
.splash-leave-active {
  transition: all 0.2s ease;
}
</style>