import { createRouter, createWebHistory } from "vue-router";
import SettingsView from '../views/SettingsView.vue'
import FilterView from '../views/FilterView.vue'
import ActivityListView from '../views/ActivityListView.vue'
import FavoritesListView from '../views/FavoritesListView.vue'
import DetailsView from '../views/DetailsView.vue'
import TestView from '../views/TestView.vue'
import SiteNotice from '../views/SiteNotice.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'
import PrivacyPolicyAppleAppStore from '../views/PrivacyPolicyAppleAppStore.vue'
import DiscoverView from '../views/DiscoverView.vue'
import Login from '../views/settings/Login.vue'
import SignupWithEmailAndPassword from '../views/settings/SignupWithEmailAndPassword.vue'
import LoginWithEmailAndPassword from '../views/settings/LoginWithEmailAndPassword.vue'
import DeleteUser from '../views/settings/DeleteUser.vue'

const routes = [
  {
    path: '/',
    name: 'DiscoverView',
    component: DiscoverView,
    meta: {
      animationClass: "splash",
      animationMode: "out-in",
      animationClass2: "topdown",
      animationMode2: "in-out"
    }
  },
  {
    path: '/filter',
    name: 'FilterView',
    component: FilterView,
    meta: {
      animationClass: "splash",
      animationMode: "out-in",
      animationClass2: "bottomup",
      animationMode2: "in-out"
    }
  },

  {
    path: '/details',
    name: 'DetailsView',
    component: DetailsView,
    meta: {
      animationClass: "splash",
      animationMode: "out-in",
      animationClass2: "topdown",
      animationMode2: "in-out"
    }
  },

  {
    path: '/favorites',
    name: 'FavoritesListView',
    component: FavoritesListView,
    meta: {
      animationClass: "splash",
      animationMode: "out-in",
      animationClass2: "topdown",
      animationMode2: "in-out"
    }
  },
  {
    path: '/settings',
    name: 'SettingsView',
    component: SettingsView,
    meta: {
      animationClass: "splash",
      animationMode: "out-in",
      animationClass2: "topdown",
      animationMode2: "in-out"
    }
  },
  {
    path: '/activitylist',
    name: 'ActivityListView',
    component: ActivityListView,
    meta: {
      animationClass: "splash",
      animationMode: "out-in",
      animationClass2: "topdown",
      animationMode2: "in-out"
    }
  },

  {
    path: '/test',
    name: 'TestView',
    component: TestView,
    meta: {
      animationClass: "splash",
      animationMode: "out-in",
      animationClass2: "topdown",
      animationMode2: "in-out"
    }
  },
  {
    path: '/sitenotice',
    name: 'SiteNotice',
    component: SiteNotice,
    meta: {
      animationClass: "splash",
      animationMode: "out-in",
      animationClass2: "splash",
      animationMode2: "out-in"
    }
  },
  {
    path: '/privacypolicy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy,
    meta: {
      animationClass: "splash",
      animationMode: "out-in",
      animationClass2: "splash",
      animationMode2: "out-in"
    }
  },
  {
    path: '/privacypolicyappleappstore',
    name: 'PrivacyPolicyAppleAppStore',
    component: PrivacyPolicyAppleAppStore,
    meta: {
      animationClass: "splash",
      animationMode: "out-in",
      animationClass2: "splash",
      animationMode2: "out-in"
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      animationClass: "splash",
      animationMode: "out-in",
      animationClass2: "splash",
      animationMode2: "out-in"
    }
  },
  {
    path: '/signupwithemailandpassword',
    name: 'SignupWithEmailAndPassword',
    component: SignupWithEmailAndPassword,
    meta: {
      animationClass: "splash",
      animationMode: "out-in",
      animationClass2: "splash",
      animationMode2: "out-in"
    }
  },
  {
    path: '/loginwithemailandpassword',
    name: 'LoginWithEmailAndPassword',
    component: LoginWithEmailAndPassword,
    meta: {
      animationClass: "splash",
      animationMode: "out-in",
      animationClass2: "splash",
      animationMode2: "out-in"
    }
  },
  {
    path: '/deleteUser',
    name: 'DeleteUser',
    component: DeleteUser,
    meta: {
      animationClass: "splash",
      animationMode: "out-in",
      animationClass2: "splash",
      animationMode2: "out-in"
    }
  },
  {
    path: "/:catchAll(.*)",
    component: DiscoverView,
    meta: {
      animationClass: "splash",
      animationMode: "out-in",
      animationClass2: "splash",
      animationMode2: "out-in"
    }
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  // https://router.vuejs.org/guide/advanced/scroll-behavior.html#async-scrolling
  // https://developer.mozilla.org/en-US/docs/Web/API/Window/scroll
  scrollBehavior(to, from, savedPosition) {
    // console.log(router.currentRoute.value)
    // console.log(to)
    // console.log(from)
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0, behavior: 'instant' }
    }
  }
})

export default router;
