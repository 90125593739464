<!-- ---------- ---------- ---------- HTML Structure ---------- ---------- ---------- -->
<template>
  <!--Welcome-->
  <div>
    <div class="textboxwelcome">
      <div>
        <span v-html="$store.state.activity.welcome[$store.state.user.countryCode]"></span>
      </div>
    </div>
  </div>
</template>

  <!-- ---------- ---------- ---------- Code ---------- ---------- ---------- -->

<script>
/* eslint-disable */
export default {
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {},
};
</script>

<style scoped>
.textboxwelcome {
  padding: 0.5rem 1rem 0.5rem 1rem;
  font-size: 0.9rem;
  border-radius: 1rem;
  background-color: #007bff80;
  text-align: left;
  color: white;
}
.btn {
  color: #ffffff;
  background-color: #6fb5ff;
  font-weight: bold;
  border: none;
  display: inline-block;
  width: 100%;
  padding: 0.5rem;
  margin-left: 0rem;
  margin-right: 0rem;
  border-radius: 1rem;
  margin-bottom: 0.5rem;
  margin-top: 0rem;
  text-align: center;
  cursor: default;
}
</style>