<template>
  <div style="margin-bottom: 4rem">
    <form @submit.prevent="handleSubmit">
      <label for="email" class="mailandpasswordlabel">Email:</label>
      <input type="email" name="email" v-model="email" required />

      <label for="email" class="mailandpasswordlabel">
        <span v-if="$store.state.user.countryCode == 'DE'">Passwort</span>
        <span v-else>Password</span>
      </label>
      <input type="password" name="password" autocomplete="on" v-model="password" required />
      <div style="margin-top: 2rem; font-size: 0.9rem" class="container">
        <div style="display: flex; align-items: top">
          <div style="width: 20%; margin-top: 0.13rem">
            <input style="transform: scale(1.2)" type="checkbox" v-model="checkboxMails" />
          </div>
          <label style="width: 80%; padding-right: 1.5rem; text-align: left">
            <span v-if="$store.state.user.countryCode == 'DE'">
              Ich möchte Neuigkeiten von Timetip per E-Mail erhalten. Von
              diesen Benachrichtigungen kann ich mich jederzeit wieder
              abmelden.
            </span>
            <span v-else>
              I want to receive news from timetip per email. I can unsubscribe
              from those messages anytime.
            </span>
          </label>
        </div>
        <div style="display: flex; align-items: top; margin-top: 2rem">
          <div style="width: 20%; margin-top: 0.13rem">
            <input
              style="transform: scale(1.2)"
              type="checkbox"
              v-model="checkboxDataPrivacy"
              required
            />
          </div>
          <label style="width: 80%; padding-right: 1.5rem; text-align: left">
            <span v-if="$store.state.user.countryCode == 'DE'">
              Mir ist bekannt, dass meine persönlichen Daten in Übereinstimmung
              mit der
            </span>
            <span v-else>
              I know that my personal data will be processed by timetip in
              accordance to the timetip
            </span>
            <router-link
              :to="{ name: 'PrivacyPolicy' }"
              style="text-decoration: none; color: #007bff"
            >
              <span
                v-if="$store.state.user.countryCode == 'DE'"
              >{{ " " }}Datenschutzerklärung{{ " " }}</span>
              <span v-else>
                {{ " " }}Privacy Policy.
                <span style="color: #dee2e6">(Mandatory)</span>
              </span>
            </router-link>
            <span v-if="$store.state.user.countryCode == 'DE'">
              von Timetip verarbeitet werden.
              <span style="color: #dee2e6">(Pflichtfeld)</span>
            </span>
          </label>
        </div>
      </div>

      <button class="signupbutton">
        <span>Sign up</span>
      </button>
      <div v-if="error" style="color: red">
        <div v-if="error == 'Firebase: Error (auth/email-already-in-use).'">
          <span v-if="$store.state.user.countryCode == 'DE'">
            Diese email wird bereits verwendet. Bitte logge dich ein oder setze
            dein Passwort zurück.
          </span>
          <span v-else>This email is already in use. Please log in or reset your password</span>
          <div>
            <button
              class="signupbutton"
              style="background: #00dba4"
              v-on:click="$router.push('/loginwithemailandpassword')"
            >
              <span v-if="$store.state.user.countryCode == 'DE'">Link zur Login Seite</span>
              <span v-else>Link to Login page</span>
            </button>
          </div>
        </div>
        <span v-else>{{ error }}</span>
      </div>
    </form>
  </div>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import FireStoreService from "@/firebase/FireStoreService.js";
import { getAuth, sendEmailVerification } from "firebase/auth";

export default {
  setup() {
    const email = ref("");
    const password = ref("");
    const error = ref(null);

    const store = useStore();
    const router = useRouter();

    const checkboxMails = ref("");
    const checkboxDataPrivacy = ref("");

    const handleSubmit = async () => {
      try {
        await store.dispatch("signup", {
          email: email.value,
          password: password.value,
        });
        store.state.checkboxMails = checkboxMails.value;
        store.state.checkboxDataPrivacy = checkboxDataPrivacy.value;
        FireStoreService.getFSMergeFavoritesSetFS();
        router.push("/");
      } catch (err) {
        error.value = err.message;
        console.log(err.message);
      }
      const auth = getAuth();
      sendEmailVerification(auth.currentUser).then(() => {
        console.log("Email verification sent!");
        // Email verification sent!
        // ...
      });
    };

    return {
      handleSubmit,
      email,
      password,
      error,
      checkboxMails,
      checkboxDataPrivacy,
    };
  },
};
</script>
<style scoped>
input {
  border-radius: 0.6rem !important;
  border-style: solid !important;
  border-image: none;
  border-color: #dee2e6;
  background-color: unset;
  border-width: 1.5px;
  padding: 0.5rem 1rem 0.5rem 1rem;
  cursor: pointer;
}
input:focus {
  outline: none;
}
/* navbar */
.signupbutton {
  margin-top: 2rem;
  display: inline-flex;
  justify-content: center;
  align-items: baseline;
  background: #007bff;
  outline: none;
  border: none;
  padding: 6px 30px;
  border-radius: 0.6rem;
  color: #fff;
  cursor: pointer;
  margin-bottom: 1rem;
}

/* blogs */
.blog {
  padding: 10px;
  margin: 30px 0 20px;
  border-top: 1px dotted #ddd;
}
.blog p {
  color: #999;
}
.blog h3 {
  color: #00dba4;
}
.blog .icons {
  color: #555;
  font-weight: bold;
  display: flex;
  align-items: center;
  font-size: 0.9em;
}
.blog .icons .material-icons {
  font-size: 1em;
  margin-left: 10px;
  cursor: pointer;
}
.blog .icons .material-icons:hover {
  color: #00dba4;
}

/* forms */
.mailandpasswordlabel {
  display: block;
  margin-bottom: 10px;
  margin-top: 20px;
}
form button {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
</style>