<template>
  <div style="margin-bottom: 4rem">
    <form @submit.prevent="handleSubmit">
      <label for="email">Email:</label>
      <input type="email" name="email" v-model="email" required />

      <label for="email">
        <span v-if="$store.state.user.countryCode == 'DE'">Passwort </span>
        <span v-else>Password </span>
      </label>
      <input
        type="password"
        name="password"
        autocomplete="on"
        v-model="password"
        required
      />
      <br />
      <button class="signupbutton"><span>Login</span></button>
      <div v-if="error" class="container" style="color: red; font-size: 0.9rem">
        <div
          v-if="
            error == 'auth/user-not-found' || error == 'auth/wrong-password'
          "
        >
          <div v-if="error == 'auth/wrong-password'">
            <span v-if="$store.state.user.countryCode == 'DE'"
              >Falsches Passwort
            </span>
            <span v-else>Wrong password</span>
          </div>
          <div v-if="error == 'auth/user-not-found'">
            <div>
              <span v-if="$store.state.user.countryCode == 'DE'"
                >Diese email ist noch nicht registriert. Bitte melde dich unter
                dem untenstehenden Link erstmalig an.
              </span>
              <span v-else
                >This mail is not regitered yet. Please first sign up with the
                link below.
              </span>
            </div>
            <button
              v-on:click="$router.push('/signupwithemailandpassword')"
              class="signupbutton"
              style="background: #007bff"
            >
              <span v-if="$store.state.user.countryCode == 'DE'"
                >Link zur Account Erstellung
              </span>
              <span v-else>Link to Signup page </span>
            </button>
          </div>
        </div>
        <span v-else>{{ error }}</span>
      </div>
    </form>
    <!--Forgot Password-->
    <div
      v-on:click="resetPassword"
      style="font-size: 0.9rem; color: #2c3e5080; cursor: pointer; margin-top: 1rem"
    >
      <div>
        <span v-if="$store.state.user.countryCode == 'DE'"
          >Passwort zurücksetzen
        </span>
        <span v-else>Reset password </span>
      </div>

      <div v-if="passwordSent && !resetPasswordErrorCode">
        <span v-if="$store.state.user.countryCode == 'DE'"
          >Wir haben dir einen link an deine E-Mail-Adresse geschickt, mit dem
          du dein Passwort zurücksetzen kannst.
        </span>
        <span v-else
          >We sent a link to your email with which you can reset your password.
        </span>
      </div>
      <div v-if="resetPasswordErrorCode" style="color: red">
        <div
          v-if="
            resetPasswordErrorCode == 'auth/missing-email' ||
            resetPasswordErrorCode == 'auth/invalid-email' ||
            resetPasswordErrorCode == 'auth/user-not-found'
          "
        >
          <div
            v-if="
              resetPasswordErrorCode == 'auth/missing-email' ||
              resetPasswordErrorCode == 'auth/invalid-email'
            "
          >
            <span v-if="$store.state.user.countryCode == 'DE'"
              >Bitte trage eine valide email Addresse ein
            </span>
            <span v-else>Please enter a valid email address. </span>
          </div>
          <div v-if="resetPasswordErrorCode == 'auth/user-not-found'">
            <span v-if="$store.state.user.countryCode == 'DE'"
              >Diese email Addresse existiert noch nicht.
            </span>
            <span v-else>This mail address does not exist yet. </span>
          </div>
        </div>
        <span v-else>{{ resetPasswordErrorCode }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import FireStoreService from "@/firebase/FireStoreService.js";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";

export default {
  setup() {
    const email = ref("");
    const password = ref("");
    const error = ref(null);
    const resetPasswordErrorCode = ref(null);
    const resetPasswordErrorCodeMessage = ref(null);
    const passwordSent = ref(false);

    const store = useStore();
    const router = useRouter();

    const handleSubmit = async () => {
      passwordSent.value = false;
      resetPasswordErrorCode.value = null;
      resetPasswordErrorCodeMessage.value = null;
      try {
        await store.dispatch("login", {
          email: email.value,
          password: password.value,
        });
        FireStoreService.getFSMergeFavoritesSetFS();
        router.push("/");
      } catch (err) {
        error.value = err.code;
      }
    };
    const resetPassword = async () => {
      resetPasswordErrorCode.value = null;
      resetPasswordErrorCodeMessage.value = null;
      const auth = getAuth();
      sendPasswordResetEmail(auth, email.value)
        .then(() => {
          passwordSent.value = true;
          console.log("Password reset email sent!");
          // Password reset email sent!
          // ..
        })
        .catch((error) => {
          resetPasswordErrorCode.value = error.code;
          resetPasswordErrorCodeMessage.value = error.message;
          console.log(resetPasswordErrorCode.value);
          console.log(resetPasswordErrorCodeMessage.value); // ..
        });
    };

    return {
      handleSubmit,
      email,
      password,
      error,
      resetPassword,
      resetPasswordErrorCode,
      resetPasswordErrorCodeMessage,
      passwordSent,
    };
  },
};
</script>
<style scoped>
input {
  border-radius: 0.6rem !important;
  border-style: solid !important;
  border-image: none;
  border-color: #dee2e6;
  background-color: unset;
  border-width: 1.5px;
  padding: 0.5rem 1rem 0.5rem 1rem;
  cursor: pointer;
}
input:focus {
  outline: none;
}
/* navbar */
.signupbutton {
  margin-top: 2rem;
  display: inline-flex;
  justify-content: center;
  align-items: baseline;
  background: #00dba4;
  outline: none;
  border: none;
  padding: 6px 30px;
  border-radius: 0.6rem;
  color: #fff;
  cursor: pointer;
  margin-bottom: 1rem;
}

/* blogs */
.blog {
  padding: 10px;
  margin: 30px 0 20px;
  border-top: 1px dotted #ddd;
}
.blog p {
  color: #999;
}
.blog h3 {
  color: #00dba4;
}
.blog .icons {
  color: #555;
  font-weight: bold;
  display: flex;
  align-items: center;
  font-size: 0.9em;
}
.blog .icons .material-icons {
  font-size: 1em;
  margin-left: 10px;
  cursor: pointer;
}
.blog .icons .material-icons:hover {
  color: #00dba4;
}

/* forms */
label {
  display: block;
  margin-bottom: 10px;
  margin-top: 20px;
}
form button {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
</style>