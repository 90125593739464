<template>
  <div class="footerstyle"
    v-if="Object.keys($store.state.filterCombosNew).length != 0 || $router.currentRoute.value.name != 'DiscoverView'">
    <!--Legal information-->
    <div class="paddingaround">
      <div class="inline">
        <router-link :to="{ name: 'SiteNotice' }" class="delete-underline">
          <span class="text" v-if="$store.state.user.countryCode == 'DE'">Impressum</span>
          <span class="text" v-if="$store.state.user.countryCode != 'DE'">Site Notice</span>
        </router-link>
      </div>

      <div class="inline">
        <router-link v-if="!$store.state.appleAppStore" :to="{ name: 'PrivacyPolicy' }" class="delete-underline">
          <span class="text" v-if="$store.state.user.countryCode == 'DE'">Datenschutzerklärung</span>
          <span class="text" v-if="$store.state.user.countryCode != 'DE'">Privacy Policy</span>
        </router-link>
        <router-link v-else :to="{ name: 'PrivacyPolicyAppleAppStore' }" class="delete-underline">
          <span class="text" v-if="$store.state.user.countryCode == 'DE'">Datenschutzerklärung</span>
          <span class="text" v-if="$store.state.user.countryCode != 'DE'">Privacy Policy</span>
        </router-link>
      </div>
      <div class="inline" v-if="!$store.state.appleAppStore">
        <a href="javascript:UC_UI.showSecondLayer();" style="text-decoration: none; color:#000000" class="delete-underline text">Cookies</a>
      </div>
    </div>

    <!--Geo Information-->
    <div v-if="$route.name == 'SettingsView' && false" class="paddingaround">
      <span>
        <a class="text delete-underline" href="http://www.geoplugin.com/geolocation/" target="_blank">IP Geolocation</a>
        by
        <a class="text delete-underline" href="http://www.geoplugin.com/" target="_blank">geoPlugin</a>
      </span>
    </div>

    <!--Photo Information-->
    <!--ActivityListView - Source not mentioned-->
    <div>
      <div v-if="$route.name == 'ActivityListView'" class="paddingaround">
        <div class="text">
          <span v-if="$store.state.user.countryCode == 'DE'">Fotos von</span>
          <span v-if="$store.state.user.countryCode != 'DE'">Photos from</span>
          <a class="text delete-underline" href="https://unsplash.com/" target="_blank">Unsplash</a>
        </div>
      </div>
      <!--DetailsView-->
      <div v-if="$route.name == 'DetailsView'" class="paddingaround">
        <!--Source not mentioned or Cat-->
        <div v-if="
          $store.state.activity.imagesource == 'none' ||
          $store.state.storeSelectedActivityId <= 0
        ">
          <span v-if="$store.state.user.countryCode == 'DE'">Foto von</span>
          <span v-if="$store.state.user.countryCode != 'DE'">Photo from</span>
          <a class="text delete-underline" href="https://unsplash.com/" target="_blank">Unsplash</a>
        </div>
        <!--Source mentioned-->
        <span class="text delete-underline" v-html="$store.state.activity.imagesource"
          v-if="$store.state.activity.imagesource != 'none'"></span>
      </div>
    </div>
    <div>
      <a v-if="$store.state.user.countryCode == 'DE'" class="delete-underline text"
        href="mailto:fback&commat;timetip&period;app?subject=Feedback%20Timetip&body=New%20activity%20ideas%3A%0D%0A-%20%0D%0A-%20%0D%0AFeedback%20for%20current%20functionalities%3A%0D%0A-%20%0D%0A-%20%0D%0AIdeas%20for%20new%20functionalities%3A%0D%0A-%20%0D%0A-%20%0D%0AYour%20final%20advice%0D%0A-%20">Ich
        freue mich über jedes Feedback</a>
      <a v-if="$store.state.user.countryCode != 'DE'" class="delete-underline text"
        href="mailto:fback&commat;timetip&period;app?subject=Feedback%20Timetip&body=New%20activity%20ideas%3A%0D%0A-%20%0D%0A-%20%0D%0AFeedback%20for%20current%20functionalities%3A%0D%0A-%20%0D%0A-%20%0D%0AIdeas%20for%20new%20functionalities%3A%0D%0A-%20%0D%0A-%20%0D%0AYour%20final%20advice%0D%0A-%20">I
        am happy for any Feedback</a>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

export default {
  components: {
  },
  props: {},
  data() {
    return {};
  },
  mounted() { },
  methods: {},
  computed: {},
};
</script>
<style scoped>
.footerstyle {
  font-size: 60%;
  padding: 0.5rem 0rem;
  pointer-events: none;
}

.inline {
  display: inline-block;
  width: 6rem;
}

.text {
  cursor: pointer;
  pointer-events: all;
  color: #2c3e50;
}

.delete-underline {
  text-decoration: none;
}

.paddingaround {
  padding-bottom: 1rem;
}
</style>