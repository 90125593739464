import "./registerServiceWorker";
import { createApp } from "vue";
import naive from "naive-ui"; // NaiveUI
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'

loadFonts()

createApp(App)
  .use(store)
  .use(router)
  .use(naive) // NaiveUI
  .use(vuetify)
  .mount("#app");

/*
const app = createApp(App);
app.use(store);
app.use(router);
app.use(naive);
app.mount("#app");
*/