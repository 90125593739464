import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'

// PRODUCTION
const firebaseConfig = {
  apiKey: "AIzaSyDn21EhLMnpWSJ4np9BZ-3dy4ZxVLgKHPA",
  authDomain: "timetip-app.firebaseapp.com",
  projectId: "timetip-app",
  storageBucket: "timetip-app.appspot.com",
  messagingSenderId: "855693566227",
  appId: "1:855693566227:web:edd8f17191fd2cc6f0bae6",
  measurementId: "G-ZYPV02LPJM"
};
// TEST
const firebaseConfigTest = {
  apiKey: "AIzaSyBTipz5vemPaTHF1_RFBDRNpzdghHB3mPk",
  authDomain: "timetip-test.firebaseapp.com",
  projectId: "timetip-test",
  storageBucket: "timetip-test.appspot.com",
  messagingSenderId: "236824668453",
  appId: "1:236824668453:web:267547235c3043b7bb52cc",
  measurementId: "G-V0ZS2G3FQF"
};

// init firebase
initializeApp(firebaseConfig)
// Apple App Store
// - @index.html: Google Analytics and Cookie Consent Tool deleted
// - @store index.js: appleAppStore: true
// - firebase use timetip-test
// - Disabe log

// Play Store & timetip.app
// - @index.html: Google Analytics and Cookie Consent Tool deleted
// - @store index.js: appleAppStore: true
// - firebase use timetip-app
// - Disabe log

// FirebaseConfig
// Local firebaseConfigTest | log false | firebase use timetip-test | Settings-->Login umstellen auf "store.state.loginUrl.local" | dynamicLinkDomain: "timetip.page.link" | npm run build | firebase deploy
// TEST firebaseConfigTest | log flase | firebase use timetip-test | Settings-->Login umstellen auf "store.state.loginUrl.test" | dynamicLinkDomain: "timetip.page.link" | npm run build | firebase deploy
// PROD1 firebaseConfig | log true | firebase use timetip-app | Settings-->Login umstellen auf "store.state.loginUrl.prod" | dynamicLinkDomain: "timetipapp.page.link" | npm run build | firebase deploy
// PROD2 firebaseConfig | log true | firebase use timetip-apple | Settings-->Login umstellen auf "store.state.loginUrl.prod" | dynamicLinkDomain: "timetipapp.page.link" | Google Analytics löschen | npm run build | firebase deploy

// Disable log
if (true) {
  console.log = function () {};
}
// Idea: Get current firebase project and decide on this basis

// init firebase auth
const auth = getAuth()

export { auth }