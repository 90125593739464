<template>
  <div>
    <!--For all Filters-->
    <div v-for="question in $store.state.filters" v-bind:key="question.id">
      <div
        v-if="(question[0].parent != 'sport' || sportsSelected) && (question[0].parent != 'game' || gamesSelected) && (!isDiscoveryView || discoveryViewQuestion == question[0].parent)"
        v-bind:class="{ nobb: question[0].parent == 'specialkind' || isDiscoveryView, firstquestion: question[0].parent == 'setting', startviewfiltercontainer: isDiscoveryViewCarousel, isDiscoveryViewDrawerCarousel: isDiscoveryViewDrawerCarousel }"
        class="border-bottom space" style="font-size: 0.9rem">

        <!--Intermmediate comments-->
        <!-- <div class="list-title border-bottom"
          style=" padding-bottom: 1rem; margin-bottom: 1rem; color: #2c3e50; font-weight: normal;"
          v-if="question[0].parent == 'type' && !isDiscoveryView">
          <span v-if="$store.state.user.countryCode == 'EN'">You can select multiple answers per question</span>
          <span v-if="$store.state.user.countryCode != 'EN'">Du kannst mehrere Antworten je Frage auswählen und Fragen
            überspringen.</span>
        </div>
        <div class="list-title border-bottom"
          style="padding-bottom: 1rem; margin-bottom: 1rem; color: #2c3e50; font-weight: normal"
          v-if="question[0].parent == 'pcount' && !isDiscoveryView">
          <span v-if="$store.state.user.countryCode == 'EN'">
            Do not answer too many questions in the beginning. The number of
            matching activities decreases with each answer.
          </span>
          <span v-if="$store.state.user.countryCode != 'EN'">
            Beantworte am besten erst einmal weniger Fragen, da sich die Zahl
            der passenden Aktivitäten mit jeder zusätzlichen Antwort
            reduziert.
          </span>
        </div> -->
        <!--Start Page Headline New-->
        <h1 v-if="question[0].parent == 'typical' && isDiscoveryViewCarousel" class="list-title"
          v-bind:class="{ startviewtitle: isDiscoveryViewCarousel }"
          style="display: flex; align-items:flex-start; justify-content: center; position: relative; font-weight: normal; font-size: 1.1rem!important;">
          <span v-if="$store.state.user.countryCode == 'DE'"> Finde Ideen für deine Freizeit</span>
          <span v-else>Get ideas for your free time</span>
        </h1>

        <!--List Title-->
        <div v-else class="list-title" v-bind:class="{ startviewtitle: isDiscoveryViewCarousel }"
          style="display: flex; align-items:flex-start; justify-content: center; position: relative; font-weight: normal; font-size: 1rem;">
          <span v-if="$store.state.user.countryCode == 'EN'"> {{ question[0].question }} </span>
          <span v-if="$store.state.user.countryCode != 'EN'"> {{ question[0].questionde }} </span>
        </div>

        <!--All Filters except age, cost and time-->
        <div v-if="
          question[0].parent != 'age' &&
          question[0].parent != 'cost' &&
          question[0].parent != 'time'
        " style="display: flex; flex-wrap: wrap; justify-content: center;">
          <button class="toggle click-answer" v-bind:id="answer.filterkey" v-bind:answer-key="answer.filterkey"
            v-bind:class="{
              selected7: answer.selected,
              notselected7: !answer.selected,
              lastanswerwithvalues: answer.lastAnswerWithValues,
              countZero: answer.count < 1 && !answer.selected,
            }" v-for="answer in question" v-bind:key="answer.id" v-on:click="clickFilter"
            v-bind:clickedName="answer.filterkey" v-bind:clickedParent="answer.parent"
            v-bind:clickedSelected="answer.selected" v-bind:clickedCount="answer.count" v-bind:clickedId="answer.id">
            <div v-if="answer.svg != 0" v-html="answer.svg" class="leftpart"></div>
            <div class="rightpart">
              <span class="noclick" v-if="$store.state.user.countryCode != 'DE'">{{ answer.nameen }}</span>
              <span class="noclick" v-if="$store.state.user.countryCode == 'DE'">{{ answer.namede }}</span>
            </div>
          </button>
          <!--Reset Button inside of one Question-->
          <button v-if="oneInQuestionSelected[question[0].parent]" v-on:click="clickAll(question)" class="toggle t2"
            v-bind:class="{
              countZero: !oneInQuestionSelected[question[0].parent],
              notselected7: oneInQuestionSelected[question[0].parent],
            }">
            <span v-if="!false">
              <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 24 30" width="20px" fill="#ffffff">
                <g>
                  <rect fill="none" height="24" width="24" />
                </g>
                <g>
                  <g>
                    <path
                      d="M16.95,6l-3.57,4.55l1.43,1.43c1.03-1.31,4.98-6.37,4.98-6.37C20.3,4.95,19.83,4,19,4H6.83l2,2H16.95z" />
                    <path
                      d="M2.81,2.81L1.39,4.22L10,13v6c0,0.55,0.45,1,1,1h2c0.55,0,1-0.45,1-1v-2.17l5.78,5.78l1.41-1.41L2.81,2.81z" />
                  </g>
                </g>
              </svg>
            </span>
          </button>
        </div>

        <!--Intersection for Target and Special. Todo: Could be wrapped into one v-for -->
        <div style="
              display: flex;
              justify-content: center;
              padding: 0.5rem 0rem 0rem 0rem;
            " v-if="
              $store.state.betaFeatures &&
              (question[0].parent == 'target' ||
                question[0].parent == 'special' ||
                question[0].parent == 'typical' ||
                question[0].parent == 'dayweektime')
            ">
          <!--Intersection for Target-->
          <div v-if="question[0].parent == 'target'" class="targetclass">
            <n-switch v-model:value="$store.state.itarget" @update:value="!$store.state.itarget"
              v-on:click="intersectionClick($store.state.itarget, 'target')" style="padding-right: 1rem"
              class="n-switcha" />
            <span style="cursor: pointer">
              <span v-on:click="
                ($store.state.itarget = !$store.state.itarget),
                intersectionClick($store.state.itarget, 'target')
              ">
                {{
                    $store.state.appTexts.intersectiontext[
                    $store.state.user.countryCode
                    ] + " "
                }}
              </span>
              <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 1 24 24" width="20px" fill="#2c3e50"
                v-on:click="showTargetTooltip = !showTargetTooltip" style="cursor: pointer">
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path
                  d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
              </svg>
              <br />
              <span v-if="showTargetTooltip" style="opacity: 0.5">
                {{
                    $store.state.appTexts.intersectiontooltip[
                    $store.state.user.countryCode
                    ]
                }}
              </span>
            </span>
          </div>

          <!--Intersection for Special-->
          <div v-if="question[0].parent == 'special'" class="specialclass">
            <n-switch v-model:value="$store.state.ispecial" @update:value="!$store.state.ispecial"
              v-on:click="intersectionClick($store.state.ispecial, 'special')" style="padding-right: 1rem"
              class="n-switchb" />
            <span style="cursor: pointer">
              <span v-on:click="
                ($store.state.ispecial = !$store.state.ispecial),
                intersectionClick($store.state.ispecial, 'special')
              ">
                {{
                    $store.state.appTexts.intersectiontext[
                    $store.state.user.countryCode
                    ] + " "
                }}
              </span>
              <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 1 24 24" width="20px" fill="#2c3e50"
                v-on:click="showSpecialTooltip = !showSpecialTooltip" style="cursor: pointer">
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path
                  d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
              </svg>
              <br />
              <span v-if="showSpecialTooltip" style="opacity: 0.5">
                {{
                    $store.state.appTexts.intersectiontooltip[
                    $store.state.user.countryCode
                    ]
                }}
              </span>
            </span>
          </div>
          <!--Intersection for typical-->
          <div v-if="question[0].parent == 'typical'">
            <n-switch v-model:value="$store.state.itypical" @update:value="!$store.state.itypical"
              v-on:click="intersectionClick($store.state.itypical, 'typical')" style="padding-right: 1rem"
              class="n-switchc" />
            <span style="cursor: pointer">
              <span v-on:click="
                ($store.state.itypical = !$store.state.itypical),
                intersectionClick($store.state.itypical, 'typical')
              ">
                {{
                    $store.state.appTexts.intersectiontext[
                    $store.state.user.countryCode
                    ] + " "
                }}
              </span>
              <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 1 24 24" width="20px" fill="#2c3e50"
                v-on:click="showTypicalTooltip = !showTypicalTooltip" style="cursor: pointer">
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path
                  d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
              </svg>
              <br />
              <span v-if="showTypicalTooltip" style="opacity: 0.5">
                {{
                    $store.state.appTexts.intersectiontooltip[
                    $store.state.user.countryCode
                    ]
                }}
              </span>
            </span>
          </div>
          <!--Intersection for dayweektime-->
          <div v-if="question[0].parent == 'dayweektime'">
            <n-switch v-model:value="$store.state.idayweektime" @update:value="!$store.state.idayweektime"
              v-on:click="intersectionClick($store.state.idayweektime, 'dayweektime')" style="padding-right: 1rem"
              class="n-switchc" />
            <span style="cursor: pointer">
              <span v-on:click="
                ($store.state.idayweektime = !$store.state.idayweektime),
                intersectionClick($store.state.idayweektime, 'dayweektime')
              ">
                {{
                    $store.state.appTexts.intersectiontext[
                    $store.state.user.countryCode
                    ] + " "
                }}
              </span>
              <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 1 24 24" width="20px" fill="#2c3e50"
                v-on:click="showDayweektimeTooltip = !showDayweektimeTooltip" style="cursor: pointer">
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path
                  d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
              </svg>
              <br />
              <span v-if="showDayweektimeTooltip" style="opacity: 0.5">
                {{
                    $store.state.appTexts.intersectiontooltip[
                    $store.state.user.countryCode
                    ]
                }}
              </span>
            </span>
          </div>
        </div>

        <!--TIME | TIME | TIME | TIME | TIME | TIME | TIME | TIME | TIME | TIME | -->
        <div v-if="(question[0].parent == 'time' && !isDiscoveryView) || discoveryViewQuestion == 'time'"
          class="slidercost" v-bind:class="{
            notactive: !$store.state.timeActive,
          }">
          <div class="timecostbox" style="display: flex">
            <div style="width: 10%"></div>
            <div style="width: 80%" class="timecostagetext">{{ timeValueName }}</div>
            <div style="width: 10%; text-align: right" @click="deactivateTimeFilter = !deactivateTimeFilter">
              <svg v-if="$store.state.timeActive" xmlns="http://www.w3.org/2000/svg" height="22px" viewBox="0 0 24 30"
                width="22px" fill="#000000" class="resetbutton">
                <g>
                  <rect fill="none" height="24" width="24" />
                </g>
                <g>
                  <g>
                    <path
                      d="M16.95,6l-3.57,4.55l1.43,1.43c1.03-1.31,4.98-6.37,4.98-6.37C20.3,4.95,19.83,4,19,4H6.83l2,2H16.95z" />
                    <path
                      d="M2.81,2.81L1.39,4.22L10,13v6c0,0.55,0.45,1,1,1h2c0.55,0,1-0.45,1-1v-2.17l5.78,5.78l1.41-1.41L2.81,2.81z" />
                  </g>
                </g>
              </svg>
            </div>
          </div>
          <TimeSliderComponent :key="rerenderTimeKey" :deactivateTimeFilter="deactivateTimeFilter" />
        </div>

        <!--COST | COST | COST | COST | COST | COST | COST | COST | COST | COST | -->
        <div v-if="question[0].parent == 'cost' && !isDiscoveryView || discoveryViewQuestion == 'cost'"
          class="slidercost" v-bind:class="{
            notactive: !$store.state.costActive,
          }">
          <div class="timecostbox" style="display: flex">
            <div style="width: 10%"></div>
            <div style="width: 80%" class="timecostagetext">{{ costValueName }}</div>
            <div style="width: 10%; text-align: right" @click="deactivateCostFilter = !deactivateCostFilter">
              <svg v-if="$store.state.costActive" xmlns="http://www.w3.org/2000/svg" height="22px" viewBox="0 0 24 30"
                width="22px" fill="#000000" class="resetbutton">
                <g>
                  <rect fill="none" height="24" width="24" />
                </g>
                <g>
                  <g>
                    <path
                      d="M16.95,6l-3.57,4.55l1.43,1.43c1.03-1.31,4.98-6.37,4.98-6.37C20.3,4.95,19.83,4,19,4H6.83l2,2H16.95z" />
                    <path
                      d="M2.81,2.81L1.39,4.22L10,13v6c0,0.55,0.45,1,1,1h2c0.55,0,1-0.45,1-1v-2.17l5.78,5.78l1.41-1.41L2.81,2.81z" />
                  </g>
                </g>
              </svg>
            </div>
          </div>
          <CostSliderComponent :key="rerenderCostKey" :deactivateCostFilter="deactivateCostFilter" />
        </div>

        <!--AGE | AGE | AGE | AGE | AGE | AGE | AGE | AGE | AGE | AGE | AGE | AGE | -->
        <div class="slidercost" v-if="question[0].parent == 'age' && !isDiscoveryView || discoveryViewQuestion == 'age'"
          v-bind:class="{ notactive: !$store.state.ageActive }" style="padding: 0.5rem 0rem 0rem 0rem">
          <div class="timecostbox" style="display: flex">
            <div style="width: 10%"></div>
            <div style="width: 80%" class="timecostagetext">{{ ageValueName }}</div>
            <div style="width: 10%; text-align: right" @click="deactivateAgeFilter = !deactivateAgeFilter">
              <svg v-if="$store.state.ageActive" xmlns="http://www.w3.org/2000/svg" height="22px" viewBox="0 0 24 30"
                width="22px" fill="#000000" class="resetbutton">
                <g>
                  <rect fill="none" height="24" width="24" />
                </g>
                <g>
                  <g>
                    <path
                      d="M16.95,6l-3.57,4.55l1.43,1.43c1.03-1.31,4.98-6.37,4.98-6.37C20.3,4.95,19.83,4,19,4H6.83l2,2H16.95z" />
                    <path
                      d="M2.81,2.81L1.39,4.22L10,13v6c0,0.55,0.45,1,1,1h2c0.55,0,1-0.45,1-1v-2.17l5.78,5.78l1.41-1.41L2.81,2.81z" />
                  </g>
                </g>
              </svg>
            </div>
          </div>
          <AgeContainer :deactivateAgeFilter="deactivateAgeFilter" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import AgeContainer from "@/components/Filters/AgeContainer.vue";

import CostSliderComponent from "@/components/Filters/CostSliderComponent.vue";
import TimeSliderComponent from "@/components/Filters/TimeSliderComponent.vue";

import ClickFilterService from "@/services/ClickFilterService.js";
import UpdateActivitiesService from "@/services/UpdateActivitiesService.js";
import { defineComponent, ref } from "vue";

export default {
  name: "EventList",
  components: {
    AgeContainer,
    CostSliderComponent,
    TimeSliderComponent,
  },
  props: {
    isDiscoveryView: Boolean,
    isDiscoveryViewCarousel: Boolean,
    isDiscoveryViewDrawerCarousel: Boolean,
    discoveryViewQuestion: String,
  },
  data() {
    return {
      rerenderAgeKey: 0,
      rerenderCostKey: 0,
      rerenderTimeKey: 0,
      showTargetTooltip: 0,
      showSpecialTooltip: 0,
      showTypicalTooltip: 0,
      showDayweektimeTooltip: 0,
      deactivateTimeFilter: false,
      deactivateCostFilter: false,
      deactivateAgeFilter: false,
    };
  },
  created() {
    var x
    if (this.isDiscoveryView) { x = "isDiscoveryView" }
    if (this.isDiscoveryViewCarousel) { x = "isDiscoveryViewCarousel" }
    if (this.isDiscoveryViewDrawerCarousel) { x = "isDiscoveryViewDrawerCarousel" }
    console.log("AllFiltersComponent created: " + x)
    // console.log(this.discoveryViewQuestion)
  },
  methods: {
    intersectionClick: function (isTrue, parent) {
      // Delete filter values of target and special, so that activities can not go down to 0
      if (isTrue) {
        this.$store.state.filters[parent].forEach(function (filterObject) {
          filterObject.selected = false;
          filterObject.lastAnswerWithValues = false;
        });
        delete this.$store.state.clickedAnswers[parent];
      }
      UpdateActivitiesService.updateActivities();
      ClickFilterService.clickFilter("onlycheckcountzeroandresethistory");
    },
    clickFilter: function (event) {
      var cQuestion = event.target.getAttribute("clickedParent");
      var cAnswer = event.target.getAttribute("clickedName");
      ClickFilterService.clickFilter(cQuestion, cAnswer);
    },
    clickAll: function (clickedQuestion) {
      var questionName = clickedQuestion[0].parent;

      //console.log(clickedQuestion);
      //console.log(questionName);
      //console.log(this.$store.state.filters);
      // console.log(this.$store.state.clickedAnswers);

      this.$store.state.filters[questionName].forEach(function (answer) {
        answer.selected = false;
      });
      delete this.$store.state.clickedAnswers[questionName];

      UpdateActivitiesService.updateActivities();
      ClickFilterService.clickFilter("onlycheckcountzeroandresethistory");
    },
  },
  watch: {
    costFixMax() {
      this.rerenderCostKey += 1;
    },
    costFixMin() {
      this.rerenderCostKey += 1;
    },
    costEmptyList() {
      this.rerenderTimeKey += 1;
    },
    timeFixMax() {
      this.rerenderTimeKey += 1;
    },
    timeFixMin() {
      this.rerenderTimeKey += 1;
    },
    timeEmptyList() {
      this.rerenderTimeKey += 1;
    },
  },

  computed: {
    costFixMin() {
      // console.log("costFixMin CP = " + this.$store.state.costFixMin);
      return this.$store.state.costFixMin;
    },
    costFixMax() {
      // console.log("costFixMax CP = " + this.$store.state.costFixMax);
      return this.$store.state.costFixMax;
    },
    costEmptyList() {
      // console.log(
      //   "costEmptyList value changed = " + this.$store.state.costEmptyList
      // );
      return this.$store.state.costEmptyList.length;
    },
    costValueName() {
      if (!this.$store.state.costActive) {
        return this.$store.state.appTexts.selectbelow[
          this.$store.state.user.countryCode
        ];
      } else {
        switch (this.$store.state.user.countryCode) {
          case "EN":
            if (
              this.$store.state.costSliderValueMin !=
              this.$store.state.costSliderValueMax
            ) {
              return (
                this.$store.state.filters.cost[
                  this.$store.state.costSliderValueMin
                ].nameen +
                " - " +
                this.$store.state.filters.cost[
                  this.$store.state.costSliderValueMax
                ].nameen
              );
            } else {
              return this.$store.state.filters.cost[
                this.$store.state.costSliderValueMax
              ].nameen;
            }
          case "DE":
            if (
              this.$store.state.costSliderValueMin !=
              this.$store.state.costSliderValueMax
            ) {
              return (
                this.$store.state.filters.cost[
                  this.$store.state.costSliderValueMin
                ].namede +
                " - " +
                this.$store.state.filters.cost[
                  this.$store.state.costSliderValueMax
                ].namede
              );
            } else {
              return this.$store.state.filters.cost[
                this.$store.state.costSliderValueMax
              ].namede;
            }
        }
      }
    },
    ageValueName() {
      if (this.$store.state.ageActive) {
        if (this.$store.state.ageMin != this.$store.state.ageMax) {
          return (
            this.$store.state.ageMin +
            " - " +
            this.$store.state.ageMax +
            " " +
            this.$store.state.appTexts.years[this.$store.state.user.countryCode]
          );
        } else {
          return (
            this.$store.state.ageMin +
            " " +
            this.$store.state.appTexts.years[this.$store.state.user.countryCode]
          );
        }
      } else {
        return this.$store.state.appTexts.selectbelow[
          this.$store.state.user.countryCode
        ];
      }
    },
    timeFixMin() {
      // console.log("timeFixMin CP = " + this.$store.state.timeFixMin);
      return this.$store.state.timeFixMin;
    },
    timeFixMax() {
      // console.log("timeFixMax CP = " + this.$store.state.timeFixMax);
      return this.$store.state.timeFixMax;
    },
    timeEmptyList() {
      // console.log(
      //   "timeEmptyList value changed = " + this.$store.state.timeEmptyList
      // );
      return this.$store.state.timeEmptyList.length;
    },
    timeValueName() {
      if (!this.$store.state.timeActive) {
        return this.$store.state.appTexts.selectbelow[
          this.$store.state.user.countryCode
        ];
      } else {
        switch (this.$store.state.user.countryCode) {
          case "EN":
            if (
              this.$store.state.timeSliderValueMin !=
              this.$store.state.timeSliderValueMax
            ) {
              return (
                this.$store.state.filters.time[
                  this.$store.state.timeSliderValueMin
                ].nameen +
                " - " +
                this.$store.state.filters.time[
                  this.$store.state.timeSliderValueMax
                ].nameen
              );
            } else {
              return this.$store.state.filters.time[
                this.$store.state.timeSliderValueMax
              ].nameen;
            }
          case "DE":
            if (
              this.$store.state.timeSliderValueMin !=
              this.$store.state.timeSliderValueMax
            ) {
              return (
                this.$store.state.filters.time[
                  this.$store.state.timeSliderValueMin
                ].namede +
                " - " +
                this.$store.state.filters.time[
                  this.$store.state.timeSliderValueMax
                ].namede
              );
            } else {
              return this.$store.state.filters.time[
                this.$store.state.timeSliderValueMax
              ].namede;
            }
        }
      }
    },
    nowSelected() {
      var isSelected = false;
      this.$store.state.filters.nowlater.forEach(function (x) {
        if (x.filterkey == "now") {
          isSelected = x.selected;
        }
      });
      return isSelected;
    },
    sportsSelected() {
      var isSelected = false;
      this.$store.state.filters.interest.forEach(function (x) {
        if (x.filterkey == "sports") {
          isSelected = x.selected;
        }
      });
      return isSelected;
    },
    gamesSelected() {
      var isSelected = false;
      this.$store.state.filters.interest.forEach(function (x) {
        if (x.filterkey == "games") {
          isSelected = x.selected;
        }
      });
      return isSelected;
    },
    interestList() {
      return this.$store.state.filters.interest;
    },
    oneInQuestionSelected() {
      var questionObject = new Object();

      for (const [question, value] of Object.entries(
        this.$store.state.filters
      )) {
        var isSelected = false;
        value.forEach(function (answer) {
          if (answer.selected) {
            isSelected = true;
          }
        });
        questionObject[question] = isSelected;
      }
      return questionObject;
    },
  },
};
</script>

<style scoped>
.resetbutton {
  cursor: pointer;
}

.noclick {
  pointer-events: none;
}

.nobb {
  border-bottom: 0px solid #ced4da !important;
}

.slidercost {
  margin: 1rem 1rem 1rem 1rem;
}

.notactive {
  opacity: 0.4;
}



.timecostbox {
  margin-bottom: 0.5rem;
  padding: 0.25rem 0rem;
  border-radius: 0.6rem;
  /* color: white; */
  /* background-color: #e83e8c; */
  display: inline-block;
}

.agebox {
  margin-bottom: 0.5rem;
  padding: 0.25rem 0rem;
  border-radius: 0.6rem;
  /* color: white; */
  /* background-color: #6610f2; */
  display: inline-block;
}

.toggle {
  margin: 0.2rem 0.2rem;
  padding: 0.25rem 0.8rem;
  border-radius: 0.6rem;
  outline: none;
  border: none;
  display: inline-flex;
  align-items: center;
}

.toggle:focus {
  outline: none;
}

.t2 {
  padding-left: 0.4rem;
  padding-right: 0.4rem;
}

.leftpart {
  /* height: 1.5rem; */
  /* width: 1rem; */
  margin-right: 0.5rem;
  pointer-events: none;
  display: flex;
}

.iconclass {
  /* bottom: 0.1rem; */
}

.rightpart {
  pointer-events: none;
}

.firstquestion {
  padding-top: 0.75rem;
}

.n-switcha {
  --n-rail-color-active: #007bff !important;
  --n-box-shadow-focus: 0 0 0 2px transparent !important;
  --n-loading-color: #007bff !important;
  --n-rail-color: #007bff80 !important;
}

.n-switchb {
  --n-rail-color-active: #007bff !important;
  --n-box-shadow-focus: 0 0 0 2px transparent !important;
  --n-loading-color: #007bff !important;
  --n-rail-color: #007bff80 !important;
}

.n-switchc {
  --n-rail-color-active: #007bff !important;
  --n-box-shadow-focus: 0 0 0 2px transparent !important;
  --n-loading-color: #007bff !important;
  --n-rail-color: #007bff80 !important;
}




.dview {
  width: 70%;
}

.dcview {
  font-weight: normal;
  /* font-style: italic; */
  width: 100%;
  font-size: 1rem;
  /* color: white; */

}

.isDiscoveryViewDrawerCarousel {
  font-size: 0.9rem;
  padding-top: 0.8rem;
  padding: 0rem 0rem 0.75rem;

}

.grey {
  fill: #2c3e5080;
}

.space {
  padding: 0.75rem 0rem 0.75rem;
}

.startviewtitle {
  font-size: 1rem !important;
  font-weight: bold !important;
  padding-bottom: 0.1rem
}

.startviewfiltercontainer {
  padding-top: 0rem;
  font-size: 0.9rem;
}

.titlefilterview {}

.filtercontainerfilterview {}
</style>
