<!-- ---------- ---------- ---------- HTML Structure ---------- ---------- ---------- -->
<template>
  <!--Tags Drawer-->
  <n-drawer v-model:show="active" :height="330" :placement="placement">
    <!--<div class="header">
      <span>
        {{ $store.state.appTexts.filter[$store.state.user.countryCode] }}
      </span>
    </div>-->
    <n-drawer-content
      body-content-style="padding: 0rem 0rem 2rem 0rem; text-align: center"
    >
      <div style="">
        <!--Clicked Filters--------------------------------------------------------------------------------------------------------------------------------------->
        <!-- <div
          style="
            padding-top: 0.75rem;
            padding-bottom: 1rem;
            margin-bottom: 0.75rem;
            background: #ffffff;
            border-bottom: 10px solid #dee2e6;
            min-height: 6.5rem;
          "
        >
          <div
            v-if="
              !(
                Object.keys($store.state.clickedAnswers).length ||
                $store.state.ageActive
              )
            "
          >
            <span
              v-if="$store.state.user.countryCode == 'DE'"
              style="font-weight: bold; color: black"
              class=""
              >Du hast noch keine Frage beantwortet</span
            >
            <span v-else style="font-weight: bold; color: black" class=""
              >You have not selected any filters yet</span
            ><br />
            <div
              style="
                display: inline-block;
                font-weight: normal;
                color: white;
                background: #F39EC5;
                padding: 0.25rem 1rem 0.25rem 1rem;
                border-radius: 0.6rem;
                margin: 1rem 0rem 0.5rem 0rem;
              "
              v-on:click="active = false, $store.state.filterClicked = true, $router.push('/filter')"
            >
              <span v-if="$store.state.user.countryCode == 'DE'" class=""
                >Klicke hier, um deine Filter auszuwählen</span
              >
              <span v-else class="">You have not selected any filters yet</span>
            </div>
          </div>
          <div
            v-if="
              Object.keys($store.state.clickedAnswers).length ||
              $store.state.ageActive
            "
          >
            <span
              v-if="$store.state.user.countryCode == 'DE'"
              style="font-weight: bold; color: black"
              class=""
              >Deine gewählten Filter</span
            >
            <span v-else style="font-weight: bold; color: black" class=""
              >Your clicked filters</span
            >
            <ClickedFiltersComponent style="padding-top: 0.25rem" />
          </div>
        </div> -->

        <!--AllFiltersComponent-------------------------------------------------------------------------------------------------------------------------------------
        <AllFiltersComponent
          style="text-align: center; padding: 1rem 0.75rem 0rem 0.75rem"
        />
        -->

        <!--Tags of this activity-----space border-top---------------------------------------------------------------------------------------------------------------------------------->
        <div class="">
          <div
            style="
              font-weight: bold;
              color: black;
              padding: 0.5rem 0.75rem 0.5rem 0.75rem;
            "
          >
            <span>
              {{
                $store.state.appTexts.activitytags[
                  $store.state.user.countryCode
                ]
              }}
            </span>
          </div>
          <div
            v-for="question in $store.state.filters"
            :key="question"
            style="display: inline"
          >
            <!--<div
          style="padding-bottom: 1rem"
          v-if="
            $store.state.activity[question[0].parent][0] != '' &&
            question[0].parent != 'age'
          "
          v-bind:class="{ paddingtop: question[0].parent != 'setting' }"
        >
          <span>{{
            question[0][
              $store.state.appTexts.question[$store.state.user.countryCode]
            ]
          }}</span>
        </div>-->
            <div
              v-for="answer in question"
              :key="answer"
              style="display: inline"
            >
              <div
                v-for="activityfilterkey in $store.state.activity[
                  question[0].parent
                ]"
                :key="activityfilterkey"
                style="display: inline"
              >
                <div
                  class="selectedFilter"
                  v-if="answer.filterkey == activityfilterkey"
                  v-bind:class="{
                    selected: answer.color == 1,
                    selected2: answer.color == 2,
                    selected3: answer.color == 3,
                    selected4: answer.color == 4,
                    selected5: answer.color == 5,
                    selected6: answer.color == 6,
                  }"
                >
                  <span style="display: inline">
                    {{
                      answer[
                        $store.state.appTexts.name[
                          $store.state.user.countryCode
                        ]
                      ]
                    }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <!--Language Drawer age-->
          <div style="padding: 0rem 0rem 0rem 0rem; display: inline">
            <!--<span style="display: block; padding: 0rem 0rem 1rem 0rem"
          >{{
            $store.state.filters.age[0][
              $store.state.appTexts.question[$store.state.user.countryCode]
            ]
          }}
        </span>-->
            <span class="selectedFilter selected4"
              >{{ "(" + $store.state.activity.agewp + ") " + $store.state.activity.agestart + " - " + $store.state.activity.ageend }}
            </span>
          </div>
        </div>
      </div>
    </n-drawer-content>
  </n-drawer>
</template>

  <!-- ---------- ---------- ---------- Code ---------- ---------- ---------- -->

<script>
/* eslint-disable */
import AllFiltersComponent from "@/components/Filters/AllFiltersComponent.vue";
import ClickedFiltersComponent from "@/components/Filters/ClickedFiltersComponent.vue";

import { defineComponent, ref } from "vue";

export default {
  components: { AllFiltersComponent, ClickedFiltersComponent },
  props: {
    showtags: Number,
  },
  data() {
    return {
      i: 1,
    };
  },
  setup() {
    const active = ref(false);
    const placement = ref("right");
    const activate = (place) => {
      active.value = true;
      placement.value = place;
    };
    return {
      active,
      placement,
      activate,
    };
  },
  mounted() {},
  watch: {
    showtags() {
      this.activate("bottom");
    },
  },
  methods: {},
  computed: {},
};
</script>

<style scoped>
.header {
  font-weight: bold;
  line-height: 1;
  padding: var(--header-padding);
  transition: border 0.3s var(--bezier);
  border-bottom: 1px solid var(--divider-color);
  border-bottom: var(--header-border-bottom);
  display: flex;
  justify-content: center;
  color: black;
}
.selectedFilter {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  margin: 0.1rem;
  padding: 0.075rem 0.5rem 0rem 0.5rem;
  border-radius: 0.6rem;
  border: 0;
  cursor: pointer;
  font-size: 0.9rem;
}
.paddingtop {
  padding-top: 1rem;
}
</style>