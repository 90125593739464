<!-- ---------- ---------- ---------- HTML Structure ---------- ---------- ---------- -->
<template>
  <div class="page">
    <div style="min-height: 100vh">
      <!-- <div
        class="clickedFilterTitle"
        v-bind:class="{
          topzero: $router.currentRoute.value.name == 'Test',
        }"
        v-if="
          $store.state.storeActivityList.length !=
          $store.state.storeActivityListFiltered.length
        "
      >
        <span
          v-if="$store.state.user.countryCode == 'DE'"
          style="font-weight: bold; color: black"
          >Deine gewählten Filter</span
        >
        <span v-else style="font-weight: bold; color: black"
          >Your clicked filters</span
        >
      </div> -->
      <ClickedFiltersTopComponent />

      <div>
        <ActivityListComponent />
      </div>
    </div>
  </div>
</template>

  <!-- ---------- ---------- ---------- Code ---------- ---------- ---------- -->

<script>
/* eslint-disable */
import ActivityListComponent from "@/components/ActivityList/ActivityListComponent.vue";
import ClickedFiltersTopComponent from "@/components/Filters/ClickedFiltersTopComponent.vue";

export default {
  components: {
    ActivityListComponent,
    ClickedFiltersTopComponent,
  },
  mounted() {
    this.$store.state.activityViewDetails = false;
  },
};
</script>

<style scoped>
.clickedFilterTitle {
  margin-bottom: 0em;
  padding: 0.75rem 0.75rem 0rem 0.75rem;
  /*background-color: #007bff; /*#6fb5ff;*/
  /*color: white;
  position: sticky;*/
  top: 40px;
  z-index: 90;
  font-weight: bold;
}
</style>