<!-- ---------- ---------- ---------- HTML Structure ---------- ---------- ---------- -->
<template>
  <div class="paddingtop">
    <CategoryInformationComponent
      :activityListPosition="activityListPosition"
      v-if="$store.state.detailsView == 'info'"
    />
    <CategoryLinksComponent
      :activityListPosition="activityListPosition"
      v-if="$store.state.detailsView == 'link'"
    />
  </div>
</template>

  <!-- ---------- ---------- ---------- Code ---------- ---------- ---------- -->

<script>
/* eslint-disable */
import CategoryInformationComponent from "@/components/Details/CategoryInformationComponent.vue";
import CategoryLinksComponent from "@/components/Details/CategoryLinksComponent.vue";

export default {
  components: {
    CategoryInformationComponent,
    CategoryLinksComponent,
  },
  props: {
    activityListPosition: Number,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {},
};
</script>

<style scoped>
.paddingtop {
  padding-top: 12px;
  padding-top: 0px;
}
</style>