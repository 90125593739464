<!-- ---------- ---------- ---------- HTML Structure ---------- ---------- ---------- -->
<template>
  <div>
    <ShortTextComponent :activityListPosition="activityListPosition" />
    <div style="padding-bottom: 5rem">
      <div style=" font-weight: bold; color: black; padding: 0.5rem 0rem 0.5rem 0rem">
        <span v-if="$store.state.user.countryCode == 'DE'">Nächste Schritte</span>
        <span v-else>Helpful links</span>
      </div>

      <!--TOP ICONDETAILS-->
      <div
        v-if="
          activity.alinkurl[$store.state.user.countryCode] != 0 ||
          activity.blinkurl[$store.state.user.countryCode] != 0 ||
          (activity.buypayload[$store.state.user.countryCode] != 0 &&
            activity.buylinks != 'none') ||
          activity.bestlinksworld != 'none' ||
          activity.bestlinksde != 'none' ||
          activity.hashtaglinks != 'none' ||
          activity.placelinks != 'none' ||
          activity.action != 0
        "
      >
        <!--placelinks-->
        <div v-if="activity.placelinks != 'none'">
          <div v-for="link in getLinkObjectsByLinkNames(activity.placelinks)" v-bind:key="link.id">
            <a
              v-if="link.linkurl[$store.state.user.countryCode] != 'none' && !(link.linkname == 'applemaps' && this.$store.state.browserName != 'safari')"
              v-bind:href="
                link.linkurl[$store.state.user.countryCode] +
                activity.placepayload[$store.state.user.countryCode] +
                link.linkurlend
              "
              target="_blank"
              class="linkdetailsbox"
            >
              <div class="linkleft">
                <img
                  v-bind:src="
                    'https://storage.googleapis.com/timetip-images/link-images/' +
                    link.linkimage +
                    '.png'
                  "
                  @error="setAltImg"
                  class="link"
                  alt="image"
                />
              </div>
              <div class="linkright">
                <span
                  class="link"
                  v-if="activity.placeteaser[$store.state.user.countryCode] != 0 && activity.placeteaser[$store.state.user.countryCode] != ''"
                >{{ activity.placeteaser[$store.state.user.countryCode] }}</span>
                <span class="link" v-else>{{ link.linktext[$store.state.user.countryCode] }}</span>
              </div>
            </a>
          </div>
        </div>

        <!--Call-->
        <div v-if="activity.action != 0">
          <a href="tel:" class="linkdetailsbox">
            <div class="linkleft">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 0 24 24"
                width="24px"
                fill="#007bff"
                class="link"
              >
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path
                  d="M6.54 5c.06.89.21 1.76.45 2.59l-1.2 1.2c-.41-1.2-.67-2.47-.76-3.79h1.51m9.86 12.02c.85.24 1.72.39 2.6.45v1.49c-1.32-.09-2.59-.35-3.8-.75l1.2-1.19M7.5 3H4c-.55 0-1 .45-1 1 0 9.39 7.61 17 17 17 .55 0 1-.45 1-1v-3.49c0-.55-.45-1-1-1-1.24 0-2.45-.2-3.57-.57-.1-.04-.21-.05-.31-.05-.26 0-.51.1-.71.29l-2.2 2.2c-2.83-1.45-5.15-3.76-6.59-6.59l2.2-2.2c.28-.28.36-.67.25-1.02C8.7 6.45 8.5 5.25 8.5 4c0-.55-.45-1-1-1z"
                />
              </svg>
            </div>
            <div class="linkright">
              <span
                class="link"
                v-if="$store.state.user.countryCode == 'DE'"
              >Klicke hier, um Jemanden anzurufen</span>
              <span class="link" v-if="$store.state.user.countryCode != 'DE'">Click here to call</span>
            </div>
          </a>
        </div>

        <!--alinkurl-->
        <div v-if="activity.alinkurl[$store.state.user.countryCode] != 0">
          <a
            v-bind:href="activity.alinkurl[$store.state.user.countryCode]"
            target="_blank"
            class="linkdetailsbox"
          >
            <div class="linkleft">
              <img
                v-bind:src="
                  require('@/assets/icons/' + activity.alinkicon + '.svg')
                "
                @error="setAltImg"
                class="link"
                alt="image"
              />
            </div>
            <div class="linkright">
              <span class="link">{{ activity.alinkteaser[$store.state.user.countryCode] }}</span>
            </div>
          </a>
        </div>

        <!--blinkurl-->
        <div v-if="activity.blinkurl[$store.state.user.countryCode] != 0">
          <a
            v-bind:href="activity.blinkurl[$store.state.user.countryCode]"
            target="_blank"
            class="linkdetailsbox"
          >
            <div class="linkleft">
              <img
                v-bind:src="
                  require('@/assets/icons/' + activity.blinkicon + '.svg')
                "
                @error="setAltImg"
                class="link"
                alt="image"
              />
            </div>
            <div class="linkright">
              <span class="link">{{ activity.blinkteaser[$store.state.user.countryCode] }}</span>
            </div>
          </a>
        </div>

        <!--bestlinks german-->
        <div v-if="activity.bestlinksde != 'none'">
          <div v-for="link in getLinkObjectsByLinkNames(activity.bestlinksde)" v-bind:key="link.id">
            <a
              v-if="link.linkurl[$store.state.user.countryCode] != 'none'"
              v-bind:href="link.linkurl[$store.state.user.countryCode]"
              target="_blank"
              class="linkdetailsbox"
            >
              <div class="linkleft">
                <img
                  v-bind:src="
                    'https://storage.googleapis.com/timetip-images/link-images/' +
                    link.linkimage +
                    '.png'
                  "
                  @error="setAltImg"
                  class="link"
                  alt="image"
                />
              </div>
              <div class="linkright">
                <span class="link">
                  {{
                    link.linktext[$store.state.user.countryCode]
                  }}
                </span>
              </div>
            </a>
          </div>
        </div>

        <!--bestlinks world-->
        <div v-if="activity.bestlinksworld != 'none'">
          <div
            v-for="link in getLinkObjectsByLinkNames(activity.bestlinksworld)"
            v-bind:key="link.id"
          >
            <a
              v-if="
                link.linktype == 'dynamic' &&
                link.linkurl[$store.state.user.countryCode] != 'none'
              "
              v-bind:href="
                link.linkurl[$store.state.user.countryCode] +
                activity.searchpayload[$store.state.user.countryCode] +
                link.linkurlend
              "
              target="_blank"
              class="linkdetailsbox"
            >
              <div class="linkleft">
                <img
                  v-bind:src="
                    'https://storage.googleapis.com/timetip-images/link-images/' +
                    link.linkimage +
                    '.png'
                  "
                  @error="setAltImg"
                  class="link"
                  alt="image"
                />
              </div>
              <div class="linkright">
                <span class="link">
                  {{
                    link.linktext[$store.state.user.countryCode]
                  }}
                </span>
              </div>
            </a>
            <a
              v-if="
                link.linktype == 'static' &&
                link.linkurl[$store.state.user.countryCode] != 'none'
              "
              v-bind:href="link.linkurl[$store.state.user.countryCode]"
              target="_blank"
              class="linkdetailsbox"
            >
              <div class="linkleft">
                <img
                  v-bind:src="
                    'https://storage.googleapis.com/timetip-images/link-images/' +
                    link.linkimage +
                    '.png'
                  "
                  @error="setAltImg"
                  class="link"
                  alt="image"
                />
              </div>
              <div class="linkright">
                <span class="link">
                  {{
                    link.linktext[$store.state.user.countryCode]
                  }}
                </span>
              </div>
            </a>
          </div>
        </div>

        <!--hashtaglinks-->
        <div v-if="activity.hashtaglinks != 'none' && activity.hashtag != 0">
          <div
            v-for="link in getLinkObjectsByLinkNames(activity.hashtaglinks)"
            v-bind:key="link.id"
          >
            <a
              v-if="
                link.linktype == 'dynamic' &&
                link.linkurl[$store.state.user.countryCode] != 'none'
              "
              v-bind:href="
                link.linkurl[$store.state.user.countryCode] +
                activity.hashtag +
                link.linkurlend
              "
              target="_blank"
              class="linkdetailsbox"
            >
              <div class="linkleft">
                <img
                  v-bind:src="
                    'https://storage.googleapis.com/timetip-images/link-images/' +
                    link.linkimage +
                    '.png'
                  "
                  @error="setAltImg"
                  class="link"
                  alt="image"
                />
              </div>
              <div class="linkright">
                <span class="link">
                  {{
                    link.linktext[$store.state.user.countryCode]
                  }}
                </span>
              </div>
            </a>
            <a
              v-if="
                link.linktype == 'static' &&
                link.linkurl[$store.state.user.countryCode] != 'none'
              "
              v-bind:href="link.linkurl[$store.state.user.countryCode]"
              target="_blank"
              class="linkdetailsbox"
            >
              <div class="linkleft">
                <img
                  v-bind:src="
                    'https://storage.googleapis.com/timetip-images/link-images/' +
                    link.linkimage +
                    '.png'
                  "
                  @error="setAltImg"
                  class="link"
                  alt="image"
                />
              </div>
              <div class="linkright">
                <span class="link">
                  {{
                    link.linktext[$store.state.user.countryCode]
                  }}
                </span>
              </div>
            </a>
          </div>
        </div>

        <!--buylinks-->
        <div v-if="activity.buylinks != 'none'">
          <!--Gets the link name Object of the selected activity and returns an Set with all data those link names from the link list-->
          <div v-for="link in getLinkObjectsByLinkNames(activity.buylinks)" v-bind:key="link.id">
            <a
              v-bind:href="
                link.linkurl[$store.state.user.countryCode] +
                activity.buypayload[$store.state.user.countryCode] +
                link.linkurlend
              "
              target="_blank"
              v-if="
                link.linktype == 'dynamic' &&
                activity.buypayload[$store.state.user.countryCode] != 0 &&
                link.linkurl[$store.state.user.countryCode] != 'none'
              "
              class="linkdetailsbox"
            >
              <div class="linkleft">
                <img
                  v-bind:src="
                    'https://storage.googleapis.com/timetip-images/link-images/' +
                    link.linkimage +
                    '.png'
                  "
                  @error="setAltImg"
                  class="link"
                  alt="image"
                />
              </div>
              <div class="linkright">
                <span
                  class="link"
                  v-if="activity.buyteaser[$store.state.user.countryCode] == 0"
                >{{ link.linktext[$store.state.user.countryCode] }}</span>
                <span
                  class="link"
                  v-if="activity.buyteaser[$store.state.user.countryCode] != 0"
                >{{ activity.buyteaser[$store.state.user.countryCode] }}</span>
              </div>
            </a>
            <a
              v-if="
                link.linktype == 'static' &&
                link.linkurl[$store.state.user.countryCode] != 'none'
              "
              v-bind:href="link.linkurl[$store.state.user.countryCode]"
              target="_blank"
              class="linkdetailsbox"
            >
              <div class="linkleft">
                <img
                  v-bind:src="
                    'https://storage.googleapis.com/timetip-images/link-images/' +
                    link.linkimage +
                    '.png'
                  "
                  @error="setAltImg"
                  class="link"
                  alt="image"
                />
              </div>
              <div class="linkright">
                <span class="link">
                  {{
                    link.linktext[$store.state.user.countryCode]
                  }}
                </span>
              </div>
            </a>
          </div>
        </div>
        <div class="space" style="font-size: 0.9rem; width: 100%">
          <a
            style="text-decoration: none; color: #2c3e50"
            href="mailto:fback&commat;timetip&period;app?subject=Feedback%20Timetip"
          >
            <div class="linkleft">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 0 24 24"
                width="24px"
                fill="#000000"
              >
                <path d="M0 0h24v24H0V0z" fill="none" opacity=".87" />
                <path
                  d="M21 8h-6.31l.95-4.57.03-.32c0-.41-.17-.79-.44-1.06L14.17 1 7.59 7.59C7.22 7.95 7 8.45 7 9v10c0 1.1.9 2 2 2h9c.83 0 1.54-.5 1.84-1.22l3.02-7.05c.09-.23.14-.47.14-.73v-2c0-1.1-.9-2-2-2zm0 4l-3 7H9V9l4.34-4.34L12.23 10H21v2zM1 9h4v12H1z"
                />
              </svg>
            </div>
            <div class="linkright">
              <span
                v-if="$store.state.user.countryCode == 'DE'"
              >Schick mir gerne eine Mail, falls du weitere Ideen hast</span>
              <span v-else>Please send me an email, if you have further ideas</span>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

  <!-- ---------- ---------- ---------- Code ---------- ---------- ---------- -->

<script>
/* eslint-disable */
import ShortTextComponent from "@/components/Details/ShortTextComponent.vue";

export default {
  components: { ShortTextComponent },
  props: {
    activityListPosition: Number,
  },
  data() {
    return {};
  },
  mounted() {
    console.log("LinksComponent mounted");
  },
  methods: {
    // Gets the link name Object of the selected activity and returns an Set with all data those link names from the link list
    // TODO: Actually quite ugly. I could change the list with the links to an object so I could access them directly
    getLinkObjectsByLinkNames: function (activityLinkNames) {
      var storeLinkArray = this.$store.state.storeLinkList;
      var linkSet = new Set([]);

      activityLinkNames.forEach(function (t) {
        linkSet.add(storeLinkArray.filter((entry) => entry.linkname == t)[0]);
      });
      return linkSet;
    },
    changeLinkView: function (event) {
      if (this.$store.state.linkView == "icon") {
        this.$store.state.linkView = "icondetails";
      } else {
        this.$store.state.linkView = "icon";
      }
    },
    setAltImg(event) {
      console.log("image could not be loaded");
      event.target.src = require("../../assets/timetip-logo.svg");
    },
  },
  computed: {
    activity() {
      return this.$store.state.storeActivityList[this.activityListPosition];
    },
  },
};
</script>

<style scoped>
.btn {
  color: #ffffff;
  font-weight: bold;
  border: none;
  display: inline-block;
  width: 100%;
  padding: 0.5rem;
  margin-left: 0rem;
  margin-right: 0rem;
  cursor: default;
}
.link {
  height: 2em;
}
.get2-title {
  font-weight: bold;
  color: #000000;
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
}
.pointer {
  cursor: pointer;
  font-weight: bold;
}
.linkdetailsbox {
  width: 100%;
  font-size: 0.9rem;
  display: block;
  padding-top: 1rem;
  padding-bottom: 1rem;
  /* background-color: #9fcdff; */
  border: none;
  display: inline-block;
  width: 100%;
  border-radius: 0rem;
  box-shadow: none;
  color: black;
  border-bottom: 1px solid #dee2e6 !important;
}

.linkleft {
  display: inline-block;
  width: 20%;
  text-align: center;
}
.linkright {
  display: inline-block;
  width: 80%;
  text-align: left;
  vertical-align: middle;
  padding-right: 1rem;
}
.inline {
  display: inline;
}
.alignleftonly {
  text-align: left;
}
.marginleft {
  margin-left: 1rem;
}
.paddingtop {
  padding-top: 0.5rem;
}
.va {
  margin-right: 0.5rem;
}
.spacetop {
  margin-top: 1rem;
}

.border-top {
  padding-top: 1.25rem;
}
</style>