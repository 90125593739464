<!-- ---------- ---------- ---------- HTML Structure ---------- ---------- ---------- -->
<template>
  <div>
    <!--Buttons on the bottom-->
    <div class="filterViewBottomContainer">
      <div class="container">
        <div class="middleButton" v-on:click="$router.push('/details')">
          <span class="apply">
            {{
              $store.state.appTexts.swipethrough[$store.state.user.countryCode]
            }}
            {{ $store.state.storeActivityListFiltered.length }}
            {{
              $store.state.appTexts.activities[$store.state.user.countryCode]
            }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

  <!-- ---------- ---------- ---------- Code ---------- ---------- ---------- -->

<script>
/* eslint-disable */
export default {
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {},
};
</script>

<style scoped>
.filterViewBottomContainer {
  position: fixed;
  bottom: 3rem;
  height: 5rem;
  width: 100%;
  overflow: hidden;
  pointer-events: none;
  z-index: 91;
}

.container {
  max-width: 50rem;
  display: inline-flex;
  justify-content: space-between;
}

.middleButton {
  position: relative;
  width: 100%;
  color: #ffffff;
  padding: 1.75rem 1.75rem;
  background-color: #007bff;
  border-radius: 1rem;
  cursor: pointer;
  pointer-events: all;
  margin: 0rem 0.5rem 0rem;
}
.apply {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
</style>