<!-- ---------- ---------- ---------- HTML Structure ---------- ---------- ---------- -->
<template>
  <div>
    <!--Buttons on the bottom-->
    <div class="filterViewBottomContainer"
    v-bind:class="{ iphonefooter: this.$store.state.browserName == 'safari' && safariStandalone }">
      <div class="container">
        <!-- One Button -->
        <div
          style="
            color: #ffffff;
            background-color: #F39EC5;
            border-radius: 1rem;
            cursor: pointer;
            pointer-events: all;
            display: flex;
            justify-content: space-between;
            height: 2.5rem;
            padding: 0rem 0rem 0rem 0.5rem;
          "
          v-on:click="
            ($store.state.filterClicked = true), $router.push('/filter')
          "
          v-bind:class="{
            nopointer: $store.state.explanationState != 1,
            rotatecenter: $store.state.lastSlide == true,
          }"
        >
          <div style="width: 2rem; height: 100%; position: relative">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              enable-background="new 0 0 24 24"
              height="18px"
              viewBox="0 0 24 24"
              width="18px"
              fill="#FFFFFF"
            >
              <g>
                <path d="M0,0h24 M24,24H0" fill="none" />
                <path
                  d="M7,6h10l-5.01,6.3L7,6z M4.25,5.61C6.27,8.2,10,13,10,13v6c0,0.55,0.45,1,1,1h2c0.55,0,1-0.45,1-1v-6 c0,0,3.72-4.8,5.74-7.39C20.25,4.95,19.78,4,18.95,4H5.04C4.21,4,3.74,4.95,4.25,5.61z"
                />
                <path d="M0,0h24v24H0V0z" fill="none" />
              </g>
            </svg>
          </div>
          <div
            style="
              height: 100%;
              display: flex;
              align-items: center;
              padding: 0rem 1rem 0rem 0rem;
            "
          >
            <span v-if="$store.state.user.countryCode == 'DE'">
              Filter</span
            >
            <span v-else>Filter</span>
          </div>
        </div>

        <!--Right Button-->

        <div
          class="rightButton"
          v-on:click="$router.push('/details')"
          v-if="false"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
            fill="#ffffff"
          >
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path
              d="M10.59 9.17L5.41 4 4 5.41l5.17 5.17 1.42-1.41zM14.5 4l2.04 2.04L4 18.59 5.41 20 17.96 7.46 20 9.5V4h-5.5zm.33 9.41l-1.41 1.41 3.13 3.13L14.5 20H20v-5.5l-2.04 2.04-3.13-3.13z"
            />
          </svg>
          <span class="apply" v-if="false">
            {{
              $store.state.appTexts.swipethrough[$store.state.user.countryCode]
            }}
            {{ $store.state.storeActivityListFiltered.length }}
            {{
              $store.state.appTexts.activities[$store.state.user.countryCode]
            }}
          </span>
        </div>

        <div
          v-if="false"
          class="middleButton"
          v-on:click="$router.push('/favorites')"
          v-bind:class="{
            middleButtonInvisible:
              $store.state.storeActivityListFavorites.length == 0,
            nopointer: $store.state.firstVisit,
          }"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
            fill="#FFFFFF"
            v-bind:class="{
              middleButtonInvisible:
                $store.state.storeActivityListFavorites.length == 0,
            }"
          >
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path
              d="M16.5 3c-1.74 0-3.41.81-4.5 2.09C10.91 3.81 9.24 3 7.5 3 4.42 3 2 5.42 2 8.5c0 3.78 3.4 6.86 8.55 11.54L12 21.35l1.45-1.32C18.6 15.36 22 12.28 22 8.5 22 5.42 19.58 3 16.5 3zm-4.4 15.55l-.1.1-.1-.1C7.14 14.24 4 11.39 4 8.5 4 6.5 5.5 5 7.5 5c1.54 0 3.04.99 3.57 2.36h1.87C13.46 5.99 14.96 5 16.5 5c2 0 3.5 1.5 3.5 3.5 0 2.89-3.14 5.74-7.9 10.05z"
            />
          </svg>
        </div>

        <div
          v-if="false"
          class="middleButton"
          v-on:click="resetFilters"
          v-bind:class="{
            middleButtonInvisible: true,
          }"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="#FFFFFF"
            class="bi bi-arrow-counterclockwise"
            viewBox="0 0 16 16"
            v-bind:class="{
              middleButtonInvisible: true,
            }"
          >
            <path
              fill-rule="evenodd"
              d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z"
            />
            <path
              d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z"
            />
          </svg>
        </div>

        <!--
        <div
          class="rightButton"
          v-on:click="$router.push('/')"
          v-if="false"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            enable-background="new 0 0 24 24"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
            fill="#FFFFFF"
          >
            <g>
              <path d="M0,0h24 M24,24H0" fill="none" />
              <path
                d="M7,6h10l-5.01,6.3L7,6z M4.25,5.61C6.27,8.2,10,13,10,13v6c0,0.55,0.45,1,1,1h2c0.55,0,1-0.45,1-1v-6 c0,0,3.72-4.8,5.74-7.39C20.25,4.95,19.78,4,18.95,4H5.04C4.21,4,3.74,4.95,4.25,5.61z"
              />
              <path d="M0,0h24v24H0V0z" fill="none" />
            </g>
          </svg>
        </div>
        -->

        <div v-if="false" class="rightButton" v-on:click="$router.push('/')">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            enable-background="new 0 0 24 24"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
            fill="#FFFFFF"
          >
            <g>
              <path d="M0,0h24 M24,24H0" fill="none" />
              <path
                d="M7,6h10l-5.01,6.3L7,6z M4.25,5.61C6.27,8.2,10,13,10,13v6c0,0.55,0.45,1,1,1h2c0.55,0,1-0.45,1-1v-6 c0,0,3.72-4.8,5.74-7.39C20.25,4.95,19.78,4,18.95,4H5.04C4.21,4,3.74,4.95,4.25,5.61z"
              />
              <path d="M0,0h24v24H0V0z" fill="none" />
            </g>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

  <!-- ---------- ---------- ---------- Code ---------- ---------- ---------- -->

<script>
/* eslint-disable */
import ResetFiltersService from "@/services/ResetFiltersService.js";

export default {
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {
    resetFilters: function () {
      ResetFiltersService.resetFilters();
    },
  },
  computed: {
    // Create list of clicked filters
    clickedFilters() {
      var filterList = new Set([]);
      for (const [key, value] of Object.entries(this.$store.state.filters)) {
        // For each entry in "age" - For example "toddler"
        value.forEach(function (x) {
          // Check, if "toddler" is selected
          if (x.selected == true) {
            filterList.add(x);
          }
        });
      }
      return filterList;
    },
    safariStandalone() {
      return window.navigator.standalone
    },
  },
};
</script>

<style scoped>
.filterViewBottomContainer {
  position: fixed;
  bottom: 2.5rem;
  padding-top: 1rem;
  height: 6rem;
  width: 100%;
  overflow: hidden;
  pointer-events: none;
  z-index: 91;
}
.iphonefooter {
  margin-bottom: env(safe-area-inset-bottom, 1rem) !important;
}
.container {
  max-width: 50rem;
  display: inline-flex;
  justify-content: flex-end; /*space-between;*/
}
.container {
  display: inline-flex;
  justify-content: center; /*space-between;*/
  max-width: 50rem;
}
.containermiddle {
  justify-content: center; /*space-between;*/
}

.apply {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
.leftButton {
  position: relative;
  color: #ffffff;
  padding: 1.75rem 1.75rem;
  background-color: #007bff;
  border-radius: 1rem;
  cursor: pointer;
  pointer-events: all;
  margin-left: 0.5rem;
}

.middleButton {
  position: relative;
  color: #ffffff;
  padding: 1.75rem 1.75rem;
  background-color: #e83e8c;
  border-radius: 1rem;
  cursor: pointer;
  pointer-events: all;
}

.rightButton {
  position: relative;
  color: #ffffff;
  padding: 1.75rem 1.75rem;
  background-color: #007bff; /*#20c997;*/
  border-radius: 1rem;
  cursor: pointer;
  pointer-events: all;
  margin-right: 0.5rem;
}
svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.middleButtonInvisible {
  background: transparent;
  pointer-events: none;
  fill: transparent;
}
</style>