/* eslint-disable */
import store from '@/store/index.js'
import FireStoreService from "@/firebase/FireStoreService.js";

export default {
  updateFavorites(uuid, name, favType) {
    if (uuid != "none") {
      var selectedActivityuuid = uuid;
      var selectedActivityName = name
      console.log("UpdateFavorites: '" + selectedActivityuuid + "'")

      // Check, if Activity is already marked as favorite. If yes, delete. Otherwise add to favoritesObject
      if (Object.keys(store.state.favoritesObject).includes(selectedActivityuuid) && favType == 'noheart') {
        delete store.state.favoritesObject[selectedActivityuuid]
      }
      else {
        store.state.favoritesObject[selectedActivityuuid] = { name: selectedActivityName, favType: favType }
      }
      console.log(store.state.favoritesObject)

      // Only works, if user logged in
      if (store.state.nutzer) {
        console.log("FireStoreService.setFS() called from updateFavorites")
        FireStoreService.setFS();
      }

    }
    // Create storeActivityListFavorites
    store.state.storeActivityListFavorites = [];
    store.state.storeActivityList.forEach(function (activityItem) {
      if (Object.keys(store.state.favoritesObject).includes(activityItem.uuid)) {
        store.state.storeActivityListFavorites.push(activityItem);
      }
    })

    // Set Local Storage
    localStorage.setItem('favoritesObject', JSON.stringify(store.state.favoritesObject));
    console.log(JSON.stringify(store.state.favoritesObject))
  }
}