/* eslint-disable */
import store from '@/store/index.js'
import { doc, setDoc, getDoc, getFirestore, onSnapshot, collection } from "firebase/firestore";
import UpdateFavoritesService from "@/services/UpdateFavoritesService.js";

const db = getFirestore();


export default {

    // TODO: This is also done by the event Listener in the beginning and one call too much
    async getFSMergeFavoritesSetFS() {
        console.log("FireStoreService.login")
        // get User
        console.log("BACKEND CALL getDoc")
        var fireStoreUserData = await getDoc((doc(db, "users", store.state.nutzer.email)))
        var fireStoreUserDataObject = fireStoreUserData.data()
        console.log(fireStoreUserDataObject)

        // Merge Favorites
        if (fireStoreUserDataObject) {
            var favoritesChanged = false
            for (const [fireKey, fireValue] of Object.entries(fireStoreUserDataObject.favorites)) {
                var fireKeyInStoreKey = false
                for (const [storeKey, storeValue] of Object.entries(store.state.favoritesObject)) {
                    if (storeKey == fireKey) {
                        fireKeyInStoreKey = true
                    }
                }
                if (!fireKeyInStoreKey) {
                    store.state.favoritesObject[fireKey] = fireValue
                    favoritesChanged = true
                    console.log(fireKey)
                    console.log("favoritesChanged")
                }
            }
            if (Object.keys(fireStoreUserDataObject.favorites).length != Object.keys(store.state.favoritesObject).length) { favoritesChanged = true }
            if (favoritesChanged) { UpdateFavoritesService.updateFavorites("none") }
        }
        // setFS data in FireStore if the user was just created or of the favorites changed
        if (!fireStoreUserDataObject || favoritesChanged) {
            await this.setFS()
        }
        else { console.log("User email already existed in FireStore and favorites did not change. No need to setDoc") }
    },

    // Listen to FS Changes
    async listenForFSChanges() {
        if (store.state.nutzer) {
            console.log("listenForFSChanges")
            const unsubscibeOnSnapshot = onSnapshot(doc(db, "users", store.state.nutzer.email), (doc) => {
                console.log("onSnapshot")
                console.log("BACKEND CALL")
                if (doc.data()) {
                    console.log("Current favorites: ", doc.data().favorites);
                    store.state.favoritesObject = doc.data().favorites
                    UpdateFavoritesService.updateFavorites("none")
                }
            });
            store.state.unsubscibeOnSnapshot = unsubscibeOnSnapshot
        }
    },

    // Set FS
    async setFS() {
        console.log("FireStoreService.setFS")
        console.log("BACKEND CALL setDoc")
        await setDoc(doc(db, "users", store.state.nutzer.email), {
            email: store.state.nutzer.email,
            uid: store.state.nutzer.uid,
            favorites: store.state.favoritesObject,
            checkboxDataPrivacy: store.state.checkboxDataPrivacy,
            checkboxMails: store.state.checkboxMails,

        });
        console.log("user data stored in Backend")
        console.log({
            email: store.state.nutzer.email,
            uid: store.state.nutzer.uid,
            favorites: store.state.favoritesObject,
            checkboxDataPrivacy: store.state.checkboxDataPrivacy,
            checkboxMails: store.state.checkboxMails,

        })
    },
    // get FS
    async getFS() {
        console.log("BACKEND CALL")
        console.log("FireStoreService.getFS")
        var x = await getDoc((doc(db, "users", store.state.nutzer.email)))
        console.log(x.data().favorites)
        return x.data()
    },
}