<template>
  <div>
    <!--Clicked Filters-->
    <div class="selectedFilterContainer" v-if="clickedFilters.size != 0 || clickedAgeTimeCostList.length != 0"
      v-bind:class="{
        // ifnotfilterorlistview: $router.currentRoute.value.name != 'FilterView' && !isDiscoveryView,
        discoveryview: isDiscoveryView,
        detailsview: $router.currentRoute.value.name == 'DetailsView'
      }">
      <!--Reset Filter icon-->
      <div class="resetfilterbutton"
        v-if="(clickedFilters.size != 0 || ($store.state.ageActive && !$store.state.ageSave) || $store.state.costActive || $store.state.timeActive) && !isDiscoveryView"
        v-on:click="resetFilters">
        <svg xmlns="http://www.w3.org/2000/svg" height="12px" viewBox="0 0 24 24" width="12px" fill="#ffffff"
          class="bi bi-arrow-counterclockwise resetbutton">
          <g>
            <rect fill="none" height="24" width="24" />
          </g>
          <g>
            <g>
              <path
                d="M16.95,6l-3.57,4.55l1.43,1.43c1.03-1.31,4.98-6.37,4.98-6.37C20.3,4.95,19.83,4,19,4H6.83l2,2H16.95z" />
              <path
                d="M2.81,2.81L1.39,4.22L10,13v6c0,0.55,0.45,1,1,1h2c0.55,0,1-0.45,1-1v-2.17l5.78,5.78l1.41-1.41L2.81,2.81z" />
            </g>
          </g>
        </svg>
      </div>
      <!--Activity Number-->
      <!-- <div class="selectedFilter notselected6" style="background: #007bff" v-on:click="$router.push('/activitylist')"
        v-if="$route.name == 'DiscoverView'">
        <span> {{ "" + animatedNumber }}</span>
        <span v-if="$store.state.user.countryCode == 'DE'"> Aktivitäten</span>
        <span v-else> activities </span>
      </div> -->
      <!--Add Filter icon-->
      <div class="resetfilterbutton" style="background: #7FBDFF"
        v-if="$route.name != 'FilterView' && $route.name != 'DiscoverView'" v-on:click="$router.push('/filter')">
        <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="#FFFFFF">
          <path d="M0 0h24v24H0V0z" fill="none" />
          <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
        </svg>
      </div>
      <div v-for="filter in clickedFilters" v-bind:key="filter.id" class="selectedFilter" v-on:click="clickFilter"
        v-bind:clickedName="filter.filterkey" v-bind:clickedParent="filter.parent"
        v-bind:clickedSelected="filter.selected" v-bind:clickedCount="filter.count" v-bind:clickedId="filter.id"
        v-bind:class="{
          notselected6: filter.selected,
          lastanswerwithvalues: filter.lastAnswerWithValues,
          countZero: filter.count < 1 && !filter.selected,
        }">
        <span class="noclick" v-if="$store.state.user.countryCode == 'DE'">
          {{
              filter.namede
          }}
        </span>

        <span class="noclick" v-else>{{ filter.nameen }}</span>
      </div>

      <!--AGE-->
      <div v-if="$store.state.ageActive && !isDiscoveryView" class="selectedFilter notselected6"
        v-on:click="clickAgeTimeCostList" v-bind:name="'age'">
        {{
            $store.state.appTexts.age[$store.state.user.countryCode] +
            ": " +
            $store.state.ageMin
        }}
        <span class="noclick" v-if="$store.state.ageMin != $store.state.ageMax">{{ " - " + $store.state.ageMax }}</span>
      </div>

      <!--TIME-->
      <div v-if="$store.state.timeActive && (!isDiscoveryView || discoveryViewQuestion == 'time')"
        class="selectedFilter notselected6" v-on:click="clickAgeTimeCostList" v-bind:name="'time'">
        <span class="noclick">
          {{
              $store.state.appTexts.time[$store.state.user.countryCode] + ": "
          }}
        </span>
        <div v-if="
          $store.state.timeSliderValueMin == $store.state.timeSliderValueMax
        " style="display: inline; pointer-events: none">
          <span class="noclick" v-if="$store.state.user.countryCode == 'DE'">
            {{
                $store.state.filters.time[$store.state.timeSliderValueMin].namede
            }}
          </span>
          <span class="noclick" v-if="$store.state.user.countryCode == 'EN'">
            {{
                $store.state.filters.time[$store.state.timeSliderValueMax].nameen
            }}
          </span>
        </div>
        <div v-else style="display: inline; pointer-events: none">
          <span class="noclick" v-if="$store.state.user.countryCode == 'DE'">
            {{
                $store.state.filters.time[$store.state.timeSliderValueMin].namede +
                " - " +
                $store.state.filters.time[$store.state.timeSliderValueMax].namede
            }}
          </span>
          <span class="noclick" v-if="$store.state.user.countryCode == 'EN'">
            {{
                $store.state.filters.time[$store.state.timeSliderValueMax].nameen +
                " - " +
                $store.state.filters.time[$store.state.timeSliderValueMax].nameen
            }}
          </span>
        </div>
      </div>

      <!--COST-->
      <div v-if="$store.state.costActive && !isDiscoveryView" class="selectedFilter notselected6"
        v-on:click="clickAgeTimeCostList" v-bind:name="'cost'">
        <span class="noclick">
          {{
              $store.state.appTexts.cost[$store.state.user.countryCode] + ": "
          }}
        </span>
        <div v-if="
          $store.state.costSliderValueMin == $store.state.costSliderValueMax
        " style="display: inline; pointer-events: none">
          <span class="noclick" v-if="$store.state.user.countryCode == 'DE'">
            {{
                $store.state.filters.cost[$store.state.costSliderValueMin].namede
            }}
          </span>
          <span class="noclick" v-if="$store.state.user.countryCode == 'EN'">
            {{
                $store.state.filters.cost[$store.state.costSliderValueMax].nameen
            }}
          </span>
        </div>
        <div v-else style="display: inline; pointer-events: none">
          <span class="noclick" v-if="$store.state.user.countryCode == 'DE'">
            {{
                $store.state.filters.cost[$store.state.costSliderValueMin].namede +
                " - " +
                $store.state.filters.cost[$store.state.costSliderValueMax].namede
            }}
          </span>
          <span class="noclick" v-if="$store.state.user.countryCode == 'EN'">
            {{
                $store.state.filters.cost[$store.state.costSliderValueMax].nameen +
                " - " +
                $store.state.filters.cost[$store.state.costSliderValueMax].nameen
            }}
          </span>
        </div>
      </div>

      <!--Todo: Refactor clickedAgeTimeCostList-->
      <!--
        <div
          v-for="atc in clickedAgeTimeCostList"
          v-bind:key="atc.id"
          v-bind:name="atc"
          class="selectedFilter"
          v-on:click="clickAgeTimeCostList"
          v-bind:clickedName="atc.name"
          v-bind:class="{
            selected4: atc == 'age',
            selected2: atc == 'time' || atc == 'cost',
          }"
        >
          <span class="noclick">
            {{ $store.state.appTexts[atc][$store.state.user.countryCode] }}
          </span>
        </div>
      -->

    </div>
  </div>
</template>    

<script>
/* eslint-disable */
import UpdateActivitiesService from "@/services/UpdateActivitiesService.js";
import ClickFilterService from "@/services/ClickFilterService.js";
import ResetFiltersService from "@/services/ResetFiltersService.js";

export default {
  components: {},
  data() {
    return {
      tweenedNumber: this.$store.state.storeActivityListFiltered.length,
    };
  },
  props: {
    isDiscoveryView: Boolean,
    discoveryViewQuestion: String,
  },
  mounted() { },
  methods: {
    resetFilters: function () {
      ResetFiltersService.resetFilters();
    },
    forceRerender(input) {
      if (
        input == "heart" &&
        Object.keys(this.$store.state.favoritesObject).length != 0
      ) {
      } else {
        this.componentKey += 1;
      }
    },
    clickFilter: function (event) {
      var cQuestion = event.target.getAttribute("clickedParent");
      var cAnswer = event.target.getAttribute("clickedName");
      ClickFilterService.clickFilter(cQuestion, cAnswer);
      this.forceRerender();
    },
    clickAgeTimeCostList: function (event) {
      console.log(event.target.getAttribute("name"));
      var x = event.target.getAttribute("name");
      switch (x) {
        case "age":
          console.log("AGE");
          this.$store.state.ageActive = false;
          break;
        case "time":
          console.log("TIME");
          delete this.$store.state.clickedAnswers["time"];
          this.$store.state.timeActive = false;
          this.$store.state.timeSliderValueMin = 0;
          this.$store.state.timeSliderValueMax = 6;
          this.$store.state.filters.time.forEach(function (entry) {
            entry.selected = false;
          });
          break;
        case "cost":
          console.log("COST");
          this.$store.state.filters.cost.selected = false;
          this.$store.state.costActive = false;
          this.$store.state.costSliderValueMin = 1;
          this.$store.state.costSliderValueMax = 4;
          delete this.$store.state.clickedAnswers["cost"];
          this.$store.state.filters.cost.forEach(function (entry) {
            entry.selected = false;
          });
          break;
      }
      UpdateActivitiesService.updateActivities();

      ClickFilterService.clickFilter("onlycheckcountzeroandresethistory");
      this.forceRerender();
    },
  },
  watch: {
    activityCount() {
      gsap.to(this.$data, { duration: 0.6, tweenedNumber: this.activityCount });
    },
  },
  computed: {
    activityCount() {
      return this.$store.state.storeActivityListFiltered.length;
    },
    animatedNumber() {
      return this.tweenedNumber.toFixed(0);
    },
    // Create list of clicked filters
    clickedFilters() {
      var filterList = new Set([]);
      var that = this
      for (const [key, value] of Object.entries(this.$store.state.filters)) {
        // For each entry in "age" - For example "toddler"
        value.forEach(function (x) {
          // Check, if "toddler" is selected
          if (
            x.selected == true &&
            x.parent != "cost" &&
            x.parent != "time" &&
            x.parent != "age" &&
            (!that.isDiscoveryView || that.discoveryViewQuestion == x.parent)
          ) {
            filterList.add(x);
          }
        });
      }
      return filterList;
    },
    clickedAgeTimeCostList() {
      var ageTimeCostList = [];

      if (this.$store.state.ageActive) {
        ageTimeCostList.push("age");
      }
      if (this.$store.state.timeActive) {
        ageTimeCostList.push("time");
      }
      if (this.$store.state.costActive) {
        ageTimeCostList.push("cost");
      }
      return ageTimeCostList;
    },
  },
};
</script>

<style scoped>
.container {
  /*max-width: 40vh;*/
  max-width: 50rem;
}

.selected {
  background-color: #20c997;
  color: #ffffff;
  border-radius: 0.25rem;
}

.selectedFilterContainer {
  font-size: 0.9rem;
  padding: 0rem 0.5rem 0rem 0.5rem;
  display: flex;
  flex-wrap: wrap-reverse;
  flex-direction: row-reverse;
  justify-content: center;
}

.discoveryview {
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  padding: 0rem 0rem 0rem 0rem;
}

.detailsview {
  /* flex-wrap: wrap; */
}

.ifnotfilterview {
  padding-top: 0.5rem;
}

.selectedFilter {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  margin: 0.1rem;
  padding: 0.075rem 0.5rem 0rem 0.5rem;
  border-radius: 0.6rem;
  border: 0;
  cursor: pointer;
}

.noclick {
  pointer-events: none;
}

.resetfilterbutton {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  margin: 0.1rem;
  width: 2rem;
  border-radius: 0.6rem;
  border: 0;
  cursor: pointer;
  background: #e83e8c;
  color: white;
}
</style>