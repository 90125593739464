/* eslint-disable */

import axios from 'axios'
import store from '@/store/index.js'

export default {
  getFilterCombos() {
    console.log("getFilterCombos is executed")


    var filterCombos = new Object()
    if (Object.keys(store.state.filterCombosNew).length == 0) {
      axios.get('/data.json')
        .then(response => (
          parseData(response.data.filtercombos) // executes function parseData with the input response.data.feed.entry (CB)
        ))
    }
    else {
      parseData()
    }

    function parseData(entries) {
      // console.log(entries)

      var filterCombos2 = {}
      var titleSet = new Set();
      // Create a Set of all titles / carousels
      for (const [key, value] of Object.entries(entries)) {
        if (value.title != "") { titleSet.add(value.title) }
      }
      // create title / carousel objects
      titleSet.forEach(function (title) {
        filterCombos2[title] = []
      })

      //////////// Get filterCombos New
      for (const [key, value] of Object.entries(entries)) {

        // Is needed to filter out list items of the database without an id
        if (key != "") {
          // Set create Objects in filterCombos - Todo: Change to Array
          var helper = {}
          helper.dComboType = value.dcombotype
          helper.dComboKey = value.dcombokey
          helper.dComboName = { DE: value.dcombonamede, EN: value.dcombonameen };
          helper.dComboImageName = value.dcomboimagename
          helper.dComboFilters = new Object()
          helper.season = value.season.split(',')
          helper.weekday = value.weekday.split(',')
          helper.daytime = value.daytime.split(',')
          helper.condition = value.condition.split(',')
          if (value.dfilterq1 != "") { helper.dComboFilters[value.dfilterq1] = value.dfiltera1.split(',') }
          if (value.dfilterq2 != "") { helper.dComboFilters[value.dfilterq2] = value.dfiltera2.split(',') }
          if (value.dfilterq3 != "") { helper.dComboFilters[value.dfilterq3] = value.dfiltera3.split(',') }

          helper.title = value.title // "1" "2"
          helper.titleText = { DE: value.titletextde, EN: value.titletexten } // "Aktuelle Inspirationen"

          if (value.title != "situation") {
            filterCombos2[value.title].push(helper) // ###1
          }
          // adapt filterCombos to time and weather conditions
          if (value.title == "situation") {
            var season = store.state.date.season
            var weekday = store.state.date.weekday
            var daytime = store.state.date.daytime
            var condition = store.state.weather.condition // fits to special = rain, good, snow

            var x = true
            if (!helper.season.includes(season) && helper.season != "") { x = false }
            if (!helper.weekday.includes(weekday) && helper.weekday != "") { x = false }
            if (!helper.daytime.includes(daytime) && helper.daytime != "") { x = false }
            if (!helper.condition.includes(condition) && helper.condition != "") { x = false }
            if (x) {
              filterCombos2[value.title].push(helper) // ###2
            }
          }
        }
      };

      // Calculate ActivityCount NEW ///////////////////////////////////////////////////////////////////////////////
      for (const [key, value] of Object.entries(filterCombos2)) {
        value.forEach(function (dComboValue) {

          var activityListFiltered = [];

          store.state.storeActivityList.forEach(function (activity) {
            var addActivity = true;

            // for all parents with children (setting: ["inside","outside"], pcount: [])
            for (const [dQuestion, dAnswer] of Object.entries(dComboValue.dComboFilters)) {
              if (dQuestion != "agewp" && dQuestion != "ageend") {
                var oneAnswerInQuestionTrue = false

                var stopIterating = false
                // for all parents with children ["inside","outside"]
                dAnswer.forEach(function (answer) {
                  if (!stopIterating) {
                    if (activity[dQuestion].includes(answer) &&
                      !(dQuestion == "target" && store.state.itarget) &&
                      !(dQuestion == "special" && store.state.ispecial) &&
                      !(dQuestion == "typical" && store.state.itypical) &&
                      !(dQuestion == "dayweektime" && store.state.idayweektime)
                    ) {
                      oneAnswerInQuestionTrue = true;
                      stopIterating = true
                    }
                    //special case for question == "sport" || "game"
                    else if (!oneAnswerInQuestionTrue && (dQuestion == "sport" && !activity.interest.includes("sports"))) {
                      oneAnswerInQuestionTrue = true;
                      stopIterating = true
                    }
                    //else if ((dQuestion == "game" && !activity.interest.includes("games"))) { oneAnswerInQuestionTrue = true; stopIterating = true }
                    //Special Intersection Logic for target and special (also a few lines above)
                    else if (
                      dQuestion == "target" && store.state.itarget ||
                      dQuestion == "special" && store.state.ispecial ||
                      dQuestion == "typical" && store.state.itypical ||
                      dQuestion == "dayweektime" && store.state.idayweektime
                    ) {
                      if ((activity[dQuestion].includes(answer))) { oneAnswerInQuestionTrue = true }
                      else { oneAnswerInQuestionTrue = false; stopIterating = true }
                    }
                  }
                })
                if (!oneAnswerInQuestionTrue) { addActivity = false; break }
              }
            }

            // Todo: Add code and logic for age
            // Age Slider Exception: Do not add activities to activityListFiltered if not in to Age Slider (same as below)
            if (addActivity && (Object.keys(dComboValue.dComboFilters).includes("agewp") || Object.keys(dComboValue.dComboFilters).includes("ageend"))
            ) {
              // var agestart = dComboValue.dComboFilters.agewp[0]
              // var ageend = dComboValue.dComboFilters.ageend[0]
              // var ageMin = store.state.ageMin
              // var ageMax = store.state.ageMax
              // if (!store.state.ageRangeSliderSwitch) {
              //   if (!(agestart <= ageMin && ageMin <= ageend)) {
              //     addActivity = false
              //   }
              // }
              // if (store.state.ageRangeSliderSwitch) {
              //   if (!((ageMin <= agestart && agestart <= ageMax) || (ageMin <= ageend && ageend <= ageMax)
              //     || (agestart <= ageMin && ageMin <= ageend) || (agestart <= ageMax && ageMax <= ageend)
              //   )) {
              //     addActivity = false
              //   }
              // }
            }

            // ShowIf
            if (addActivity) {
              for (const [dQuestion, dAnswer] of Object.entries(dComboValue.dComboFilters)) {
                if (dAnswer.includes("now")) {
                  if (
                    (!(activity.showifdaytime.includes(store.state.date.daytime) || activity.showifdaytime == "")) ||
                    (!(activity.showifweekday.includes(store.state.date.weekday) || activity.showifweekday == "")) ||
                    (!(activity.showifseason.includes(store.state.date.season) || activity.showifseason == "")) ||
                    (!(activity.showifweather.includes(store.state.weather.condition) || activity.showifweather == ""))
                  ) {
                    // if (dComboValue.dComboKey == 'spring') {
                    //   if (activity.activityde == 'Besuche einen Reiterhof') {
                    //     // Debugging
                    //   }
                    // }
                    addActivity = false
                  }
                }

                // Do not show specific winter activities in spring or summer
                if ((store.state.date.season == "spring" || store.state.date.season == "summer") && activity.showifseason.includes("winter")) {
                  // unless "winter" is selected
                  if (!(dQuestion == "season" && dAnswer.includes("winter"))) {
                    addActivity = false
                  }
                }
              }
            }

            if (addActivity) {
              activityListFiltered.push(activity);
            }
          })

          dComboValue.dCount = activityListFiltered.length;
        });
      }
      var activityCombo = filterCombos2.activities[0]
      delete filterCombos2.activities

      // Generate idList with activities NEW ///////////////////////////////////////////////////////////////////////////////
      activityCombo.dComboIdList = [];
      var activityListFiltered = [];

      store.state.storeActivityList.forEach(function (activity) {
        var addActivity = true;

        // for all parents with children (setting: "inside")
        for (const [dQuestion, dAnswer] of Object.entries(activityCombo.dComboFilters)) {
          dAnswer.forEach(function (answer) {
            if (dQuestion != "agewp" && dQuestion != "ageend") {
              if (
                !activity[dQuestion].includes(answer) ||
                activityCombo.dComboIdList.includes(activity.listPosition)
              ) {
                addActivity = false;
              }
            } else {
              if (!((activity.agewp <= answer && answer <= activity.ageend) || (activity.agewp <= answer && answer <= activity.ageend)
              )) {
                addActivity = false;
              }
            }
          })
        }
        if (addActivity) {
          activityListFiltered.push(activity);
        }
      });
      for (var i = 0; i < 7; i++) {

        if (activityListFiltered.length != 0) {
          activityCombo.dComboIdList.push(
            activityListFiltered[i].listPosition
          )
        }

        // Add Random activities
        // if (activityListFiltered.length != 0) {
        //   activityCombo.dComboIdList.push(
        //     activityListFiltered[
        //       Math.floor(Math.random() * activityListFiltered.length)
        //     ].listPosition
        //   );
        // }
        // if (i == 0) {
        //   activityCombo.dCount = activityListFiltered.length;
        // }
      }

      store.state.filterCombosNew = filterCombos2
      store.state.activityCombo = activityCombo
      console.log("store.state.filterCombosNew")
      console.log(store.state.filterCombosNew)
      console.log("store.state.activityCombo")
      console.log(store.state.activityCombo)
    }
  }
}