<template>
  <div v-if="!$store.state.ageRangeSliderSwitch">
    <AgeSliderComponent :key="rerenderAgeKey" :deactivateAgeFilter="deactivateAgeFilter" />
  </div>
  <div v-if="$store.state.ageRangeSliderSwitch">
    <AgeSliderComponentTwo :key="rerenderAgeKey" :deactivateAgeFilter="deactivateAgeFilter" />
  </div>
  <div class="switch">
    <AgeSwitchComponent />
  </div>
</template>
<script>
/* eslint-disable */
import AgeSliderComponent from "@/components/Filters/AgeSliderComponent.vue";
import AgeSliderComponentTwo from "@/components/Filters/AgeSliderComponentTwo.vue";
import AgeSwitchComponent from "@/components/Filters/AgeSwitchComponent.vue";
export default {
  components: { AgeSliderComponent, AgeSliderComponentTwo, AgeSwitchComponent },
  data() {
    return { rerenderAgeKey: 0 };
  },
  props: {
    deactivateAgeFilter: Boolean,
  },
  methods: {},
  watch: {
    ageFixMax() {
      this.rerenderAgeKey += 1;
    },
    ageFixMin() {
      this.rerenderAgeKey += 1;
    },
    ageActive() {
      if (!this.$store.state.ageActive) {
        this.rerenderAgeKey += 1;
      }
    },
  },
  computed: {
    ageFixMin() {
      // console.log("ageFixMin CP = " + this.$store.state.ageFixMin);
      return this.$store.state.ageFixMin;
    },
    ageFixMax() {
      // console.log("ageFixMax CP = " + this.$store.state.ageFixMax);
      return this.$store.state.ageFixMax;
    },
    ageActive() {
      // console.log("ageActive Computed Property changes");
      return this.$store.state.ageActive;
    },
  },
};
</script>
<style scoped>
.switch {
  margin: 0rem 1rem 0rem 1rem;
}
</style>