<template>
  <div style="margin-top: 1rem; margin-bottom: 4rem">
    <div>
      <span v-if="$store.state.user.countryCode == 'DE'">Möchtest du deinen Account wirklich löschen?</span>
      <span v-else>Do you really want to delete your accout? </span>
    </div>
    <div style="display:flex; justify-content: center">
      <div v-on:click="deleteCurrentUser"
        style="color: white;background: #dc3545;border-radius: 0.6rem;box-shadow: rgb(222 226 230) 0px 1px 5px; padding: 0.5rem 1rem; display: flex; align-items: center; margin: 1rem; cursor: pointer; font-size: 0.9rem">
        <span v-if="$store.state.user.countryCode == 'DE'">Account löschen</span>
        <span v-else>Delete account </span>
      </div>
      <div v-on:click="$router.push('/')"
        style="color: white;background: #00dba4;border-radius: 0.6rem;box-shadow: rgb(222 226 230) 0px 1px 5px; padding: 0.5rem 1rem; display: flex; align-items: center; margin: 1rem; cursor: pointer; font-size: 0.9rem">
        <span v-if="$store.state.user.countryCode == 'DE'">Account behalten</span>
        <span v-else>Keep account </span>
      </div>
    </div>

  </div>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import FireStoreService from "@/firebase/FireStoreService.js";
import { getAuth, deleteUser } from "firebase/auth";

export default {
  setup() {
    const router = useRouter();
    const store = useStore();

    const deleteCurrentUser = async () => {
      const auth = getAuth();
      const user = auth.currentUser;
      deleteUser(user).then(() => {
        // User deleted.
        console.log("user deleted")
        logOutUser();
        router.push("/");

      }).catch((error) => {
        logOutUser();
        router.push("/");
        // An error ocurred
        // ...
      });
    };
    const logOutUser = async () => {
      store.dispatch("logout");
      localStorage.setItem("userLoggedIn", "false");
      localStorage.setItem("user", "null");
    };
    return {
      deleteCurrentUser
    };
  },
};
</script>
<style scoped>
input {
  border-radius: 0.6rem !important;
  border-style: solid !important;
  border-image: none;
  border-color: #dee2e6;
  background-color: unset;
  border-width: 1.5px;
  padding: 0.5rem 1rem 0.5rem 1rem;
  cursor: pointer;
}

input:focus {
  outline: none;
}

/* navbar */
.signupbutton {
  margin-top: 2rem;
  display: inline-flex;
  justify-content: center;
  align-items: baseline;
  background: #00dba4;
  outline: none;
  border: none;
  padding: 6px 30px;
  border-radius: 0.6rem;
  color: #fff;
  cursor: pointer;
  margin-bottom: 1rem;
}

/* blogs */
.blog {
  padding: 10px;
  margin: 30px 0 20px;
  border-top: 1px dotted #ddd;
}

.blog p {
  color: #999;
}

.blog h3 {
  color: #00dba4;
}

.blog .icons {
  color: #555;
  font-weight: bold;
  display: flex;
  align-items: center;
  font-size: 0.9em;
}

.blog .icons .material-icons {
  font-size: 1em;
  margin-left: 10px;
  cursor: pointer;
}

.blog .icons .material-icons:hover {
  color: #00dba4;
}

/* forms */
label {
  display: block;
  margin-bottom: 10px;
  margin-top: 20px;
}

form button {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
</style>