/* eslint-disable */

import axios from 'axios'
import store from '@/store/index.js'

export default {
  getWeatherData() {
    console.log("GetWeatherData is executed")

    if (process.env.NODE_ENV == "production") {
      // let url = "https://api.openweathermap.org/data/2.5/weather?q={Frankfurt am Main}&appid={6fcaaf6f5f14cf579fbe587e3dc966cd}"
      var url = "https://api.openweathermap.org/data/2.5/weather?q=" + store.state.city + "&appid=6fcaaf6f5f14cf579fbe587e3dc966cd"
      return axios.get(url)
        .then(response => (parseData(response.data, "real")))
        .catch((error) => (fakeData(), console.log("44444")));
    }
    else { fakeData() }

    function fakeData() {
      // https://openweathermap.org/weather-conditions
      // description: clear sky, few clouds, scattered clouds, broken clouds, overcast clouds, shower rain, rain, thunderstorm, snow, mist
      console.log("use fake weatherData")
      var data = {
        "coord": { "lon": 8.6833, "lat": 50.1167 },
        "weather": [{ "id": 800, "main": "Clear", "description": "clear sky", "icon": "01d" }],
        "base": "stations",
        "main": { "temp": 283.33, "feels_like": 281.79, "temp_min": 281.99, "temp_max": 285.05, "pressure": 1014, "humidity": 53 },
        "visibility": 10000,
        "wind": { "speed": 4.63, "deg": 70 },
        "clouds": { "all": 0 },
        "dt": 1650439935,
        "sys": { "type": 2, "id": 54759, "country": "DE", "sunrise": 1650428533, "sunset": 1650479148 },
        "timezone": 7200,
        "id": 2925533,
        "name": "Frankfurt am Main",
        "cod": 200
      }
      parseData(data, "fake")
    }

    function parseData(data, realorfake) {
      store.state.weather.full = data
      var description = data.weather[0].description
      var celsius = Math.ceil(data.main.temp - 273.15)
      var condition = "";
      var temperature = "";
      var d = new Date()
      var hours = d.getHours() // 0-24

      // condition
      if (description.includes("clear sky") || description.includes("few clouds")) { condition = "clear" }
      if (description.includes("snow")) { condition = "snow" }
      if (description.includes("rain") || description.includes("thunderstorm")) { condition = "rain" }
      if ((description.includes("scattered clouds") || description.includes("broken clouds") || description.includes("overcast clouds") || description.includes("mist"))) { condition = "clouds" }

      // temp
      store.state.weather.celsius = celsius
      // if (celsius < 8) { temperature = "inside" } // winter jacket
      // if (8 <= celsius < 12) { temperature = "inside" } // jacket
      // if (12 <= celsius < 16) { temperature = "outside" } // pullover
      // if (17 <= celsius < 20) { temperature = "outside" } // tshirt
      // if (20 <= celsius) { temperature = "outside" } // shorts

      if (realorfake == "real") {
        console.log("store.state.weather in " + store.state.city)
        console.log(store.state.weather)
      }
      else {
        console.log("fake store.state.weather in " + store.state.city)
        console.log(store.state.weather)
      }
      store.state.weather.temperature = celsius
      store.state.weather.condition = condition // fits to special = rain, clear, clowds, snow
      store.state.weatherLoaded = true
    }
  }
}

