<template>
  <div class="container" v-if="$store.state.activityListView == 'card'">
    <div class="scrolling-component" ref="scrollComponent">
      <activityListItem-component
        v-for="(activity, index) in posts"
        :activity="activity"
        v-bind:key="index"
      />
    </div>
  </div>
</template>


<script>
/* eslint-disable */
import getTenActivities from "@/services/getTenActivities";
import ActivityListItemComponent from "@/components/ActivityList/ActivityListItemComponent.vue";
import { ref, onMounted, onUnmounted } from "vue";

export default {
  props: {},
  components: {
    ActivityListItemComponent,
  },
  data() {
    return {};
  },
  setup() {
    console.log("ListComponent setup() executed - this should be exectued after data is loaded");

    var test = 0;

    const posts = ref(getTenActivities(0));
    const scrollComponent = ref(null);

    const loadMorePosts = () => {
      test = test + 10;
      console.log(test);
      let newPosts = getTenActivities(test);
      console.log(newPosts);
      posts.value.push(...newPosts);
    };

    onMounted(() => {
      window.addEventListener("scroll", handleScroll);
    });

    onUnmounted(() => {
      window.removeEventListener("scroll", handleScroll);
    });

    const handleScroll = (e) => {
      let element = scrollComponent.value;
      if (element.getBoundingClientRect().bottom < window.innerHeight) {
        loadMorePosts();
      }
    };
    return {
      posts,
      scrollComponent,
    };
  },
};
</script>
<style scoped>
.container {
  max-width: 50rem;
}
</style>