<!-- ---------- ---------- ---------- HTML Structure ---------- ---------- ---------- -->
<template>
  <div>
    <!--Plan - Currently not used -->
    <div
    >
      <div
        data-bs-toggle="collapse"
        data-bs-target="#generalapproach"
        aria-expanded="false"
        aria-controls="generalapproach"
        class="alignleft dropdown"
        v-bind:class="{
          alignleft: $store.state.linkView == 'icondetails',
        }"
      >
        <div class="inline va">
          <span>General approach to plan your activity</span>
        </div>
        <span
          ><svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            fill="currentColor"
            class="bi bi-caret-down-fill pointer"
            viewBox="0 2 16 16"
          >
            <path
              d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"
            /></svg
        ></span>
      </div>
      <div class="collapse planbottomspace" id="generalapproach">
        <div class="textboxplan">
          <span v-html="$store.state.activity.plan"></span>
        </div>
      </div>
    </div>
  </div>
</template>

  <!-- ---------- ---------- ---------- Code ---------- ---------- ---------- -->

<script>
/* eslint-disable */
export default {
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {},
};
</script>

<style scoped>

.inline {
  display: inline;
}
.va {
  margin-right: 0.5rem;
}
.dropdown {
  font-weight: bold;
  color: #000000;
  cursor: pointer;
}
.planbottomspace {
  margin-top: 0.5rem;
}
.textboxplan {
  padding: 0.5rem 1rem 0.5rem 1rem;
  font-size: 0.9rem;
  border-radius: 0.25rem;
  background-color: #20c99780;
  text-align: left;
}
</style>