<template>
  <div class="borderandpadding">
    <!-- v-bind:class="{
    noborderandpadding:
    activity.uuid ==
    $store.state.storeActivityListFiltered[
    $store.state.storeActivityListFiltered.length - 1
    ].uuid,
    }"-->
    <div class="cardContainer">
      <div class="cardContainerAbsolute" v-bind:selectedActivityName="activity.activity"
        v-bind:selectedActivityuuid="activity.uuid" v-bind:selectedActivityId="activity.id"
        v-bind:selectedActivityListPosition="activity.listPosition" v-bind:selectedActivity="activity">
        <div class="cardLeft click-activity-list-item" v-bind:id="activity.uuid"
          v-bind:selected-activity-name="activity.activity"
          v-on:click="setStoreSelectedActivity(activity.id, activity.listPosition, activity)">
          <div class="cardImageContainer">
            <!--loading="lazy"-->
            <img v-if="activity.imageavailable < 3" class="cardImage" v-bind:src="
              'https://storage.googleapis.com/timetip-images/activity-images/' +
              activity.imagetext +
              '.jpg'
            " @error="setAltImg" />
          </div>
        </div>

        <div class="cardMiddle click-activity-list-item" v-bind:id="activity.uuid"
          v-bind:selected-activity-name="activity.activity"
          v-on:click="setStoreSelectedActivity(activity.id, activity.listPosition, activity)">
          <div class="cardMiddleText column">
            <span v-if="$store.state.user.countryCode != 'DE'">
              {{ activity.activity }}
            </span>
            <span v-if="$store.state.user.countryCode == 'DE'">
              {{ activity.activityde }}
            </span>
          </div>
        </div>

        <div class="cardRight">
          <DetailsHeartComponent :activityNumber="activity.listPosition" style="height: 100%;width: 100%;" />
          <!-- <div class="cardRightHeart column">
            <svg
              v-if="!Object.keys($store.state.favoritesObject).includes(activity.uuid)"
              xmlns="http://www.w3.org/2000/svg"
              width="20px"
              height="20px"
              viewBox="0 0 24 24"
              fill="currentColor"
              class="hearticon favoritenotselected"
            >
              <path d="M0 0h24v24H0V0z" fill="none" />
              <path
                d="M16.5 3c-1.74 0-3.41.81-4.5 2.09C10.91 3.81 9.24 3 7.5 3 4.42 3 2 5.42 2 8.5c0 3.78 3.4 6.86 8.55 11.54L12 21.35l1.45-1.32C18.6 15.36 22 12.28 22 8.5 22 5.42 19.58 3 16.5 3zm-4.4 15.55l-.1.1-.1-.1C7.14 14.24 4 11.39 4 8.5 4 6.5 5.5 5 7.5 5c1.54 0 3.04.99 3.57 2.36h1.87C13.46 5.99 14.96 5 16.5 5c2 0 3.5 1.5 3.5 3.5 0 2.89-3.14 5.74-7.9 10.05z"
              />
            </svg>
            <svg
              v-if="Object.keys($store.state.favoritesObject).includes(activity.uuid)"
              xmlns="http://www.w3.org/2000/svg"
              width="20px"
              height="20px"
              viewBox="0 0 24 24"
              fill="currentColor"
              class="hearticon favoriteselected"
            >
              <path d="M0 0h24v24H0V0z" fill="none" />
              <path
                d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"
              />
            </svg>
          </div>-->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import UpdateFavoritesService from "@/services/UpdateFavoritesService.js";
import DetailsHeartComponent from "@/components/Details/DetailsHeartComponent.vue";

export default {
  props: {
    activity: Object,
  },
  components: {
    DetailsHeartComponent,
  },
  mounted() { },
  methods: {
    setStoreSelectedActivity: function (selectedActivityId, selectedActivityListPosition, selectedActivity) {
      this.$store.state.storeSelectedActivityId = selectedActivityId;
      this.$store.state.activity = selectedActivity;
      this.$store.state.storeSelectedActivityListPosition = selectedActivityListPosition;

      this.$router.push("/details");
    },
    updateFavorites: function (event) {
      var uuid = event.target.getAttribute("selectedActivityuuid")
      var name = event.target.getAttribute("selectedActivityName")
      UpdateFavoritesService.updateFavorites(uuid, name);
    },
    setAltImg(event) {
      console.log("image could not be loaded");
      event.target.src = require("../../assets/error.png");
    },
  },
  computed: {},
};
</script>
<style scoped>
.borderandpadding {
  padding-top: 0.375em;
  border-bottom: 1px solid #ced4da;
  padding-bottom: 0.375em;
}

.noborderandpadding {
  border-bottom: 0px solid #ced4da;
}

.cardContainer {
  cursor: pointer;
  overflow: hidden;
  padding-top: 20%;
  /*18.54% equals golden ratio*/
  background: #ffffff;
  position: relative;
}

.cardContainerAbsolute {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.cardLeft {
  position: absolute;
  left: 0;
  width: 30%;
  height: 100%;
}

.cardImageContainer {
  width: 100%;
  overflow: hidden;
  height: 100%;
  background: #EEF0F2;
  pointer-events: none;
  border-radius: 0.5rem;
}

.cardImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
}

.cardMiddle {
  position: absolute;
  left: 30%;
  width: 60%;
  height: 100%;
}

.cardMiddleText {
  position: absolute;
  font-size: 0.9rem;
  top: 50%;
  transform: translate(0%, -50%);
  padding-left: 5%;
  padding-right: 5%;
  pointer-events: none;
  text-align: left;
}

.cardRight {
  position: absolute;
  left: 90%;
  width: 10%;
  height: 100%;
}

.cardRightHeart {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}

.hearticon {
  pointer-events: none;
}

.selected {
  background-color: #20c997;
  color: #ffffff;
  border-radius: 0.25rem;
}

.favoriteselected {
  color: red;
}

.favoritenotselected {
  color: #6fb5ff;
}

.comment {
  margin-top: 2em;
  font-size: 60%;
}

.form-control {
  margin-bottom: 0.5rem;
}

/*.image-list {
}*/
.image-item {
  cursor: pointer;
  width: 25%;
  margin: 0;
  padding: 0rem 0rem;
  display: inline;
}

.thumbnail {
  position: relative;
}

.caption {
  color: #000000;
  font-size: 1.5vw;
  /*font-weight: bold;*/
  position: absolute;
  top: 10%;
  left: 0;
  width: 100%;
}

.selectedFilterContainer {
  padding-top: 1rem;
}

.selectedFilter {
  background-color: #6fb5ff;
  color: #ffffff;
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  margin: 0.1rem;
  padding: 0rem 0.5rem;
  border-radius: 0.6rem;
  border: 0;
  cursor: pointer;
}

.delete-underline {
  text-decoration: none;
  color: #ffffff;
}
</style>