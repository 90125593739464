<!-- ---------- ---------- ---------- HTML Structure ---------- ---------- ---------- -->
<template>
  <div>
    <div class="text">
      <span v-if="timetip == 0 && $store.state.user.countryCode == 'EN'">
        In this section I will write a short text about this activity soon. My
        target is to motivate you to do this activity or at least to arouse some
        interest.
      </span>
      <span v-if="timetip == 0 && $store.state.user.countryCode != 'EN'">
        In diesem Abschnitt werde ich bald einen kurzen Text über diese
        Aktivität schreiben. Mein Ziel ist es, dich für diese Aktivität zu
        begeistern oder zumindest ein wenig Interesse zu wecken.
      </span>
      <div class="mb-3" v-if="false">
        <textarea class="form-control" id="exampleFormControlTextarea1"
          placeholder="In this section I will write a short text about this activity soon. My target is to motivate you to do this activity or at least to arouse some interest. Sometimes it is also my target to give you a short advice. The explanation of the activity can be found behind the info button."
          rows="6"></textarea>
      </div>
      <div @click="useTrimmedText = !useTrimmedText">
        <span v-if="timetip != 0 && useTrimmedText">{{ trimmedText }}</span>
        <span v-if="timetip != 0 && !useTrimmedText">{{ timetip }}</span>
      </div>
    </div>
    <div class="smallSpaceTop"></div>
  </div>
</template>

  <!-- ---------- ---------- ---------- Code ---------- ---------- ---------- -->

<script>
/* eslint-disable */
export default {
  components: {},
  props: {
    activityListPosition: Number,
  },
  data() {
    return {
      //storeSpiceObject: this.$store.state.storeSpiceObject,
      filteredSpiceObject: {},
      randomizedSpiceObject: {},
      useTrimmedText: true,
    };
  },
  mounted() { },
  methods: {},
  // update the filtered spices, when the activity changes
  computed: {
    trimmedText() {
      if (this.$store.state.isTouchDevice) {
        // https://stackoverflow.com/questions/5454235/shorten-string-without-cutting-words-in-javascript
        var yourString = this.timetip; //replace with your string.
        var maxLength = 120 // maximum number of characters to extract

        if (yourString.length > 120) {
          //trim the string to the maximum length
          var trimmedString = yourString.substr(0, maxLength);

          //re-trim if we are in the middle of a word
          trimmedString = trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(" "))) + " ..."
        }
        else {
          trimmedString = this.timetip
        }

        return trimmedString
      }
      else return this.timetip
    },

    timetip() {
      return this.$store.state.storeActivityList[this.activityListPosition].timetip[
        this.$store.state.user.countryCode
      ];
    },
  },
};
</script>

<style scoped>
/*Added "-webkit-user-select: none" to prevent unintended clicks that trigger a google search on android phones*/
span {
  -webkit-user-select: none;
}

.mb-3 {
  margin-bottom: 0rem !important;
}

.form-control {
  font-size: 0.9rem;
  border: none;
  overflow: visible;
  color: #2c3e50;
}

.text {
  font-size: 0.9rem;
}

.smallSpaceTop {
  padding-top: 1rem;
}
</style>