/* eslint-disable */

import axios from 'axios'
import store from '@/store/index.js'

export default {
  getImage(wikitext, imagetext) {
    console.log("GetImage is executed for <" + wikitext + "> and <" + imagetext + "> with the following content:")


    // Get the Wikipedia Image, if there is one
    let imageUrl = 'https://en.wikipedia.org/w/api.php?action=query&origin=*&prop=pageimages&format=json&piprop=original&titles=' + wikitext
    let image;

    axios.get(imageUrl)
      .then(response => (
        parseImageData(response.data)
      ))
    function parseImageData(data) {
      //console.log(data)
      let page = data.query.pages;
      let pageId = Object.keys(data.query.pages)[0];
      //console.log(pageId)
      if (page[pageId]['original'] != undefined) {
        console.log("Image loaded from Wikipedia")
        image = page[pageId].original.source;
        store.state.storeUnsplashImage = image
        store.state.imageLoaded = true;
      }
      else {
        console.log("Image loaded from unsplash")


        // Get the Unsplash Image, if there is no Wikipedia Picture
        let imageUrl = 'https://api.unsplash.com/search/photos?page=1&orientation=landscape&query=' + imagetext + '&client_id=4rC2JFgUYcTtTiHB1imZmqbuDZSQTIrUs77mLyNG5J8'

        axios.get(imageUrl)
          .then(response => (
            parseImageData(response.data)
          ))

        function parseImageData(data) {
          console.log(data)
          let image = data.results[0].urls.regular;
          store.state.storeUnsplashImage = image
          store.state.imageLoaded = true;
        }
      }
    }
  }
}