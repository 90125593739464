<!-- ---------- ---------- ---------- HTML Structure ---------- ---------- ---------- -->
<template>
  <div>
    <!--Snackbar-->
    <transition name="splash">
      <div
        style="position: fixed; top: 50%; left: 50%; transform: translate(-50%, -50%); max-width: 50rem; width: 80vw; height: auto;
        background: #ffffff; color: black;
        padding: 1rem; border-radius: 1rem;
        cursor: pointer; pointer-events: all;
        z-index: 5555;
        box-shadow: 0 2px 8px grey;
      "
        v-if="filterSnackbar"
        @click="$store.state.filterSnackbar = false"
      >
        <span
          v-if="$store.state.user.countryCode == 'DE'"
        >Wenn du alle Antworten einer Frage auswählen möchtest, kannst du die Frage einfach auslassen. Die Ergebnisse sind dieselben.</span>
        <span v-else>If you want to choose all answers of a question, you can simply skip the question. The results are the same. </span>
      </div>
    </transition>
  </div>
</template>

  <!-- ---------- ---------- ---------- Code ---------- ---------- ---------- -->

<script>
/* eslint-disable */

export default {
  components: {},
  props: {},
  data() {
    return {};
  },
  mounted() { },
  methods: {},
  computed: {
    filterSnackbar() {
      return this.$store.state.filterSnackbar
    },
  },
};
</script>

<style scoped>
.red {
  background: #dc3545 !important;
}
.blue {
  background: #0dcaf0 !important;
}
.green {
  background: #20c997 !important;
}
.grey {
  background: grey !important;
}
.white {
  background: white !important;
  color: black !important;
}
.splash-enter-from,
.splash-leave-to {
  opacity: 0;
}
.splash-enter-active {
  transition: all 0.3s ease;
}
.splash-leave-active {
  transition: all 0.6s ease;
}
.nodetailsview {
  top: 50% !important;
}
</style>