<template>
  <n-space vertical class="swiper-no-swiping">
    <n-slider
      class="n-slider"
      v-model:value="value"
      range
      placement="top"
      :min="$store.state.ageFixMin"
      :max="$store.state.ageFixMax"
      :steps="1"
      :tooltip="false"
      :marks="marks"
    />
  </n-space>
</template>

<script>
/* eslint-disable */
import { defineComponent, ref } from "vue";
import UpdateActivitiesService from "@/services/UpdateActivitiesService.js";
import { useStore } from "vuex";
import ClickFilterService from "@/services/ClickFilterService.js";

export default defineComponent({
  setup() {
    const store = useStore();
    return {
      value: ref([store.state.ageMin, store.state.ageMax]),
      marks: {
        [store.state.ageFixMin]: store.state.ageFixMin,
        [store.state.ageFixMax]: store.state.ageFixMax,
      },
    };
  },
  props: {
    deactivateAgeFilter: Boolean,
  },
  watch: {
    value() {
      console.log("Slider value changed");
      this.updateAgeSlider()
    },
    deactivateAgeFilter() {
      this.$store.state.ageActive = !this.$store.state.ageActive;
      if (!this.$store.state.ageActive) {
        this.$store.state.ageRangeSliderSwitch = true;
      }

      UpdateActivitiesService.updateActivities();

      ClickFilterService.clickFilter("onlycheckcountzeroandresethistory");
    }
  },
  methods: {
    updateAgeSlider() {
      let that = this;
      let thats = this.$store;
      let ageMin = this.value[0];
      let ageMax = this.value[1];

      setTimeout(function () {
        if (that.value[0] == ageMin && that.value[1] == ageMax) {
          console.log("ageMin = " + Math.min.apply(null, that.value));
          thats.state.ageMin = Math.min.apply(null, that.value);
          console.log("ageMax = " + Math.max.apply(null, that.value));
          thats.state.ageMax = Math.max.apply(null, that.value);

          thats.state.ageActive = true;
          UpdateActivitiesService.updateActivities();

          ClickFilterService.clickFilter("onlycheckcountzeroandresethistory");
        }
      }, 100);
    }

  },
});
</script>
<style scoped>
.n-slider {
  --n-dot-border-active: 2px solid #007bff !important;
  --n-fill-color: #007bff !important;
  --n-fill-color-hover: #007bff !important;
  touch-action: pan-x;
}
</style>