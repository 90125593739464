<!-- ---------- ---------- ---------- HTML Structure ---------- ---------- ---------- -->
<template>
  <svg viewBox="0 0 2150 2150" class="cardImage">
    <defs>
      <clipPath id="clip0">
        <rect x="1127" y="164" width="2147" height="2148" />
      </clipPath>
    </defs>
    <g clip-path="url(#clip0)" transform="translate(-1127 -164)">
      <path
        d="M1161.5 1238C1161.5 663.9 1626.68 198.5 2200.5 198.5 2774.32 198.5 3239.5 663.9 3239.5 1238 3239.5 1812.1 2774.32 2277.5 2200.5 2277.5 1626.68 2277.5 1161.5 1812.1 1161.5 1238Z"
        stroke="#ffffff"
        stroke-width="68.75"
        stroke-miterlimit="8"
        fill="#007BFF"
        fill-rule="evenodd"
      />
      <path
        d="M0 0 0.304593 181.89"
        stroke="#ffffff"
        stroke-width="91.6667"
        stroke-linecap="round"
        stroke-miterlimit="8"
        fill="none"
        fill-rule="evenodd"
        transform="matrix(-1 0 0 1 2613.3 862)"
      />
      <path
        d="M1787 862 1787 1043.89"
        stroke="#ffffff"
        stroke-width="91.6667"
        stroke-linecap="round"
        stroke-miterlimit="8"
        fill="none"
        fill-rule="evenodd"
      />
      <path
        d="M2915.5 1239.5C2915.5 1633.83 2595.38 1953.5 2200.5 1953.5 1805.62 1953.5 1485.5 1633.83 1485.5 1239.5 1937.05 1458.04 2463.95 1458.04 2915.5 1239.5Z"
        stroke="#007BFF"
        stroke-width="0"
        stroke-linecap="round"
        stroke-miterlimit="8"
        fill="#ffffff"
        fill-rule="evenodd"
      />
      <path
        d="M2842.87 1587.07 2786.61 1554.59"
        stroke="#007BFF"
        stroke-width="22.9167"
        stroke-linecap="round"
        stroke-miterlimit="8"
        fill="none"
        fill-rule="evenodd"
      />
      <path
        d="M2572.45 1864.3 2539.97 1808.04"
        stroke="#007BFF"
        stroke-width="22.9167"
        stroke-linecap="round"
        stroke-miterlimit="8"
        fill="none"
        fill-rule="evenodd"
      />
      <path
        d="M2200.5 1967.46 2200.5 1902.5"
        stroke="#007BFF"
        stroke-width="22.9167"
        stroke-linecap="round"
        stroke-miterlimit="8"
        fill="none"
        fill-rule="evenodd"
      />
      <path
        d="M1839.96 1864.3 1872.44 1808.04"
        stroke="#007BFF"
        stroke-width="22.9167"
        stroke-linecap="round"
        stroke-miterlimit="8"
        fill="none"
        fill-rule="evenodd"
      />
      <path
        d="M1556.75 1587.07 1613.01 1554.59"
        stroke="#007BFF"
        stroke-width="22.9167"
        stroke-linecap="round"
        stroke-miterlimit="8"
        fill="none"
        fill-rule="evenodd"
      />
      <path
        d="M1513.55 1128.28 1613.02 1242.33 1479.63 1358.66 1380.16 1244.61Z"
        fill="#007BFF"
        fill-rule="evenodd"
      />
      <path
        d="M1520.91 1286.27C1548.07 1268.8 1584.24 1276.66 1601.71 1303.81 1619.17 1330.97 1611.32 1367.15 1584.16 1384.61 1557 1402.08 1520.83 1394.22 1503.36 1367.06 1485.89 1339.91 1493.75 1303.73 1520.91 1286.27Z"
        fill="#ffffff"
        fill-rule="evenodd"
      />
      <path
        d="M2897.59 1137.56 3056.89 1265.51 2958.73 1387.72 2799.43 1259.76Z"
        fill="#007BFF"
        fill-rule="evenodd"
      />
      <text
        fill="#007BFF"
        font-family="Calibri,Calibri_MSFontService,sans-serif"
        font-weight="400"
        font-size="147"
        transform="matrix(1 0 0 1 1657.79 1558)"
      >
        8
        <tspan font-size="147" x="232.062" y="215">7</tspan>
        <tspan font-size="147" x="506.159" y="295">6</tspan>
        <tspan font-size="147" x="800.61" y="220">5</tspan>
        <tspan font-size="147" x="1006.74" y="3">4</tspan>
      </text>
      <path
        d="M2449.71 680.922C2452.75 646.248 2526.62 624.392 2614.71 632.104 2702.81 639.815 2771.76 674.175 2768.72 708.849 2771.76 674.175 2702.81 639.815 2614.71 632.104 2526.62 624.392 2452.75 646.248 2449.71 680.922Z"
        stroke="#ffffff"
        stroke-width="68.75"
        stroke-linecap="round"
        stroke-miterlimit="8"
        fill="#FFFFFF"
        fill-rule="evenodd"
      />
      <path
        d="M0 0 125.187 136.492"
        stroke="#007BFF"
        stroke-width="68.75"
        stroke-linecap="round"
        stroke-miterlimit="8"
        fill="none"
        fill-rule="evenodd"
        transform="matrix(-0.99619 -0.0872085 -0.0872085 0.99619 2840.14 654.664)"
      />
      <path
        d="M2757.63 682.573 2757.63 682.573"
        stroke="#ffffff"
        stroke-width="68.75"
        stroke-linecap="round"
        stroke-miterlimit="8"
        fill="none"
        fill-rule="evenodd"
      />
      <path
        d="M2395.44 635.426 2493.6 743.739"
        stroke="#007BFF"
        stroke-width="68.75"
        stroke-linecap="round"
        stroke-miterlimit="8"
        fill="none"
        fill-rule="evenodd"
      />
      <path
        d="M2464.83 656.941 2464.83 656.942"
        stroke="#ffffff"
        stroke-width="68.75"
        stroke-linecap="round"
        stroke-miterlimit="8"
        fill="none"
        fill-rule="evenodd"
      />
      <path
        d="M1624.05 710.922C1620.74 676.275 1689.41 641.353 1777.43 632.921 1865.46 624.489 1939.51 645.741 1942.83 680.389 1939.51 645.741 1865.46 624.489 1777.43 632.921 1689.41 641.353 1620.74 676.275 1624.05 710.922Z"
        stroke="#ffffff"
        stroke-width="68.75"
        stroke-linecap="round"
        stroke-miterlimit="8"
        fill="#FFFFFF"
        fill-rule="evenodd"
      />
      <path
        d="M0 0 125.187 136.492"
        stroke="#007BFF"
        stroke-width="68.75"
        stroke-linecap="round"
        stroke-miterlimit="8"
        fill="none"
        fill-rule="evenodd"
        transform="matrix(-0.995444 0.0953492 0.0953492 0.995444 2003.2 614.124)"
      />
      <path
        d="M1927.14 656.569 1927.14 656.569"
        stroke="#ffffff"
        stroke-width="68.75"
        stroke-linecap="round"
        stroke-miterlimit="8"
        fill="none"
        fill-rule="evenodd"
      />
      <path
        d="M1562.42 676.051 1678.63 764.714"
        stroke="#007BFF"
        stroke-width="68.75"
        stroke-linecap="round"
        stroke-miterlimit="8"
        fill="none"
        fill-rule="evenodd"
      />
      <path
        d="M1634.56 684.593 1634.56 684.593"
        stroke="#ffffff"
        stroke-width="68.75"
        stroke-linecap="round"
        stroke-miterlimit="8"
        fill="none"
        fill-rule="evenodd"
      />
      <path
        d="M2817.61 1286.27C2844.77 1268.8 2880.94 1276.66 2898.41 1303.81 2915.88 1330.97 2908.02 1367.15 2880.86 1384.61 2853.71 1402.08 2817.53 1394.22 2800.07 1367.06 2782.6 1339.91 2790.46 1303.73 2817.61 1286.27Z"
        fill="#ffffff"
        fill-rule="evenodd"
      />
    </g>
  </svg>
</template>
<style scoped>
.cardImage {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
</style>