<!-- ---------- ---------- ---------- HTML Structure ---------- ---------- ---------- -->
<template>
  <div>
    <!--Image local-->
    <div class="relativecontainer">
      <div
        class="cardleft"
        v-on:click="($store.state.detailsView = 'info'), getWikipediaData()"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 0 24 24"
          width="24px"
          fill="#007bff"
        >
          <path d="M0 0h24v24H0V0z" fill="none" />
          <path
            d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"
          />
        </svg>
        <div class="cd"></div>
        <div
          v-bind:class="{ infocus: $store.state.detailsView == 'info' }"
        ></div>
      </div>

      <div class="cardmiddle" v-on:click="$store.state.detailsView = 'spice'">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          enable-background="new 0 0 24 24"
          height="24px"
          viewBox="0 0 24 24"
          width="24px"
          fill="#007bff"
        >
          <g><rect fill="none" height="24" width="24" /></g>
          <g>
            <g>
              <g>
                <g>
                  <path
                    d="M12,22C6.49,22,2,17.51,2,12S6.49,2,12,2s10,4.04,10,9c0,3.31-2.69,6-6,6h-1.77c-0.28,0-0.5,0.22-0.5,0.5 c0,0.12,0.05,0.23,0.13,0.33c0.41,0.47,0.64,1.06,0.64,1.67C14.5,20.88,13.38,22,12,22z M12,4c-4.41,0-8,3.59-8,8s3.59,8,8,8 c0.28,0,0.5-0.22,0.5-0.5c0-0.16-0.08-0.28-0.14-0.35c-0.41-0.46-0.63-1.05-0.63-1.65c0-1.38,1.12-2.5,2.5-2.5H16 c2.21,0,4-1.79,4-4C20,7.14,16.41,4,12,4z"
                  />
                  <circle cx="6.5" cy="11.5" r="1.5" />
                  <circle cx="9.5" cy="7.5" r="1.5" />
                  <circle cx="14.5" cy="7.5" r="1.5" />
                  <circle cx="17.5" cy="11.5" r="1.5" />
                </g>
              </g>
            </g>
          </g>
        </svg>
        <div class="cd"></div>
        <div
          v-bind:class="{ infocus: $store.state.detailsView == 'spice' }"
        ></div>
      </div>

      <div class="cardright" v-on:click="$store.state.detailsView = 'link'">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 0 24 24"
          width="24px"
          fill="#007bff"
        >
          <path d="M0 0h24v24H0V0z" fill="none" />
          <path
            d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM4 12c0-.61.08-1.21.21-1.78L8.99 15v1c0 1.1.9 2 2 2v1.93C7.06 19.43 4 16.07 4 12zm13.89 5.4c-.26-.81-1-1.4-1.9-1.4h-1v-3c0-.55-.45-1-1-1h-6v-2h2c.55 0 1-.45 1-1V7h2c1.1 0 2-.9 2-2v-.41C17.92 5.77 20 8.65 20 12c0 2.08-.81 3.98-2.11 5.4z"
          />
        </svg>
        <!--
        <img
          v-bind:src="
                  'https://storage.googleapis.com/timetip-images/link-images/' +
            'chilibluefilled.png'
          "
          alt="n/v"
        />
        -->
        <div class="cd"></div>
        <div
          v-bind:class="{ infocus: $store.state.detailsView == 'link' }"
        ></div>
      </div>
    </div>
  </div>
</template>

  <!-- ---------- ---------- ---------- Code ---------- ---------- ---------- -->

<script>
/* eslint-disable */
import GetWikipediaData from "@/services/GetWikipediaData.js";

export default {
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {
    getWikipediaData: function () {
      GetWikipediaData.getWikipediaData(
        this.$store.state.activity.wikitext[this.$store.state.user.countryCode]
      );
    },
  },
  computed: {},
};
</script>

<style scoped>
.relativecontainer {
  position: relative;
  /*background: #00000020;*/
  width: 100%;
  height: 2.5rem;
  z-index: 10;
  cursor: pointer;
}
.cardmiddle {
  position: absolute;
  top: 0;
  left: 33.3333%;
  width: 33.3333%;
  height: 100%;
  display: none;
}
.cardleft {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background: transparent;
}
.cardright {
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  background: transparent;
}

img,
svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 1.5em;
  object-fit: contain;
  cursor: pointer;
  z-index: 11;
  color: black;
}
.cd {
  position: absolute;
  background: #ffffff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 3rem;
  width: 3rem;
  border-radius: 1rem;
  cursor: pointer;
}
.infocus {
  position: absolute;
  background: #007bff40;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 3rem;
  width: 3rem;
  border-radius: 1rem;
  cursor: pointer;
}
</style>