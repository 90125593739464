/* eslint-disable */
import store from '@/store/index.js'


export default {
  searchActivities(event) {
    console.log("searchActivitiesService is executed")
    if (event == "reset") {
      query = ""
    }
    else {
      var query = event.target.value;
    }

    // Reset filter if query is empty string
    if (query.length == 0) {
      store.state.searchToggle = false
      store.state.searchText = ""


      console.log("set searchToggle and searchText = false");
    }
    else {

      // Do nothing if query is too short
      //if (query.length < 3) return;

      var filtered = [];
      query = query.toLowerCase();
      console.log(query);

      // Search in activity
      store.state.storeActivityListFiltered.forEach(function (value) {
        if (store.state.user.countryCode == 'DE') { var activity = value.activityde.toLowerCase() }
        else { var activity = value.activity.toLowerCase() }
        if (
          (activity.search(query) >= 0)) {
          filtered.push(value);
        }
      });
      store.state.storeActivityListSearched = filtered;
      store.state.searchToggle = true;
      store.state.searchText = query;
    }
  }
}