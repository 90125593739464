<template>
  <!--AgeActive-->
  <!-- <n-space>
    <n-switch v-model:value="ageActive" @update:value="activateAgeSlider" />
    <span style="cursor: pointer" v-on:click="activateAgeSlider">{{
      $store.state.appTexts.ageactive[$store.state.user.countryCode]
    }}</span>
  </n-space> -->

  <!--ChildrenParentSwitch-->
  <n-space v-if="$store.state.ageFixMin < 18 && $store.state.betaFeatures">
    <n-switch
      v-model:value="active2"
      @update:value="clickChildrenParentSwitch"
    />
    <span style="cursor: pointer"
      ><span v-on:click="clickChildrenParentSwitch"
        >{{
          $store.state.appTexts.withparents[$store.state.user.countryCode] + " "
        }}
      </span>
      <span v-on:click="show = !show">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="20px"
          viewBox="0 1 24 24"
          width="20px"
          fill="#2c3e50"
        >
          <path d="M0 0h24v24H0V0z" fill="none" />
          <path
            d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"
          />
        </svg>
      </span>
      <br />
      <span v-if="show" class="tt">
        <span v-if="!$store.state.ageChildrenParentsSwitch">
          {{
            $store.state.appTexts.tooltipchildalone[
              $store.state.user.countryCode
            ]
          }}
        </span>
        <span v-if="$store.state.ageChildrenParentsSwitch">
          {{
            $store.state.appTexts.tooltipchildwithparents[
              $store.state.user.countryCode
            ]
          }}
        </span>
      </span>
    </span>
  </n-space>

  <!--RangeSliderSwitch-->
  <n-space v-if="$store.state.betaFeatures">
    <n-switch v-model:value="active" @update:value="clickRangeSliderSwitch" />
    <span style="cursor: pointer" v-on:click="clickRangeSliderSwitch">{{
      $store.state.appTexts.agegroups[$store.state.user.countryCode]
    }}</span>
  </n-space>

  <!--SaveAgeSwitch-->
  <n-space v-if="$store.state.betaFeatures">
    <n-switch
      v-model:value="$store.state.ageSave"
      @update:value="!$store.state.ageSave, activateAgeSave()"
    />
    <span style="cursor: pointer">
      <span
        v-on:click="
          ($store.state.ageSave = !$store.state.ageSave), activateAgeSave()
        "
        >{{
          $store.state.appTexts.agesavetext[$store.state.user.countryCode] + " "
        }}
      </span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="20px"
        viewBox="0 1 24 24"
        width="20px"
        fill="#2c3e50"
        v-on:click="showAgeSave = !showAgeSave"
      >
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"
        />
      </svg>
      <br />
      <span v-if="showAgeSave" style="opacity: 0.5">{{
        $store.state.appTexts.agesavetooltip[$store.state.user.countryCode]
      }}</span>
    </span>
  </n-space>
</template>

<script>
/* eslint-disable */
import { defineComponent, ref } from "vue";
import { useStore } from "vuex";
import { computed } from "vue";

import UpdateActivitiesService from "@/services/UpdateActivitiesService.js";
import ClickFilterService from "@/services/ClickFilterService.js";

export default defineComponent({
  data() {
    return { show: false, showAgeSave: false };
  },
  setup() {
    const store = useStore();
    return {
      ageActive: ref(computed(() => store.state.ageActive)),
      active: ref(computed(() => store.state.ageRangeSliderSwitch)),
      active2: ref(computed(() => store.state.ageChildrenParentsSwitch)),
    };
  },
  methods: {
    activateAgeSave: function () {
      if (this.$store.state.ageSave) {
        this.$store.state.ageActive = true;
      }
      UpdateActivitiesService.updateActivities();
      ClickFilterService.clickFilter("onlycheckcountzeroandresethistory");
    },

    activateAgeSlider: function () {
      this.$store.state.ageActive = !this.$store.state.ageActive;
      if (!this.$store.state.ageActive) {
        this.$store.state.ageRangeSliderSwitch = true;
      }

      UpdateActivitiesService.updateActivities();

      ClickFilterService.clickFilter("onlycheckcountzeroandresethistory");
    },
    clickChildrenParentSwitch: function () {
      this.$store.state.ageChildrenParentsSwitch =
        !this.$store.state.ageChildrenParentsSwitch;

      this.$store.state.ageMin = Math.min.apply(null, [
        this.$store.state.ageMin,
        17,
      ]);
      this.$store.state.ageMax = Math.min.apply(null, [
        this.$store.state.ageMax,
        17,
      ]);

      this.$store.state.ageActive = true;
      UpdateActivitiesService.updateActivities();

      ClickFilterService.clickFilter("onlycheckcountzeroandresethistory");
    },
    clickRangeSliderSwitch: function () {
      this.$store.state.ageRangeSliderSwitch =
        !this.$store.state.ageRangeSliderSwitch;
      this.$store.state.ageMin = this.$store.state.ageFixMin;
      this.$store.state.ageMax = this.$store.state.ageFixMax;

      this.$store.state.ageActive = true;
      UpdateActivitiesService.updateActivities();

      ClickFilterService.clickFilter("onlycheckcountzeroandresethistory");
    },
  },
});
</script>
<style scoped>
.n-space {
  flex-flow: row !important;
  text-align: left;
}
.n-switch.n-switch--active {
  --n-rail-color-active: #007bff !important;
  --n-box-shadow-focus: 0 0 0 2px transparent !important;
  --n-loading-color: #007bff !important;
  /*background-color: #6610f280 !important;*/
  --n-rail-color: #007bff80 !important;
}
.n-switch {
  --n-rail-color-active: #007bff !important;
  --n-box-shadow-focus: 0 0 0 2px transparent !important;
  --n-loading-color: #007bff !important;
  /*background-color: #6610f280 !important;*/
  --n-rail-color: #007bff80 !important;
}
.tt {
  opacity: 0.5;
}
</style>
<style>
</style>