<!-- ---------- ---------- ---------- HTML Structure ---------- ---------- ---------- -->
<template>
  <div class="page filterviewclass" style="background: white; z-index: 200; padding-bottom: 6rem">
    <!-- <ExplanationComponent /> -->

    <!--Clicked Filters-->
    <ClickedFiltersTopComponent />

    <!--AllFilters-->
    <div class="container" style="padding-top: 0rem; padding-bottom: 0rem">
      <AllFiltersComponent />
    </div>

    <!--Buttons on the bottom-->
    <div>
      <transition name="buttonenter" appear>
        <div class="filterViewBottomContainer" v-bind:class="{
          tobottom: $router.currentRoute.value.name != 'FilterView',
        }" v-if="!(Object.keys(this.$store.state.clickedAnswers).length == 0 && !this.$store.state.ageActive)">
          <div class="container flexy">
            <!-- Reset Button -->
            <!-- <transition name="resetenter" appear>
              <div class="leftButton" v-if="
                clickedFilters.size != 0 ||
                ($store.state.ageActive && !$store.state.ageSave)
              " v-on:click="resetFilters">
                <svg xmlns="http://www.w3.org/2000/svg" height="22px" viewBox="0 0 24 26" width="22px" fill="#ffffff"
                  class="bi bi-arrow-counterclockwise resetbutton">
                  <g>
                    <rect fill="none" height="24" width="24" />
                  </g>
                  <g>
                    <g>
                      <path
                        d="M16.95,6l-3.57,4.55l1.43,1.43c1.03-1.31,4.98-6.37,4.98-6.37C20.3,4.95,19.83,4,19,4H6.83l2,2H16.95z" />
                      <path
                        d="M2.81,2.81L1.39,4.22L10,13v6c0,0.55,0.45,1,1,1h2c0.55,0,1-0.45,1-1v-2.17l5.78,5.78l1.41-1.41L2.81,2.81z" />
                    </g>
                  </g>
                </svg>
              </div>
            </transition> -->
            <!-- Middle Button -->
            <div style="display: flex; justify-content: center; width: 100%; pointer-events: all; cursor:pointer;"
              v-on:click="clickFilterButton">
              <button style="border-radius: 1rem; color: white;
          background: #6fb5ff; box-shadow: 0px 1px 5px #dee2e6;
          height: 3rem; padding: 0rem 1.5rem;"
                v-bind:class="{ blue2: Object.keys(this.$store.state.clickedAnswers).length != 0 || this.$store.state.ageActive }">
                
                
                <!-- <div v-if="Object.keys(this.$store.state.clickedAnswers).length == 0 && !this.$store.state.ageActive"
                  id="tt-d-surprise" v-on:click="supprise()">
                  <span v-if="$store.state.user.countryCode == 'DE'">Lass dich überraschen</span>
                  <span v-else>Surprise me</span>
                </div> -->
                
                <div>
                  <span v-if="$store.state.user.countryCode != 'DE'">Show&nbsp;</span>
                  {{ animatedNumber + "&nbsp;" }}
                  <span v-if="$store.state.user.countryCode == 'DE'">Aktivitäten anzeigen</span>
                  <span v-else>activities</span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<!-- ---------- ---------- ---------- Code ---------- ---------- ---------- -->

<script>
/* eslint-disable */
import AllFiltersComponent from "@/components/Filters/AllFiltersComponent.vue";
import ClickedFiltersTopComponent from "@/components/Filters/ClickedFiltersTopComponent.vue";
import ClickedFiltersComponent from "@/components/Filters/ClickedFiltersComponent.vue";
import ResetFiltersService from "@/services/ResetFiltersService.js";
import ExplanationComponent from "@/components/Navigation/ExplanationComponent.vue";
import ActivityListComponent from "@/components/ActivityList/ActivityListComponent.vue";
import TimetipSVG from "@/components/Navigation/TimetipSVG.vue";

export default {
  components: {
    AllFiltersComponent,
    ExplanationComponent,
    ClickedFiltersTopComponent,
    ClickedFiltersComponent,
    ActivityListComponent,
    TimetipSVG,
  },
  data() {
    return {
      showDropDown: false,
      maxHeightOn: true,
      checker: 1, // als Erinnerung
      tweenedNumber: this.$store.state.storeActivityListFiltered.length,
      rerenderActivityListKey: 0,
    };
  },

  mounted() {
  },
  beforeCreate() {
    this.$store.state.filterClicked = true;
    // if (this.$store.state.filterSetAtDiscoverView) {
    //   ResetFiltersService.resetFilters();
    //   this.$store.state.filterSetAtDiscoverView = false;
    // }
  },
  unmounted() {
    this.$store.state.filterClicked = false;
  },
  methods: {
    resetFilters: function () {
      ResetFiltersService.resetFilters();
    },
    clickFilterButton: function () {
      // this.$router.push("/activitylist");
      if (this.$store.state.activityViewDetails && false) {
        (this.$store.state.filterClicked = true), this.$router.push("/details");
      } else {
        (this.$store.state.filterClicked = true),
          this.$router.push("/details");
      }
    },
  },
  computed: {
    animatedNumber() {
      return this.tweenedNumber.toFixed(0);
    },
    activityCount() {
      return this.$store.state.storeActivityListFiltered.length;
    },
    activityListFiltered() {
      return this.$store.state.storeActivityListFiltered;
    },
    // Create list of clicked filters
    clickedFilters() {
      var filterList = new Set([]);
      for (const [key, value] of Object.entries(this.$store.state.filters)) {
        // For each entry in "age" - For example "toddler"
        value.forEach(function (x) {
          // Check, if "toddler" is selected
          if (x.selected == true) {
            filterList.add(x);
          }
        });
      }
      return filterList;
    },
    clickedAgeTimeCostList() {
      var ageTimeCostList = [];

      if (this.$store.state.ageActive) {
        ageTimeCostList.push("age");
      }
      if (this.$store.state.timeActive) {
        ageTimeCostList.push("time");
      }
      if (this.$store.state.costActive) {
        ageTimeCostList.push("cost");
      }
      return ageTimeCostList;
    },
    activityListFilteredLength() {
      return this.$store.state.storeActivityListFiltered.length;
    },
  },
  watch: {
    activityCount() {
      gsap.to(this.$data, { duration: 0.5, tweenedNumber: this.activityCount });
    },
    activityListFilteredLength() {
      this.rerenderActivityListKey += 1;
    },
  },
};
</script>

<style scoped>
/* http://jsfiddle.net/Wcgvt/181/ */
/* https://www.w3schools.com/css/css_overflow.asp */

/* switch transitions */
.switch-enter-from,
.switch-leave-to {
  /* opacity: 0; */
  transform: translateY(100vh);
}

.switch-enter-active {
  transition: all 0.5s ease;
}

.switch-leave-active {
  transition: all 0.5s ease;
}


@keyframes slidedown {
  0% {
    max-height: 1.6rem;
  }

  100% {
    max-height: 40vh;
  }
}

@keyframes slideup {
  0% {
    max-height: 40vh;
  }

  100% {
    max-height: 1.6rem;
  }
}

.fade-enter-from {
  height: 0rem;
  opacity: 0;
  transform: scale(0);
}

.fade-enter-to {
  height: 2.1rem;
  transform: scale(1);
}

.fade-enter-active {
  transition: height 0.3s ease, opacity 0.3s ease 0.3s, transform 0.3s ease 0.3s;
}

.fade-leave-from {
  height: 2.1rem;
}

.fade-leave-to {
  height: 0rem;
  opacity: 0;
}

.fade-leave-active {
  transition: height 0.3s ease-in-out 0.3s, opacity 0.3s ease-in-out;
}

.fade2-enter-from {
  height: 0rem;
  opacity: 0;
  transform: scale(0);
}

.fade2-enter-to {
  height: 25.4px;
  transform: scale(1);
}

.fade2-enter-active {
  transition: height 0.3s ease, opacity 0.3s ease 0.3s, transform 0.3s ease 0.3s;
}

.fade2-leave-from {
  height: 25.4px;
}

.fade2-leave-to {
  height: 0rem;
  opacity: 0;
}

.fade2-leave-active {
  transition: height 0.3s ease-in-out 0.3s, opacity 0.3s ease-in-out;
}

/* Show Filterbuttons */
.buttonenter-enter-from {
  opacity: 0;
  transform: scale(0.6);
}

.buttonenter-enter-active {
  transition: opacity 0s ease 0.5s, transform 0.3s ease 0.5s;
}

.buttonenter-leave-to {
  transform: scale(0);
}

.buttonenter-leave-active {
  transition: all 0.5s ease;
}

/* Show Filterbuttons End */

/* resetenter */
.resetenter-enter-from {
  transform: translateX(-5rem);
}

.resetenter-enter-active {
  transition: all 0.3s ease;
}

/* resetenter */

/* Buttons on the bottom */

.filterViewBottomContainer {
  position: fixed;
  bottom: 0rem;
  height: 5rem;
  width: 100%;
  overflow: hidden;
  pointer-events: none;
  z-index: 91;
}

.tobottom {
  bottom: 0rem;
}

.container {
  max-width: 50rem;
  display: inline-flex;
  justify-content: space-between;
}

.flexy {
  display: inline-flex;
}

.leftButton {
  position: relative;
  color: #ffffff;
  padding: 1.75rem 1.75rem;
  background-color: #e83e8c;
  border-radius: 1rem;
  cursor: pointer;
  pointer-events: all;
  margin-left: 0.5rem;
  margin-right: 1rem;
}

.middleButton {
  position: relative;
  width: 100%;
  color: #ffffff;
  padding: 1.75rem 1.75rem;
  background-color: #007bff;
  border-radius: 1rem;
  cursor: pointer;
  pointer-events: all;
  margin-right: 0.5rem;
}

.full {
  position: relative;
  width: 100%;
  color: #ffffff;
  padding: 1.75rem 1.75rem;
  background-color: #007bff;
  border-radius: 1rem;
  cursor: pointer;
  pointer-events: all;
  margin: 0rem 0.5rem 0rem;
}

.apply {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.rightButton {
  position: absolute;
  right: 1rem;
  bottom: 1rem;
  color: #ffffff;
  padding: 1.75rem 1.75rem;
  background-color: #007bff;
  border-radius: 50%;
  cursor: pointer;
  pointer-events: all;
}

.resetbutton {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.activityCount {
  font-weight: none;
  color: #fbfbfb;
  background-color: #ffffff;
  border: 1px;
  cursor: auto;
  display: inline-block;
  padding: 0.25rem 1rem;
  background-color: #007bff;
  border-radius: 50%;
  pointer-events: all;
}

.filterexplanation {
  font-size: 1rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.logo {
  position: relative;
  padding: 2rem;
  margin: 4rem 0rem 4rem 0rem;
  box-sizing: border-box;
  display: block;
}

.blue2 {
  background: #007bff !important;
}
</style>