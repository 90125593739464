import store from '@/store/index.js'

export default {
    getLocalStorage() {
        console.log("getLocalStorage is executed")

        if (localStorage.getItem("firstVisit") != null) {
            console.log("Page has already been visited: Set firstVisit to false")
            store.state.firstVisit = false
        }
        if (localStorage.getItem("detailsExplanationFinished") != null) {
            console.log("Page has already been visited: Set detailsExplanationFinished to true")
            store.state.detailsExplanationFinished = true
        }
        if (localStorage.getItem("filterExplanationClicked") != null) {
            console.log("Page has already been visited: Set filterExplanationClicked to true")
            store.state.filterExplanationClicked = true
        }


        // console.log("Get ageChildrenParentsSwitch, ageMin, ageMax and ageSave from local storage")
        // if (localStorage.getItem("ageChildrenParentsSwitch") != null) {
        //     if (localStorage.getItem("ageChildrenParentsSwitch") == "true") {
        //         store.state.ageChildrenParentsSwitch = true;
        //     }
        //     if (localStorage.getItem("ageChildrenParentsSwitch") == "false") {
        //         store.state.ageChildrenParentsSwitch = false;
        //     }
        //     console.log(store.state.ageChildrenParentsSwitch)
        // }
        if (localStorage.getItem("ageMin") != null) {
            store.state.ageMin = parseInt(localStorage.getItem("ageMin"))
            // console.log(parseInt(localStorage.getItem("ageMin")))
        }
        if (localStorage.getItem("ageMax") != null) {
            store.state.ageMax = parseInt(localStorage.getItem("ageMax"))
            // console.log(parseInt(localStorage.getItem("ageMax")))
        }
        if (localStorage.getItem("ageSave") != null) {
            if (localStorage.getItem("ageSave") == "true") {
                store.state.ageSave = true;
            }
            if (localStorage.getItem("ageSave") == "false") {
                store.state.ageSave = false;
            }
            // console.log(store.state.ageSave)
        }


        // Load favorites from local Storage
        // New
        var favoritesObject = JSON.parse(localStorage.getItem("favoritesObject"))
        if (favoritesObject != null) {
            // check if activity still exists in ActivityList and delete otherwise
            var anActivityChanged = false;
            for (const [key, value] of Object.entries(favoritesObject)) {
                var favoriteInActivityList = false;
                store.state.storeActivityList.forEach(function (activity) {
                    if (key == activity.uuid) {
                        favoriteInActivityList = true;
                    }
                })
                if (!favoriteInActivityList) {
                    delete favoritesObject[key]
                    anActivityChanged = true
                }
            }
            if (anActivityChanged) {
                localStorage.setItem('favoritesObject', JSON.stringify(store.state.favoritesObject))
            }
            store.state.favoritesObject = favoritesObject
            // Create List of favorites
            store.state.storeActivityList.forEach(function (activityItem) {
                if (Object.keys(store.state.favoritesObject).includes(activityItem.uuid)) {
                    store.state.storeActivityListFavorites.push(activityItem);
                }
            })
        }
        console.log("-firstVisit: " + store.state.firstVisit)
        console.log("-favorites: " + favoritesObject)
        console.log("-ageMin: " + store.state.ageMin + " ageMax: " + store.state.ageMax + " ageSave" + store.state.ageSave)

    }
}