<!-- ---------- ---------- ---------- HTML Structure ---------- ---------- ---------- -->
<template>
  <div>
    <div class="select-relative-right click-favorite" v-bind:selectedActivityName="activity.activity"  v-bind:selected-activity-name="activity.activity"
      v-bind:selectedActivityuuid="activity.uuid" v-on:click="updateFavorites" v-bind:id="activity.uuid">
      <div v-if="$store.state.storeSelectedActivityId >= 0" class="hearticonparent">
        <svg v-if="!Object.keys($store.state.favoritesObject).includes(activity.uuid)"
          xmlns="http://www.w3.org/2000/svg" height="18px" width="18px" viewBox="0 0 24 24" fill="transparent"
          class="hearticon favoriteselected" stroke="#007bff80" stroke-width="2px" v-bind:class="{
            detailsview1: $router.currentRoute.value.name == 'DetailsView',
          }">
          <path d="M0 0h24v24H0V0z" fill="none" stroke="none" />
          <path
            d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z" />
        </svg>

        <svg v-if="Object.keys($store.state.favoritesObject).includes(activity.uuid) && cFavStatus == 'heart'"
          xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 24 24" width="20px" fill="#dc3545"
          class="hearticon favoriteselected" v-bind:class="{
            detailsview2: $router.currentRoute.value.name == 'DetailsView',
          }">
          <path d="M0 0h24v24H0V0z" fill="none" stroke="none" />
          <path
            d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z" />
        </svg>

        <!--Plan-->
        <svg v-if="Object.keys($store.state.favoritesObject).includes(activity.uuid) && cFavStatus == 'plan'"
          xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 24 24" width="20px" fill="#0dcaf0" v-bind:class="{
            detailsview2: $router.currentRoute.value.name == 'DetailsView',
          }">
          <path d="M0 0h24v24H0V0z" fill="none" />
          <path
            d="M19 4h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V10h14v10zm0-12H5V6h14v2zm-7 5h5v5h-5z" />
        </svg>

        <!--Done-->
        <svg v-if="Object.keys($store.state.favoritesObject).includes(activity.uuid) && cFavStatus == 'done'"
          xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 24 24" width="20px" fill="#20c997" v-bind:class="{
            detailsview2: $router.currentRoute.value.name == 'DetailsView',
          }">
          <path d="M0 0h24v24H0V0z" fill="none" />
          <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z" />
        </svg>

        <!--Exclude-->
        <svg v-if="Object.keys($store.state.favoritesObject).includes(activity.uuid) && cFavStatus == 'deleted'"
          xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 24 24" width="20px" fill="grey" v-bind:class="{
            detailsview2: $router.currentRoute.value.name == 'DetailsView',
          }">
          <path d="M0 0h24v24H0V0z" fill="none" />
          <path
            d="M7 11v2h10v-2H7zm5-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
        </svg>
      </div>
    </div>

    <!--Snackbar-->
    <!-- <transition name="splash">
      <div
        style="position: fixed; top: 50%; right: 50%; transform: translate(-50%, -50%); max-width: 40rem; width: auto; height: auto;
        background: #007bff; color: #ffffff;
        padding: 0.5rem; border-radius: 1rem;
        cursor: pointer; pointer-events: all;
        z-index: 5555;
        box-shadow: 0 2px 8px grey;
      "
        v-if="snackbar || true"
        @click="snackbar = false"
        v-bind:class="{
          white: cFavStatus == 'noheart',
          red: cFavStatus == 'heart',
          blue: cFavStatus == 'plan',
          green: cFavStatus == 'done',
          grey: cFavStatus == 'deleted'
        }"
      >
        <span>{{ text }}</span>
      </div>
    </transition>-->
  </div>
</template>

  <!-- ---------- ---------- ---------- Code ---------- ---------- ---------- -->

<script>
/* eslint-disable */
import UpdateFavoritesService from "@/services/UpdateFavoritesService.js";

export default {
  components: {},
  props: {
    activityNumber: Number,
  },
  data() {
    return {
      snackbar: false,
      text: 'Below',
      textObject: {
        heart: {DE: "Zu Favoriten hinzugefügt",EN: "Added to favorites"},
        plan: {DE: "Zu geplanten Aktivitäten hinzugefügt",EN: "Added to planned activities"},
        done: {DE: "Zu erledigten Aktivitäten hinzugefügt",EN: "Added to done activities"},
        noheart: {DE: "Aktivität nicht mehr markiert",EN: "Activity not marked anymore"}
      },
      i: 0,
    };
  },
  mounted() { },
  methods: {
    updateFavorites(event) {
      var uuid = event.target.getAttribute("selectedActivityuuid")
      var name = event.target.getAttribute("selectedActivityName")
      var favType = this.cFavStatus
      console.log("+++++++++++++++++++++++++++++++++")

      console.log(this.snackbar)
      console.log("updateFavorites")
      if (favType == "noheart") { favType = 'heart'; this.text = this.textObject.heart[this.$store.state.user.countryCode] } else
        if (favType == "heart") { favType = 'plan'; this.text = this.textObject.plan[this.$store.state.user.countryCode] } else
          if (favType == "plan") { favType = 'done'; this.text = this.textObject.done[this.$store.state.user.countryCode] } else
            if (favType == "done") { favType = 'noheart'; this.text = this.textObject.noheart[this.$store.state.user.countryCode] } else // Aktivität wird beim nächsten Mal nicht mehr angezeigt
              if (favType == "deleted") { favType = 'noheart'; this.text = this.textObject.noheart[this.$store.state.user.countryCode] }
      console.log(favType)

      this.$store.state.favoritesSnackboarAndText.snackbar = true
      this.$store.state.favoritesSnackboarAndText.text = this.text
      this.$store.state.favoritesSnackboarAndText.favType = favType


      UpdateFavoritesService.updateFavorites(uuid, name, favType);
      var i = this.i
      var that = this
      console.log(i)
      console.log(this.i)

      setTimeout(function () {
        console.log("FALSE")
        if (i + 1 == that.i) {
          that.$store.state.favoritesSnackboarAndText.snackbar = false
        }
      }, 800);
      this.i++
    },
  },
  computed: {
    cFavStatus() {
      if (!Object.keys(this.$store.state.favoritesObject).includes(this.activity.uuid)) {
        return "noheart"
      }
      else {
        if (!this.$store.state.favoritesObject[this.activity.uuid].favType) { return "heart" }
        else
          return this.$store.state.favoritesObject[this.activity.uuid].favType
      }
    },
    activity() {
      return this.$store.state.storeActivityList[this.activityNumber];
    },
  },
};
</script>

<style scoped>
.select-relative-right {
  display: inline-block;
  width: 3rem;
  cursor: pointer;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
}

.hearticonparent {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}

.hearticon {
  pointer-events: none;
}

.favoriteselected {
  color: #ff0000;
}

.detailsview1 {
  stroke: #ffffff;
  height: 20px !important;
  width: 20px !important;
}

.detailsview2 {
  height: 22px !important;
  width: 22px !important;
}
</style>