<template>
  <div class="page">test</div>
</template>
<script>
/* eslint-disable */

export default {
  components: {},
  data() {
    return {};
  },
  methods: {},

  computed: {},
};
</script>
<style scoped>
</style>