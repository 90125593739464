/* eslint-disable */

import axios from 'axios'
import store from '@/store/index.js'

export default {
  getWikipediaData(activity) {
    console.log("GetWikipediaData is executed")
    store.state.wikiLoaded = false;
    //    let extractShort = extract.substr(0,200)
    let contentUrl
    if (store.state.user.countryCode == 'DE') {
      contentUrl = 'https://de.wikipedia.org/w/api.php?format=json&action=query&origin=*&prop=extracts&exintro&explaintext&redirects=1&titles=' + activity
    }
    else { contentUrl = 'https://en.wikipedia.org/w/api.php?format=json&action=query&origin=*&prop=extracts&exintro&explaintext&redirects=1&titles=' + activity }
    let content;

    axios.get(contentUrl)
      .then(response => (
        parseData(response.data)
      ))

    function parseData(data) {
      //console.log(data)
      let page = data.query.pages;
      let pageId = Object.keys(data.query.pages)[0];
      //console.log(pageId)
      content = page[pageId].extract;
      //console.log(content)
      store.state.storeWikipediaText = content
      store.state.wikiLoaded = true;
    }
  }
}