/* eslint-disable */

import axios from 'axios'
import store from '@/store/index.js'

export default {
  getLinks() {
    console.log("getLinks is executed")
    //console.log("has to be executed after countryCode is known")

    var linkList = []

    axios.get('data.json')
      .then(response => (
        parseData(response.data.links) // executes function parseData with the input response.data.feed.entry (CB)
      ))

    function parseData(entries) {
      for (const [key, value] of Object.entries(entries)) {
        if (key != "") {
          var entry = {
            linkname: value.linkname,
            linkimage: value.linkimage,
            linktype: value.linktype,
            linktext: { EN: value.linktexten, DE: value.linktextde },
            linkurl: { EN: value.linkurlen, DE: value.linkurlde },
            linkurlend: value.linkurlend,
          };

          linkList.push(entry);
        }
      };

      console.log("store.state.storeLinkList")
      console.log(linkList)

      store.state.storeLinkList = linkList
      store.state.linksLoaded = true
      console.log("getLinks was executed, store.state.linksLoaded = true")
    }
  }
}