<template>
  <div style="margin-bottom: 5rem">

    <!--Search-->
    <div class="container" style="margin-top: 1rem">
      <input
        id="search"
        v-on:input="searchActivities"
        v-model="$store.state.searchText"
        type="search"
        :placeholder="searchtext"
        class="form-control bg-none border-1"
        autocomplete="off"
      />
    </div>

    <!--v-on:click="changeActivityListView"-->
    <!-- <div class="activityListTitle conditionsandinterests">
    </div>-->

    <!--Maybe find a more inteligent solution than to forceRerender all the time - actually I only need it, if-->
    <ActivityListItemBoxComponent :key="componentKey" v-on:click="forceRerender('heart')" />

    <!--Feedback Mail-->
    <div class="container">
      <a
        style="text-decoration: none; color: #2c3e50"
        href="mailto:fback&commat;timetip&period;app?subject=Feedback%20Timetip"
      >
        <div class style="font-size: 0.9rem; padding: 0.375rem 0rem">
          <div
            style="
              cursor: pointer;
              overflow: hidden;
              padding-top: 20%;
              background: #ffffff;
              position: relative;
            "
          >
            <div
              style=" position: absolute; top: 0; left: 0; width: 100%; height: 100%; display: flex; align-items: center;"
            >
              <div
                style=" width: 30%; background: #dee2e680; height: 100%; border-radius: 0.5rem; display: flex;
                  justify-content: center; align-items: center;
                "
              >
                <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24"
                  height="24px"  viewBox="0 0 24 24" width="24px" fill="#FFFFFF"
                >
                  <g>
                    <rect fill="none" height="24" width="24" />
                  </g>
                  <g>
                    <path
                      d="M11.07,12.85c0.77-1.39,2.25-2.21,3.11-3.44c0.91-1.29,0.4-3.7-2.18-3.7c-1.69,0-2.52,1.28-2.87,2.34L6.54,6.96 C7.25,4.83,9.18,3,11.99,3c2.35,0,3.96,1.07,4.78,2.41c0.7,1.15,1.11,3.3,0.03,4.9c-1.2,1.77-2.35,2.31-2.97,3.45 c-0.25,0.46-0.35,0.76-0.35,2.24h-2.89C10.58,15.22,10.46,13.95,11.07,12.85z M14,20c0,1.1-0.9,2-2,2s-2-0.9-2-2c0-1.1,0.9-2,2-2 S14,18.9,14,20z"
                    />
                  </g>
                </svg>
              </div>
              <div style="width: 60%; text-align: left; font-size: 0.9rem">
                <div style="padding-left: 5%">
                  <span
                    v-if="$store.state.user.countryCode == 'DE'"
                  >Schick mir gerne eine Mail, falls dir eine Aktivität fehlt</span>
                  <span v-else>Please send me an email, if you have further ideas</span>
                </div>
              </div>
              <div style="width: 10%"></div>
            </div>
          </div>
        </div>
      </a>
    </div>

    <BottomButtonsComponent />
  </div>
</template>    

<script>
/* eslint-disable */
import SearchActivitiesService from "@/services/SearchActivitiesService.js";
import BottomButtonsComponent from "@/components/ActivityList/BottomButtonsComponent.vue";
import ActivityListItemBoxComponent from "@/components/ActivityList/ActivityListItemBoxComponent.vue";
import ClickedFiltersComponent from "@/components/Filters/ClickedFiltersComponent.vue";

export default {
  components: {
    BottomButtonsComponent,
    ActivityListItemBoxComponent,
    ClickedFiltersComponent,
  },
  props: {
    checker: 0, // als Erinnerung
  },
  data() {
    return { componentKey: 0 };
  },
  created() {
    if (this.$router.currentRoute.value.name == "ActivityListView") {
      // window.scrollTo(0, 0);
    }
  },
  unmounted() {
    SearchActivitiesService.searchActivities("reset");
  },
  methods: {
    forceRerender(input) {
      if (
        input == "heart" &&
        Object.keys(this.$store.state.favoritesObject).length != 0
      ) {
      } else {
        this.componentKey += 1;
      }
      if (input == "filterclick") {
        this.componentKey += 1;
      }
    },
    searchActivities: function (event) {
      SearchActivitiesService.searchActivities(event);
      this.forceRerender();
    },
    changeActivityListView: function (event) {
      if (this.$store.state.activityListView == "list") {
        this.$store.state.activityListView = "card";
      } else {
        this.$store.state.activityListView = "list";
      }
    },
  },
  watch: {
    activityCount(){this.forceRerender('filterclick')}
  },
  computed: {
    activityCount(){return this.$store.state.storeActivityListFiltered.length},
    activityListFiltered() {
      return this.$store.state.storeActivityListFiltered;
    },
    searchtext() {
      var countryCode = "Aktivitäten durchsuchen";
      if (this.$store.state.user.countryCode != "DE") {
        countryCode = "Search activities";
      }
      return countryCode;
    },
  },
};
</script>

<style scoped>
.clickedFilterList {
  padding-top: 0em;
  padding-bottom: 1rem;
}
.activityListTitle {
  margin: 0rem 0rem 0.375rem 0rem;
  padding: 0.5rem 0rem;
  background-color: #6fb5ff;
  color: white;
  position: sticky;
  top: 2.5rem;
  z-index: 100;
}
.conditionsandinterests {
  padding: 0.5rem 0rem;
  background-color: transparent;
  color: #007bff;
  font-weight: bold;
  position: sticky;
  top: 2.5rem;
}
.listbox {
  background-color: #20c99627;
  padding: 1rem;
  border-radius: 0.25rem;
}
.list-item {
  cursor: pointer;

  padding: 0.25rem 0.5rem;
  box-shadow: 0;
  border-radius: 0rem;
  border: 0;
  background-color: transparent;
  color: #000000;
  display: block;
  text-align: center;
  align-items: center;
  margin: auto;
  margin-top: 0rem;
  margin-bottom: 0rem;
  border-bottom: 0px solid #ced4da;
  width: auto;
}
.container {
  /*max-width: 40vh;*/
  max-width: 50rem;
}
.borderandpadding {
  padding-top: 0.375em;
  border-bottom: 1px solid #ced4da;
  padding-bottom: 0.375em;
}
.cardContainer {
  cursor: pointer;
  /*
  border-bottom: 1px solid #ced4da;
  padding-top: 0.2em;
  padding-bottom: 0.2em;*/

  overflow: hidden;
  padding-top: 20%; /*18.54% equals golden ratio*/
  background: #ffffff;
  position: relative;
}
.cardContainerAbsolute {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.cardLeft {
  position: absolute;
  left: 0;
  width: 30%;
  height: 100%;
}
.cardImageContainer {
  width: 100%;
  overflow: hidden;
  height: 100%;
  background: #6fb5ff;
  pointer-events: none;
}
.cardImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
}

.cardMiddle {
  position: absolute;
  left: 30%;
  width: 60%;
  height: 100%;
}

.cardMiddleText {
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
  padding-left: 5%;
  pointer-events: none;
  text-align: left;
}
.cardRight {
  position: absolute;
  left: 90%;
  width: 10%;
  height: 100%;
}
.cardRightHeart {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}
.hearticon {
  pointer-events: none;
}
/*.list-item:hover {
  font-weight: bold;
}*/
.selected {
  background-color: #20c997;
  color: #ffffff;
  border-radius: 0.25rem;
}
.favoriteselected {
  color: red;
}
.favoritenotselected {
  color: #6fb5ff;
}
.comment {
  margin-top: 2em;
  font-size: 60%;
}
.form-control {
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
}
/*.image-list {
}*/
.image-item {
  cursor: pointer;
  width: 25%;
  margin: 0;
  padding: 0rem 0rem;
  display: inline;
}

.thumbnail {
  position: relative;
}

.caption {
  color: #000000;
  font-size: 1.5vw;
  /*font-weight: bold;*/
  position: absolute;
  top: 10%;
  left: 0;
  width: 100%;
}
.selectedFilterContainer {
  padding-top: 1rem;
  font-size: 0.9rem;
}
.selectedFilter {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  margin: 0.1rem;
  padding: 0rem 0.5rem;
  border-radius: 0.6rem;
  border: 0;
  cursor: pointer;
}
.delete-underline {
  text-decoration: none;
  color: #ffffff;
}
.noclick {
  pointer-events: none;
}
</style>