<!-- ---------- ---------- ---------- HTML Structure ---------- ---------- ---------- -->
<template>
  <div class="container explanationstyle" v-on:click="$router.push('/')">
    <h1 v-if="de" class="header">Willkommen bei Timetip!</h1>
    <h1 v-else class="header">Welcome to Timetip!</h1>

    <div class="cardImageContainer">
      <TimetipSVG />
      <div class="spinner-container" v-if="!$store.state.bootingDone">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
          <div class="line"></div>
        </div>
      </div>
    </div>
    <h4 v-if="de" class="text">
      Entdecke über 500 Aktivitäten <br />für deine Freizeit!
    </h4>
    <!--Finde Ideen und Tipps <br />für deine Freizeit!-->
    <h4 v-else class="text">Get ideas and tips for your free time!</h4>
  </div>
  <!--Wähle zwischen mehr als 100 Filtern und entdecke über 500 Aktivitäten, die deinen Wünschen entsprechen.-->
</template>

  <!-- ---------- ---------- ---------- Code ---------- ---------- ---------- -->

<script>
/* eslint-disable */
import TimetipSVG from "@/components/Navigation/TimetipSVG.vue";

export default {
  components: { TimetipSVG },
  data() {
    return {};
  },
  methods: {
    setAltImg(event) {
      console.log("image could not be loaded");
      event.target.src = require("../../assets/timetip-logo.svg");
    },
  },
  computed: {
    de() {
      return this.$store.state.user.countryCode == "DE";
    },
  },
};
</script>

<style scoped>
.spinner-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.spinner-border {
  color: transparent;
  height: 100%;
  width: 100%;
  animation: 2s linear infinite spinner-border;
}
.explanationstyle {
  padding-top: 10vh;
  text-align: left;
  margin-bottom: 2rem;
  max-width: 30rem;
  z-index: 301;
  cursor: pointer;
  height: 90vh;
}
.header {
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 3rem;
  color: white;
}

.line {
  position: absolute;
  transform: translate(0%, -50%);
  height: 1vw;
  width: 15vh;
  background: #007bff;
  border-radius: 2rem;
}
.cardImageContainer {
  position: relative;
  padding: 30%;
  margin-bottom: 3rem;
}
.cardImage {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.cardImage2 {
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.text {
  text-align: center;
  color: white;
  line-height: 150%;
}
</style>