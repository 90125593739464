<template>
  <swiper :modules="[Virtual]" :slidesPerView="1" :centeredSlides="true" :spaceBetween="0" :navigation="false"
    :rewind="true" :virtual="true" class="mySwiper" @swiper="setSwiperRef" @transitionEnd="updateActiveIndex" :style="{
      '--swiper-navigation-color': '#fff',
      '--swiper-pagination-color': '#fff',
      '--swiper-navigation-size': '4vh',
    }">
    <swiper-slide v-for="(oneActivity, index) in $store.state.storeActivityListFiltered" :key="index"
      :virtualIndex="index">
      <div>
        <div class="container">
          <!--Activity Title-->
          <div class="details-title">
            <span v-if="$store.state.user.countryCode != 'DE'">
              {{
              oneActivity.activity
              }}
            </span>
            <span v-if="$store.state.user.countryCode == 'DE'">
              {{
              oneActivity.activityde
              }}
            </span>
          </div>
          <!--Activity Image-->
          <div class="outerbox" style="display: flex; justify-content:center;">
            <div style=" width: 100%; max-width: 60vh; position: relative">
              <v-responsive :aspect-ratio="3 / 2" style="position: relative; pointer-events: none">
                <div class="cardmiddle">

                  <!--Heart bottom right-->
                  <DetailsHeartComponent :activityNumber="oneActivity.listPosition" class="redheart" />

                  <!--Activitiy Number and Count top right-->
                  <div class="swipercount" v-on:click="
                    ($store.state.filterClicked = true),
                    $router.push('/filter')
                  ">
                    <span>
                      {{ index + 1 }}/{{
                      $store.state.storeActivityListFiltered.length
                      }}
                    </span>
                  </div>

                  <!--Info Button bottom left-->
                  <div class="bottomleft" v-on:click="switchcategory('info')" v-bind:class="{
                    nopointer: $store.state.firstVisit,
                  }" v-if="oneActivity.wikitext[$store.state.user.countryCode] != 0">
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px"
                      fill="#ffffff" v-bind:class="{
                        switchcategoryblue: $store.state.detailsView == 'info',
                      }">
                      <path d="M0 0h24v24H0V0z" fill="none" />
                      <path
                        d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
                    </svg>
                  </div>
                  <!--Share Button top right-->
                  <div class="switchcategory" v-on:click="sharebuttonclick()" v-bind:class="{
                    nopointer: $store.state.firstVisit,
                  }">
                    <svg xmlns="http://www.w3.org/2000/svg" height="21px" viewBox="0 0 24 24" width="21px"
                      fill="#FFFFFF" v-bind:class="{
                        bottomleftblue: $store.state.detailsView == 'spice',
                      }">
                      <path d="M0 0h24v24H0V0z" fill="none" />
                      <path
                        d="M18 16.08c-.76 0-1.44.3-1.96.77L8.91 12.7c.05-.23.09-.46.09-.7s-.04-.47-.09-.7l7.05-4.11c.54.5 1.25.81 2.04.81 1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3c0 .24.04.47.09.7L8.04 9.81C7.5 9.31 6.79 9 6 9c-1.66 0-3 1.34-3 3s1.34 3 3 3c.79 0 1.5-.31 2.04-.81l7.12 4.16c-.05.21-.08.43-.08.65 0 1.61 1.31 2.92 2.92 2.92s2.92-1.31 2.92-2.92c0-1.61-1.31-2.92-2.92-2.92zM18 4c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zM6 13c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm12 7.02c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1z" />
                    </svg>
                  </div>

                  <!--Previous and Next Buttons left and right-->
                  <div class="left" v-on:click="slideTo(index)" v-if="!$store.state.isTouchDevice">
                    <svg v-bind:class="{ noshow: index == 0 }" xmlns="http://www.w3.org/2000/svg" height="22px"
                      viewBox="0 0 24 24" width="22px" fill="#ffffff">
                      <path d="M0 0h24v24H0V0z" fill="none" opacity=".87" />
                      <path d="M17.51 3.87L15.73 2.1 5.84 12l9.9 9.9 1.77-1.77L9.38 12l8.13-8.13z" />
                    </svg>
                  </div>
                  <div class="right" v-on:click="slideTo(index + 2)" v-if="!$store.state.isTouchDevice">
                    <svg v-bind:class="{
                      noshow:
                        index + 1 ==
                        $store.state.storeActivityListFiltered.length
                    }" xmlns="http://www.w3.org/2000/svg" height="22px" viewBox="0 0 24 24" width="22px"
                      fill="#ffffff">
                      <g>
                        <path d="M0,0h24v24H0V0z" fill="none" />
                      </g>
                      <g>
                        <polygon points="6.23,20.23 8,22 18,12 8,2 6.23,3.77 14.46,12" />
                      </g>
                    </svg>
                  </div>
                  <!--Image-->
                  <!--ShowTags Click area in the middle of the image to see clickedFilters and Activity tags-->
                  <div v-on:click="showClickedFilters(), showShowtags()" style="
                      pointer-events: all;
                      z-index: 0;
                      cursor: pointer;
                      position: relative;
                      height: 100%;
                    ">
                    <div class="darkcorners"></div>
                    <img v-if="oneActivity.imageavailable == 1"
                      v-bind:src="'https://storage.googleapis.com/timetip-images/activity-images/' + oneActivity.imagetext + '.jpg'"
                      @load="onImgLoad" @error="setAltImg" />
                    <ImageLoaded class="imageLoaded" v-if="
                      oneActivity.imageavailable == 2
                    " />
                  </div>
                </div>
                <!--Loading Spinner-->
                <div class="loading" v-if="!isLoaded">
                  <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
              </v-responsive>
            </div>
            <!--Activity Category Selector-->
            <!-- <DetailCategoriesSelectorComponent
              class="categorySelector"
              v-bind:class="{ nopointer: $store.state.firstVisit }"
              v-if="false"
            />-->
          </div>

          <!--Text, Links and Information below the image-->
          <div>
            <DetailCategoriesComponent :activityListPosition="oneActivity.listPosition"
              v-bind:class="{ nopointer: $store.state.firstVisit }" />
          </div>
        </div>
      </div>
    </swiper-slide>
    <!--Slide Up TagsComponent-->
    <TagsComponent :showtags="showtags" />
  </swiper>
</template>
<script>
/* eslint-disable */
import DetailCategoriesComponent from "@/components/Details/DetailCategoriesComponent.vue";
import DetailCategoriesSelectorComponent from "@/components/Details/DetailCategoriesSelectorComponent.vue";
import GetWikipediaData from "@/services/GetWikipediaData.js";
import GetImageService from "@/services/GetImageService.js";

import ImageLoaded from "@/components/Details/ImageLoaded.vue";
import DetailsHeartComponent from "@/components/Details/DetailsHeartComponent.vue";

// import Swiper core and required modules
import { Virtual, Navigation, Pagination, Scrollbar, A11y } from "swiper";

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import TagsComponent from "@/components/Details/TagsComponent.vue";

export default {
  components: {
    Swiper,
    SwiperSlide,
    ImageLoaded,
    DetailsHeartComponent,
    DetailCategoriesComponent,
    DetailCategoriesSelectorComponent,
    TagsComponent,
  },
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log("slide change");
    };
    // Create array with 1000 slides
    const slides = Array.from({ length: 600 }).map(
      (_, index) => `Slide ${index + 1}`
    );
    return {
      onSwiper,
      onSlideChange,
      modules: [Navigation, Pagination, Scrollbar, A11y],
      slides,
      Virtual,
    };
  },
  data() {
    return {
      isLoaded: false,
      showtags: 0,
    };
  },
  beforeCreate() { },
  created() { },
  mounted() {
    console.log("---DetailsSwiper mounted");
    this.$store.state.detailsView = "link";

    // get listPosition Todo: Maybe make that simpler
    var that = this
    var listPosition = 0
    var i = 0
    this.$store.state.storeActivityListFiltered.forEach(function (activity) {
      if (activity.id == that.$store.state.storeSelectedActivityId) {
        listPosition = i
      }
      i++
    })
    // console.log("move to selected swiper slide");
    this.swiperRef.slideTo(listPosition, 0);

    // Execute a swipe Animation on the first Visit
    if (this.$store.state.firstVisit) {
      var that = this;

      // Those two are needed to prevent a bug that the slidePrev Below takes too long
      setTimeout(function () {
        that.swiperRef.slideNext(1000);
        that.swiperRef.slidePrev(1000);
      }, 5);

      setTimeout(function () {
        that.swiperRef.slideNext(3000);
        setTimeout(function () {
          that.swiperRef.slidePrev(3000);
          that.$store.state.firstVisit = false;
        }, 500);
      }, 500);
      // if (this.$store.state.browserName != "safari" && false) {
      //   setTimeout(function () {
      //     that.swiperRef.slidePrev(6000);
      //     that.$store.state.firstVisit = false;
      //   }, 1000);
      // } else {

      // }
    }
  },
  // This is needed if I delete a clicked filter and the activities change. Normally they would not go back to 1.
  watch: {
    activityListFilteredLength() {
      console.log("move to first swiper slide");
      // swiper.slideTo(index, speed, runCallbacks)
      this.swiperRef.slideTo(0, 0);
    },
  },
  computed: {
    activityListFilteredLength() {
      return this.$store.state.storeActivityListFiltered.length;
    },
    activitySelected() {
      if (this.$store.state.storeSelectedActivityId >= 0) {
        return true;
      } else {
        return false;
      }
    },
  },

  methods: {
    showClickedFilters() {
      this.$store.state.showClickedFiltersAtDetails = !this.$store.state.showClickedFiltersAtDetails
    },
    showShowtags() {
      if (this.$store.state.betaFeatures) { this.showtags = this.showtags + 1; }
    },
    sharebuttonclick() {
      console.log("hallo");
      console.log(
        this.$store.state.appTexts.share[this.$store.state.user.countryCode] +
        ": '" +
        this.$store.state.activity[
        this.$store.state.appTexts.activitynamehelper[
        this.$store.state.user.countryCode
        ]
        ] +
        "'. " +
        this.$store.state.appTexts.shareend[
        this.$store.state.user.countryCode
        ] +
        " - "
      );
      if (navigator.share) {
        navigator
          .share({
            title: document.title + " App",
            text:
              this.$store.state.appTexts.share[
              this.$store.state.user.countryCode
              ] +
              ": '" +
              this.$store.state.activity[
              this.$store.state.appTexts.activitynamehelper[
              this.$store.state.user.countryCode
              ]
              ] +
              "'. " +
              this.$store.state.appTexts.shareend[
              this.$store.state.user.countryCode
              ] +
              " - ",
            url: "https://www.timetip.app/",
          })
          .then(() => console.log("Successful share"))
          .catch((error) => console.log("Error sharing:", error));
      }
    },
    switchcategory(input) {
      console.log("switchcategory");
      if (input == "info") {
        switch (this.$store.state.detailsView) {
          case "link":
            console.log("switch to info");
            this.$store.state.detailsView = "info";
            GetWikipediaData.getWikipediaData(
              this.$store.state.activity.wikitext[
              this.$store.state.user.countryCode
              ]
            );
            break;
          case "info":
            console.log("switch to link");
            this.$store.state.detailsView = "link";
            break;
        }
      }
    },
    onImgLoad() {
      this.isLoaded = true;
      console.log("---DetailsImage loaded");
    },
    setAltImg(event) {
      console.log("image could not be loaded");
      event.target.src = require("../../assets/error.png");
    },
    setSwiperRef(swiper) {
      this.swiperRef = swiper;
    },
    slideTo(index) {
      this.swiperRef.slideTo(index - 1, 400);
    },
    append() {
      this.swiperRef.virtual.appendSlide("Slide " + ++this.appendNumber);
    },
    // Todo: Why two times prependNumber?
    prepend() {
      this.swiperRef.virtual.prependSlide([
        "Slide " + --this.prependNumber,
        "Slide " + --this.prependNumber,
      ]);
    },
    updateActiveIndex: function () {
      console.log("updateActiveIndex");
      window.scrollTo(0, 0);
      this.$store.state.detailsView = "link";
      this.$store.state.storeSelectedActivityId = this.$store.state.storeActivityListFiltered[this.swiperRef.activeIndex].id
      this.$store.state.activity = this.$store.state.storeActivityListFiltered[this.swiperRef.activeIndex]

      // Do this for all cases | Todo: Check, if that is needed
      this.$store.state.imageLoaded = false;

      // get Image data
      if (this.$store.state.activity.imageavailable != 1) {
        GetImageService.getImage(
          this.$store.state.activity.wikitext.EN,
          this.$store.state.activity.imagetext
        );
      }


      if (this.slideNumber != this.swiperRef.activeIndex) {
        this.slideNumber = this.swiperRef.activeIndex;

      } else {
        console.log("slide number didn't actually change");
        // if (this.swiperRef.activeIndex == this.$store.state.storeActivityListFiltered.length - 1) {
        //   this.$store.state.lastSlide = true;
        //   console.log("Last Slide");
        //   this.swiperRef.slideTo(0, 0)
        //   let that = this;
        //   setTimeout(function () {
        //     console.log("that.$store.state.lastSlide = false");
        //     that.$store.state.lastSlide = false;
        //   }, 600);
        // }
      }
    },
  },
};
</script>
<style scoped>
.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
}

.swiper-container {
  width: 100%;
  height: 100%;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 10;
  border-radius: 1rem;
}

.nopointer {
  pointer-events: none;
}

.imageLoaded {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.categories {
  bottom: 0;
  position: absolute;
  width: 100%;
  z-index: 160;
}

.redheart {
  position: absolute;
  z-index: 2000;
  bottom: 0;
  right: 0;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  width: 3rem;
  pointer-events: all;
}

.switchcategory {
  position: absolute;
  color: white;
  top: 0;
  right: 0;
  text-align: left;
  pointer-events: all;
  cursor: pointer;
  z-index: 2;
  padding: 0.75rem;
}

.darkcorners {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 10000;
  background-image: radial-gradient(transparent 60%, black 300%);
  border-radius: 1rem;
}

.swipercount {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  padding-top: 0.75rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 0.75rem;

  pointer-events: all;
  cursor: pointer;
  color: white;
}

.switchcategoryblue {
  fill: #007bff;
}

.bottomleft {
  position: absolute;
  color: white;
  bottom: 0;
  left: 0;
  pointer-events: all;
  cursor: pointer;
  z-index: 2;
  padding: 0.75rem;
}

.bottomleftblue {
  fill: #007bff;
}

.bottommiddle {
  position: absolute;
  color: white;
  bottom: 0;
  left: 20%;
  right: 20%;
  text-align: middle;
  pointer-events: all;
  cursor: pointer;
  z-index: 2;
  padding: 0.75rem;
}

.swiper-container {
  width: 100%;
  height: 100%;
}

/* DetailView */

.container {
  max-width: 80vh
}

.details-title {
  z-index: 80;
  font-weight: bold;
  padding: 0.75rem 0.2rem 0.75rem 0.2rem;
  color: #000000;
  width: 100%;
}

.outerbox {
  padding-bottom: 1rem;
  /*2.2 rem */
  position: relative;
}

.swiperi {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  border-radius: 1rem;
  background: #9fcdff;
  z-index: 9;
}

/*SwiperContainer */
.relativecontainer {
  overflow: hidden;
  padding-top: 71.8%;
  /* 61,8 = Golden Cut */
  position: relative;
  width: 100%;
  max-height: 10%;
}

.cardmiddle {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.categorySelector {
  bottom: 0;
  position: absolute;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.nopointer {
  pointer-events: none;
}

.left {
  position: absolute;
  color: white;
  top: 50%;
  left: 0%;
  transform: translate(0, -50%);
  pointer-events: all;
  cursor: pointer;
  z-index: 2;
  padding: 0.75rem;
}

.right {
  position: absolute;
  color: white;
  top: 50%;
  right: 0%;
  transform: translate(0, -50%);
  pointer-events: all;
  cursor: pointer;
  z-index: 2;
  padding: 0.75rem;
}

.noshow {
  fill: transparent;
  color: transparent;
  pointer-events: none;
}
</style>