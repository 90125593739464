<!-- ---------- ---------- ---------- HTML Structure ---------- ---------- ---------- -->
<template>
  <div class="page">
    <!--English-->
    <div v-if="$store.state.user.countryCode != 'DE'" class="container">
      <h1>Privacy Policy</h1>
      <h2>1. An overview of data protection</h2>
      <h3>General information</h3>
      <p>
        The following information will provide you with an easy to navigate
        overview of what will happen with your personal data when you visit this
        website. The term “personal data” comprises all data that can be used to
        personally identify you. For detailed information about the subject
        matter of data protection, please consult our Data Protection
        Declaration, which we have included beneath this copy.
      </p>
      <h3>Data recording on this website</h3>
      <h4>
        Who is the responsible party for the recording of data on this website
        (i.e., the “controller”)?
      </h4>
      <p>
        The data on this website is processed by the operator of the website,
        whose contact information is available under section “Information about
        the responsible party (referred to as the “controller” in the GDPR)” in
        this Privacy Policy.
      </p>
      <h4>How do we record your data?</h4>
      <p>
        We collect your data as a result of your sharing of your data with us.
        This may, for instance be information you enter into our contact form.
      </p>
      <h2>2. Hosting</h2>
      <h3>External Hosting</h3>
      <p>
        This website is hosted by an external service provider (host). Personal
        data collected on this website are stored on the servers of the host.
        These may include, but are not limited to, IP addresses, contact
        requests, metadata and communications, contract information, contact
        information, names, web page access, and other data generated through a
        web site.
      </p>
      <p>
        The host is used for the purpose of fulfilling the contract with our
        potential and existing customers (Art. 6(1)(b) GDPR) and in the interest
        of secure, fast, and efficient provision of our online services by a
        professional provider (Art. 6(1)(f) GDPR). If appropriate consent has
        been obtained, the processing is carried out exclusively on the basis of
        Art. 6 (1)(a) GDPR and § 25 (1) TTDSG, insofar the consent includes the
        storage of cookies or the access to information in the user's end device
        (e.g., device fingerprinting) within the meaning of the TTDSG. This
        consent can be revoked at any time.
      </p>
      <p>
        Our host will only process your data to the extent necessary to fulfil
        its performance obligations and to follow our instructions with respect
        to such data.
      </p>
      <p>We are using the following host:</p>
      <p>
        Google Ireland Limited<br />
        Gordon House, Barrow Street<br />
        Dublin 4<br />
        Irland
      </p>
      <h2>3. General information and mandatory information</h2>
      <h3>Data protection</h3>
      <p>
        The operators of this website and its pages take the protection of your
        personal data very seriously. Hence, we handle your personal data as
        confidential information and in compliance with the statutory data
        protection regulations and this Data Protection Declaration.
      </p>
      <h3>
        Information about the responsible party (referred to as the “controller”
        in the GDPR)
      </h3>
      <p>The data processing controller on this website is:</p>
      <p>
        Timetip GmbH<br />
        Angererstrasse, 24<br />
        80796 München
      </p>

      <p>
        <img v-bind:src="require('../assets/ttp.png')" class="phonum" /><br />
        <img v-bind:src="require('../assets/ttm.png')" class="emage" />
      </p>
      <p>
        The controller is the natural person or legal entity that
        single-handedly or jointly with others makes decisions as to the
        purposes of and resources for the processing of personal data (e.g.,
        names, e-mail addresses, etc.).
      </p>
      <h3>Right to log a complaint with the competent supervisory agency</h3>
      <p>
        In the event of violations of the GDPR, data subjects are entitled to
        log a complaint with a supervisory agency, in particular in the member
        state where they usually maintain their domicile, place of work or at
        the place where the alleged violation occurred. The right to log a
        complaint is in effect regardless of any other administrative or court
        proceedings available as legal recourses.
      </p>
      <h3>SSL and/or TLS encryption</h3>
      <p>
        For security reasons and to protect the transmission of confidential
        content, such as purchase orders or inquiries you submit to us as the
        website operator, this website uses either an SSL or a TLS encryption
        program. You can recognize an encrypted connection by checking whether
        the address line of the browser switches from “http://” to “https://”
        and also by the appearance of the lock icon in the browser line.
      </p>
      <p>
        If the SSL or TLS encryption is activated, data you transmit to us
        cannot be read by third parties.
      </p>
      <h3>Rejection of unsolicited e-mails</h3>
      <p>
        We herewith object to the use of contact information published in
        conjunction with the mandatory information to be provided in our Site
        Notice to send us promotional and information material that we have not
        expressly requested. The operators of this website and its pages reserve
        the express right to take legal action in the event of the unsolicited
        sending of promotional information, for instance via SPAM messages.
      </p>
      <h2>4. Recording of data on this website</h2>
      <h3>Cookies</h3>
      <p>
        Our websites and pages use what the industry refers to as “cookies.”
        Cookies are small text files that do not cause any damage to your
        device. They are either stored temporarily for the duration of a session
        (session cookies) or they are permanently archived on your device
        (permanent cookies). Session cookies are automatically deleted once you
        terminate your visit. Permanent cookies remain archived on your device
        until you actively delete them, or they are automatically eradicated by
        your web browser.
      </p>
      <p>
        In some cases, it is possible that third-party cookies are stored on
        your device once you enter our site (third-party cookies). These cookies
        enable you or us to take advantage of certain services offered by the
        third party (e.g., cookies for the processing of payment services).
      </p>
      <p>
        Cookies have a variety of functions. Many cookies are technically
        essential since certain website functions would not work in the absence
        of the cookies (e.g., the shopping cart function or the display of
        videos). The purpose of other cookies may be the analysis of user
        patterns or the display of promotional messages.
      </p>
      <p>
        Cookies, which are required for the performance of electronic
        communication transactions, or for the provision of certain functions
        you want to use (e.g., for the shopping cart function) or those that are
        necessary for the optimization (required cookies) of the website (e.g.,
        cookies that provide measurable insights into the web audience), shall
        be stored on the basis of Art. 6(1)(f) GDPR, unless a different legal
        basis is cited. The operator of the website has a legitimate interest in
        the storage of required cookies to ensure the technically error free and
        optimized provision of the operator’s services. If your consent to the
        storage of the cookies and similar recognition technologies has been
        requested, processing occurs exclusively on the basis of the consent
        obtained (Art. 6(1)(a) GDPR and § 25 (1) TTDSG); this consent may be
        revoked at any time.
      </p>
      <p>
        You have the option to set up your browser in such a manner that you
        will be notified any time cookies are placed and to permit the
        acceptance of cookies only in specific cases. You may also exclude the
        acceptance of cookies in certain cases or in general or activate the
        delete function for the automatic eradication of cookies when the
        browser closes. If cookies are deactivated, the functions of this
        website may be limited.
      </p>
      <p>
        In the event that third-party cookies are used or if cookies are used
        for analytical purposes, we will separately notify you in conjunction
        with this Data Protection Policy and, if applicable, ask for your
        consent.
      </p>
      <h4>Data processing</h4>
      <p>
        We have concluded a data processing agreement (DPA) with the
        above-mentioned provider. This is a contract mandated by data privacy
        laws that guarantees that they process personal data of our website
        visitors only based on our instructions and in compliance with the GDPR.
      </p>
      <h3>Contact form</h3>
      <p>
        If you submit inquiries to us via our contact form, the information
        provided in the contact form as well as any contact information provided
        therein will be stored by us in order to handle your inquiry and in the
        event that we have further questions. We will not share this information
        without your consent.
      </p>
      <p>
        The processing of these data is based on Art. 6(1)(b) GDPR, if your
        request is related to the execution of a contract or if it is necessary
        to carry out pre-contractual measures. In all other cases the processing
        is based on our legitimate interest in the effective processing of the
        requests addressed to us (Art. 6(1)(f) GDPR) or on your agreement (Art.
        6(1)(a) GDPR) if this has been requested; the consent can be revoked at
        any time.
      </p>
      <p>
        The information you have entered into the contact form shall remain with
        us until you ask us to eradicate the data, revoke your consent to the
        archiving of data or if the purpose for which the information is being
        archived no longer exists (e.g., after we have concluded our response to
        your inquiry). This shall be without prejudice to any mandatory legal
        provisions, in particular retention periods.
      </p>
      <h3>Request by e-mail, telephone, or fax</h3>
      <p>
        If you contact us by e-mail, telephone or fax, your request, including
        all resulting personal data (name, request) will be stored and processed
        by us for the purpose of processing your request. We do not pass these
        data on without your consent.
      </p>
      <p>
        These data are processed on the basis of Art. 6(1)(b) GDPR if your
        inquiry is related to the fulfillment of a contract or is required for
        the performance of pre-contractual measures. In all other cases, the
        data are processed on the basis of our legitimate interest in the
        effective handling of inquiries submitted to us (Art. 6(1)(f) GDPR) or
        on the basis of your consent (Art. 6(1)(a) GDPR) if it has been
        obtained; the consent can be revoked at any time.
      </p>
      <p>
        The data sent by you to us via contact requests remain with us until you
        request us to delete, revoke your consent to the storage or the purpose
        for the data storage lapses (e.g. after completion of your request).
        Mandatory statutory provisions - in particular statutory retention
        periods - remain unaffected.
      </p>
      <h3>Google Forms</h3>
      <p>
        We have integrated Google Forms into this website. The provider is
        Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Ireland
        (hereinafter referred to as „Google”).
      </p>
      <p>
        Google Forms enables us to generate online forms to record messages,
        inquiries and other entries entered by visitors to our website. All
        entries you make will be processed on Google’s servers. Google Forms
        stores a cookie in your browser that contains a unique ID (NID cookie).
        This cookie stores a wide range of information, including, for example
        your language settings.
      </p>
      <p>
        We use Google Forms on the basis of our legitimate interest in
        determining your needs as effectively as possible (Art. 6(1)(f) GDPR).
        If appropriate consent has been obtained, the processing is carried out
        exclusively on the basis of Art. 6(1)(a) GDPR and § 25 (1) TTDSG,
        insofar the consent includes the storage of cookies or the access to
        information in the user’s end device (e.g., device fingerprinting)
        within the meaning of the TTDSG. This consent can be revoked at any
        time.
      </p>
      <p>
        The data you enter into the form will remain in our possession until you
        ask us to delete them, revoke your consent to the archiving of your data
        or until the purpose of archiving the data no longer exists (e.g., upon
        completion of the processing of your inquiry). This does not affect
        mandatory statutory provisions – in particular those governing retention
        periods.
      </p>
      <p>
        For more information, please consult Google’s Data Privacy Policy at
        <a
          href="https://policies.google.com/"
          target="_blank"
          rel="noopener noreferrer"
          >https://policies.google.com/</a
        >.
      </p>
      <h3>Registration on this website</h3>
      <p>
        You have the option to register on this website to be able to use
        additional website functions. We shall use the data you enter only for
        the purpose of using the respective offer or service you have registered
        for. The required information we request at the time of registration
        must be entered in full. Otherwise, we shall reject the registration.
      </p>
      <p>
        To notify you of any important changes to the scope of our portfolio or
        in the event of technical modifications, we shall use the e-mail address
        provided during the registration process.
      </p>
      <p>
        We shall process the data entered during the registration process on the
        basis of your consent (Art. 6(1)(a) GDPR).
      </p>
      <p>
        The data recorded during the registration process shall be stored by us
        as long as you are registered on this website. Subsequently, such data
        shall be deleted. This shall be without prejudice to mandatory statutory
        retention obligations.
      </p>
      <h3>Registration with Google</h3>
      <p>
        Instead of registering directly on this website, you can register with
        Google. The provider of this service is Google Ireland Limited
        (“Google”), Gordon House, Barrow Street, Dublin 4, Ireland.
      </p>
      <p>
        To register with Google, you must only enter your Google name and
        password. Google will identify you and confirm your identity to our
        website.
      </p>
      <p>
        When you sign in with Google, we may be able to use certain information
        in your account to complete your profile with us. You decide whether you
        want this information to be used and if so, which information it is,
        within the framework of your Google security settings, which you can
        find here:
        <a
          href="https://myaccount.google.com/security"
          target="_blank"
          rel="noopener noreferrer"
          >https://myaccount.google.com/security</a
        >
        and
        <a
          href="https://myaccount.google.com/permissions"
          target="_blank"
          rel="noopener noreferrer"
          >https://myaccount.google.com/permissions</a
        >.
      </p>
      <p>
        The data processing associated with Google’s registration is based on
        our legitimate interest in making the registration process as simple as
        possible for our users (Art. 6(1)(f) GDPR). Since the use of the
        registration function is voluntary and the users themselves can decide
        on the respective access options, no conflicting predominant rights of
        the data subjects are apparent.
      </p>
      <h2>5. Plug-ins and Tools</h2>
      <h3>Google Web Fonts</h3>
      <p>
        To ensure that fonts used on this website are uniform, this website uses
        so-called Web Fonts provided by Google. When you access a page on our
        website, your browser will load the required web fonts into your browser
        cache to correctly display text and fonts.
      </p>
      <p>
        To do this, the browser you use will have to establish a connection with
        Google’s servers. As a result, Google will learn that your IP address
        was used to access this website. The use of Google Web Fonts is based on
        Art. 6(1)(f) GDPR. The website operator has a legitimate interest in a
        uniform presentation of the font on the operator’s website. If
        appropriate consent has been obtained, the processing is carried out
        exclusively on the basis of Art. 6(1)(a) GDPR and § 25 (1) TTDSG,
        insofar the consent includes the storage of cookies or the access to
        information in the user’s end device (e.g., device fingerprinting)
        within the meaning of the TTDSG. This consent can be revoked at any
        time.
      </p>
      <p>
        If your browser should not support Web Fonts, a standard font installed
        on your computer will be used.
      </p>
      <p>
        For more information on Google Web Fonts, please follow this link:
        <a
          href="https://developers.google.com/fonts/faq"
          target="_blank"
          rel="noopener noreferrer"
          >https://developers.google.com/fonts/faq</a
        >
        and consult Google’s Data Privacy Declaration under:
        <a
          href="https://policies.google.com/privacy?hl=en"
          target="_blank"
          rel="noopener noreferrer"
          >https://policies.google.com/privacy?hl=en</a
        >.
      </p>
      <h2>6. Online marketing and partner programs</h2>
      <h3>Affiliate Programs on this website</h3>
      <p>
        We participate in affiliate partner programs. In conjunction with
        affiliate partner programs, ads of businesses (advertisers) are placed
        on websites of other enterprises within the affiliate partner network
        (publisher). If you click on one of these affiliate ads, you will be
        transferred to the promoted offer. If you should subsequently engage in
        a certain transaction (conversion), the publisher will receive a
        respective commission in exchange for the service. To be able to compute
        the commission amount, the affiliate network operator must be in a
        position to track the ad that has resulted in you seeing the offer and
        in you completing the predefined transaction. To make this possible,
        cookies or comparable recognition technologies are deployed (e.g.,
        device fingerprinting).
      </p>
      <p>
        Data is stored and analyzed on the basis of Art. 6(1)(f) GDPR. The
        website operator has a legitimate interest in the correct computation of
        its affiliate compensation. If appropriate consent has been obtained,
        the processing is carried out exclusively on the basis of Art. 6(1)(a)
        GDPR and § 25 (1) TTDSG, insofar the consent includes the storage of
        cookies or the access to information in the user’s end device (e.g.,
        device fingerprinting) within the meaning of the TTDSG. This consent can
        be revoked at any time.
      </p>
      <p>We participate in the following affiliate programs:</p>
      <h4>Amazon partner program</h4>
      <p>
        The provider is Amazon Europe Core S.à.r.l. For details, please consult
        Amazon’s Data Privacy Declaration at:
        <a
          href="https://www.amazon.de/gp/help/customer/display.html?nodeId=201909010"
          target="_blank"
          rel="noopener"
          >https://www.amazon.de/gp/help/customer/display.html?nodeId=201909010</a
        >.
      </p>
    </div>

    <!--German-->
    <div v-if="$store.state.user.countryCode == 'DE'" class="container">
      <h1>Datenschutzerklärung</h1>
      <h2>1. Datenschutz auf einen Blick</h2>
      <h3>Allgemeine Hinweise</h3>
      <p>
        Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit
        Ihren personenbezogenen Daten passiert, wenn Sie diese Website besuchen.
        Personenbezogene Daten sind alle Daten, mit denen Sie persönlich
        identifiziert werden können. Ausführliche Informationen zum Thema
        Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten
        Datenschutzerklärung.
      </p>
      <h3>Datenerfassung auf dieser Website</h3>
      <h4>Wer ist verantwortlich für die Datenerfassung auf dieser Website?</h4>
      <p>
        Die Datenverarbeitung auf dieser Website erfolgt durch den
        Websitebetreiber. Dessen Kontaktdaten können Sie dem Abschnitt „Hinweis
        zur Verantwortlichen Stelle“ in dieser Datenschutzerklärung entnehmen.
      </p>
      <h4>Wie erfassen wir Ihre Daten?</h4>
      <p>
        Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese
        mitteilen. Hierbei kann es sich z. B. um Daten handeln, die Sie in ein
        Kontaktformular eingeben.
      </p>
      <h2>2. Hosting</h2>
      <h3>Externes Hosting</h3>
      <p>
        Diese Website wird bei einem externen Dienstleister gehostet (Hoster).
        Die personenbezogenen Daten, die auf dieser Website erfasst werden,
        werden auf den Servern des Hosters gespeichert. Hierbei kann es sich v.
        a. um IP-Adressen, Kontaktanfragen, Meta- und Kommunikationsdaten,
        Vertragsdaten, Kontaktdaten, Namen, Websitezugriffe und sonstige Daten,
        die über eine Website generiert werden, handeln.
      </p>
      <p>
        Der Einsatz des Hosters erfolgt zum Zwecke der Vertragserfüllung
        gegenüber unseren potenziellen und bestehenden Kunden (Art. 6 Abs. 1
        lit. b DSGVO) und im Interesse einer sicheren, schnellen und effizienten
        Bereitstellung unseres Online-Angebots durch einen professionellen
        Anbieter (Art. 6 Abs. 1 lit. f DSGVO). Sofern eine entsprechende
        Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschließlich
        auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG,
        soweit die Einwilligung die Speicherung von Cookies oder den Zugriff auf
        Informationen im Endgerät des Nutzers (z. B. Device-Fingerprinting) im
        Sinne des TTDSG umfasst. Die Einwilligung ist jederzeit widerrufbar.
      </p>
      <p>
        Unser Hoster wird Ihre Daten nur insoweit verarbeiten, wie dies zur
        Erfüllung seiner Leistungspflichten erforderlich ist und unsere
        Weisungen in Bezug auf diese Daten befolgen.
      </p>
      <p>Wir setzen folgenden Hoster ein:</p>
      <p>
        Google Ireland Limited<br />
        Gordon House, Barrow Street<br />
        Dublin 4<br />
        Irland
      </p>
      <h2>3. Allgemeine Hinweise und Pflicht­informationen</h2>
      <h3>Datenschutz</h3>
      <p>
        Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten
        sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und
        entsprechend den gesetzlichen Datenschutzvorschriften sowie dieser
        Datenschutzerklärung.
      </p>
      <h3>Hinweis zur verantwortlichen Stelle</h3>
      <p>
        Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website
        ist:
      </p>
      <p>
        Timetip GmbH<br />
        Angererstrasse, 24<br />
        80796 München
      </p>

      <p>
        <img v-bind:src="require('../assets/ttp.png')" class="phonum" /><br />
        <img v-bind:src="require('../assets/ttm.png')" class="emage" />
      </p>
      <p>
        Verantwortliche Stelle ist die natürliche oder juristische Person, die
        allein oder gemeinsam mit anderen über die Zwecke und Mittel der
        Verarbeitung von personenbezogenen Daten (z. B. Namen, E-Mail-Adressen
        o. Ä.) entscheidet.
      </p>
      <h3>Beschwerde­recht bei der zuständigen Aufsichts­behörde</h3>
      <p>
        Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein
        Beschwerderecht bei einer Aufsichtsbehörde, insbesondere in dem
        Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes oder
        des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht besteht
        unbeschadet anderweitiger verwaltungsrechtlicher oder gerichtlicher
        Rechtsbehelfe.
      </p>
      <h3>SSL- bzw. TLS-Verschlüsselung</h3>
      <p>
        Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung
        vertraulicher Inhalte, wie zum Beispiel Bestellungen oder Anfragen, die
        Sie an uns als Seitenbetreiber senden, eine SSL- bzw.
        TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran,
        dass die Adresszeile des Browsers von „http://“ auf „https://“ wechselt
        und an dem Schloss-Symbol in Ihrer Browserzeile.
      </p>
      <p>
        Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten,
        die Sie an uns übermitteln, nicht von Dritten mitgelesen werden.
      </p>
      <h3>Widerspruch gegen Werbe-E-Mails</h3>
      <p>
        Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten
        Kontaktdaten zur Übersendung von nicht ausdrücklich angeforderter
        Werbung und Informationsmaterialien wird hiermit widersprochen. Die
        Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im
        Falle der unverlangten Zusendung von Werbeinformationen, etwa durch
        Spam-E-Mails, vor.
      </p>
      <h2>4. Datenerfassung auf dieser Website</h2>
      <h3>Cookies</h3>
      <p>
        Unsere Internetseiten verwenden so genannte „Cookies“. Cookies sind
        kleine Textdateien und richten auf Ihrem Endgerät keinen Schaden an. Sie
        werden entweder vorübergehend für die Dauer einer Sitzung
        (Session-Cookies) oder dauerhaft (permanente Cookies) auf Ihrem Endgerät
        gespeichert. Session-Cookies werden nach Ende Ihres Besuchs automatisch
        gelöscht. Permanente Cookies bleiben auf Ihrem Endgerät gespeichert, bis
        Sie diese selbst löschen oder eine automatische Löschung durch Ihren
        Webbrowser erfolgt.
      </p>
      <p>
        Teilweise können auch Cookies von Drittunternehmen auf Ihrem Endgerät
        gespeichert werden, wenn Sie unsere Seite betreten
        (Third-Party-Cookies). Diese ermöglichen uns oder Ihnen die Nutzung
        bestimmter Dienstleistungen des Drittunternehmens (z. B. Cookies zur
        Abwicklung von Zahlungsdienstleistungen).
      </p>
      <p>
        Cookies haben verschiedene Funktionen. Zahlreiche Cookies sind technisch
        notwendig, da bestimmte Websitefunktionen ohne diese nicht funktionieren
        würden (z. B. die Warenkorbfunktion oder die Anzeige von Videos). Andere
        Cookies dienen dazu, das Nutzerverhalten auszuwerten oder Werbung
        anzuzeigen.
      </p>
      <p>
        Cookies, die zur Durchführung des elektronischen Kommunikationsvorgangs,
        zur Bereitstellung bestimmter, von Ihnen erwünschter Funktionen (z. B.
        für die Warenkorbfunktion) oder zur Optimierung der Website (z. B.
        Cookies zur Messung des Webpublikums) erforderlich sind (notwendige
        Cookies), werden auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO
        gespeichert, sofern keine andere Rechtsgrundlage angegeben wird. Der
        Websitebetreiber hat ein berechtigtes Interesse an der Speicherung von
        notwendigen Cookies zur technisch fehlerfreien und optimierten
        Bereitstellung seiner Dienste. Sofern eine Einwilligung zur Speicherung
        von Cookies und vergleichbaren Wiedererkennungstechnologien abgefragt
        wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage dieser
        Einwilligung (Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG); die
        Einwilligung ist jederzeit widerrufbar.
      </p>
      <p>
        Sie können Ihren Browser so einstellen, dass Sie über das Setzen von
        Cookies informiert werden und Cookies nur im Einzelfall erlauben, die
        Annahme von Cookies für bestimmte Fälle oder generell ausschließen sowie
        das automatische Löschen der Cookies beim Schließen des Browsers
        aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität
        dieser Website eingeschränkt sein.
      </p>
      <p>
        Soweit Cookies von Drittunternehmen oder zu Analysezwecken eingesetzt
        werden, werden wir Sie hierüber im Rahmen dieser Datenschutzerklärung
        gesondert informieren und ggf. eine Einwilligung abfragen.
      </p>
      <h4>Auftragsverarbeitung</h4>
      <p>
        Wir haben einen Vertrag über Auftragsverarbeitung (AVV) mit dem oben
        genannten Anbieter geschlossen. Hierbei handelt es sich um einen
        datenschutzrechtlich vorgeschriebenen Vertrag, der gewährleistet, dass
        dieser die personenbezogenen Daten unserer Websitebesucher nur nach
        unseren Weisungen und unter Einhaltung der DSGVO verarbeitet.
      </p>
      <h3>Kontaktformular</h3>
      <p>
        Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre
        Angaben aus dem Anfrageformular inklusive der von Ihnen dort angegebenen
        Kontaktdaten zwecks Bearbeitung der Anfrage und für den Fall von
        Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht ohne
        Ihre Einwilligung weiter.
      </p>
      <p>
        Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1
        lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags
        zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen
        erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf
        unserem berechtigten Interesse an der effektiven Bearbeitung der an uns
        gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer
        Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde;
        die Einwilligung ist jederzeit widerrufbar.
      </p>
      <p>
        Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei uns,
        bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung
        widerrufen oder der Zweck für die Datenspeicherung entfällt (z. B. nach
        abgeschlossener Bearbeitung Ihrer Anfrage). Zwingende gesetzliche
        Bestimmungen – insbesondere Aufbewahrungsfristen – bleiben unberührt.
      </p>
      <h3>Anfrage per E-Mail, Telefon oder Telefax</h3>
      <p>
        Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird Ihre
        Anfrage inklusive aller daraus hervorgehenden personenbezogenen Daten
        (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei uns
        gespeichert und verarbeitet. Diese Daten geben wir nicht ohne Ihre
        Einwilligung weiter.
      </p>
      <p>
        Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1
        lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags
        zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen
        erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf
        unserem berechtigten Interesse an der effektiven Bearbeitung der an uns
        gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer
        Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde;
        die Einwilligung ist jederzeit widerrufbar.
      </p>
      <p>
        Die von Ihnen an uns per Kontaktanfragen übersandten Daten verbleiben
        bei uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur
        Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt
        (z. B. nach abgeschlossener Bearbeitung Ihres Anliegens). Zwingende
        gesetzliche Bestimmungen – insbesondere gesetzliche Aufbewahrungsfristen
        – bleiben unberührt.
      </p>
      <h3>Google Forms</h3>
      <p>
        Wir haben Google Forms auf dieser Website eingebunden. Anbieter ist die
        die Google Ireland Limited, Gordon House, Barrow Street, Dublin 4,
        Irland (nachfolgend Google).
      </p>
      <p>
        Google Forms ermöglicht es uns, Online-Formulare zu erstellen um
        Nachrichten, Anfragen und sonstige Eingaben unserer Websitebesucher
        strukturiert zu erfassen. Alle von Ihnen getätigten Eingaben werden auf
        den Servern von Google verarbeitet. Google Forms speichert ein Cookie in
        Ihrem Browser, das eine eindeutige ID enthält (NID-Cookie). Dieses
        Cookie speichert verschiedene Informationen wie beispielsweise Ihre
        Spracheinstellungen.
      </p>
      <p>
        Der Einsatz von Google Forms erfolgt auf Grundlage unseres berechtigten
        Interesses an einer möglichst nutzerfreundlichen Ermittlung Ihres
        Anliegens (Art. 6 Abs. 1 lit. f DSGVO). Sofern eine entsprechende
        Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschließlich
        auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG,
        soweit die Einwilligung die Speicherung von Cookies oder den Zugriff auf
        Informationen im Endgerät des Nutzers (z. B. Device-Fingerprinting) im
        Sinne des TTDSG umfasst. Die Einwilligung ist jederzeit widerrufbar.
      </p>
      <p>
        Die von Ihnen im Formular eingegebenen Daten verbleiben bei uns, bis Sie
        uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung
        widerrufen oder der Zweck für die Datenspeicherung entfällt (z. B. nach
        abgeschlossener Bearbeitung Ihrer Anfrage). Zwingende gesetzliche
        Bestimmungen – insbesondere Aufbewahrungsfristen – bleiben unberührt.
      </p>
      <p>
        Weitere Informationen entnehmen Sie der Datenschutzerklärung von Google
        unter
        <a
          href="https://policies.google.com/"
          target="_blank"
          rel="noopener noreferrer"
          >https://policies.google.com/</a
        >.
      </p>
      <h3>Registrierung auf dieser Website</h3>
      <p>
        Sie können sich auf dieser Website registrieren, um zusätzliche
        Funktionen auf der Seite zu nutzen. Die dazu eingegebenen Daten
        verwenden wir nur zum Zwecke der Nutzung des jeweiligen Angebotes oder
        Dienstes, für den Sie sich registriert haben. Die bei der Registrierung
        abgefragten Pflichtangaben müssen vollständig angegeben werden.
        Anderenfalls werden wir die Registrierung ablehnen.
      </p>
      <p>
        Für wichtige Änderungen etwa beim Angebotsumfang oder bei technisch
        notwendigen Änderungen nutzen wir die bei der Registrierung angegebene
        E-Mail-Adresse, um Sie auf diesem Wege zu informieren.
      </p>
      <p>
        Die Verarbeitung der bei der Registrierung eingegebenen Daten erfolgt
        zum Zwecke der Durchführung des durch die Registrierung begründeten
        Nutzungsverhältnisses und ggf. zur Anbahnung weiterer Verträge (Art. 6
        Abs. 1 lit. b DSGVO).
      </p>
      <p>
        Die bei der Registrierung erfassten Daten werden von uns gespeichert,
        solange Sie auf dieser Website registriert sind und werden anschließend
        gelöscht. Gesetzliche Aufbewahrungsfristen bleiben unberührt.
      </p>
      <h3>Registrierung mit Google</h3>
      <p>
        Statt einer direkten Registrierung auf dieser Website können Sie sich
        mit Google registrieren. Anbieter dieses Dienstes ist die Google Ireland
        Limited („Google”), Gordon House, Barrow Street, Dublin 4, Irland.
      </p>
      <p>
        Um sich mit Google zu registrieren, müssen Sie ausschließlich Ihre
        Google-Namen und Ihr Passwort eingeben. Google wird Sie identifizieren
        und unserer Website Ihre Identität bestätigen.
      </p>
      <p>
        Wenn Sie sich mit Google anmelden, ist es uns ggf. möglich bestimmte
        Informationen auf Ihrem Konto zu nutzen, um Ihr Profil bei uns zu
        vervollständigen. Ob und welche Informationen das sind, entscheiden Sie
        im Rahmen Ihrer Google-Sicherheitseinstellungen, die Sie hier finden:
        <a
          href="https://myaccount.google.com/security"
          target="_blank"
          rel="noopener noreferrer"
          >https://myaccount.google.com/security</a
        >
        und
        <a
          href="https://myaccount.google.com/permissions"
          target="_blank"
          rel="noopener noreferrer"
          >https://myaccount.google.com/permissions</a
        >.
      </p>
      <p>
        Die Datenverarbeitung, die mit der Google-Registrierung einhergeht
        beruht auf unserem berechtigten Interesse, unseren Nutzern einen
        möglichst einfachen Registrierungsprozess zu ermöglichen (Art. 6 Abs. 1
        lit. f DSGVO). Da die Nutzung der Registrierungsfunktion freiwillig ist
        und die Nutzer selbst über die jeweiligen Zugriffsmöglichkeiten
        entscheiden können, sind keine entgegenstehenden überwiegenden Rechte
        der Betroffenen ersichtlich.
      </p>
      <h2>5. Plugins und Tools</h2>
      <h3>Google Web Fonts</h3>
      <p>
        Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so
        genannte Web Fonts, die von Google bereitgestellt werden. Beim Aufruf
        einer Seite lädt Ihr Browser die benötigten Web Fonts in ihren
        Browsercache, um Texte und Schriftarten korrekt anzuzeigen.
      </p>
      <p>
        Zu diesem Zweck muss der von Ihnen verwendete Browser Verbindung zu den
        Servern von Google aufnehmen. Hierdurch erlangt Google Kenntnis darüber,
        dass über Ihre IP-Adresse diese Website aufgerufen wurde. Die Nutzung
        von Google WebFonts erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f
        DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an der
        einheitlichen Darstellung des Schriftbildes auf seiner Website. Sofern
        eine entsprechende Einwilligung abgefragt wurde, erfolgt die
        Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO
        und § 25 Abs. 1 TTDSG, soweit die Einwilligung die Speicherung von
        Cookies oder den Zugriff auf Informationen im Endgerät des Nutzers
        (z. B. Device-Fingerprinting) im Sinne des TTDSG umfasst. Die
        Einwilligung ist jederzeit widerrufbar.
      </p>
      <p>
        Wenn Ihr Browser Web Fonts nicht unterstützt, wird eine Standardschrift
        von Ihrem Computer genutzt.
      </p>
      <p>
        Weitere Informationen zu Google Web Fonts finden Sie unter
        <a
          href="https://developers.google.com/fonts/faq"
          target="_blank"
          rel="noopener noreferrer"
          >https://developers.google.com/fonts/faq</a
        >
        und in der Datenschutzerklärung von Google:
        <a
          href="https://policies.google.com/privacy?hl=de"
          target="_blank"
          rel="noopener noreferrer"
          >https://policies.google.com/privacy?hl=de</a
        >.
      </p>
      <h2>6. Online-Marketing und Partner­programme</h2>
      <h3>Affiliate-Programme auf dieser Website</h3>
      <p>
        Wir nehmen an Affiliate-Partnerprogrammen teil. Bei
        Affiliate-Partner-Programmen werden Werbeanzeigen eines Unternehmens
        (Advertiser) auf Webseiten von anderen Unternehmen des
        Affiliate-Partner-Netzwerks (Publisher) platziert. Wenn Sie auf eine
        dieser Affiliate-Werbeanzeigen klicken, werden Sie zum beworbenen
        Angebot weitergeleitet. Sollten Sie anschließend eine bestimmte
        Transaktion (Conversion) tätigen, erhält der Publisher hierfür eine
        Vergütung. Zur Berechnung dieser Vergütung ist es erforderlich, dass der
        Affiliate-Netzwerkbetreiber nachvollziehen kann, über welche
        Werbeanzeige Sie auf das jeweilige Angebot gekommen sind und die
        vordefinierte Transaktion vorgenommen haben. Hierfür werden Cookies oder
        vergleichbare Wiedererkennungstechnologien (z. B. Device-Fingerprinting)
        eingesetzt.
      </p>
      <p>
        Die Speicherung und Analyse der Daten erfolgt auf Grundlage von Art. 6
        Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse
        an der korrekten Berechnung seiner Affiliate-Vergütung. Sofern eine
        entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung
        ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25
        Abs. 1 TTDSG, soweit die Einwilligung die Speicherung von Cookies oder
        den Zugriff auf Informationen im Endgerät des Nutzers (z. B.
        Device-Fingerprinting) im Sinne des TTDSG umfasst. Die Einwilligung ist
        jederzeit widerrufbar.
      </p>
      <p>Wir nehmen an folgenden Affiliate-Programmen teil:</p>
      <h4>Amazon Partner­programm</h4>
      <p>
        Anbieter ist die Amazon Europe Core S.à.r.l. Details entnehmen Sie der
        Datenschutzerklärung von Amazon unter:
        <a
          href="https://www.amazon.de/gp/help/customer/display.html?nodeId=201909010"
          target="_blank"
          rel="noopener noreferrer"
          >https://www.amazon.de/gp/help/customer/display.html?nodeId=201909010</a
        >.
      </p>
    </div>
  </div>
</template>

  <!-- ---------- ---------- ---------- Code ---------- ---------- ---------- -->

<script>
/* eslint-disable */

export default {
  mounted() {},
  components: {},
  methods: {},
};
</script>
}
<style scoped>
.container {
  margin-top: 1rem;
  margin-bottom: 2rem;
  text-align: left;
  word-break: break-word;
}
.link {
  display: block;
  word-break: break-word;
}
.emage {
  height: 1rem;
}
.phonum {
  height: 1rem;
}
</style>