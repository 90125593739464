/* eslint-disable */
import store from '@/store/index.js'
import UpdateActivitiesService from "@/services/UpdateActivitiesService.js";

export default {
  resetFilters() {
    console.log("resetFilters is executed")

    for (const [key, value] of Object.entries(store.state.filters)) {
      value.forEach(function (filterObject) {
        filterObject.selected = false;
        filterObject.lastAnswerWithValues = false
      })
    }
    store.state.clickedAnswers = {}


    store.state.timeActive = false
    store.state.timeSliderValueMin = 0
    store.state.timeSliderValueMax = 6
    store.state.costActive = false
    store.state.costSliderValueMin = 1
    store.state.costSliderValueMax = 4

    if (!store.state.ageSave) {
      store.state.ageActive = false
      store.state.ageChildrenParentsSwitch = true;
      store.state.ageRangeSliderSwitch = true;
    }
    UpdateActivitiesService.updateActivities();
    store.state.storeSelectedActivityId = store.state.storeActivityList[0].id
    store.state.activity = store.state.storeActivityList[0]
  }
}