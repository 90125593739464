/* eslint-disable */

import axios from 'axios'
import store from '@/store/index.js'
import GetActivitiesService from "@/services/GetActivitiesService.js";

export default {
  getFilters() {
    console.log("getFilters is executed")

    var filterList = []

    return axios.get('/data.json')
      .then(response => {
        // I actually do not need the "return" here. Remove it and exchange {} with ()
        return parseData(response.data.filterlist) // executes function parseData with the input response.data.feed.entry (CB)
      })

    function parseData(entries) {
      for (const [key, value] of Object.entries(entries)) {
        if (key != "") {
          var entry = {
            filtercategory: value.filtercategory,
            filterkey: value.filterkey,
            filtercolor: value.filtercolor,
            filtercount: value.filtercount,

            svg: value.svg,

            filterquestionen: value.filterquestionen,
            filternameen: value.filternameen,
            filterquestionde: value.filterquestionde,
            filternamede: value.filternamede,

            filterstatus: value.filterstatus,
          };
          filterList.push(entry);
        }
      };

      //console.log(filterList)

      var filters = new Object()
      var questionSet = new Set();


      // Create a Set of all filter categories
      filterList.forEach(function (x) {
        if (x != "") {
          questionSet.add(x.filtercategory)
        }
      });

      // For all Filter categories ("interests","targets","sports","games" ...)
      questionSet.forEach(function (entry) {
        filters[entry] = []

        var i = 0
        filterList.forEach(function (x) {
          if (x.filtercategory == entry) {
            var helper = new Object();
            // TODO delete helper.name, if no error occurred
            // At first it was "name" everywhere and then I substituted it with filterkey. This might not have been done correctly at some places, so do not delete name
            //helper.name = x.filterkey;
            helper.filterkey = x.filterkey;
            helper.nameen = x.filternameen;
            helper.namede = x.filternamede;
            helper.svg = x.svg;
            helper.question = x.filterquestionen;
            helper.questionde = x.filterquestionde;
            helper.count = 0;
            helper.parent = entry;
            helper.color = x.filtercolor;
            helper.selected = false;
            helper.id = i;
            if (i == 0) { helper.drawerActive = false }
            filters[entry][i] = helper;
            i++
          }
        });

      })
      console.log("store.state.filters")
      console.log(filters)
      store.state.filters = filters

      // I do not need the "return" here for the .then function
      return Promise.resolve(filters)
    }
  }
}